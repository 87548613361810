import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { FaTrash, FaTrashRestore } from "react-icons/fa"

export const ApplicationStatus = ({ appStatus, appDataPath }) => {
    return <>
        <div className="d-flex w-100 justify-content-between mt-1">
            <span>Application status: <Status appStatus={appStatus} /></span>
            <WithdrawToggle appStatus={appStatus} appDataPath={appDataPath} />
        </div>
    </>
}



const Status = ({ appStatus }) => {
    if (appStatus === "withdrawn") {
        return <span className="badge text-bg-danger">Withdrawn</span>
    }
    if (appStatus === "complete") {
        return <span className="badge text-bg-success">Complete</span>
    }

    if (appStatus === "notStarted") {
        return <span className="badge text-bg-secondary">Not started</span>
    }

    // incomplete is formatted as "incomplete (nComplete/nChecks)"
    if (appStatus.startsWith("incomplete")) {
        // const nCompleteData = appStatus.match(/\((\d+)\/(\d+)\)/)
        // const nTodo = nCompleteData[2] - nCompleteData[1]
        // const nComplete = nCompleteData[1]
        return <span className="badge text-bg-secondary">Incomplete</span>
    }

    return <span className="badge text-bg-secondary">{appStatus}</span>
}

const WithdrawToggle = ({ appStatus, appDataPath }) => {
    const btnText = appStatus === "withdrawn"
        ? <><FaTrashRestore /> Undo Withdraw</>
        : <><FaTrash /> Withdraw</>
    const db = getFirestore();
    const appDocRef = doc(db, appDataPath);

    async function onClick() {
        const oldState = appStatus === "withdrawn"
        const newState = !oldState
        await setDoc(appDocRef, {
            withdrawn: newState
        }, { merge: true });

    }
    return <button onClick={() => onClick()} className="btn btn-light btn-sm">{btnText}</button>
}

