export const tiptapBookStyles = `.book {
  .tiptap *::selection {
    background-color: #6FC3FF
  }

  .tiptapWrapper {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .editor {
    padding: 0px !important;
    padding-top: 10px !important;
  }

  .editableWrapper {
  }

  .tiptapContentWrapper {
    background-color: white;
    overflow: visible;
    flex-grow: 2;
    padding-left: 79px;
    padding-right: 79px;
    padding-bottom: 60px;
    font-family: "Times New Roman", Times, serif;

    @media (max-width: 1100px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }



  .editor {
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    border-radius: 0px 0px 5px;

  }

  .ProseMirror-focused {
    outline: none;
  }

  .ProseMirror {
    height: 100%;

    p {
      margin: 0px;
    }

    img {
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    /* Override padding for images inside a .borderedbox */
    .bordered-box img {
      padding-left: 0;
      padding-right: 0;
    }

    .purple-box img {
      padding-left: 0;
      padding-right: 0;
    }

    blockquote {
      border-left: 3px solid rgba(#0d0d0d, 0.1);
      padding-left: 1.2rem;
      margin-left: 10px;
      margin-top: 20px;
    }


  }

  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
  }


  .react-component-with-content {
    background: #faf594;
    border: 3px solid #0d0d0d;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;

    .label {
      margin-left: 1rem;
      background-color: #0d0d0d;
      font-size: 0.6rem;
      letter-spacing: 1px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      position: absolute;
      top: 0;
      padding: 0.25rem 0.75rem;
      border-radius: 0 0 0.5rem 0.5rem;
    }

    .content {
      margin: 2.5rem 1rem 1rem;
      padding: 0.5rem;
      border: 2px dashed #0d0d0d20;
      border-radius: 0.5rem;
    }
  }

  .editor>div {
    height: 100%;
  }

  .mathjax {
    display: block;
    margin: 1rem 0;
    padding: 1rem;
    background: #f8f8fa;
    border-radius: 5px;
  }

  .bordered-box {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 20px 0;
    background-color: white;
  }

  .purple-box {
    border-radius: 5px;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 20px 0;
    background-color: #eceef8;
  }

  .vertical-space {
    height: 5px;
    width: 100%;
    background-color: red;
  }

  .tiptap>div>h1 {
    font-weight: 300;
    padding-left: 10px;
    margin-top: 25px;
  }

  .tiptap>div>h2 {
    font-size: 1.8rem;
    font-weight: 300;
    padding-left: 10px;
    margin-top: 35px;
  }

  .tiptap>div>h3 {
    font-size: 1.5rem;
    font-weight: 300;
    padding-left: 10px;
    margin-top: 30px;
  }

  .tiptap h4 {
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 500;
  }

  .tiptap h3 {
    padding-left: 10px;
  }

  .tiptap h2 {
    padding-left: 10px;
  }

  .tiptap h1 {
    padding-left: 10px;
  }

  .tiptap .bordered-box h3 {
    font-size: 1.5rem;
    font-weight: 300;
  }


  .tiptap>pre {
    margin-left: 10px;
    background-color: #f5f2f0;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.8rem;
  }


  .tiptap>p {
    margin-block-end: 16px;
    text-wrap-mode: normal;
    overflow-wrap: normal;
    margin-left: 10px;
    margin-right: 10px;
  }

  .tiptap p {
    margin-bottom: 10px;
    text-align: justify;
  }

  .tiptap li p {
    margin-bottom: 5px;
  }


  .tiptap>.node-interactive-demo {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .indented {
    padding-left: 10px;
    padding-right: 10px;
  }

  .align-left {
    margin-left: 0px !important;
  }

  .align-left .katex {
    text-align: left !important;
  }

  .align-right .katex {
    text-align: right !important;
  }
}
`;
