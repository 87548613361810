import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { getLocalUTCTimezone, getSectionTimeStr } from "components/timezones/timezoneHelper"
import { ProfileContext } from "contexts/ProfileContext"
import { CourseContext } from "contexts/CourseContext"
import { functions } from "firebaseApp"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import Swal from "sweetalert2"
import { collection, getFirestore, query, where } from "firebase/firestore"
import { useCollection } from "react-firebase-hooks/firestore"
import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import type { DateTimeFormatOptions } from "luxon";

export const SectionSwitch = () => {
  const courseId = useCourseId()
  const { getSectionsSnapshot } = useContext(CourseContext)

  const sectionRef = collection(getFirestore(), "sections")
  const sectionQuery = query(sectionRef, where("courseId", "==", courseId));
  const [sectionCollection, sectionsLoading, sectionError] = useCollection(sectionQuery)


  if (sectionsLoading) return <>Looking for availible sections...</>

  console.log(sectionCollection)
  
  return <CoursePageSingleCol
    column = {<SectionSwitchView sectionCollection = {sectionCollection}/>}
  />
}

export const SectionSwitchView = ({ sectionCollection }) => {

  console.log(sectionCollection)
  // represented in timeIndex
  const [currSelection, setCurrSelection] = useState(null)
  const courseId = useCourseId()
  const { userData } = useContext(ProfileContext)
  const isUserMinor = userData?.isMinor || false
  console.log('isUserMinor', isUserMinor)
  const sectionMap = filterSections(sectionCollection, isUserMinor)
  const sizeMap = getSizeMap(sectionMap)
  const timeMap = getTimeMap(sectionMap)
  console.log(sectionMap)
  console.log(sizeMap)
  console.log(timeMap)
  const availibleTimes = findAvailibleTimes(sizeMap, timeMap)
  const userTz = getLocalUTCTimezone()

  const makeChangeSectionRequest = async () => {
    // 0 is a valid selection
    if (currSelection == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You must select a new section time before submitting!',
      })
    }
    // we actually do the heavy lifting of selecting the section here
    const options = timeMap[currSelection]
    const newSectionId = choseSection(options, sizeMap)
    // make a server request to function changeSection

    const changeSectionFn = functions.httpsCallable('changeSection');

    Swal.fire({
      title: 'Processing change...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    })


    try {
      const response = await changeSectionFn({ 
        sectionId:newSectionId, 
        courseId:courseId 
      });
      const responseData = response.data

      console.log(responseData)
      if ('error' in responseData) {
        Swal.fire({
          title: 'Error',
          text: `${responseData.error}`,
          icon: 'error',
        })
        return
      }
  
      Swal.fire({
        title: 'Success!',
        text: `You have successfully changed your section time!`,
        icon: 'success',
      })
    } catch(e) {
      Swal.fire({
        title: 'Error',
        text: `Unable to change section: ${e}`,
        icon: 'error',
      })
    }
    
    
  }


  const dropdownLabel = (currSelection != null) ? getSectionTimeStr(userTz, currSelection) : "Select a New Section Time"
  return <div className="mt-3">
    <div>
      <h1>Section Time Change</h1>
      <p>Sometimes scheduling changes are necessary. We would like to help you find a new section time. This is a permanent switch. If you can not make a for a single week, instead you should watch the recorded section.</p>
      
      <div className="alert alert-warning"> <b>Caution:</b> To help us maintain a stable system, students can only change sections <b>two times</b> during the course. We  can not support exceptions to this at scale, so be careful when changing times!</div>
      <div className="mt-4" style={{ margin: "auto" }}>
        <ShowLocalTime />
      </div>
      <div className="mt-4" style={{ margin: "auto" }}>
        <h3>Chose a New Section Time</h3>
        <p>You will attend a 50 minute section once a week for 6 weeks. The first section of the course will be the week of April 22nd, and your last section will be the week of May 27th. First select an availible time:</p>
        <div>
          <Dropdown >
            <Dropdown.Toggle size={"lg"} variant="outline-primary" id="dropdown-basic">
              {dropdownLabel}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {availibleTimes.map((time) => {
                const timeInt = safeParse(time)
                const timeStr = getSectionTimeStr(userTz, timeInt)
                return <Dropdown.Item key={time} onClick={() => setCurrSelection(timeInt)}>{timeStr}</Dropdown.Item>
              })}
            </Dropdown.Menu>
          </Dropdown>
          <hr />
          <button onClick={() => makeChangeSectionRequest()} className="mt-3 btn btn-primary btn-lg" disabled={currSelection == null}>Change My Section Time</button>


        </div>
      </div>
    </div>
  </div>
}

const filterSections = (sectionCollection, isUserMinor) => {
  const sectionMap = {}
  for (const sectionDoc of sectionCollection.docs) {
    const section = sectionDoc.data()
    console.log(section)
    if (!section) continue
    if (!section.isActive) continue
    const sectionSize = section?.members?.length || 0
    if (sectionSize == 0) continue
    if (!section.timeIndex || section.timeIndex == "none" || section.timeIndex == "None"){
      continue
    } 

    // critically important: filter out all sections that don't have a match on
    // minor / not minor
    const isSectionMinor = section.isMinor || false
    if (isSectionMinor != isUserMinor) continue
    sectionMap[sectionDoc.id] = section
  }
  return sectionMap
}

const findAvailibleTimes = (sizeMap, timeMap) => {
  const maxSize = 14
  const availibleTimes = []
  for (const time in timeMap) {
    const sectionIds = timeMap[time]
    for (const sectionId of sectionIds) {
      const sectionSize = sizeMap[sectionId]
      if (sectionSize + 1 < maxSize) {
        availibleTimes.push(time)
        break
      }
    }
  }
  return availibleTimes
}

const getTimeMap = (sectionMap) => {
  // maps from a time to a list of section ids
  // size map already has a reduced set of sections
  const timeMap = {}
  for (const sectionId in sectionMap) {
    const section = sectionMap[sectionId]
    const sectionTime = safeParse(section.timeIndex)
    if (!timeMap[sectionTime]) {
      timeMap[sectionTime] = []
    }
    timeMap[sectionTime].push(sectionId)
  }
  return timeMap
}

const getSizeMap = (sectionMap) => {
  const sizeMap = {}
  for (const sectionId in sectionMap) {
    const section = sectionMap[sectionId]
    const sectionSize = section.members.length
    sizeMap[sectionId] = sectionSize

  }
  return sizeMap
}

const choseSection = (options, sizeMap) => {
  // randomly chose among the smallest sections
  const minSize = Math.min(...options.map((sectionId) => sizeMap[sectionId]))
  const smallestSections = options.filter((sectionId) => sizeMap[sectionId] == minSize)
  console.log(smallestSections)
  const randomChoiceIndex = Math.floor(Math.random() * smallestSections.length)
  const chosenSectionId = smallestSections[randomChoiceIndex]
  return chosenSectionId

}

function safeParse(maybeStr) {
  if (typeof maybeStr === 'number') {
    return maybeStr;
  } else {
    return parseInt(maybeStr);
  }
}

const ShowLocalTime = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const options: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const localTime = time.toLocaleString(navigator.language, options);
  const timeZone = getLocalUTCTimezone();
  const timeZoneLink = `https://dayspedia.com/time/zones/${timeZone.toLowerCase()}/`
  return <div className="">
    <h3>Verify your clock is correct</h3>
    <div className="">
      The current time on your computer is <div style={{ fontSize: 18, paddingLeft: 8 }} className='badge bg-primary'>{localTime}</div> and your local timezone is <a target="_blank" href={timeZoneLink}>{timeZone}</a>.
      If that is <b>not</b> the correct time, you must fix your computer time, or manually convert time zones.
      {" "}<a target="_blank" href="https://support.microsoft.com/en-us/windows/how-to-set-your-time-and-time-zone-dfaa7122-479f-5b98-2a7b-fa0b6e01b261">PC Instructions</a>.
      {" "}<a target="_blank" href="https://support.apple.com/guide/mac-help/set-the-date-and-time-automatically-mchlp2996/">Mac Instructions</a>.
      {" "}⚠️ Times reported in this form and on this website will be with respect to this computer time (unless otherwise stated).
    </div>
  </div>
};