// StudentFeedback.js
import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {
  submitGratitude,
  updateStudentComment,
  useRoomMembers,
} from '../firebase/realtime/Rooms';
// import { updateKarma } from 'course/PeerLearning/KarmaUtils';
import {useUserId} from 'hooks/user/useUserId';

export const StudentFeedback = ({show, onClose, roomId}) => {
  const {courseId} = useParams();
  const userId = useUserId();
  const roomMembers = useRoomMembers(courseId, roomId);

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [comment, setComment] = useState('');

  /**
   * Send a simple "thanks" or "not now" feedback.
   * If "thanks" is chosen, we update karma and reveal the comment box.
   * TODO: fix the karma - right now the rules are broken so JW commented it out
   * If "not now," we just close the modal.
   * In either case - we submit a 1 for thanks and a 0 for not now
   */
  const sendFeedbackStudent = async val => {
    try {
      await submitGratitude(val, roomId, userId, courseId);
      if (val === 1) {
        // await updateKarma(courseId, userId, roomMembers, 'gratitude');
        setShowCommentBox(true);
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Error submitting student feedback:', error);
    }
  };

  /**
   * Submit an optional comment about an exceptional session.
   * After submission, reset the comment box and close the modal.
   */
  const sendMessage = async () => {
    if (!comment.trim()) return;
    try {
      await updateStudentComment(comment, roomId, userId, courseId);
      setShowCommentBox(false);
      setComment('');
      onClose();
    } catch (error) {
      console.error('Error updating student comment:', error);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      {/* If you'd prefer no title or close button, remove this header. */}
      <Modal.Header closeButton>
        <Modal.Title>Session Feedback</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!showCommentBox && (
          <>
            <h5 className="mb-3">
              Would you like to send thanks to the people you worked with?
            </h5>
            <div className="d-flex justify-content-center mb-3">
              <Button
                variant="primary"
                className="mr-2"
                onClick={() => sendFeedbackStudent(1)}
              >
                Send thanks!
              </Button>
              <Button
                variant="secondary"
                onClick={() => sendFeedbackStudent(0)}
              >
                Not now
              </Button>
            </div>
            <div className="text-center mt-3">
              If you want to report anything about this experience to the course
              staff,{' '}
              <a
                href="https://forms.gle/maUVMNnTZLL86UXf7"
                target="_blank"
                rel="noopener noreferrer"
              >
                fill out this form
              </a>
              .
            </div>
          </>
        )}

        {showCommentBox && (
          <>
            <h5 className="mb-3 text-center">
              Had an exceptional session? Send a message to the section leader!
            </h5>
            <textarea
              className="form-control"
              placeholder="Describe what made it exceptional..."
              value={comment}
              onChange={e => setComment(e.target.value)}
              rows={3}
            />
            <div className="mt-3 d-flex justify-content-end">
              <Button variant="primary" onClick={sendMessage}>
                Send message
              </Button>
            </div>
            <div className="text-center mt-3">
              If you want to report anything about this experience to the course
              staff,{' '}
              <a
                href="https://forms.gle/maUVMNnTZLL86UXf7"
                target="_blank"
                rel="noopener noreferrer"
              >
                fill out this form
              </a>
              .
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StudentFeedback;
