import { LandingNav } from "landing/components/LandingNav"
import { Takeaways } from "./Takeaways";
import { TeachQuotes } from "./TeachQuotes";
import { FAQ } from "./FAQ";
import { useCourseId } from "../../../../hooks/router/useUrlParams";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUserId } from "hooks/user/useUserId";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Responsibilities } from "./Responsibilities";
import { JobQualifications } from "./JobQualifications";
import { useEffect, useState } from "react";
import styled from "styled-components";
export const Explore = () => {
    return <div>
        <LandingNav applicationType={"sectionLeader"} subpage={"About the job"}/>
        
        <Details />
    </div>
}

const Details = () => {

    return (
     <div>
        <Takeaways />
        <TeachQuotes/>
      
        
        <div className="d-flex flex-row justify-content-center w-100">
          <div style={{padding:'10px'}}>
            <div style={{maxWidth:'700px'}} id="fp-7">
            <Responsibilities />
            <div style={{height:'20px'}} id="fp-8"/>
            <JobQualifications/>
            <div style={{height:'20px'}} id="fp-9"/>
            <FAQ/>
            <div style={{height:'20px'}} id="fp-10"/>
            <AgreeButton/>
            </div>
          </div>

        </div>
      </div>
    );
  }

const AgreeButton = () => {
  const navigate = useNavigate();
  const userId = useUserId()
  const {targetCourseId} = useParams()
  const firebasePath = `users/${userId}/${targetCourseId}/sectionLeaderApplication`
  const db = getFirestore();
  const docRef = doc(db, firebasePath);
  const [didHitResponsibilities, setDidHitResponsibilities] = useState(false);

  const [didHitBottom, setDidHitBottom] = useState(false);
  const [btnText, setBtnText] = useState("Keep Scrolling");

  async function onClick() {

    await setDoc(docRef, {hasReadAboutJob: true}, { merge: true });
    navigate(`/public/applyteach/${targetCourseId}`)
    return;

  }

  useEffect(() => {
    // listen to see if we hit the bottom
    function handleScroll() {
      if(!document.getElementById("fp-7")) return;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      const bottom = scrollHeight - scrollTop - clientHeight;
      // check if past fp-7

      if (bottom < 300) {
        setDidHitBottom(true);
        setDidHitResponsibilities(true);
        setBtnText("Mark Complete");
      } else if (bottom < document.getElementById("fp-7").offsetHeight) {
        setDidHitResponsibilities(true);
        setDidHitBottom(false);

        setBtnText("Keep Scrolling");
      } else {
        setDidHitResponsibilities(false);
        setDidHitBottom(false);
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])


  return  <AgreeButtonButton
      onClick={onClick}
      className={`btn btn-success btn-lg ${!didHitResponsibilities ? "d-none" : ""}`}
      disabled={!didHitBottom}
    >
      {btnText}
    </AgreeButtonButton>

}


export const NextButton = () => {
  const userId = useUserId()
  const {targetCourseId} = useParams()
  const firebasePath = `users/${userId}/${targetCourseId}/sectionLeaderApplication`
  const db = getFirestore();
  const docRef = doc(db, firebasePath);
  
  const scrollToList = [
    "fp-1",
    "fp-2",
    "fp-3",
    "fp-4",
    "fp-5",
    "fp-6",
    "fp-7",
    "fp-8",
  ]


  async function onClick() {

    // find closest div in scrollToList thats below the current scroll position
    let closestDiv = null;
    let closestDistance = null;

    for (let i = 0; i < scrollToList.length; i++) {
      const id = scrollToList[i];
      const div = document.getElementById(id);
      if (!div) return;
      const divTop = div.getBoundingClientRect().top - 57;
      // const topDelta = divTop - currentScroll;
      if (divTop > 100 && (!closestDistance || divTop < closestDistance)) {
        closestDistance = divTop;
        closestDiv = div;
        break;
      }
    }


    if (!closestDiv) return;
    window.scrollTo({
      top: closestDiv.offsetTop - 57,
      behavior: 'smooth'
    });

  }


  return  <NextButtonButton
      onClick={onClick}
      className={`btn btn-primary btn-lg`}
    >
      Next
    </NextButtonButton>

}

// Fix in the bottom middle
export const AgreeButtonButton = styled.button`
  position: fixed;
  bottom: 40px;
  left: calc(50% - 60px);
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: opacity 0.2s;
  z-index: 1000;
`;

export const NextButtonButton = styled.button`
  position: absolute;
  left: calc(50% - 45px);
  bottom: 50px;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: opacity 0.2s;
  z-index: 1000;
`;