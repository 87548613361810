import { doc, getFirestore } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"
import SplitPane from "react-split-pane"
import { BottomNav } from "review/components/BottomNav"
import { useUploadedVideo } from "course/application/sectionLeaderApplication/teachingDemo/useUploadedVideo"
import { GradingPane } from "./GradingPane"
import { FlagWithOverlay } from "components/countries/LocationInfo"
import { useContext } from "react"
import { ReviewContext } from "review/contexts/ReviewContext"
import { Loading } from "components/loading/Loading"

export const ReviewApplicant = () => {
  const userId = useParams().userId
  const { reviewLoading } = useContext(ReviewContext)

  if (reviewLoading) {
    return <Loading />
  }

  return <div style={{height:'100vh'}}><SplitPane split="horizontal" 
  allowResize={false} 
  primary="second"
  overflow="auto"
  defaultSize={54}>
    <ReviewApplicantSafe key={userId}/>
    <BottomNav />
    </SplitPane></div>
}

const ReviewApplicantSafe = () => {
  const userId = useParams().userId
  const courseId = useCourseId()

  const db = getFirestore();
  const appDocRef = doc(db, `users/${userId}/${courseId}/sectionLeaderApplication`)

  const [appData, appDataLoading, appDataError] = useDocumentData(appDocRef);
  const [videoUrl, videoLoading, videoError] = useUploadedVideo(userId)
  console.log(appDataError)

  if (appDataLoading || videoLoading) {
    return <></>
  }

  const props = {appData, videoUrl}

  return <SplitPane
  size={400}
  minSize={400}
  primary="second"
>
  <ApplicationPane {...props} />
  <GradingPane/>
</SplitPane>
}

export const ApplicationPane = (props) => {
  const {
    name,
    country,
    currentOccupation,
    previousCodingExperience,
    previousTeachingExperience,
    pythonDebug,
    karelDebug,
  } = props.appData
  const { videoUrl } = props

  if(!videoUrl) {
    return <></>
  }

  console.log(country)


  return <div className="w-100 p-3 h-100 d-flex justify-content-center" style={{maxHeight:'calc(100vh - 57px)', overflow:'scroll'}}>
    <div style={{ maxWidth: 800 }}>

      <h3>{name} <FlagWithOverlay country={country.code} height={18}/></h3>
      <hr/>
      <b>Teaching Demo</b><br/>
      <video src={videoUrl} key={videoUrl} className="w-100" controls={true}></video>
      <div style={{height: 20}}></div>
      <AppAnswer
        question="Current Occupation"
        answer={currentOccupation}
      />
      <AppAnswer
        question="Previous Coding Experience"
        answer={previousCodingExperience}
      />
      <AppAnswer
        question="Previous Teaching Experience"
        answer={previousTeachingExperience}
      />
      <AppAnswer
        question="Python Debugging"
        answer={pythonDebug}
      />
      <AppAnswer
        question="Karel Debugging"
        answer={karelDebug}
      />



    </div>
  </div>
}

const AppAnswer = ({ question, answer }) => {
  return <><b>{question}</b><br /><p>{answer}</p></>
}



const bottomNavStyle = {
  height: 57,
  borderTop: '2px solid #ccc',
}