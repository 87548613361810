
import { useContext } from 'react';
import { HorizontalSpeechBubble } from 'components/duolessons/lessontypes/SpeechBubbles';
import styled from 'styled-components';
import { FaBookOpen, FaCheckCircle, FaPython, FaSync } from 'react-icons/fa';
import { useCourseId } from 'hooks/router/useUrlParams';
import { useNavigate } from 'react-router';
import { LessonContext } from '../LessonContext';
import { Link } from 'react-router-dom';

export const CompletePage = ({ onComplete, data }) => {
  console.log('data', data)
  const { lessonId } = useContext(LessonContext)
  const courseId = useCourseId()
  console.log('lessonId', lessonId)
  if (lessonId === 'welcome-to-karel' && courseId === 'public') {
    return <ApplyLink />
  }
  return <LessonOuter>
    <HorizontalSpeechBubble text={data.speechText} iconPath={data.speechIcon} />

    <CompleteInner>
      <div style={{ flexGrow: 1 }} />
      <div style={{ height: 20 }} />
      <h3><FaPython /> Optional Examples</h3>
      <ExamplesGrid examples={data.examples} />
      <div style={{ height: 20 }} />
      <h3 className='mt-4'><FaBookOpen /> Readings</h3>
      <ReadingsGrid readings={data.readings} />
      <div style={{ flexGrow: 2 }} />
    </CompleteInner>
  </LessonOuter>

}

const ApplyLink = () => {
  
  return <LessonOuter>
    <CompleteInner>
      <div style={{ height: 80 }} />

      <FaCheckCircle size={100} color='green' />
      <h1 className='mt-4'>Congratulations</h1>
      <h2>You have completed the lesson!</h2>
      <Link to="/public/join/cip5" className='btn btn-primary btn-lg mt-4' onClick={() => {} }>Apply to Code in Place 2025</Link>
    </CompleteInner>
  </LessonOuter>
}

const ReadingsGrid = ({ readings }) => {
  return <ReadingsGridOuter>
    {readings.map((reading, i) => {
      return <ReadingCard key={i} reading={reading} />
    })}
  </ReadingsGridOuter>
}

const ReadingCard = ({ reading }) => {
  // always open readings in a new tab
  const onClick = () => {
    window
      .open(reading.url, '_blank')
      .focus();
  }
  return <a
    href={""}
    onClick={onClick}
  >
    {reading.title}
  </a>
}


const ExamplesGrid = ({ examples }) => {
  return <ExamplesGridOuter>
    {examples.map((example, i) => {
      return <ExampleCard key={i} example={example} />
    })}
  </ExamplesGridOuter>
}

const ExampleCard = ({ example }) => {

  const { lessonId, currSlideId } = useContext(LessonContext)

  // don't useHistoryNavigate because this is a side quest
  // instead we will call navigate and give a from state
  const navigate = useNavigate()
  const courseId = useCourseId()
  const onClick = () => {
    const assnId = example.assnId
    const urlPath = `/${courseId}/ide/a/${assnId}`
    const fromPath = `/${courseId}/learn/${lessonId}/${currSlideId}`
    navigate(urlPath, {
      state: { from: fromPath },
    })
  }
  return <ExampleCardOuter
    onClick={onClick}
    tabIndex={0}
  >
    <ExampleCardInner>
      {example.title}
    </ExampleCardInner>
  </ExampleCardOuter>
}

const ReadingsGridOuter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const ExamplesGridOuter = styled.div`
  width:100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 20px;
  justify-content: center; /* Center the entire grid content */

  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ExampleCardOuter = styled.a`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ExampleCardInner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`




const CompleteInner = styled.div`
  
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
`

const LessonOuter = styled.div`
  display:flex;
  flex-direction:column;
  align-items:start;
  width:100%;
  flex-grow:1;
  height:100%;
`
