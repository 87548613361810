import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';

export const ApplyLink = ({
  to,
  children,
  className,
}: {
  to: string;
  children: any;
  className: string;
}) => {
  const navigate = useHistoryNavigate();
  return (
    <button
      onClick={() => navigate(to)}
      className={className}
      style={{
        width: '275px',
      }}
    >
      {children}
    </button>
  );
};
