import {useState} from 'react';
import {componentMainFile} from './ReactInTipTap';
import styled from 'styled-components';
import {FaArrowUp, FaUpload} from 'react-icons/fa';

import {CsvFileViewer, JpgFileViewer, JsonFileViewer} from './FileViewer';
import {MonacoTipTap} from './MonacoTipTap';
import {uploadFile} from 'utils/uploadFileQuiet';
import {useUserId} from 'hooks/user/useUserId';
import {reactInTipTapStorageRoot} from './useFile';

export const InteractiveMultiFileCodeView = ({files, onFileChange}) => {
  const [activeFile, setActiveFile] = useState(componentMainFile);

  const onValueChange = newCode => {
    onFileChange(activeFile, newCode);
  };

  return (
    <>
      <FilesTab
        files={files}
        onFileChange={onFileChange}
        activeFile={activeFile}
        setActiveFile={setActiveFile}
      />
      <FileViewer
        fileName={activeFile}
        fileContent={files[activeFile]}
        onValueChange={onValueChange}
      />
    </>
  );
};

const FileViewer = ({fileName, fileContent, onValueChange}) => {
  const fileType = fileName.split('.').pop();

  if (fileType === 'jsx') {
    return (
      <MonacoTipTap
        value={fileContent}
        onChange={onValueChange}
        readOnly={false}
        mode="javascript"
      />
    );
  }
  if (fileType === 'csv') {
    return <CsvFileViewer fileContent={fileContent} />;
  }
  if (fileType == 'json') {
    return <JsonFileViewer fileContent={fileContent} />;
  }
  if (fileType === 'jpg' || fileType === 'jpeg') {
    return <JpgFileViewer fileContent={fileContent} />;
  }
  return <></>;
};

const FilesTab = ({activeFile, setActiveFile, files, onFileChange}) => {
  const userId = useUserId();
  const [uploadProgress, setUploadProgress] = useState(0);

  console.log('uploadProgress', uploadProgress);
  console.log('userId', userId);

  const onUploadComplete = (url, fileName, uploadId) => {
    onFileChange(fileName, uploadId);
  };

  const setErrorState = error => {
    console.error('Error uploading file', error);
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (!file) return;
    if (!userId) return;

    const allowedTypes = [
      'application/json',
      'text/csv',
      'text/json',
      'image/jpeg',
      'image/jpg',
    ];
    if (!allowedTypes.includes(file.type)) {
      return;
    }

    const storageDir = reactInTipTapStorageRoot;
    uploadFile(
      file,
      storageDir,
      onUploadComplete,
      setUploadProgress,
      setErrorState,
    );
  };

  const openFileChooser = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json, .csv, .png, .jpg, .jpeg';
    input.onchange = handleFileUpload;
    input.click();
  };

  return (
    <FileTabOuter>
      <ul className="nav nav-tabs">
        <FileTabs
          activeFile={activeFile}
          files={files}
          setActiveFile={setActiveFile}
        />
        <li className="nav-item">
          <a className="nav-link " onClick={openFileChooser}>
            <FaUpload />
          </a>
        </li>
      </ul>
    </FileTabOuter>
  );
};

const FileTabs = ({activeFile, files, setActiveFile}) => {
  return Object.keys(files).map(fileName => {
    return (
      <li key={fileName} className="nav-item">
        <a
          className={`nav-link ${fileName === activeFile ? 'active' : ''}`}
          onClick={() => setActiveFile(fileName)}
        >
          {fileName}
        </a>
      </li>
    );
  });
};

const FileTabOuter = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
`;
