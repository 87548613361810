import {doc, getFirestore, onSnapshot} from 'firebase/firestore';
import {createContext, useEffect, useState} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {useHeadingIndex} from './useHeadingIndex';
import {get} from 'firebase/database';
import {useCourseId} from 'hooks/router/useUrlParams';

const defaultData = {
  bookOutline: undefined,
  bookOutlineLoading: true,
  bookChaptersLoading: true,
  bookFirebaseRoot: undefined,
  bookUrlRoot: undefined,
  getChapterTitle: chapter => '',
  isDraft: false,
  editing: true,
  bookTitle: '',
  liveReact: false,
  version: undefined,
  setEditing: newState => {},
  setLiveReact: newState => {},
  chapterCache: {},
};

export const BookContext = createContext(defaultData);

export const BookProvider = ({version, children, bookId}) => {
  const db = getFirestore();

  const courseId = useCourseId();

  const getBookUrlRoot = version => {
    if (version === 'draft') return `/${courseId}/${bookId}`;
    if (version === 'published') return `/${courseId}/${bookId}`;
  };

  // old path where books were stored
  // const coursePath = `/textbook/${courseId}`
  // const docPath = textbookId ? `${coursePath}/books/${textbookId}` : coursePath

  const bookFirebaseRoot = `textbook/${courseId}/books/${bookId}/${version}`;
  const bookUrlRoot = getBookUrlRoot(version);

  const isDraft = version === 'draft';

  const [bookOutlineRaw, outlineLoading] = useDocumentData(
    doc(db, `${bookFirebaseRoot}/outline`),
  );
  const [editing, setEditing] = useState(isDraft);
  const [liveReact, setLiveReact] = useState(true);
  const [chapterCache, setChapterCache] = useState({});
  const [chaptersLoading, setChaptersLoading] = useState(true);

  // const headingIndex = useHeadingIndex(chaptersLoading, chapterCache);

  const titleMap = bookOutlineRaw?.titles;

  const getChapterTitle = chapter => {
    return titleMap?.[chapter] || '';
  };

  useEffect(() => {
    if (!bookOutlineRaw) {
      return;
    }

    const unsubscribeFunctions = [];
    let expectedChapterCount = 0;
    const loadedChapterPaths = new Set();

    for (const part of bookOutlineRaw.outline) {
      const chaptersAndExamples = [
        ...(part.sections ?? []),
        ...(part.examples ?? []),
      ];

      for (const chapterKey of chaptersAndExamples) {
        expectedChapterCount++;
        const chapterDocPath = `${bookFirebaseRoot}/chapters/${chapterKey}/doc`;
        const docRef = doc(db, chapterDocPath);

        const unsubscribe = onSnapshot(docRef, docSnap => {
          if (docSnap.exists()) {
            const chapterData = docSnap.data();

            // Update the chapterCache state
            setChapterCache(prevChapterCache => ({
              ...prevChapterCache,
              [chapterDocPath]: chapterData,
            }));
          } else {
            // Document does not exist, set to null
            setChapterCache(prevChapterCache => ({
              ...prevChapterCache,
              [chapterDocPath]: undefined,
            }));
          }

          loadedChapterPaths.add(chapterDocPath);

          // Check if all chapters have been loaded
          if (loadedChapterPaths.size === expectedChapterCount) {
            setChaptersLoading(false);
          }
        });

        unsubscribeFunctions.push(unsubscribe);
      }
    }

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
    };
  }, [bookOutlineRaw]);

  const bookOutline = sanitizeOutline(bookOutlineRaw);

  const bookTitle = 'Teacher Handbook'

  return (
    <BookContext.Provider
      value={{
        bookOutline,
        bookOutlineLoading: outlineLoading,
        bookChaptersLoading: outlineLoading || chaptersLoading,
        getChapterTitle,
        editing,
        isDraft,
        bookFirebaseRoot,
        bookUrlRoot,
        setEditing,
        version,
        chapterCache,
        liveReact,
        setLiveReact,
        bookTitle
      }}
    >
      {children}
    </BookContext.Provider>
  );
};

const sanitizeOutline = bookOutlineRaw => {
  if (bookOutlineRaw) {
    return bookOutlineRaw;
  }

  return {
    outline: [],
    titles: {},
  };
};
