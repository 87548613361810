import { IDEContext } from 'ide/contexts/IDEContext';
import { useContext, useEffect } from 'react';
import { TermModel } from 'ide/TerminalPane/GeneralTerminal/Model';
import { getLineNumber } from 'ide/ErrorMessage/errorhint';
import {
  createErrorTestResult,
  EMPTY_TEST_RESULT,
} from 'assignments/unitTest/unitTestResults';
import { safeLogCodeRun } from 'ide/ErrorMessage/logResults';
import { compileKarelCode } from 'ide/utils/codeExecution';
import { deepCopy } from '@firebase/util';
import {
  focusRelevantPane,
  getAllFileNames,
  updateLastOpenedFile,
} from 'ide/utils/general';
import { getOnwardsUrl } from 'ide/HomeExitButton';
import { useNavigate } from 'react-router';
import { useCourseId } from 'hooks/router/useUrlParams';
import { NavigationHistoryContext } from 'contexts/NavigationContext';
import { inferUnitTestType } from 'ide/UnitTest/runUnitTests';
import { runUnitTestsAndReportResults } from 'ide/UnitTest/runUnitTestsAndReportResults';
import { ProjectFilesCode, ProjectFileStructure, type ProjectFileData } from 'projects/types';
import { AssignmentUnitTestBehavior } from 'assignments/types';
import type { ProjectDocument } from 'projects/projectDocument';

export const useGetTerminalRef = (runCode, isKarel, endRepl) => {
  const { pyodideClientRef, setIsRunning } = useContext(IDEContext);

  const getTerminalRef = () => {
    const pyodideClient = pyodideClientRef.current;
    const handleRepl = async mode => {
      if (mode) {
        setIsRunning(true);
        pyodideClient.setReplSession(endRepl);
      } else {
        setIsRunning(false);
        await pyodideClient.endRepl();
      }
    };

    return new TermModel(
      '%',
      () => runCode(true),
      () => { },
      () => pyodideClient.raisePyStopFlag(),
      isKarel,
      handleRepl,
      cmd => pyodideClient.handleReplCommand(cmd),
    );
  };

  return getTerminalRef;
};

export const useIDETitle = assnData => {
  useEffect(() => {
    if (assnData && assnData.metaData && assnData.metaData.title) {
      document.title = `Code in Place | ${assnData.metaData.title}`;
    } else {
      document.title = 'Code in Place | IDE';
    }
  }, [assnData]);
};

interface UseCodeFuncsResult {
  handleError: (stderr: string, code: string) => Promise<string>;
  runCode: (fromTerminal?: boolean) => Promise<void>;
  testCode: (testFile: ProjectFileData) => Promise<void>;
}

export const useCodeFuncs = (
  userId: string,
  projectData: ProjectDocument,
  currentFile: ProjectFileData,
  filesCode: ProjectFilesCode,
  fileStructure: ProjectFileStructure,
  isKarel: boolean,
  isConsole: boolean,
  isGraphics: boolean,
): UseCodeFuncsResult => {
  const ideContext = useContext(IDEContext);
  const navigate = useNavigate();
  const courseId = useCourseId();
  const {
    terminalRef,
    setRunErrorOccurred,
    terminalViewState,
    setTerminalViewState,
    setErrorLineNo,
    setStepMode,
    setIsRunning,
    pyodideClientRef,
    codeToRun,
    karelWorldState,
    stepSaveOn,
    canvasViewState,
    setCanvasViewState,
    setKarelWorldState,
    karelSleepTime,
    setRunSuccessOccurred,
    runMoodSurvey,
    assnData,
  } = ideContext;
  const { getPreviousLocation } = useContext(NavigationHistoryContext);
  const previousLocation = getPreviousLocation();

  // dynamically adjust sleep time for karel
  useEffect(() => {
    if (pyodideClientRef.current) {
      pyodideClientRef.current.setKarelSleepTime(karelSleepTime);
    }
  }, [karelSleepTime]);

  // When the file code updates (when collab editor changes), change codeToRun
  useEffect(() => {
    if (filesCode) {
      // update what code I am going to run...
      codeToRun.current = filesCode[currentFile?.id]?.content ?? '';
    }
  }, [filesCode, currentFile]);

  // Every time the opened file is change, update the 'last opened file' property
  // so that the open file persists the next time the IDE is open
  useEffect(() => {
    updateLastOpenedFile(projectData.uid, currentFile);
  }, [currentFile]);

  const compileKarel = async code => {
    // the point of compiling karel is to make sure folks
    // don't have any non karel features in their code
    // if anything goes wrong, then skip this test

    try {
      const compileResult = compileKarelCode(code, karelWorldState);

      if (compileResult.status === 'error') {
        await handleKarelError(compileResult, code);
        setIsRunning(false);
        return false;
      }
    } catch (e) {
      console.error('Error compiling Karel code', e);
      // by returning true, we are skipping the karel feature test
      return true
    }
    return true;
  };

  const relayWorldStateFromCode = async (newState) => {
    // console.log(newState)
    setKarelWorldState(newState);
  }

  const resetKarelAndCompile = async (pyodideClient, code) => {
    // returns true if Karel compiles successfully
    if (isKarel) {

      const compilationResult = await compileKarel(code)
      if (!compilationResult) return false;


      // open the canvas if it is not open
      if (canvasViewState === 'minimized') {
        setCanvasViewState('standard');
      }
      // tell pyodide about the current state of karel
      pyodideClient.setKarelInfo(
        deepCopy(karelWorldState),
        relayWorldStateFromCode,
        karelSleepTime,
      );
    } else {
      pyodideClient.setKarelInfo({}, () => { });
    }
    return true;
  };

  const handleError = async (stderr: string, code: string) => {
    const terminal = terminalRef.current;
    const error_message = await terminal.handleStderr(code, stderr);
    setRunErrorOccurred(true);
    // make sure the terminal is visible
    if (terminalViewState === 'minimized') {
      setTerminalViewState('standard');
    }
    // Grab the line number from the stderr
    if (!terminal.replMode) {
      const errorLineNo = getLineNumber(stderr);
      if (errorLineNo > 0) {
        setErrorLineNo(errorLineNo);
      }
    }
    return error_message;
  };

  const handleKarelError = async (compileResult, code) => {
    let terminal = terminalRef.current;
    const errorResult = compileResult.error;
    await handleError(
      'Line ' + errorResult.lineNumber + ': ' + errorResult.msg,
      code,
    );
    terminal._prompt();
    const errorMsg =
      'Line ' + compileResult.error.lineNumber + ': ' + compileResult.error.msg;
    const unitTestResults = createErrorTestResult(errorMsg);
    safeLogCodeRun({ userId, projectData, code, unitTestResults });
  };

  /*
   * This function is resposible for running and testing the user code
   * It is called when the user clicks the run button, or types python <filename> in the terminal
   */
  const runCode = async (fromTerminal = false) => {
    let terminal = terminalRef.current;
    let pyodideClient = pyodideClientRef.current;
    let code = codeToRun.current;
    self.courseId = courseId;
    self.projectId = projectData.uid ?? '';
    setStepMode(false);
    setIsRunning(true);

    focusRelevantPane(terminal, isKarel, isConsole, isGraphics);
    if (!fromTerminal) terminal.writeAndScroll('python ' + currentFile.name);

    // reset karel. Should happen on every run
    // important: this also compiles karel to look for errors
    const compiles = await resetKarelAndCompile(pyodideClient, code);
    if (!compiles) {
      setIsRunning(false);
      return;
    }

    // Run Code
    await pyodideClient.loadFiles(getAllFileNames(fileStructure), filesCode);

    await pyodideClient.runCode(code, currentFile, stepSaveOn);

    self.courseId = null;
    self.projectId = null;
    // Pass all compilers
    setRunSuccessOccurred(true);

    // logs code once run is complete
    safeLogCodeRun({ userId, projectData, code });
    setIsRunning(false);
    // Randomly mood survey
    runMoodSurvey();
    return;
  };

  const testCode = async (testFile: ProjectFileData) => {
    const code = filesCode[testFile.id]?.content ?? '';
    const pyodideClient = pyodideClientRef.current;
    const unitTestType = inferUnitTestType(assnData, projectData);
    if (code === '') {
      console.error('Could not find code for test file. Running anyway.', {
        testFile,
        filesCode,
      });
    }
    // reset karel. Should happen on every run
    // await resetKarel(pyodideClient, code);
    setStepMode(false);
    if (unitTestType !== 'ai') {
      setIsRunning(true);
    }
    const unitTestResults = await runUnitTestsAndReportResults({
      ideContext,
      navigate,
      userId,
      courseId,
      onwardsUrl: getOnwardsUrl(courseId, location, previousLocation),
      silent: false,
    });
    // setRunSuccessOccurred(true); this used to trigger the mobile ide to switch to console output
    safeLogCodeRun({
      userId,
      projectData,
      code,
      unitTestResults,
    });
    if (unitTestType !== 'ai') {
      setIsRunning(false);
    }
    return;
  };

  return { handleError, runCode, testCode };
};

export const useStepFunctions = () => {
  const { setStepData, setKarelWorldState, pyodideClientRef, stepList } =
    useContext(IDEContext);

  function step(ptr) {
    if (!stepList) {
      return;
    }
    const lineno = stepList[ptr]['lineno'];
    const pyodideClient = pyodideClientRef.current;
    setStepData(stepList[ptr]['locals']);

    if (stepList[ptr]['karel']) {
      setKarelWorldState(stepList[ptr]['karel'].state);
    }
    if (stepList[ptr]['graphics']) {
      const graphicsData = stepList[ptr]['graphics'];
      pyodideClient.stepGraphics(graphicsData);
    }
    return lineno;
  }

  function stepListSize() {
    if (stepList) {
      return stepList.length;
    } else {
      return 0;
    }
  }

  return { step, stepListSize };
};
