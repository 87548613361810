import { IDEContext } from "ide/contexts/IDEContext";
import { useContext, useEffect, useState } from "react";
import { MobileOutputTabs } from "./MobileOutputTabs";
import { VisualOutput } from "./VisualOutput";
import { IDETerminalView } from "ide/TerminalPane/GeneralTerminal/IDETerminalView";
import styled from "styled-components";

export const MobileOutput = ({}) => {

  const {
    terminalRef,
    projectData,
    assnData,
    runErrorOccurred,
    setRunErrorOccurred,
    runSuccessOccurred,
    setRunSuccessOccurred,
    terminalViewState,
    setTerminalViewState,
    selectedMobileTab,
    selectedMobileOutputTab,
    setSelectedMobileOutputTab
  } = useContext(IDEContext);

  console.log("MobileOutput selectedMobileTab", selectedMobileTab);
  
  const isConsole =
    projectData?.type === 'console' || assnData?.metaData?.type === 'console';


  console.log('selectedMobileOutputTab', selectedMobileOutputTab);
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.refreshTerm();
    }
  }, []);

  useEffect(() => {
    if (runErrorOccurred) {
      setSelectedMobileOutputTab('Console');
      setRunErrorOccurred(false);
    }
  }, [runErrorOccurred]);

  // useEffect(() => {
  //   if (runSuccessOccurred) {
  //     if (isConsole) {
  //       setSelectedMobileOutputTab('Console');
  //     } else {
  //       setSelectedMobileOutputTab('Visual');
  //     }
  //     setRunSuccessOccurred(false);
  //   }
  // }, [runSuccessOccurred]);

  // These need to be kept mounted so that their state doesn't get cleared when you switch tabs
  const hiddenOutputClassName = selectedMobileTab === 'Output' ? 'd-flex' : 'd-none';
  const hiddenTerminalClassName = selectedMobileOutputTab === 'Console' ? 'd-flex' : 'd-none';

  return (
    <MobileOutputOuter className={`${hiddenOutputClassName} flex-column`}>
      {/* The tab that says UnitTest or Console */}
      <MobileOutputTabs/>
      {/* The visual output (unit tests, karel, graphics canvas) */}
      <VisualOutput/>

      {/* The terminal output */}
      <TerminalOuter className={`${hiddenTerminalClassName}`}>
        <IDETerminalView
          terminalRef={terminalRef}
          viewState={terminalViewState}
          setViewState={setTerminalViewState}
        />
      </TerminalOuter>
    </MobileOutputOuter>
  );
};

const bottomBarHeight = 84;
const titleBarHeight = 58;
const offset = bottomBarHeight + titleBarHeight;

const MobileOutputOuter = styled.div`
`;


const TerminalOuter = styled.div`
  height: calc(100vh - ${198}px);
`
