import {Button} from 'react-bootstrap';
import {FaCopy} from 'react-icons/fa';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useCourseId} from 'hooks/router/useUrlParams';
import {SocialMediaIconRow} from 'publishedCode/Published';
import { Link } from 'react-router-dom';
import {QRCodeSVG} from "qrcode.react";

// Provide this constant or import it from your config/utilities
const WHATSAPP_NUMBER = '+1 (415) 728-3856';

const PosterContainer = styled.div`
position: relative;
  max-width: 700px;
  margin: 2rem auto;
  padding: 2rem;
  border: 2px solid #222;
  border-radius: 8px;
  background: #fff;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const PosterTitle = styled.h1`
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
`;

// font needs to have a clear difference between I and 1
const PosterSubtitle = styled.h2`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
`;

const CodeFont = styled.span`
  font-family: Consolas, 'Courier New', monospace;
`

const ShareInstructions = styled.div`
  margin: 1.5rem 0;
`;

const TextBox = styled.textarea`
  width: 100%;
  min-height: 60px;
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
`;

const WhatsAppProjectShare = () => {
  // If using React Router:
  // const { whatsappkey } = useParams();

  // If you have a custom hook:
  const {whatsappkey} = useParams();

  const courseId = useCourseId();
  console.log('Whatsapp Key from URL: ', whatsappkey);

  // Fallback if for some reason there's no key in the URL
  const finalKey = whatsappkey;

  const copyToClipboard = (text, finalText) => {
    navigator.clipboard.writeText(text);
    Swal.fire({
      icon: 'success',
      title: 'Copied!',
      text: `${finalText} copied to clipboard.`,
      timer: 1000,
      showConfirmButton: false,
    });
  };

  const finalKeyUppercase = finalKey.toUpperCase();

  const shareText = `Hey! I want you to see my Code in Place project. You can interact with my program directly on WhatsApp. Send message ${finalKeyUppercase} to ${WHATSAPP_NUMBER} to see it run!`;


  return (
    <PosterContainer>
      {/* Add a "Beta" badge to the top right */}
      <PosterTitle>Run your code on WhatsApp</PosterTitle>
      <div className='alert alert-danger'>
        <strong>Important:</strong> This feature is in beta. Please report any issues on <Link to={`/${courseId}/report`}>the reporting forum</Link>. 
      </div>
      <PosterSubtitle>
      Send a whatsapp message to...
        <br/> 
        <div style={{fontSize: '2rem', fontWeight: 'bold'}}>
        <strong style={{
          color: "purple"
        }}>{WHATSAPP_NUMBER}</strong>
        </div>
      </PosterSubtitle>
      <PosterSubtitle>
        With the text message...
        <br/>
        <div style={{fontSize: '2rem', fontWeight: 'bold', color: "hotpink", borderRadius: '5px',  paddingRight: "50px", paddingLeft: "50px", textAlign: "center"}}>
        <strong>{<CodeFont>{finalKeyUppercase}</CodeFont>}</strong>
        </div>
      </PosterSubtitle>
      <PosterSubtitle>Or scan the QR code below.</PosterSubtitle>
      <WhatsAppQRCode message={finalKeyUppercase} />
      <ShareInstructions>
        Anyone who sends the message <b>{<CodeFont>{finalKeyUppercase}</CodeFont>}</b> to{' '}
        <b>{WHATSAPP_NUMBER}</b> will be able to run and interact with your code
        directly in WhatsApp!
      </ShareInstructions>

      {/* Copy just the key */}
      <Button
        variant="light"
        style={{marginRight: '1rem'}}
        onClick={() => copyToClipboard(finalKeyUppercase, 'Key')}
      >
        <FaCopy style={{marginRight: '0.5rem'}} />
        Copy Key
      </Button>

      {/* Copy full share message */}
      <Button
        variant="light"
        onClick={() => copyToClipboard(shareText, 'Full message')}
      >
        <FaCopy style={{marginRight: '0.5rem'}} />
        Copy Full Message
      </Button>

      <WhatsappMessageDiv> 
      Hey! I want you to see my Code in Place project. You can interact with my program directly on WhatsApp. Send message <b><CodeFont>{finalKeyUppercase}</CodeFont></b> to {WHATSAPP_NUMBER} to see it run!
      </WhatsappMessageDiv>

      {/* Direct WhatsApp share link, if desired */}
      <div style={{marginTop: '1rem'}}>
        <span>Share this message on: </span>
        <SocialMediaIconRow referralText={shareText} />
      </div>
    </PosterContainer>
  );
};

const WhatsAppQRCode = ({message}) => {
  const phoneNumber = "14157283856"
  
  // Encode the message to ensure spaces/special characters are handled correctly
  const encodedMessage = encodeURIComponent(message);
  
  // Construct the WhatsApp URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <div style={{ textAlign: "center" }}>
      <QRCodeSVG value={whatsappUrl} size={128} />
    </div>
  );
};



const WhatsappMessageDiv = styled.div`
  /* Increase the margin on top/bottom, auto-center horizontally */
  margin: 12px auto;
  /* Constrain how wide the bubble can go */
  max-width: 75%;
  padding: 10px 12px;

  background-color: #DCF8C6; 
  border-radius: 10px;
  position: relative;

  font-family: Arial, sans-serif;
  font-size: 15px;
  color: #303030;
  line-height: 1.4;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;

  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -10px;              /* move the tail out to the right more */
    width: 0;
    height: 0;
    border: 12px solid transparent;  /* bigger triangle overall */
    border-bottom: 0;        
    border-right: 0;         
    border-left-color: #DCF8C6; /* left side gets the green */
  }
`;


export default WhatsAppProjectShare;
