import {Loading} from 'components/loading/Loading';
import {CourseContext} from 'contexts/CourseContext';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {signInWithCustomToken, getAuth} from 'firebase/auth';
import {useCipHttpsCallable} from '../../firebase/functions/use_cip_https_callable';

const useCanvasAuth = () =>
  useCipHttpsCallable<unknown, {token: string}>('canvasAuth');

export const CustomAuth = () => {
  const location = useLocation();
  const auth = getAuth();
  const [error, setError] = useState(null);
  const [thrownError, setThrownError] = useState(false);
  const [canvasAuth] = useCanvasAuth();

  useEffect(() => {
    const state = location.state;
    if (!('code' in state) || !('state' in state)) {
      return;
    }

    const subState = JSON.parse(state.state);

    const fetchToken = async () => {
      // make firebase function call
      try {
        const response = await canvasAuth({
          code: state.code,
          courseId: subState.courseId,
        });

        if (!response || !response.data) {
          // handle error
          return;
        }
        const token = response.data.token;

        if (!token) {
          // handle error
          return;
        }
        await signInWithCustomToken(auth, token);

        const returnUrl = subState.returnUrl;
        // url decode returnUrl
        const decodedUrl = decodeURIComponent(returnUrl);
        window.location.href = decodedUrl;
      } catch (error) {
        setError(error.message);
        setThrownError(true);
      }
    };

    fetchToken();
  }, []);

  if (thrownError) {
    return <div>{error}</div>;
  }

  return <Loading />;
};

export const RedirectToAuth = () => {
  const {courseCanvasAuthLink} = useContext(CourseContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (courseCanvasAuthLink) {
      navigate(courseCanvasAuthLink);
    }
  }, []);

  return <Loading />;
};

export const ReturnToCourse = ({code}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // make firebase function call

    const getToken = async () => {
      // Get token

      setLoading(false);
    };
    getToken();
  }, []);

  return <Loading />;
};
