import {useTranslateFunction} from 'contexts/TranslationContext';
import {
  getLearnReferralLink,
  getTeachReferralLink,
} from 'course/application/general/getReferralCode';
import LogOnClick from 'engagement/LogOnClick';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {runBtnColor} from 'ide/NavIDE';
import {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {
  FaCopy,
  FaFacebook,
  FaInstagram,
  FaPaperPlane,
  FaPlay,
  FaShareAlt,
  FaWhatsapp,
} from 'react-icons/fa';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const LEARN_REFERRAL_MESSAGE = `I am applying to Stanford's Python course. Come learn with me: `;
const TEACH_REFERRAL_MESSAGE = `I am applying to Stanford's Python course. Come teach with me: `;
/**
 * Props for the ReferAFriend component
 */
interface ReferAFriendProps {
  /**
   * Whether to show the link to apply as a teacher.
   * Only present if the application is for a teacher role.
   */
  showTeacherLink?: boolean;
}

export const ReferAFriend = ({showTeacherLink}: ReferAFriendProps) => {
  const courseId = useCourseId();
  const userId = useUserId();
  const learnShareLink = getLearnReferralLink(userId, courseId);
  const teachShareLink = getTeachReferralLink(userId, courseId);
  const [showModal, setShowModal] = useState(false);

  const t = useTranslateFunction('application');

  const CustomButton = ReferButton;

  const learnReferralText = `${LEARN_REFERRAL_MESSAGE} ${encodeURIComponent(
    learnShareLink,
  )}`;
  const teacherReferralText = `${TEACH_REFERRAL_MESSAGE} ${encodeURIComponent(
    teachShareLink,
  )}`;
  const referralBodyDescription = t(
    'Invite others to Code in Place with a personalized link! It may help you get in:',
  );

  const referralButton = CustomButton ? (
    <CustomButton
      onClick={() => {
        setShowModal(true);
      }}
    />
  ) : (
    <Button variant="light" onClick={() => setShowModal(true)}>
      <FaShareAlt>Share</FaShareAlt>
    </Button>
  );

  return (
    <>
      <LogOnClick
        eventName="select_content"
        eventParams={{
          content_type: 'button',
          content_id: 'share_referral_button',
        }}
      >
        {referralButton}
      </LogOnClick>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share this Opportunity</Modal.Title>
          <br />
        </Modal.Header>
        <Modal.Body>
          <p>{referralBodyDescription}</p>
          <b>{t('Share with new learners:')}</b>
          <ReferralSection
            shareLink={learnShareLink}
            referralText={learnReferralText}
          />

          {showTeacherLink && (
            <>
              <b>{t('Share with new teachers:')}</b>
              <ReferralSection
                shareLink={teachShareLink}
                referralText={teacherReferralText}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const IconRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin: 20px 0;
  font-size: 24px;
`;

interface SocialMediaIconRowProps {
  /**
   * The text to share on social media.
   */
  referralText: string;
}
const SocialMediaIconRow = ({referralText}: SocialMediaIconRowProps) => {
  return (
    <IconRow>
      <LogOnClick
        eventName="share"
        eventParams={{
          content_type: 'link',
          method: 'whatsapp',
          content_id: 'share_referral_button',
        }}
      >
        <a
          href={`https://wa.me/?text=${referralText}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp style={{color: '#25D366'}} />
        </a>
      </LogOnClick>
      <LogOnClick
        eventName="share"
        eventParams={{
          content_type: 'link',
          method: 'instagram',
          content_id: 'share_referral_button',
        }}
      >
        <a
          href={`https://www.instagram.com/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram style={{color: '#C13584'}} />
        </a>
      </LogOnClick>
      <LogOnClick
        eventName="share"
        eventParams={{
          content_type: 'link',
          method: 'twitter',
          content_id: 'share_referral_button',
        }}
      >
        <a
          href={`https://twitter.com/intent/tweet?url=${referralText}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{color: 'black'}}>𝕏 </span>
        </a>
      </LogOnClick>
      <LogOnClick
        eventName="share"
        eventParams={{
          content_type: 'link',
          method: 'facebook',
          content_id: 'share_referral_button',
        }}
      >
        <a
          href={`https://facebook.com/sharer/sharer.php?u=${referralText}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook style={{color: '#4267B2'}} />
        </a>
      </LogOnClick>
    </IconRow>
  );
};

interface ReferralSectionProps {
  shareLink: string;
  referralText: string;
}

const ReferralSection = ({shareLink, referralText}: ReferralSectionProps) => {
  const t = useTranslateFunction('application');

  function handleCopy() {
    navigator.clipboard.writeText(shareLink);
    Swal.fire({
      title: t('Referral link copied to clipboard!'),
      icon: 'success',
    });
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '20px',
        }}
      >
        <input
          type="text"
          value={shareLink}
          readOnly
          style={{
            flex: 1,
            padding: '5px',
            border: '1px solid #ddd',
            borderRadius: '4px',
          }}
        />
        <LogOnClick
          eventName="select_content"
          eventParams={{
            content_type: 'button',
            content_id: 'copy_referral_button',
          }}
        >
          <Button variant="secondary" onClick={handleCopy}>
            <FaCopy /> {t('Copy')}
          </Button>
        </LogOnClick>
      </div>
      <SocialMediaIconRow referralText={referralText} />
    </>
  );
};

const ReferButton = ({onClick}) => {
  const t = useTranslateFunction('application');
  return (
    <>
      <ShareButtonOuter className="btn btn-primary mt-3" onClick={onClick}>
        {/* <FaCopy /> Share! */}
        <FaPaperPlane /> Share!
      </ShareButtonOuter>
    </>
  );
};

const ShareButtonOuter = styled.div`
  // background-color: ${runBtnColor};
  // border-color: ${runBtnColor};
  color: white;
`;
