import {Outlet} from 'react-router-dom';
import {BookPage} from './layout/BookLayout';
import {BookProvider} from './context/BookContext';

export const TextbookOuter = ({bookId, version}) => {
  return (
    <BookProvider version={version} bookId={bookId}>
      <BookPage>
        <Outlet />
      </BookPage>
    </BookProvider>
  );
};
