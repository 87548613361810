import KarelSvgGroup from 'components/loading/KarelSvgGroup';
import {Stack} from 'react-bootstrap';
import styled from 'styled-components';

// Define the original dimensions based on the SVG viewBox.
const ORIGINAL_WIDTH = 208;
const ORIGINAL_HEIGHT = 209;

// Create a styled SVG component that scales based on the provided "scale" prop.
const StyledSVG = styled.svg<{scale?: number}>`
  display: block;
  margin: 0 auto;
  width: ${({scale = 1}) => `${ORIGINAL_WIDTH * scale}px`};
  height: ${({scale = 1}) => `${ORIGINAL_HEIGHT * scale}px`};
`;

const ExceptionText = styled.code<{scale?: number}>`
  font-size: ${({scale = 1}) => `${12 * scale}px`};
  font-weight: bold;
  background-color: rgb(230, 136, 133);
  color: black;
  padding-top: ${({scale = 1}) => `${7 * scale}px`};
  padding-bottom: ${({scale = 1}) => `${7 * scale}px`};
  padding-left: ${({scale = 1}) => `${7 * scale}px`};
  padding-right: ${({scale = 1}) => `${7 * scale}px`};
`;
export function BlockedKarelWithText({scale = 1}: {scale?: number}) {
  return (
    <Stack className="justify-content-center align-items-center">
      <BlockedKarel scale={scale} />
      <div style={{marginTop: `${4 * scale}px`}}>
        <ExceptionText scale={scale}>Exception: Front is blocked</ExceptionText>
      </div>
    </Stack>
  );
}

/**
 * BlockedKarel component renders the SVG icon.
 * This follows the patterns of KarelLoadingAnimation and KarelSpinningAnimation.
 * @param scale - The scale of the SVG.
 */
export function BlockedKarel({scale = 1}: {scale?: number}) {
  return (
    <StyledSVG
      id="eDC2JlY8FeO1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 208 209"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      scale={scale}
    >
      <g transform="translate(10 6.14)">
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="translate(100.5 99)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="matrix(0 1-1 0 100.5 99)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
      </g>
      <KarelSvgGroup translateX={4.4} translateY={4.7} />
      <line
        x1="-11.58011"
        y1="0"
        x2="196.41989"
        y2="0"
        transform="matrix(0 1-1 0 206 11.58)"
        fill="none"
        stroke="#000"
        strokeWidth="4"
      />
      <line
        x1="-11.58011"
        y1="0"
        x2="196.41989"
        y2="0"
        transform="matrix(0 1-1 0 1.90 11.58)"
        fill="none"
        stroke="#000"
        strokeWidth="4"
      />
      <line
        x1="-11.58011"
        y1="0"
        x2="196.41989"
        y2="0"
        transform="matrix(-1 0 0-1 196.42 2)"
        fill="none"
        stroke="#000"
        strokeWidth="4"
      />
      <line
        x1="-11.58011"
        y1="0"
        x2="196.41989"
        y2="0"
        transform="translate(11.58 207)"
        fill="none"
        stroke="#000"
        strokeWidth="4"
      />
    </StyledSVG>
  );
}
