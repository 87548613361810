import {sidebarClasses} from 'react-pro-sidebar';

export const bookMenuBackground = '#2E2D29';

export const bookMenuStyles = {
  root: {
    padding: '0px',
    margin: '0px',
  },
  button: {
    [`&.active`]: {
      backgroundColor: '#13395e',
      color: 'black',
    },
    [`&:hover`]: {
      backgroundColor: '#6FC3FF',
      color: 'black',
    },
    color: 'rgb(204,204,204)',
    padding: '10px',
    margin: '0px',
    height: '25px',

    fontWeight: 100,
    backgroundColor: bookMenuBackground,
  },
  icon: {
    color: '#ccc',
    padding: '0px',
    margin: '0px',
    width: '12px',
    minWidth: '10px',
    marginRight: '6px',
  },
};

export const bookRootStyles = {
  border: '0px',
  [`.${sidebarClasses.container}`]: {
    border: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    backgroundColor: bookMenuBackground,
    paddingBottom: '10px',
    paddingTop: '10px',
    borderColor: 'black',
    paddingLeft: '0px',
    fontSize: '18px',
    // fontFamily: `"Times New Roman", serif`,
  },
  
};
