import {
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  getFirestore,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';
import {uuidv4} from 'lib0/random';
import {AssignmentId} from 'assignments/types';
import {CourseId} from 'types/common';

/**
 * Structure of the autograding scripts pulled from the Assignment Editor and passed
 * to the pyodide runner
 */
export type GradingScripts = {
  onInput: string;
  onOutput: string;
  onFinish: string;
};

export interface AdvancedConsoleUnitTest {
  gradingScripts: GradingScripts;
  name: string;
  key: string;
}

export function isAdvancedConsoleUnitTest(
  testObject: any,
): testObject is AdvancedConsoleUnitTest {
  return (
    testObject &&
    typeof testObject === 'object' &&
    typeof testObject.gradingScripts === 'object' &&
    typeof testObject.gradingScripts.onInput === 'string' &&
    typeof testObject.gradingScripts.onOutput === 'string' &&
    typeof testObject.gradingScripts.onFinish === 'string' &&
    typeof testObject.name === 'string' &&
    typeof testObject.key === 'string'
  );
}

const ADVANCED_CONSOLE_UNIT_TEST_DOC_CONVERTER: FirestoreDataConverter<
  AdvancedConsoleUnitTest[]
> = {
  toFirestore(unitTests: AdvancedConsoleUnitTest[]): DocumentData {
    return {
      unitTestsAdvancedConsole: unitTests.map(unitTest => {
        return {
          gradingScripts: {...unitTest.gradingScripts},
          name: unitTest.name,
          key: unitTest.key || uuidv4(),
        };
      }),
      lastUpdated: Timestamp.now(),
    };
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions,
  ): AdvancedConsoleUnitTest[] {
    const data = snapshot.data(options);
    if (!data) {
      console.debug('No data found in snapshot');
      return [];
    }
    const unitTests = data.unitTestsAdvancedConsole ?? data.unitTests;
    if (!unitTests || !Array.isArray(unitTests)) {
      console.debug('No branches found in snapshot');
      return [];
    }
    return unitTests.filter(unitTest => {
      if (!isAdvancedConsoleUnitTest(unitTest)) {
        console.warn('Failed to load advanced console unit test: ', unitTest);
        return false;
      }
      return true;
    });
  },
};

export function getAdvancedConsoleUnitTestReference(
  courseId: CourseId,
  assnId: AssignmentId,
): DocumentReference<AdvancedConsoleUnitTest[]> {
  return doc(
    getFirestore(),
    `assns/${courseId}/assignments/${assnId}/docs/unitTests`,
  ).withConverter(ADVANCED_CONSOLE_UNIT_TEST_DOC_CONVERTER);
}
