import {CodeAdventureProvider, useCodeAdventure} from './CodeAdventureContext';
import Editor from '@monaco-editor/react';
import {PyodideClient} from 'components/pyodide/PyodideProvider';
import {useEffect, useCallback} from 'react';
import {TermModel} from 'ide/TerminalPane/GeneralTerminal/Model';
import {NonIDETerminalView} from 'ide/TerminalPane/GeneralTerminal/SimpleTerminalView';
import ActionWidget from './ActionWidgets';
import Swal from 'sweetalert2';
import './CodeAdventureMonacoEditor.scss';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';
import {DesktopView} from './DesktopView';
import {MobileView} from './MobileView';
import {SlideTree} from './SlideTree';

export const CodeAdventureLesson = ({onComplete, data}) => {
  return (
    <>
      <CodeAdventureProvider data={data}>
        <CodeAdventureInner onComplete={onComplete} data={data} />
      </CodeAdventureProvider>
    </>
  );
};

export const CodeAdventureInner = ({onComplete, data}) => {
  const {
    code,
    setCode,
    prevSlideNodes,
    prevSlideNodesCodes,
    setRunning,
    currentSlideNode,
    setCurrentSlideNode,
    slideRootRef,
    slideNodes,
    pyodideClientRef,
    terminalRef,
    decorations,
    isTabletOrLarger,
  } = useCodeAdventure();

  const {initialCode, slideTree, tasks, title} = data;

  useEffect(() => {
    prevSlideNodesCodes.current.push(initialCode);
    setCode(initialCode);
  }, []);

  useEffect(() => {
    async function loadSlideData() {
      if (slideTree?.length > 0) {
        try {
          const nodeList: SlideTree = slideTree;
          slideRootRef.current = nodeList[0];
          setCurrentSlideNode(nodeList[0]);
          for (const node of nodeList) {
            slideNodes.current[node['id']] = node;
          }
        } catch (e) {
          console.error('invalid slide tree in lesson editor', e);
        }
      }
    }
    loadSlideData();
  }, [slideTree]);

  function onNext(lastSlideNodeCode: string = code, correct: boolean = false) {
    const nextSlideNode =
      currentSlideNode['nextCorrect'] && currentSlideNode['nextIncorrect']
        ? correct
          ? currentSlideNode['nextCorrect']
          : currentSlideNode['nextIncorrect']
        : currentSlideNode['next'];

    if (nextSlideNode) {
      prevSlideNodesCodes.current.push(lastSlideNodeCode);
      prevSlideNodes.current.push(currentSlideNode);
      decorations?.clear();
      setCurrentSlideNode(slideNodes.current[nextSlideNode]);
    } else {
      Swal.fire({
        title: "You've reached the end of this lesson!",
        text: 'Would you like to continue to the next lesson?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          onComplete();
        }
      });
    }
  }

  function onBack() {
    if (prevSlideNodesCodes.current.length) {
      setCode(prevSlideNodesCodes.current.pop());
    }
    if (prevSlideNodes.current.length) {
      setCurrentSlideNode(prevSlideNodes.current.pop());
    }
  }

  // Pyodide Client and Terminal setup
  useEffect(() => {
    pyodideClientRef.current = new PyodideClient();
    const pyodideClient = pyodideClientRef.current;

    terminalRef.current = new TermModel(
      '%',
      () => runCode(),
      () => onEnd(),
      () => pyodideClient.raisePyStopFlag(),
    );
    const terminal = terminalRef.current;

    pyodideClient.setHandlers(
      endTerminal,
      stdout => terminal.handleStdout(stdout),
      (stderr, code) => handleError(stderr, code),
      pmt => terminal.handleStdin(pmt),
    );
    terminal.initTerm();
  }, []);

  const onEnd = () => {
    setRunning(false);
  };

  const handleError = async (stderr: string, code: string) => {
    setRunning(false);
    const terminal = terminalRef.current;
    const error_message = await terminal.handleStderr(code, stderr);
    return error_message;
  };

  const endTerminal = useCallback(() => {
    const terminal = terminalRef.current;
    terminal.handleEnd();
  }, []);

  const runCode = async () => {
    // Important stuff
    const pyodideClient = pyodideClientRef.current;

    // Set terminal
    const terminal = terminalRef.current;
    terminal.focusTerm();

    // Run Code!
    const activeFile = 'main.py';
    setRunning(true);
    terminal.writeAndScroll('python main.py');
    await pyodideClient.loadFile(activeFile, code);
    await pyodideClient.runCode(
      code,
      {
        name: activeFile,
        id: '',
      },
    );
    // await onRun(projectId, courseId); TODO do we want to log these runs?
    return;
  };

  const actionWidgetProps = {runCode, onComplete, onNext};
  const viewData = {title, tasks, onBack, actionWidgetProps};

  return (
    <div className="code-adventure d-flex flex-column h-100">
      {isTabletOrLarger ? (
        <DesktopView {...viewData} />
      ) : (
        <MobileView {...viewData} />
      )}
    </div>
  );
};

export const AssnPrompt = ({tasks, title}) => {
  return (
    <>
      <div className="flex-grow-1">
        <h4>Task: {title}</h4>
        <p>{tasks}</p>
      </div>
    </>
  );
};

export const LessonCodeEditor = () => {
  const {code, editorRef, setEditorLoaded} = useCodeAdventure();
  const onMount = editor => {
    editorRef.current = editor;
    setEditorLoaded(true);
  };

  // on unmount, indicate that the editor is no longer loaded
  useEffect(() => {
    return () => {
      editorRef.current = null;
      setEditorLoaded(false);
    };
  }, []);

  return (
    <Editor
      value={code}
      defaultLanguage={'python'}
      onMount={onMount}
      options={{
        readOnly: true,
        fontSize: 14,
        padding: {top: 0, bottom: 0},
        scrollBeyondLastColumn: 0,
        scrollBeyondLastLine: false,
        scrollbar: {
          vertical: 'auto',
          horizontal: 'hidden',
        },
        minimap: {
          enabled: false,
        },
      }}
    />
  );
};

export const CodeAdventureTerminal = () => {
  return (
    <NonIDETerminalView className="d-block h-100 mx-1 p-2 rounded bg-dark overflow-scroll" />
  );
};

export const AdventureControls = ({actionWidgetProps, onBack}) => {
  const {currentSlideNode, slideRootRef} = useCodeAdventure();

  return (
    <KarelSpeechBubble>
      <ActionWidget
        {...{...actionWidgetProps, ...currentSlideNode?.['widget']}}
        widget={currentSlideNode?.['widget']['type']}
        prompt={currentSlideNode?.['prompt']}
      >
        <Button
          variant="light"
          size="sm"
          className="mt-1"
          onClick={() => onBack()}
          disabled={currentSlideNode === slideRootRef.current}
        >
          <div className="d-flex flex-row align-items-center">Back</div>
        </Button>
      </ActionWidget>
    </KarelSpeechBubble>
  );
};

export const KarelSpeechBubble = ({children}) => {
  const {isTabletOrLarger} = useCodeAdventure();
  return (
    <div className="d-flex flex-row align-items-center px-2">
      <LargeImgIcon src={'/karel192.png'} alt={'Karel'} />
      <LeftPointingTriangle className="border-primary" />
      <div
        className={`speech-bubble ${isTabletOrLarger ? 'w-75 ' : ''}my-3 p-3 rounded bg-primary`}
      >
        {children}
      </div>
    </div>
  );
};

const LeftPointingTriangle = styled.div`
  position: relative;
  width: 0;
  height: 0;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-right: 20px solid;
  z-index: 1;
`;

const LargeImgIcon = styled.img`
  width: 50px;
  height: 50px;
`;
