import ErrorBoundary from "components/errors/ErrorBoundary";
import { Loading } from "components/loading/Loading";
import Gate from "contexts/Gate";
import { ProfileContext } from "contexts/ProfileContext";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useUserId } from "hooks/user/useUserId";
import { IDEProvider } from "ide/contexts/IDEContext";
import { pairProjectLoader } from "ide/hooks/loadIdeData";
import { CollabProvider } from "ide/sync/CollabContext";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { addTeachingTeamToRoom, useIsInRoom } from "../../firebase/realtime/Rooms";
import { PairProgramWithZoom } from "./PairProgramWithZoom";



const PairProgramWithVideoWithProvider = () => {
  return  (
    <IDEProvider useProject={pairProjectLoader}>
      <CollabProvider>
        <PairProgramWithZoom/>
      </CollabProvider>
    </IDEProvider>
  )
}


const ForceJoinPage = (props) => {
    const { courseId, roomId, uid } = props
    const { userData, loading } = useContext(ProfileContext)
    const displayName = userData?.displayName

    // Gracefully handle if they don't have userData
    if (!loading && userData === undefined) {
      const msg = `userData for ${uid} is undefined`
      console.error(msg)
      return <ErrorBoundary/>
    }

    if(loading) return <Loading />

    const canSeePage = Gate.hasAdminRole(userData)
    if (canSeePage) {
      return (
        <>
          <div
            style={{
              margin: 'auto',
              textAlign: 'center', // Center the text inside the container div
              width: '80%', // Set a fixed or relative width for the container div
              maxWidth: '600px', // Limit the maximum width of the container div
            }}
          >
            You are not currently in this session. Click below to force join the room.
          </div>
          <div
            style={{
              display: 'flex', // Use flex to center the button
              justifyContent: 'center', // Center the button horizontally
              marginTop: '1rem', // Add some margin at the top of the button
            }}
          >
            <Button onClick={() => addTeachingTeamToRoom(courseId, roomId, uid, displayName)}>Force Join Session</Button>
          </div>
        </>
      );
    }
    else {
      return (
        <>
        <div
          style={{
            margin: 'auto',
            textAlign: 'center', // Center the text inside the container div
            width: '80%', // Set a fixed or relative width for the container div
            maxWidth: '600px', // Limit the maximum width of the container div
          }}
        >
          You are not permitted to be in this session. Please contact course staff if you think this is a mistake.
        </div>
        <div
          style={{
            display: 'flex', // Use flex to center the button
            justifyContent: 'center', // Center the button horizontally
            marginTop: '1rem', // Add some margin at the top of the button
          }}
        >
        </div>
        </>
      )
    }
  };

export const CheckJoinPage = () => {
    const { courseId, roomId } = useParams();
    const userId = useUserId();
    const isInRoom = useIsInRoom(courseId, roomId, userId);


    if(isInRoom === undefined) return <Loading />

    if(isInRoom) {
        return <PairProgramWithVideoWithProvider />
    } else {
        return <ForceJoinPage courseId={courseId} roomId={roomId} uid={userId}/>
    }
}