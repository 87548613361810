import{useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {submitSLFeedback, useUserHasSentFeedback} from '../firebase/realtime/Rooms';
import {FaHome} from 'react-icons/fa';
import {useUserId} from 'hooks/user/useUserId';
import { useCourseId } from 'hooks/router/useUrlParams';

// LikertScale component remains the same as before.
const LikertScale = ({stuckLevel, setStuckLevel}) => {
  return (
    <div className="mt-4">
      <h5>How stuck was the student when you first joined?</h5>
      <div className="d-flex align-items-center justify-content-center">
        <span>Not stuck at all</span>
        <div className="d-flex flex-grow-1 justify-content-around">
          {[...Array(5)].map((_, i) => (
            <div key={i} style={{margin: '0 10px'}}>
              <input
                type="radio"
                name="stuckLevel"
                value={i}
                checked={stuckLevel === `${i}`}
                onChange={() => setStuckLevel(`${i}`)}
              />
            </div>
          ))}
        </div>
        <span>Very stuck</span>
      </div>
    </div>
  );
};

const SLFeedbackCard = ({sendFeedback}) => {
  const [comment, setComment] = useState('');
  const [feedbackChoice, setFeedbackChoice] = useState(undefined);
  const [stuckLevel, setStuckLevel] = useState('');

  const indicesToText = {
    0: 'Not now',
    1: 'It was not good',
    2: 'It was good, but could have been better',
    3: 'It was great!',
  };

  const handleFeedback = () => {
    if (feedbackChoice === undefined) return;
    sendFeedback(
      feedbackChoice,
      indicesToText[feedbackChoice],
      comment,
      stuckLevel,
    );
    // Reset the feedback form after submission if needed:
    setComment('');
    setStuckLevel('');
  };

  return (
    <div
      className="card d-flex flex-column align-items-center mx-auto mt-3"
      role="alert"
      style={{maxWidth: '30rem', backgroundColor: '#fff1d8'}}
    >
      <div className="card-body mt-1 text-center">
        <h5 className="card-title">How did that session go?</h5>
        <div className="d-flex flex-column justify-content-center">
          {Object.keys(indicesToText)
            .reverse()
            .map(index => (
              <button
                key={index}
                className={`btn btn-outline-primary mt-2 ${
                  feedbackChoice === index ? 'active' : ''
                }`}
                onClick={() => setFeedbackChoice(index)}
              >
                {indicesToText[index]}
              </button>
            ))}
        </div>
        <LikertScale stuckLevel={stuckLevel} setStuckLevel={setStuckLevel} />
        <div className="text-center" style={{marginTop: '50px'}}>
          Let us know if you have any feedback
        </div>
        <div className="row d-flex justify-content-center mt-2">
          <textarea
            className="form-control"
            placeholder=""
            value={comment}
            rows={3}
            onChange={e => setComment(e.target.value)}
          />
          <div className="mt-3 d-flex justify-content-center">
            <button className="btn btn-primary" onClick={handleFeedback}>
              Submit feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const GoHome = ({courseId}) => {
  const styleRetButton = {
    borderRadius: 5,
    padding: '10px',
    textDecoration: 'none',
  };
  return (
    <div className="mt-3">
      <div style={{width: '100%', textAlign: 'center'}}>
        <p>Head back to the home page to do more TeachNow</p>
      </div>
      <div className="d-flex justify-content-center">
        <Link
          to={`/${courseId}`}
          className="text-purple text-center"
          style={{...styleRetButton, color: '#b757f6'}}
        >
          <FaHome size={50} />
          <div>Go to home page</div>
        </Link>
      </div>
    </div>
  );
};

const ThanksForTheFeedback = () => {
  return (
    <div className="mt-3">
      <div style={{width: '100%', textAlign: 'center'}}>
        <p>Thank you for submitting feedback!</p>
      </div>
    </div>
  );
}


export const SLFeedbackPage = () => {
  const [roomId, setRoomId] = useState('');
  const userId = useUserId();
  const courseId = useCourseId();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasSentFeedback = useUserHasSentFeedback(userId, courseId, roomId);
  
  useEffect(() => {
    if (searchParams.has('r')) {
      setRoomId(searchParams.get('r'));
    }
  }, [searchParams]);

  if (roomId === '') return <></>;

  const sendFeedbackSL = async (val, text, comment, stuckLevel) => {
    try {
      await submitSLFeedback(
        val,
        text,
        comment,
        stuckLevel,
        roomId,
        userId,
        courseId,
      );
      // Optionally, add any success notification or redirection logic here.
    } catch (error) {
      console.error('Error submitting SL feedback: ', error);
    }
  };

  return (
    <div style={{padding: '5vh'}}>
      {
        hasSentFeedback
          ? <ThanksForTheFeedback />
          : <SLFeedbackCard sendFeedback={sendFeedbackSL} />
      }
      <GoHome courseId={courseId} />
    </div>
  );
};

export default SLFeedbackPage;
