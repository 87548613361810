import {ReactNode} from 'react';

/**
 * A namespace is a collection of translations for a specific purpose.
 */
export type NamespaceId = string;

/**
 * A language code is a two-letter code that represents a language.
 */
export type LanguageCode = string;

/**
 * Takes a provided key and returns the translation for that key.
 * The specific translation is determined by the namespace provided when the
 * function is created, the user's current course (if any), and the user's
 * current language.
 *
 * @param key The key to translate.
 * @returns The translated string.
 */
export type TranslationFunction = (key: ReactNode) => ReactNode;

/**
 * Maps supported language codes to user-friendly names.
 */
export const LANGUAGE_CODE_TO_NAME: Record<LanguageCode, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  zh: '汉语',
  hi: 'हिन्दी',
  ar: 'اَلْعَرَبِيَّةُ',
};

/**
 * Maps supported language codes to the direction of the text.
 */
export const LANGUAGE_CODE_TO_DIRECTION: Record<LanguageCode, 'ltr' | 'rtl'> = {
  en: 'ltr',
  es: 'ltr',
  fr: 'ltr',
  zh: 'ltr',
  hi: 'ltr',
  ar: 'rtl',
};

/**
 * Stores the most recent language selection so when an unauthenticated user
 * refreshes the page, they will be redirected to the page in their preferred
 * language. When we have a user, we store their preferred language, which
 * overrides the unauthenticated preferred language.
 */
export const UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY =
  'cip__unauthenticatedPreferredLanguage';

/**
 * The course ID to use when the course ID is not found in the URL params.
 */
export const FALLBACK_COURSE_ID = 'public';
