import { database } from "../../firebaseApp";
import { useEffect, useState } from "react";
import { firestore } from "../../firebaseApp";
import { onSnapshot, serverTimestamp } from "firebase/firestore";



///// REWRITTEN JAN 2025 FOR CIP5 WHICH DOES NOT USE REALTIME DATABASE ANYMORE /////
import { doc, getFirestore } from 'firebase/firestore';
import { useDocumentData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import type { CourseId } from "types/common";


const db = getFirestore();

export const useIsInRoom = (courseId, currentRoomId, uid) => {
  const userRoomPath = `teachnow/${courseId}/active_rooms/${uid}`;
  const roomDocRef = doc(db, userRoomPath);
  const [isInRoom, setIsInRoom] = useState(false);
  const [activeRoomData] = useDocumentData(roomDocRef);

  useEffect(() => {
      if (activeRoomData) {
          if (activeRoomData.roomId === currentRoomId) {
              setIsInRoom(true); // If document exists and roomId matches
          } else {
              setIsInRoom(false); // If document does exist, but roomId does not match
          }
      } else {
          setIsInRoom(false); // If document does not exist
      }
  }, [activeRoomData, currentRoomId]);

  return isInRoom;
};

export const useRoomInfoOnce = (courseId: CourseId, roomId: string): any => {
  const roomsPath = `teachnow/${courseId}/room_logs/${roomId}`;
  const roomsDocRef = doc(db, roomsPath);
  const [roomLogsData, roomLogsDataLoading] = useDocumentDataOnce(roomsDocRef);
  return roomLogsData;
};









////// EVERYTHING BELOW HERE IS FROM CIP4 OR PRIOR //////
export const ROOM_JOINED = "joined";
export const ROOM_EXITED = "exited";
export const ROOM_FORCE_EXITED = "force_exited";

const roomStatuses = [ROOM_JOINED, ROOM_EXITED, ROOM_FORCE_EXITED];

export const getRoomsPath = (courseId) => `${courseId}/teachnow/room_logs`;
const getLearnersPath = (courseId) => `${courseId}/teachnow/learners`;


// TODO: ACTIVE ROOM 

export const useActiveRoom = (uid, courseId): string | undefined => {
  const [activeRoom, setActiveRoom] = useState(undefined);

  useEffect(() => {
    if (uid) {
      const learnersPath = getLearnersPath(courseId);
      const activeRoomPath = `${learnersPath}/${uid}/activeRoom`;
      const activeRoomRef = database.ref(activeRoomPath);
      // const activeRoomRef = database.ref(`/${courseId}/learners/${uid}/activeRoom`)
      activeRoomRef.on('value', snap => {
        const val = snap.exists() ? snap.val() : null;
        setActiveRoom(val);
      });
      return () => {
        activeRoomRef.off();
      };
    }
  }, [uid, courseId]);
  return activeRoom;
};

// export const useRoomInfoOnce = (courseId: string, roomId: string): RoomInfo => {
//   const [roomInfo, setRoomInfo] = useState(undefined);

//   useEffect(() => {
//     const roomsPath = getRoomsPath(courseId);
//     const roomInfoPath = `${roomsPath}/info/${roomId}`;
//     const roomInfoRef = database.ref(roomInfoPath);
//     // get room info once

//     roomInfoRef.get().then((snap) => {
//       if (snap.exists()) {
//         setRoomInfo(snap.val());
//       } else {
//         console.log("Room does not exist");
//       }
//     });
//   }, [courseId, roomId]);

//   return roomInfo;
// };

export const useRole = (courseId, roomId, uid) => {
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    if (uid && roomId) {
      const roomsPath = getRoomsPath(courseId);
      const rolePath = `${roomsPath}/members/${roomId}/${uid}/role`;
      const pilotRef = database.ref(rolePath);
      pilotRef.on("value", (snap) => {
        if (snap.exists()) {
          // Roles: 'pilot', 'copilot', 'sl' (pilot/copilot for peer pilot/sl for office hours)
          setRole(snap.val());
        }
      });
    }
  }, [uid, roomId]);
  return role;
};

export const getRoomMembersUidToDisplayName = async (courseId, roomId) => {
  const roomsPath = getRoomsPath(courseId);
  const membersPath = `${roomsPath}/members/${roomId}`;
  const membersRef = database.ref(membersPath);
  const membersSnap = await membersRef.once('value');
  if (membersSnap.exists()) {
    const membersDict = membersSnap.val();
    const membersLst = Object.keys(membersDict);
    const uidToDisplayName = {};
    membersLst.forEach(m => {
      uidToDisplayName[m] = membersDict[m]['displayName'];
    });
    return uidToDisplayName;
  }
  return {};
};

export const useRoomMembers = (courseId, roomId) => {
  const [memberInfo, setMemberInfo] = useState(undefined);

  useEffect(() => {
    const roomsPath = getRoomsPath(courseId);
    const membersPath = `${roomsPath}/members/${roomId}`;
    const membersRef = database.ref(membersPath);
    const memberSnap = membersRef.on('value', snap => {
      if (snap.exists()) {
        const membersDict = snap.val();
        const membersLst = Object.keys(membersDict);
        const membersRequesting = membersLst.filter(
          m => membersDict[m]['isRequesting'],
        );
        const membersDisplayName = membersLst.map(
          m => membersDict[m]['displayName'],
        );
        const uidToPresenceData = {};
        const uidToDisplayName = {};
        membersLst.forEach(m => {
          uidToDisplayName[m] = membersDict[m]['displayName'];
          uidToPresenceData[m] = membersDict[m]['presence'];
        });
        const d = {
          members: membersLst,
          requesters: membersRequesting,
          displayNames: membersDisplayName,
          uidToDisplayName: uidToDisplayName,
          uidToPresenceData: uidToPresenceData,
        };

        setMemberInfo(d);
      }
    });
  }, [courseId, roomId]);

  return memberInfo;
};

export const addTeachingTeamToRoom = async (
  courseId,
  roomId,
  uid,
  displayName,
) => {
  const roomsPath = getRoomsPath(courseId);
  const roomMemberUidPath = `${roomsPath}/members/${roomId}/${uid}`;
  const teachingTeamRef = database.ref(roomMemberUidPath);
  try {
    await teachingTeamRef.set({
      displayName: displayName,
      isRequesting: false,
      role: 'extra-sl',
      status: 'joined',
    });
    return true;
  } catch (error) {
    return false;
  }
};

// export const useIsInRoom = (courseId, roomId, uid) => {
//   const [isInRoom, setIsInRoom] = useState(undefined);

//   useEffect(() => {
//     const roomsPath = getRoomsPath(courseId);
//     const membersPath = `${roomsPath}/members/${roomId}`;
//     const membersRef = database.ref(membersPath);
//     const onValueChange = (snap) => {
//       if (snap.exists() && snap.hasChild(uid)) {
//         setIsInRoom(true);
//       } else {
//         setIsInRoom(false);
//       }
//     };

    // Attach the listener

    // membersRef.on("value", onValueChange);

//     // Cleanup the listener when the component is unmounted or courseId, roomId, or uid change
//     return () => {
//       membersRef.off("value", onValueChange);
//     };
//   }, [courseId, roomId, uid]);

//   return isInRoom;
// };



export const submitSLFeedback = async (
  feedback,
  feedbackText,
  comment,
  stuckLevel,
  roomId,
  uid,
  courseId,
) => {
  const ref = firestore
    .collection('teachnow')
    .doc(courseId)
    .collection('tickets')
    .doc(roomId)
    .collection('slFeedback')
    .doc(uid);

  return ref.set({
    rating: feedback,
    ratingText: feedbackText,
    version: 'v1',
    studentStuckLevel: stuckLevel,
    additionalComments: comment,
    timestamp: Date.now(),
    timestampV2: serverTimestamp(),
  });
};

export const submitGratitude = async (gratitude, roomId, uid, courseId) => {
  const ref = firestore
    .collection('teachnow')
    .doc(courseId)
    .collection('tickets')
    .doc(roomId)
    .collection('gratitude')
    .doc(uid);

  return ref.set({
    gratitude: gratitude,
    version: 'v1',
    timestamp: Date.now(),
    timestampV2: serverTimestamp(),
  });
};

export const updateStudentComment = async (comment, roomId, uid, courseId) => {
  const ref = firestore
    .collection('teachnow')
    .doc(courseId)
    .collection('tickets')
    .doc(roomId)
    .collection('gratitude')
    .doc(uid);

  return ref.update({
    messageToSL: comment,
  });
};

export const useUserHasSentFeedback = (userId, courseId, roomId) => {
  const [hasSentFeedback, setHasSentFeedback] = useState(false);

  useEffect(() => {
    // Only set up the Firestore listener if all dependencies are valid
    if (!userId || !courseId || !roomId) return;

    const db = getFirestore();
    const docRef = doc(db, `teachnow/${courseId}/tickets/${roomId}/slFeedback/${userId}`);
    const unsubscribe = onSnapshot(docRef, snapshot => {
      setHasSentFeedback(snapshot.exists());
    });

    // Cleanup on unmount or when dependencies change
    return () => unsubscribe();
  }, [userId, courseId, roomId]);

  return hasSentFeedback;
};
