import {Button, Nav, Navbar, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
  FaUsers,
  FaPlay,
  FaStop,
  FaEdit,
  FaWindowClose,
  FaSyncAlt,
  FaDownload,
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaCog,
} from 'react-icons/fa';
import {useContext, useState, createRef, useEffect} from 'react';
import useMediaQuery, {Breakpoints} from '../utils/useMediaQuery';
import Swal from 'sweetalert2';
import {
  checkIsProjectConsole,
  checkIsProjectGraphics,
  checkIsProjectKarel,
  getAllFileNamesWithoutImages,
  getProjectFilesCode,
  updateProjectTitle,
  getAllImages,
  onAssignmentSubmit,
  getNextSubmissionId,
  publishProjectToWhatsapp
} from './utils/general';
import {IDEContext} from './contexts/IDEContext';
import {PeerSessionContext} from './contexts/PeerSessionContext';
import {useCourseId} from 'hooks/router/useUrlParams';

import {MeetingButtons} from './PairProgram/MeetingButtons';
import {PyodideContext} from 'components/pyodide/PyodideProvider';
import {areWorldsEqual} from 'components/pyodide/KarelLib/util';
import {DiagnosticNav} from 'diagnostic/DiagnosticNav';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {HomeExitButton} from './HomeExitButton';
import {useUserId} from 'hooks/user/useUserId';
import {isAdmin, isInstructor} from 'contexts/profileUtil';
import {ProfileContext} from 'contexts/ProfileContext';
import {CourseContext} from 'contexts/CourseContext';
import {useNavigate} from 'react-router';
import {canEditAssignment} from 'assignments/utils';
import {UnitTestButton} from './UnitTest/RunUnitTestButton';
import styled from 'styled-components';
import { CollabContext } from './sync/CollabContext';
import ShareProject from './ShareProject';
import { getFirestore, collection, getDoc, doc } from 'firebase/firestore';

const navBackgroundColor = '#d0e0ff'; //#ccddff
const sliderColor = '#3273f6';
export const runBtnColor = '#bb34fe';
const disabledSliderColor = '#cdd5e8';

const OverflowTitle = styled.code`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  min-width: 60px;
`;

export const NavIDE = ({
  isRunning,
  startStopButtonClicked,
  projectData,
  assnData,
  stepFunc,
  stepListSize,
  onPublish,
  fileStructure,
}) => {
  const ideContext = useContext(IDEContext);
  const {
    editable,
    karelWorldState,
    defaultKarelWorldState,
    isDiagnostic,
    isSubmitted,
    setIsSubmitted,
    whatsappProjectKey,
    filesCode
  } = ideContext;
  const navigate = useNavigate();
  const {isFeatureEnabled, courseCanvasAuthLink} = useContext(CourseContext);

  const { roomLoaded } = useContext(CollabContext);
  const courseId = useCourseId();
  const userId = useUserId();
  const isTablet = useMediaQuery(Breakpoints.TAB);
  const projectId = projectData.uid;
  const assnId = assnData?.metaData?.uid;
  const onSubmitLink = assnData?.metaData?.onSubmitLink;
  const shareUrl = `/${courseId}/share/${projectId}`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {userData} = useContext(ProfileContext);
  const isUserAdmin =
    isAdmin(userData.courseRole) || isInstructor(userData.courseRole);
  const userCanEditAssignment = canEditAssignment(userData);
  const [submissionTimestamp, setSubmissionTimestamp] = useState(null);
  const [title, setTitle] = useState(
    assnData?.metaData?.title ? assnData.metaData.title : projectData.title,
  );
  const gradesEnabled = isFeatureEnabled('grades');
  const nextSubmissionId = getNextSubmissionId(assnData, projectId);
  const isTabletOrLarger = useMediaQuery(Breakpoints.TAB);

  const [showShareProject, setShowShareProject] = useState(false);

  useEffect(() => {
    setIsSubmitted(assnData?.submissionData?.isSubmitted);
    console.log(assnData?.submissionData?.timestamp);
    setSubmissionTimestamp(assnData?.submissionData?.timestamp?.toDate());
  }, [assnData]);

  useEffect(() => {
    // pop loading screen
    if (isSubmitting && !(courseId === 'cs105f24')) {
      // check if Swal is already open

      Swal.fire({
        title: 'Submitting...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
        showLoaderOnConfirm: true,
      });
    }
  }, [isSubmitting]);

  const downloadProject = async () => {
    try {
      const files = getAllFileNamesWithoutImages(fileStructure);
      const filesCode = await getProjectFilesCode(projectData.uid);
      const images = await getAllImages(fileStructure);

      const zip = new JSZip();
      const folder = zip.folder(projectData.title);
      files.forEach(file => {
        if (filesCode.hasOwnProperty(file.id)) {
          folder.file(file.name, filesCode[file.id].content);
        }
      });

      for (var image of Object.keys(images)) {
        if (images[image]) {
          const response = await fetch(images[image]);
          const blob = await response.blob();
          folder.file(image, blob);
        }
      }

      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, `${projectData.title}.zip`);
    } catch (e) {
      await Swal.fire(`There was an error downloading your project`, e.message);
    }
  };

  const isRenamable = !assnData && editable;
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  const isGraphics = checkIsProjectGraphics(projectData, assnData);

  
  const checkUnique = async key => {
    // just return tue for now for teseting 
    const whatsappProjectRef = doc(getFirestore(), 'whatsappProjects', key);
    const whatsappProjectDoc = await getDoc(whatsappProjectRef);

    if (whatsappProjectDoc.exists()) {
      return false;
    }
    return true;
  };

  const onShareClick = () => {
    const files = getAllFileNamesWithoutImages(fileStructure);

    // If you don't have at least one file, then you shouldn't even be shown the modal 
    if (files.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You need to have at least one file to publish this app!',
      });
      return;
    }
    setShowShareProject(true);
  };



  if (isDiagnostic) {
    return (
      <DiagnosticNav
        isRunning={isRunning}
        startStopButtonClicked={startStopButtonClicked}
        isKarel={isKarel}
        karelWorldState={karelWorldState}
        defaultKarelWorldState={defaultKarelWorldState}
        editable={editable}
      />
    );
  }

  return (
    <Navbar
      style={{backgroundColor: navBackgroundColor}}
      variant="dark"
      className="fixed-top"
    >
      <Navbar.Brand
        style={{color: 'black', fontWeight: 600}}
        id="ide-project-title"
      >
        <HomeExitButton />
        {isTabletOrLarger && <OverflowTitle id="ide-title">IDE</OverflowTitle>}
        {isTabletOrLarger && ' | '}
        {isTabletOrLarger ? title : <span style={{  maxWidth: '80px', 
          overflow: 'hidden', 
          display: 'inline-block', 
          whiteSpace: 'nowrap', 
          textOverflow: 'ellipsis',
          verticalAlign: 'middle',
        }}>{title}</span>}
        {isRenamable ? (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Edit Project Name</Tooltip>}
          >
            <button
              id="rename-project-button"
              onClick={async () => {
                const {value: projectName, isConfirmed} = await Swal.fire({
                  title: 'Edit Project Name',
                  input: 'text',
                  inputValue: title,
                  inputLabel: 'What do you want your project name to be?',
                  showCancelButton: true,
                  inputValidator: value => {
                    if (!value) {
                      return "You can't have an empty project name!";
                    }
                  },
                });

                if (!isConfirmed) {
                  return;
                }

                await updateProjectTitle(projectData.uid, projectName);
                setTitle(projectName);
              }}
              style={{marginLeft: '10px'}}
              className="bg-transparent border-0"
            >
              <label className="visually-hidden">Edit Project Name</label>
              <FaEdit />
            </button>
          </OverlayTrigger>
        ) : null}
        {isTabletOrLarger && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Download Project</Tooltip>}
          >
            <button
              className="bg-transparent border-0"
              onClick={downloadProject}
              id="download-project"
            >
              <label className="visually-hidden">Download Project</label>
              <FaDownload />
            </button>
          </OverlayTrigger>
        )}
        {userCanEditAssignment && isTabletOrLarger && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Edit Assignment</Tooltip>}
          >
            <button
              className="bg-transparent border-0"
              onClick={() => navigate(`/${courseId}/assnEditor/${assnId}`)}
            >
              <label className="visually-hidden">Edit Assignment</label>
              <FaCog />
            </button>
          </OverlayTrigger>
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{justifyContent: 'space-between'}}
      >
        {/* This is the left side */}

        <Nav>
          <Nav.Item></Nav.Item>
        </Nav>

        {/* Not MOBILE */}
        {isTablet ? (
          <>
            <Nav>
              <StartStopButton
                isRunning={isRunning}
                startStopButtonClicked={startStopButtonClicked}
                isKarel={isKarel}
                karelWorldState={karelWorldState}
                defaultKarelWorldState={defaultKarelWorldState}
                editable={editable}
              />
              {assnId && !isKarel && <UnitTestButton />}
              <ReplaySlider stepFunc={stepFunc} stepListSize={stepListSize} />
            </Nav>

            {/* This is the right side */}
            <Nav>
              {roomLoaded ? <MeetingButtons /> : <></>}
              {gradesEnabled && assnId && !isUserAdmin && (
                <>
                  {isSubmitted ? (
                    <button
                      className="btn btn-small"
                      onClick={() => {
                        try {
                          navigator.clipboard.writeText(
                            `https://codeinplace.stanford.edu/${courseId}/ide/p/${projectId}`,
                          );
                          Swal.fire({
                            title: 'Link Copied',
                            html: `
                      <div>Link copied to clipboard</div>
                      <input value="https://codeinplace.stanford.edu/${courseId}/ide/p/${projectId}" readonly>
                    `,
                          });
                        } catch (e) {
                          Swal.fire({
                            title: 'Error',
                            html: `
                      <div>There was an error copying the link</div>
                      <p>Please copy it directly</p>
                      <input value="https://codeinplace.stanford.edu/${courseId}/ide/p/${projectId}" readonly>`,
                          });
                        }
                      }}
                    >
                      Copy Submission Link
                    </button>
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={() => {
                      setIsSubmitting(true);
                      onAssignmentSubmit(
                        courseId,
                        assnId,
                        userId,
                        projectId,
                        isSubmitted,
                        setIsSubmitted,
                        courseCanvasAuthLink,
                        !!onSubmitLink ? onSubmitLink : '',
                        navigate,
                        ideContext,
                      )
                        .then(() => {
                          setIsSubmitting(false);
                        })
                        .catch(e => {
                          setIsSubmitting(false);
                          Swal.fire('Error', e.message, 'error');
                        });
                    }}
                    className="btn btn-success btn-sm mr-2"
                    style={{width: '100px'}}
                  >
                    {isSubmitted ? 'Unsubmit' : 'Submit'}
                  </button>
                </>
              )}
              {gradesEnabled && assnId && isUserAdmin && (
                <>
                  {submissionTimestamp && courseId === 'cs105f24' ? (
                    <p>
                      Submitted At{' '}
                      {new Date(submissionTimestamp).toLocaleString()}
                    </p>
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={() => {
                      if (!nextSubmissionId) {
                        window.location.href = `/${courseId}/grading/${assnId}`;
                      } else {
                        window.location.href = `/${courseId}/ide/p/${nextSubmissionId}`;
                      }
                    }}
                    className="btn btn-success btn-sm mr-2"
                  >
                    Next Submission
                  </button>
                </>
              )}

              <button
                disabled={roomLoaded}
                id="share-button"
                onClick={onShareClick}
                className="btn btn-primary btn-sm mr-2"
              >
                <FaUsers /> Share
              </button>
              {showShareProject && (
                <ShareProject 
                  showShareProject={showShareProject}
                  setShowShareProject={setShowShareProject}
                  shareUrl={window.location.origin + shareUrl} 
                  checkUnique={checkUnique} 
                  title={title}
                />
              )}
              {/* <button className="btn btn-primary btn-sm">
                <FaHandshake /> Get Help
              </button> */}
            </Nav>
          </>
        ) : (
          // MOBILE VIEW
          <>
            <Nav>
              <StartStopButton
                isRunning={isRunning}
                startStopButtonClicked={startStopButtonClicked}
                isKarel={isKarel}
                karelWorldState={karelWorldState}
                defaultKarelWorldState={defaultKarelWorldState}
                editable={editable}
              />
              {assnId && !isKarel && <UnitTestButton />}
              {!assnId && <button
                disabled={roomLoaded}
                id="share-button"
                onClick={onShareClick}
                className="btn btn-primary btn-sm mr-2"
              >
                <FaUsers /> 
              </button>}
              {showShareProject && (
                <ShareProject 
                  showShareProject={showShareProject}
                  setShowShareProject={setShowShareProject}
                  shareUrl={window.location.origin + shareUrl} 
                  checkUnique={checkUnique} 
                  title={title}
                />
              )}
            </Nav>
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

const ReplaySlider = ({stepFunc, stepListSize}) => {
  const stepMin = 1;
  const {
    editable,
    stepMode,
    setStepMode,
    setLineNo,
    stepPtr,
    setStepPtr,
    hasHistory,
    karelWorldState,
    defaultKarelWorldState,
  } = useContext(IDEContext);
  const isDisabled = !hasHistory;

  return (
    <div
      className="d-flex flex-row justify-content-center gap-2"
      style={{
        opacity: isDisabled ? 0.4 : 1.0,
      }}
      id="replay-mode-stepper"
    >
      <div
        onClick={() => {
          if (stepPtr > stepMin && hasHistory) {
            setStepPtr(stepPtr - 1);
            setLineNo(stepFunc(stepPtr - 1));

            setStepMode(true);
          }
        }}
        style={{marginTop: '2px', cursor: 'pointer'}}
        id="replay-mode-left-button"
        aria-label="Replay Mode Left Button"
        aria-hidden={isDisabled}
      >
        <FaArrowAltCircleLeft
          color={isDisabled ? disabledSliderColor : sliderColor}
        />
      </div>
      <input
        id="stepslider"
        type="range"
        min={stepMin}
        max={stepListSize() - 1}
        value={stepPtr}
        onChange={e => {
          const stepIdx = Number(e.target.value);
          setStepPtr(stepIdx);
          setLineNo(stepFunc(stepIdx));

          setStepMode(true);
        }}
        disabled={!hasHistory}
        aria-label="replay"
      ></input>
      <div
        onClick={() => {
          if (stepPtr < stepListSize() - 1 && hasHistory) {
            setStepPtr(stepPtr + 1);
            setLineNo(stepFunc(stepPtr + 1));
            setStepMode(true);
          }
        }}
        style={{marginTop: '2px', cursor: 'pointer'}}
        id="replay-mode-right-button"
        aria-label="Replay Mode Right Button"
        aria-hidden={isDisabled}
      >
        <FaArrowAltCircleRight
          color={isDisabled ? disabledSliderColor : sliderColor}
        />
      </div>

      <div
        onClick={() => {
          setStepMode(false);
        }}
        style={{
          cursor: 'pointer',
          visibility: !stepMode ? 'hidden' : 'visible',
          marginTop: '2px',
        }}
        id="replay-mode-close-button"
      >
        <FaWindowClose color={sliderColor} />
      </div>
    </div>
  );
};

export const StartStopButton = ({
  isRunning,
  startStopButtonClicked,
  isKarel,
  defaultKarelWorldState,
  karelWorldState,
  editable,
}) => {
  const {
    karelWorldEditMode
  } = useContext(IDEContext);
  const {isPyodideLoading} = useContext(PyodideContext);
  const atStarterWorld = areWorldsEqual(
    defaultKarelWorldState,
    karelWorldState,
  );
  const startStopRef = createRef<HTMLButtonElement>();
  let buttonText = '';
  let buttonIcon;
  if (isKarel) {
    buttonText = isRunning ? 'Stop' : !atStarterWorld ? 'Reset' : 'Run';

    buttonIcon = isRunning ? (
      <FaStop />
    ) : !atStarterWorld ? (
      <FaSyncAlt />
    ) : (
      <FaPlay />
    );
  } else {
    buttonText = isRunning ? 'Stop' : 'Run';
    buttonIcon = isRunning ? <FaStop /> : <FaPlay />;
  }

  const isDisabled = isPyodideLoading || karelWorldEditMode;

  if (isPyodideLoading) {
    buttonText = 'Loading';
    buttonIcon = <></>;
  }

  return (
    <div>
      <button
        id="start-stop-button"
        style={{
          width: 80,
          backgroundColor: runBtnColor,
          borderColor: runBtnColor,
        }} // keep a constant width as you change text
        className="btn btn-primary btn-sm mr-2"
        disabled={isDisabled}
        onClick={() => {
          startStopRef.current.blur();
          startStopButtonClicked();
        }}
        ref={startStopRef}
      >
        {buttonIcon} {buttonText}
      </button>
    </div>
  );
};
