import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaLink, FaRemoveFormat } from "react-icons/fa";
import { useUserId } from "hooks/user/useUserId";
import { EmojiDropdown } from "../components/EmojiDropdown";
import { EditLinkButton } from "./buttons/EditLinkButton";

export const SimpleButtonBar = ({ editor, editable }) => {

  // users need to be logged in only if they are going to upload images
  const userId = useUserId()

  const textStyleColor = editor?.getAttributes('textStyle')?.color
  const dividerRight = { borderRight: "1px solid lightgrey" }
  if (!editable) return <></>;
  return (
    <div
      className="discussion-button-bar d-flex align-items-center"
      style={{
        top: "0",
        background: "white",
        border: "1px solid lightgrey",
        borderRadius: "5px 5px 0px 0px",
      }}
    >

    

      {/* Bold, italic, underline */}
      <div
        style={dividerRight}
      >
        


        <input
          className="btn btn-sm btn-light"
          type="color"
          style={{ width: 32, height: 31, paddingTop: '9px', paddingBottom: '5px' }}
          onInput={event => editor.chain().focus().setColor(event.target.value).run()}
          value={textStyleColor ? textStyleColor : 'black'}
        />

        <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="btn btn-sm btn-light"
        ><FaRemoveFormat /></button>
      </div>


      <DropdownButton id="text-align-dropdown" flip={true} variant="light" size="sm" title={<FaAlignLeft />}>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('left').run()}><FaAlignLeft /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('center').run()}><FaAlignCenter /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('justify').run()}><FaAlignJustify /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('right').run()}><FaAlignRight /></Dropdown.Item>
      </DropdownButton>


      <EmojiDropdown editor={editor} />





      {/* fancy tools */}



      <EditLinkButton editor={editor}/>

     

      


    </div>
  );
};


