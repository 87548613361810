import {IDEContext} from 'ide/contexts/IDEContext';
import {LeftColTitle} from 'ide/LeftCol';
import {useContext} from 'react';
import {isIDE} from 'components/duolessons/lessonchats/ChatConstants';

export const MoreItem = ({title, tab, onClick}) => {
  return (
    <div
      style={{
        padding: '16px',
        background: '#eeeeee',
        borderRadius: '12px',
        width: '47%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick(tab);
      }}
    >
      {title}
    </div>
  );
};
export const MoreScreen = ({onClick}) => {
  const {chatType} = useContext(IDEContext);

  return (
    <>
      <LeftColTitle title="More Actions" />
      <div className="p-3 d-flex flex-wrap flex-row gap-3">
        <MoreItem tab="Files" title="Files" onClick={onClick} />
        <MoreItem tab="FAQ" title="FAQs" onClick={onClick} />
        <MoreItem tab="Docs" title="Docs" onClick={onClick} />
        <MoreItem tab="Settings" title="Settings" onClick={onClick} />
        {isIDE(chatType) && (
          <MoreItem tab="Chat" title="Chat" onClick={onClick} />
        )}
      </div>
    </>
  );
};
