import {
  Sidebar,
  Menu,
  MenuItem,
  sidebarClasses,
  SubMenu,
} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import {
  FaBookmark,
  FaLinkedin,
  FaReddit,
  FaRocket,
  FaUser,
} from 'react-icons/fa';
import {useContext} from 'react';
import styled from 'styled-components';
import {
  bookMenuBackground,
  bookMenuStyles,
  bookRootStyles,
} from './MenuItemStyles';
import {SidebarHeader} from './SidebarTitle';
import {SidebarFooter} from './SodebarFooter';
import {partToPsetMap} from './psetMap';
import {BookContext} from 'textbook/context/BookContext';

export const BookSidebar = ({toggled, onBreakPoint, onBackdropClick}) => {
  return (
    <div>
      <Sidebar
        breakPoint="lg"
        width="270px"
        collapsedWidth="70px"
        collapsed={false}
        rootStyles={bookRootStyles}
        toggled={toggled}
        onBreakPoint={onBreakPoint}
        onBackdropClick={onBackdropClick}
      >
        <SidebarHeader />

        <Menu style={{flexGrow: 1}} menuItemStyles={bookMenuStyles}>
          <Parts />
        </Menu>
        <SidebarFooter />
      </Sidebar>
    </div>
  );
};

const Parts = () => {
  const {bookOutline, bookOutlineLoading} = useContext(BookContext);

  if (bookOutlineLoading) return <></>;

  const partList = bookOutline['outline'];

  return (
    <>
      {partList.map((partData, index) => {
        return <Part partData={partData} key={index} />;
      })}
    </>
  );
};

const Part = ({partData}) => {
  const {bookUrlRoot} = useContext(BookContext);
  const title = partData?.title;
  const sectionsList = partData?.sections;
  const examplesList = partData?.examples;
  const partKey = partData?.key;
  const partUrl = `${bookUrlRoot}/${partKey}`;

  const hasExamples = examplesList && examplesList.length > 0;

  // the part of the title after the colon (minus whitespace)
  const subname = title.split(':')[1]?.trim();

  return (
    <>
      <MenuItem
        icon={<FaBookmark />}
        component={<Link to={partUrl} />}
        id={`nav-${title}`}
      >
        {title}
      </MenuItem>
      {sectionsList.map((sectionData, index) => {
        return <ChapterLink chapterKey={sectionData} key={index} />;
      })}

      {hasExamples && (
        <SubMenu label="Stories" defaultOpen>
          {examplesList.map((exampleData, index) => {
            return (
              <ChapterLink
                chapterKey={exampleData}
                // icon={<FaRocket />}
                // icon={<span></span>} // Spacer icon to create indent
                marginLeft={5}
                key={index}
              />
            );
          })}
        </SubMenu>
      )}

      <PracticeLink partKey={partKey} subname={subname} />

      <PartSeparator />
    </>
  );
};

const PracticeLink = ({partKey, subname}) => {
  if (!(partKey in partToPsetMap)) {
    return <></>;
  }

  const psetId = partToPsetMap[partKey];

  return (
    <MenuItem
      icon={<FaRocket />}
      component={<Link to={`/cs109/pset/${psetId}/splash`} />}
    >
      {subname} Problems
    </MenuItem>
  );
};

const ChapterLink = ({chapterKey, marginLeft = 0, icon = undefined}) => {
  const {getChapterTitle, bookUrlRoot} = useContext(BookContext);
  const title = getChapterTitle(chapterKey);
  const chapterUrl = `${bookUrlRoot}/${chapterKey}`;
  return (
    <MenuItem
      icon={icon}
      component={<Link to={chapterUrl} />}
      id={`nav-${title}`}
    >
      <span
        style={{marginLeft: marginLeft}}
        dangerouslySetInnerHTML={{__html: title}}
      />
    </MenuItem>
  );
};

export const CourseMenuItem = ({title, icon, subpage, isLinkActive = true}) => {
  // TODO: fix this
  // const active = isActive(subpage)

  const courseId = useCourseId();

  return (
    <MenuItem
      component={isLinkActive ? <Link to={`/${courseId}/${subpage}`} /> : null}
      active={isLinkActive}
      id={`nav-${subpage}`}
      icon={icon}
    >
      {title}
    </MenuItem>
  );
};

export const InBoundMenuItem = ({title, icon, to}) => {
  return (
    <MenuItem icon={icon} component={<Link to={to} />} id={`nav-${title}`}>
      {title}
    </MenuItem>
  );
};

export const OutboundMenuItem = ({title, icon, to}) => {
  return (
    <MenuItem
      icon={icon}
      component={<Link target="_blank" to={to} />}
      id={`nav-${title}`}
    >
      {title}
    </MenuItem>
  );
};

export const Divider = () => {
  return <hr style={{margin: '10px'}} />;
};

const PartSeparator = styled.hr`
  margin: 11px;
  background-color: ${bookMenuBackground};
`;
