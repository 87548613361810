import {FaBorderStyle} from 'react-icons/fa';

export function BorderedBoxButton({editor}) {
  const insertBorderedBox = () => {
    const {$from, $to, from, to, empty} = editor.state.selection;

    const selectedText = editor.state.doc.textBetween(from, to);

    console.log(selectedText);

    editor.chain().focus();

    if (!empty) {
      // // Wrap selected text in a bordered-box
      editor
        .chain()
        .insertContentAt(
          {from, to},
          `<div class="bordered-box">${selectedText}</div>`,
        )
        .run();
    } else {
      // Insert an empty bordered-box if nothing is selected
      editor
        .chain()
        .insertContent('<div class="bordered-box"><p></p></div>')
        .setTextSelection(from + 1)
        .run();
    }
  };

  return (
    <button
      onClick={() => insertBorderedBox()}
      className="btn btn-sm btn-light"
    >
      <FaBorderStyle />
    </button>
  );
}
