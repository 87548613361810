import {useContext, useEffect, useState} from 'react';
import {Button, Modal, Dropdown} from 'react-bootstrap';
import {
  FaWhatsapp,
  FaWifi,
} from 'react-icons/fa';
import { checkIsProjectConsole, checkIsProjectGraphics, checkIsProjectKarel, getAllFileNamesWithoutImages, publishProjectToWhatsapp, publishProject, getRandLowerCaseString } from './utils/general';
import { IDEContext } from './contexts/IDEContext';
import { ProfileContext } from 'contexts/ProfileContext';
import styled from 'styled-components';
import { useCourseId } from 'hooks/router/useUrlParams';
import Swal from 'sweetalert2';

interface ShareProjectProps {
  showShareProject: boolean;
  setShowShareProject: (show: boolean) => void;
  shareUrl: string;
  checkUnique: (key: string) => Promise<boolean>;
  title: string;
}


const SelectFilesInner = ({updateMainFile, files}) => {
  const [mainFileId, setMainFileId] = useState(files && files.length > 0 ? files[0].id : "")


  useEffect(() => {
    updateMainFile(mainFileId)
  }, [mainFileId])

  if (!files || files.length < 1) {
    return <>No files to choose from</>
  }


  if(files.length == 1) {
    return <></>
  }


  return (
    <div >
    <label className='text-align-left w-100'>Select your main file </label>
    <select className='form-control' value={mainFileId}
    onChange={(e) => {
      setMainFileId(e.target.value)
    }}
    >
      {
        files.map((file) => {
          return (
            <option key={file.id} value={file.id}>{file.name}</option>
          )
        })
      }

    </select>
  </div>
  )

}


const KarelShareModalInner = ({files, goToSharedProject, mainFile, setMainFile }) => {


  return (
    <>
    <div className='d-flex justify-content-evenly'>
      <ShareButton className='bg-primary'
      onClick={goToSharedProject}
      >
          <span style={{
            fontSize: "xxx-large",
          }}><FaWifi/>  </span>
          <br/>
          Web
      </ShareButton>
    </div>
    <SelectFilesInner
      files={files}
      updateMainFile={(mfId) => setMainFile(mfId)}
    />
    </>
  )
}

const TerminalCheck = ({ showTerminal, setShowTerminal }) => {
  return (
    <div className="form-check mt-3 d-flex align-items-center justify-content-center gap-2">
      <input
        type="checkbox"
        className="form-check-input"
        id="showTerminal"
        checked={showTerminal}
        onChange={(e) => setShowTerminal(e.target.checked)}
      />
      <label className="form-check-label" htmlFor="showTerminal">
        Show Terminal?
      </label>
    </div>
  );
};

const GraphicsShareModalInner = ({files, showTerminal, setShowTerminal, setMainFile}) => {
  return (
    <>
    <SelectFilesInner files={files} updateMainFile={(mainFileId) => {setMainFile(mainFileId)}} />
    <TerminalCheck showTerminal={showTerminal} setShowTerminal={setShowTerminal}/>
    </>
  )
}


const ConsoleWhatsAppShareModalInner = ({files, goToSharedProject, goToWhatsAppProject, mainFile, setMainFile}) => {

  return (
    <div>
      <h1>Run your project on...</h1>
        <div className='d-flex justify-content-evenly'>
          <ShareButton style={{backgroundColor: "#25D366"}}
          onClick={goToWhatsAppProject}
          >
            <span style={{
                fontSize: "xxx-large"
              }}><FaWhatsapp/></span>
              <br/>
              WhatsApp
          </ShareButton>
          <ShareButton className='bg-primary'
          onClick={goToSharedProject}
          
          >
              <span style={{
                fontSize: "xxx-large",
              }}><FaWifi/>  </span>
              <br/>
              Web
          </ShareButton>
        </div>
        <SelectFilesInner files={files} updateMainFile={(mainFileId) => setMainFile(mainFileId)}/>
    </div>
  )
}

export const ShareProject = ({
  showShareProject,
  setShowShareProject,
  shareUrl,
  checkUnique,
  title
}: ShareProjectProps) => {
  const { assnData, projectData, filesCode, fileStructure, defaultKarelWorldState, whatsappProjectKey, setWhatsappProjectKey } = useContext(IDEContext)
  const { userData } = useContext(ProfileContext)
  const courseId = useCourseId()
  const [showTerminal, setShowTerminal] = useState(true)
  const [mainFile, setMainFile] = useState("")

  const onPublishWeb = async () => {
    await publishProject(
      projectData.uid,
      mainFile,
      fileStructure,
      filesCode,
      courseId,
      {
        displayName: userData.displayName,
        id: userData.id,
        photoURL: userData.photoURL ? userData.photoURL:""
      },
      assnData,
      showTerminal,
      defaultKarelWorldState,
      title
    )
    // open /{courseId}/share/{projectId} in a new tab
    
  }

  const onPublishWhatsapp = async () => {
    // check if whatsappkey exists
    let newKey = whatsappProjectKey
    console.log("whatsappKey", whatsappProjectKey)
    if (!whatsappProjectKey) {
      newKey = getRandLowerCaseString()
      while (!await checkUnique(newKey)) {
        newKey = getRandLowerCaseString()
      }
      console.log("new key", newKey)
    }
    console.log("new key", whatsappProjectKey)
    await publishProjectToWhatsapp(
      projectData.uid,
      mainFile,
      fileStructure,
      filesCode,
      {
        displayName: userData.displayName,
        id: userData.id,
        photoURL: userData.photoURL ? userData.photoURL:""
      },
      assnData,
      title,
      newKey
    )
    // open /{courseId}/share/{projectId} in a new tab
    setWhatsappProjectKey(newKey)
    return newKey
  }
  

  const goToSharedProject = async  () => {
        // navigate(`/${courseId}/share/${projectData.uid}`)
    // Open in new tab
    await onPublishWeb()
    window.open(`/${courseId}/share/${projectData.uid}`, '_blank');
    setShowShareProject(false)
  }

  // const goToWhatsAppProject = async () => {
  //   const newKey = await onPublishWhatsapp()
  //   // Open in new tab
  //   const whatsAppUrl = `/${courseId}/whatsapp/${newKey}`
  //   window.open(whatsAppUrl, '_blank');

  //   setShowShareProject(false)
  // }

  const goToWhatsAppProject = async () => {
    // Open a new tab immediately to keep the user gesture
    // if not exactly 1 file, say we do not support this
    if (!files || files.length !== 1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'We do not support sharing projects with multiple files on WhatsApp yet.',
      })
      return
    }
    const newWindow = window.open('', '_blank')
  
    const newKey = await onPublishWhatsapp()
    const whatsAppUrl = `/${courseId}/whatsapp/${newKey}`
  
    // Set the URL after async operation
    if (newWindow) {
      newWindow.location.href = whatsAppUrl
    }
  
    setShowShareProject(false)
  }

  const files = getAllFileNamesWithoutImages(fileStructure);
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  const isGraphics = checkIsProjectGraphics(projectData, assnData);

  return (
    <Modal
      show={showShareProject}
      onHide={() => setShowShareProject(false)}
      centered
    >
      <Modal.Body
        style={{
          textAlign: "center",
        }}
      >
      { isKarel || isGraphics &&
      <div className="d-flex justify-content-evenly">
        <button 
          className="btn btn-purple" 
          onClick={goToSharedProject}
          style={{ backgroundColor: 'purple', color: 'white', fontSize: 'large', padding: '10px 20px', borderRadius: '5px', border: 'none' }}
        >
            Publish
          </button>
        </div>
        }

        {isKarel && <KarelShareModalInner files={files} goToSharedProject={goToSharedProject} mainFile={mainFile} setMainFile={setMainFile}/>}
        {isGraphics && <GraphicsShareModalInner files={files} showTerminal={showTerminal} setShowTerminal={setShowTerminal} setMainFile={setMainFile}/>}
        {isConsole && <ConsoleWhatsAppShareModalInner files={files} goToSharedProject={goToSharedProject} goToWhatsAppProject={goToWhatsAppProject} mainFile={mainFile} setMainFile={setMainFile}/>}
      </Modal.Body>
    </Modal>
  );
};


// add onhover
const ShareButton = styled.button`
  border-radius: 10px;
  border: none;
  padding: 30px;
  font-size: x-large;
  width: 50%;
  margin: 10px;
  color: white;
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
`


// const onShareClick = async ({fileStructure, isConsole, isGraphics}) => {
//   const files = getAllFileNamesWithoutImages(fileStructure);

//   const hasMoreThanOneFile = files.length > 1;
//   let publishStatus = false;

//   // Check if there are any extra files
//   let showTerminalVal = isConsole ? true : false;
//   let showTerminalConfirmedVal = true;

  // if (isGraphics) {
  //   const {
  //     value: showTerminal,
  //     isConfirmed: showTerminalConfirmed,
  //   } = await Swal.fire<boolean>({
  //     title: 'Do you want to show the terminal?',
  //     input: 'checkbox',
  //     // Checkboxes represent boolean values as 0 or 1 in Swal
  //     inputValue: Number(true),
  //     inputPlaceholder: 'Show Terminal?',
  //     confirmButtonText:
  //       'Continue <i class="fa fa-arrow-right"></i>',
  //   });

  //   showTerminalVal = showTerminal;
  //   showTerminalConfirmedVal = showTerminalConfirmed;
  // }

//   if (hasMoreThanOneFile) {
//     const listFiles = {};
//     for (let i = 0; i < files.length; i++) {
//       listFiles[files[i].id] = files[i].name;
//     }

//     const {value: mainFile, isConfirmed: isConfirmed} =
//       await Swal.fire({
//         title: 'Select Your Main File!',
//         text: 'What is the main file you want to run for your published app?',
//         input: 'select',
//         confirmButtonText: 'Publish!',
//         inputOptions: listFiles,
//         inputPlaceholder: 'Select a file',
//         showCancelButton: true,
//         inputValidator: result =>
//           !result && 'You need to select a file!',
//       });

//     if (isConfirmed) {
//       publishStatus = await onPublish(
//         mainFile,
//         !!showTerminalVal,
//         title,
//       );
//     }
//   } else {
//     const mainFile = files[0].id;

//     publishStatus = await onPublish(
//       mainFile,
//       !!showTerminalVal,
//       title,
//     );
//   }

//   if (isKarel) {
//     if (publishStatus && showTerminalConfirmedVal) {
//       const {isConfirmed: isConfirmed} = await Swal.fire({
//         title: 'Congrats!',
//         html:
//           `
//             <div><b>You just published your project!</b></div><br />
//             <input value=` +
//           window.location.origin +
//           shareUrl +
//           ` />
//           `,
//         icon: 'success',
//         confirmButtonText: 'View your project!',
//       });

//       if (isConfirmed) {
//         window.open(shareUrl, '_blank');
//       }
//     } else {
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         html: `We couldn't publish your project. Make sure to <a href="https://codeinplace.stanford.edu/cip3/profile"> set your display name.</a>`,
//       });
//     }
//   } else {
//     setShowShareProject(true);
//   }
// };




export default ShareProject;









// const handleGenerateMessage = async () => {
//   // if there is no unique key, show an error message
//   if (!uniqueKey) {
//     setErrorMessage('Please enter a key.');
//     return;
//   }
//   setIsCheckingKey(true);
//   setErrorMessage('');
//   // check if the key is unique
//   const unique = await checkUnique(uniqueKey);
//   if (!unique) {
//       setErrorMessage('Please generate a unique key.');
//       setGeneratedText('');
//   } else {
//       const success = await publishProjectToWhatsapp(
//         projectData.uid,
//         mainFile,
//         fileStructure,
//         filesCode,
//         userData,
//         assnData,
//         title,
//         uniqueKey
//       )
//       if (!success) {
//         setErrorMessage('Error generating message. Please try again.');
//         setGeneratedText('');
//       } else {
//         setWhatsappProjectKey(uniqueKey);
//         setGeneratedText(getWhatsappDefaultText(uniqueKey));
//       }
//     }
// };




// useEffect(() => {
//   if (!whatsappProjectKey) return;
//   setGeneratedText(getWhatsappDefaultText(whatsappProjectKey));
// }, [whatsappProjectKey])

// // 1) For the first share dropdown (the link)
// // Include a short message plus the URL
// const linkShareMessage = `Check out what I built on Code in Place: ${shareUrl}`;
// const encodedLinkShareMessage = encodeURIComponent(linkShareMessage);

// // 2) For the second share dropdown (the generated text)
// const encodedGeneratedText = encodeURIComponent(generatedText);