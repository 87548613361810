import React, { useState, useEffect, useContext, useRef } from 'react'; // every page needs to import react

// if you want to interact with the database
import 'firebase/compat/firestore';
import 'react-tabs/style/react-tabs.css';
import AssnPrompt from '../AssnPrompt/AssnPrompt';


import { FileEditor } from '../Editor/Editor';
import {
  getPromptPath,
  getSolnPath,
  isNullOrUndefined,
} from '../../utils/general';
import FileTree from '../Files/FileTree';
import { IDEContext } from '../contexts/IDEContext';
import { LeftColTitle } from '../LeftCol';
import { DocsSplash } from '../LeftCols/DocsSplash';
import styled from 'styled-components';
import { SettingsView } from '../LeftCols/Settings';

import { IDEChat } from 'components/duolessons/lessonchats/IDEChat';
import { inferShowSolutionBehavior } from '../../assignments/models/assignmentMetadata';
import { CompletionContext } from 'contexts/CompletionContext';
import { MobileOutput } from './output/MobileOutput';
import { getAllFileNames } from 'ide/utils/general';
import { BottomNavBar } from './bottomBar/BottomNavBar';
import { MoreScreen } from './more/MobileMoreTab';

const bottomBarHeight = 85;

export const MobileIDEView = ({
  onFileCodeUpdate,
  revertToStarterFn,
  onChangeFile,
}) => {
  const {
    projectData,
  } = useContext(IDEContext);

  const promptPath = getPromptPath(projectData);
  const hasAssn = !isNullOrUndefined(promptPath);
  const bottomBarRef = useRef(null);
  return (
    <ContentOuter>
      <Content
        onFileCodeUpdate={onFileCodeUpdate}
        revertToStarterFn={revertToStarterFn}
        onChangeFile={onChangeFile}
        bottomBarRef={bottomBarRef}
      />

      {/* The mobile output is always mounted,
       so that its state doesn't get cleared when you switch tabs */}
      <MobileOutput />
      
      <BottomNavBar
        hasAssn={hasAssn}
        hasMore={true}
        bottomBarRef={bottomBarRef}
      />
    </ContentOuter>
  );
};

const Content = ({
  onFileCodeUpdate,
  revertToStarterFn,
  onChangeFile,
  bottomBarRef,
}) => {
  const {
    selectedMobileTab,
    setSelectedMobileTab,
    projectData,
    fileStructure,
    setFileStructure,
    assnData,
    currentFile,
  } = useContext(IDEContext);

  const showSolutionBehavior = inferShowSolutionBehavior(assnData?.metaData);
  const promptPath = getPromptPath(projectData);
  const solnPath = getSolnPath(projectData);
  const { isAssnCompleted } = useContext(CompletionContext);
  const assignmentId = assnData?.metaData?.uid ?? projectData?.uid;
  const isAssignmentCompleted = isAssnCompleted(assignmentId);

  if (selectedMobileTab === 'Problem') {
    return <AssnPrompt
      revertToStarterFn={revertToStarterFn}
      promptPath={promptPath}
      solnPath={solnPath}
      assnTitle={projectData.title}
      showSolutionBehavior={showSolutionBehavior}
      isAssignmentCompleted={isAssignmentCompleted}
      bottomBarRef={bottomBarRef}
    />
  }

  if (selectedMobileTab === 'Code') {
    return <FileEditor
      currFile={currentFile}
      allFiles={getAllFileNames(fileStructure)}
      onFileCodeUpdate={onFileCodeUpdate}
      projectId={projectData.uid}
      revertToStarterFn={revertToStarterFn}
    />
  }

  if (selectedMobileTab === 'More') {
    return <MoreScreen
      onClick={tab => {
        setSelectedMobileTab(tab);
      }}
    />
  }

  if (selectedMobileTab === 'Files') {
    return <FileTree
      projectId={projectData.uid}
      fileStructure={fileStructure}
      setFileStructure={setFileStructure}
      onChangeFile={onChangeFile}
      currFile={currentFile}
      isMobile={true}
      switchToCodeTab={() => {
        setSelectedMobileTab('Code');
      }}
      isCreative={undefined}
      onFileCodeUpdate={() => { }}
    />
  }

  if (selectedMobileTab === 'FAQ') {
    return <LeftColTitle title="Coming Soon!" />
  }

  if (selectedMobileTab === 'Docs') {
    return <DocsSplash bottomBarRef={bottomBarRef} />
  }

  if (selectedMobileTab === 'Settings') {
    return <SettingsView />
  }

  if (selectedMobileTab === 'Chat') {
    return <IDEChat bottomBarRef={bottomBarRef} />
  }

  return null;
};

const ContentOuter = styled.div`
  display: flex;          /* We want a flex container */
  flex-direction: column;
  flex: 1;                /* let it grow to fill parent */
  min-height: 0;          /* critical: prevents child from pushing this container taller */
  padding-top: 55px;      /* your existing styles */
`;

