import {ProfileContext} from 'contexts/ProfileContext';
import {UserIcon} from 'course/forum/components/UserChip/UserChip';
import LogOnClick from 'engagement/LogOnClick';
import {getAuth, signOut} from 'firebase/auth';
import {useContext} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const WelcomeNav = () => {
  return (
    <NavOuter>
      <div className="container-fluid container-figure w-100">
        <NavInner>
          <NavLeft>
            {/* Left */}
            <LogOnClick
              eventName="select_content"
              eventParams={{
                content_type: 'button',
                content_id: 'welcome_to_home_nav_button',
              }}
            >
              <Link to="/">
                <StanfordTitle>Stanford</StanfordTitle>
                <NavTitle>| Code in Place</NavTitle>
              </Link>
            </LogOnClick>
          </NavLeft>
          {/* Right */}
          <NavRight />
        </NavInner>
      </div>
    </NavOuter>
  );
};

const NavRight = ({}) => {
  return (
    <RightButtons>
      <LoginViewCourseButton />
    </RightButtons>
  );
};

const LoginViewCourseButton = () => {
  const auth = getAuth();

  return (
    <>
      <ProfileNav />
      <LogOnClick
        eventName="sign_out"
        eventParams={{
          content_type: 'button',
          selection_context: 'welcome_nav',
        }}
      >
        <button onClick={() => signOut(auth)} className="btn btn-light">
          Log Out
        </button>
      </LogOnClick>
    </>
  );
};

const ProfileNav = () => {
  const {userData} = useContext(ProfileContext);

  const photoUrl = userData ? userData.photoURL : '';
  const displayName = userData ? userData.displayName : 'A';
  if (!photoUrl || !displayName) {
    return null;
  }
  return (
    <>
      <UserIcon photoURL={photoUrl} name={displayName} />
    </>
  );
};

/* --- Styled Components --- */

const RightButtons = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const NavOuter = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff; /* Ensures the nav doesn't appear transparent */
  z-index: 1000; /* Keeps the nav on top of other elements */

  /* Only when the .scrolled-past class is present do we show the bottom border */
  &.scrolled-past {
    border-bottom: 2px solid lightgrey;
    transition: border-bottom 0.1s ease-in-out;
  }

  @media (max-width: 768px) {
  }
`;

const NavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const NavLeft = styled.div``;

const NavTitle = styled.span`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  color: black;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const StanfordTitle = styled.span`
  display: inline-block;
  font-family: Stanford, 'Source Serif Pro', Georgia, Times, 'Times New Roman',
    serif;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  transform: translateZ(0);
  letter-spacing: 0;
  font-feature-settings: 'liga';
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #8c1515;
  font-size: 36px;
  line-height: 40px;
  vertical-align: bottom;
  margin-right: 10px;
  padding-right: 5px;
  background-color: white;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    padding-right: 0px;
  }
`;
