import * as Babel from '@babel/standalone';
import {transformImportsExportsPlugin} from './transformImportsExportsPlugin';
import * as React from 'react';
import {componentMainFile} from './ReactInTipTap';

export const evaluateCode = async (
  files,
  availableModules,
  setRenderedComponent,
  setErrorMessage,
) => {
  console.log('files', files);

  const imports = {};

  try {
    const code = files[componentMainFile];

    setErrorMessage(null); // Clear any previous errors

    // Collect import statements using a regular expression
    const importRegex = /^import\s+([\s\S]+?)\s+from\s+['"]([^'"]+)['"];?$/gm;
    const importMatches = [...code.matchAll(importRegex)];

    for (const match of importMatches) {
      const [fullMatch, importedEntities, modulePath] = match;

      if (availableModules[modulePath]) {
        // Handle different import styles
        if (
          importedEntities.startsWith('{') &&
          importedEntities.endsWith('}')
        ) {
          // Named imports
          const names = importedEntities
            .slice(1, -1)
            .split(',')
            .map(s => s.trim());
          for (const name of names) {
            if (name === 'React') {
              // Skip adding React to imports to prevent overriding
              continue;
            }
            if (availableModules[modulePath][name]) {
              imports[name] = availableModules[modulePath][name];
            } else {
              throw new Error(
                `Module "${modulePath}" does not export "${name}".`,
              );
            }
          }
        } else if (importedEntities.startsWith('* as ')) {
          // Namespace import
          const name = importedEntities.slice(5).trim();
          imports[name] = availableModules[modulePath];
        } else {
          // Default import
          const name = importedEntities.trim();
          if (name === 'React') {
            continue;
          }
          imports[name] =
            availableModules[modulePath].default ||
            availableModules[modulePath];
        }
      } else {
        throw new Error(
          `Module "${modulePath}" is not available. Available modules: ${Object.keys(
            availableModules,
          ).join(', ')}`,
        );
      }
    }

    // Transpile the code using Babel with the custom plugin
    const transpiledCode = Babel.transform(code, {
      presets: [['react', {runtime: 'classic'}]],
      plugins: [transformImportsExportsPlugin],
      compact: false, // Prevent code minification
      minified: false, // Prevent variable name mangling
      babelrc: false, // Disable .babelrc file
      configFile: false, // Disable babel.config.js file
    }).code;

    // Dynamically evaluate the code with resolved imports
    const func = new Function(
      'React', // Always inject React
      ...Object.keys(imports), // Inject resolved imports
      `${transpiledCode}; return InteractiveDemo || __default__;`, // Return the exported component
    );

    // Call the function with React and the resolved imports
    const Component = func(React, ...Object.values(imports));

    // Validate that Component is a valid React component
    if (
      typeof Component === 'function' ||
      (typeof Component === 'object' && Component !== null)
    ) {
      setRenderedComponent(() => props => (
        <Component {...props} files={files} />
      ));
    } else {
      throw new Error(
        'The evaluated code did not return a valid React component.',
      );
    }
  } catch (err) {
    // Display error messages if the evaluation fails
    setErrorMessage(`Evaluation Error: ${err.message}`);
    setRenderedComponent(null);
  }
};
