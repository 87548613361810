import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, FaCode, FaFont, FaHeading, FaImage, FaItalic, FaLink, FaPaintBrush, FaParagraph, FaPython, FaQuoteLeft, FaRemoveFormat, FaSquare, FaSquareRootAlt, FaTerminal, FaTextHeight, FaUnderline, FaShapes, FaMap, FaCalendarDay } from "react-icons/fa";
import FaKarel from "../../../pyodide/KarelLib/images/classic/karelEast.png"
import { onInsertImage } from "../extensions/Image";
import { useUserId } from "hooks/user/useUserId";
import Swal from "sweetalert2";
import { EditLinkButton } from "./buttons/EditLinkButton";
import { EmojiDropdown } from "../components/EmojiDropdown";
import { ImageButton } from "./buttons/ImageButton";
import { onInsertDateTime } from "../extensions/LocalDateTime";
import { ReactInTipTapButton } from "./buttons/ReactInTipTapButton";
import { ColorPicker } from "./buttons/ColorPicker";
import { BorderedBoxButton } from "./buttons/BorderedBoxButton";


export const TextbookButtonBar = ({ editor, editable }) => {

  // users need to be logged in only if they are going to upload images
  const userId = useUserId()

  const textStyleColor = editor?.getAttributes('textStyle')?.color
  const dividerRight = { borderRight: "1px solid lightgrey" }
  if (!editable) return <></>;
  return (
    <div
      className="discussion-button-bar d-flex align-items-center"
      style={{
        top: "0",
        background: "white",
        border: "1px solid lightgrey",
        borderRadius: "5px 5px 0px 0px",
      }}
    >

      {/* Basic text */}
      <div style={dividerRight} >

        <button
          onClick={() => editor.chain().focus().insertContent(`<p></p>`).run()}
          className={"btn btn-sm btn-light "}
        >
          <FaParagraph />
        </button>
        <button
          onClick={() => editor.chain().focus().insertContent(`<h3></h3>`).run()}
          className={"btn btn-sm btn-light "}

        >
          <FaHeading />
        </button>

        <button
          onClick={() => editor.chain().focus().insertContent(`<h4></h4>`).run()}
          className={"btn btn-sm btn-light "}

        >
          H4
        </button>
        <button
          onClick={() => editor.chain().focus().insertContent(`<br/>`).run()}
          className={"btn btn-sm btn-light "}

        >
          Br
        </button>
      </div>

      <BorderedBoxButton editor={editor} />

      {/* Bold, italic, underline */}
      <div
        style={dividerRight}
      >
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className="btn btn-sm btn-light"
        ><FaBold /></button>

        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className="btn btn-sm btn-light"
        ><FaItalic /></button>

        <button
          onClick={() => {
            editor.chain().focus().setNode('terminal').run();
          }}
          className="btn btn-sm btn-light"
        >
          <FaTerminal /> {/* Assuming you have an icon component FaTerminal */}
        </button>


        <ColorPicker editor={editor}/>

        <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="btn btn-sm btn-light"
        ><FaRemoveFormat /></button>
      </div>


      <DropdownButton id="text-align-dropdown" flip={true} variant="light" size="sm" title={<FaAlignLeft />}>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('left').run()}><FaAlignLeft /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('center').run()}><FaAlignCenter /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('justify').run()}><FaAlignJustify /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('right').run()}><FaAlignRight /></Dropdown.Item>
      </DropdownButton>


      <EmojiDropdown editor={editor} />


      <button
        onClick={() =>
          editor.chain().focus()
            .insertContent("<karelworld></karelworld><p></p>")
            .run()
        }
        className="btn btn-sm btn-light"
      >
        <FaMap />
      </button>

      <EditLinkButton editor={editor}/>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={"btn btn-sm btn-light "}
      >
        <FaCode />
      </button>

      <div style={dividerRight}>

        <ImageButton editor={editor}/>

        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={"btn btn-sm btn-light "}
        >
          <FaQuoteLeft />
        </button>


        <button
          onClick={() =>
            editor.chain().focus()
              .insertContent("<runnable-karel></runnable-karel><p></p>")
              .run()
          }
          className="btn btn-sm btn-light karel-icon-img"
        >
          <img src={FaKarel} className="karel-icon" />
        </button>

        <button
          onClick={() =>
            editor.chain().focus()
              .insertContent("<runnable-graphics></runnable-graphics><p></p>")
              .run()
          }
          className={"btn btn-sm btn-light "}
        >
          <FaShapes />
        </button>

        <button 
          onClick={() => {
            onInsertDateTime(editor)
          }}
          className={"btn btn-sm btn-light "}
        >
          <FaCalendarDay />
        </button>

        <ReactInTipTapButton editor={editor}/>
      </div>



    </div>
  )
};


