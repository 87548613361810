import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRunning } from "react-icons/fa";

export function RunsDisplay(props: {
  numRuns: number;
  iconSize: string | number;
  showNumber?: boolean;
  disabled?: boolean;
}) {
  const { showNumber, iconSize, numRuns, disabled } = props;

  const runColor = "#ac3ff5"



  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Runs
        </Tooltip>
      )}
    >
      <div className="like-button-container">
        <FaRunning
          size={iconSize}
          className="likeButton"
          style={{ color: runColor }}
        />

        {showNumber && <span style={{color: "gray"}}>{numRuns}</span>}
      </div>
    </OverlayTrigger>
  );
}
