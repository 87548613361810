import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import {reactInTipTapStorageRoot, useFile, useImageUrl} from './useFile'; // Assuming useFileContent fetches file content
import {useEffect, useState} from 'react';
import styled from 'styled-components';

export const JpgFileViewer = ({fileContent}) => {
  const fileId = fileContent; // The ID of the uploaded file
  const [imageUrl, loading, error] = useImageUrl(fileId);

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <div>Loading image...</div>;
  }

  return <img src={imageUrl} alt="Uploaded JPG" style={{maxWidth: '100%'}} />;
};

export const JsonFileViewer = ({fileContent}) => {
  console.log('JsonFileViewer fileContent', fileContent);
  const fileId = fileContent; // The ID of the uploaded file
  const [content, loading, error] = useFile(fileId); // Fetch file content

  const maxEntries = 20;

  const renderContent = () => {
    if (!content) {
      return '';
    }
    try {
      const parsedJson = JSON.parse(content);
      const nOriginalKeys = Object.keys(parsedJson).length;
      if (nOriginalKeys <= maxEntries) {
        return JSON.stringify(parsedJson, null, 2);
      }
      const keys = Object.keys(parsedJson).slice(0, maxEntries); // Show only first few keys
      const truncatedJson = {};
      keys.forEach(key => (truncatedJson[key] = parsedJson[key]));
      return JSON.stringify(truncatedJson, null, 2) + '\n\n...and more!';
    } catch (err) {
      return `Invalid JSON: ${err.message}`;
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading file: {error}</p>;
  }

  return (
    <TextFileOuter>
      <JsonFile>{renderContent()}</JsonFile>
    </TextFileOuter>
  );
};

export const CsvFileViewer = ({fileContent}) => {
  const fileId = fileContent; // The ID of the uploaded file
  const [content, loading, error] = useFile(fileId); // Fetch file content
  const [parsedData, setParsedData] = useState([]);
  const [maxColumns, setMaxColumns] = useState(0);
  const [parseError, setParseError] = useState(null);

  useEffect(() => {
    if (content && !loading) {
      try {
        // Parse CSV content
        const rows = parseCSV(content);
        setParsedData(rows);

        // Determine the maximum number of columns
        const maxCols = Math.max(...rows.map(row => row.length));
        setMaxColumns(maxCols);
      } catch (err) {
        setParseError(`Failed to parse CSV: ${err.message}`);
      }
    }
  }, [content, loading]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading file: {error}</p>;
  }

  if (parseError) {
    return <p>{parseError}</p>;
  }

  if (!parsedData.length) {
    return <p>No data available in the CSV file.</p>;
  }

  return (
    <StyledTable>
      <tbody>
        {parsedData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({length: maxColumns}, (_, colIndex) => (
              <td key={colIndex}>{row[colIndex] || ''}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const parseCSV = csvContent => {
  return csvContent
    .trim()
    .split('\n')
    .map(row => row.split(',').map(cell => cell.trim()));
};

// Styled Table Component
const StyledTable = styled.table`
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  thead th {
    background-color: #f8f9fa;
    padding: 10px;
    border: 1px solid #dee2e6;
  }
  tbody td {
    padding: 10px;
    border: 1px solid #dee2e6;
  }
`;

const TextFileOuter = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const JsonFile = styled.pre`
  background: #f6f8fa;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
`;
