/**
 * @fileoverview Contains elements for rendering the /{courseId}/aboutcourse page.
 * If the courseId is for a live-CIP course, we also render expectations for
 * the various roles as a syllabus to clearly outline expectations for each
 * role in our course.
 */

import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { StudentCard, ExperiencedStudentCard, MentorCard, SectionLeaderCard, AdminCard } from "course/expectations/ExpectationsCards";
import { ProfileContext } from 'contexts/ProfileContext';
import { CourseContext } from "contexts/CourseContext";
import { isMinimumRole } from "contexts/profileUtil";
import { Role } from "types/role";
import { TabSystem } from "components/tabbableContent/TabSystem";
import { CoursePageSingleCol } from 'components/layout/CoursePageSingleCol';

/**
 * Contains either default and basic information about Code in Place
 * or, for live CIP courses, the basic CIP info and the expecatations
 * for the user's role and any non-similarly-permissioned roles beneath them.
 * (e.g., SLs cannot see mentors' expectations, but both can see student and
 * experienced student expectations.)
 * 
 * @returns {JSX.Element} rendered {courseId}/aboutcourse page
 */
export const AboutCourse = () => {
  const { isCodeInPlaceCourse, isFoothill } = useContext(CourseContext);
  const courseId = useCourseId();

  /**
    We eventually need some course context for checking if a course is a live-CIP course (e.g. CIP3, CIP4).
    This is literally checking to see if the course is anything but a live-CIP course but doesn't scale
    well as we expand.
  */
  if (isFoothill || !isCodeInPlaceCourse || (courseId && courseId === "public")) {
    return <DefaultAboutCourse />;
  }

  return <CoursePageSingleCol column={<ExpectationsPage />}/>
}

/**
 * This is the default behavior for courses which uses the associated
 * courseId's default about-page information pulled from firebase.
 * No other expectations or cards are rendered. Essentially just here
 * for visual clarity and easy modifications later on.
 * 
 * @returns {JSX.Element} default "About Course" information pulled from firebase alongside default instructor cards
 */
export const DefaultAboutCourse = () => {
  return <SinglePageExpectations />;
}

/**
 * Content container for rending an instructor card which is in the style of a Polaroid image
 * 
 * @param {Object} props - component properties
 * @param {string} props.imagePath - path to instructor's image (e.g. "/people/Chris.jpg")
 * @param {string} props.instructorName - name of the instructor we are rendering
 * @returns {JSX.Element} image card that renders the instructor and a brief intro for them
 */
const InstructorCard = ({ imagePath, instructorName }) => {
  return (
    <div className="card">
      <div className=" card-body">
        <div className="">
          <img src={process.env.PUBLIC_URL + imagePath} className="w-100" alt={instructorName} />
        </div>
        <div className="p-0" >
          <p className="card-text">
            <b>{instructorName}</b> is a professor of Code in Place
          </p>
        </div>
      </div>
    </div>
  )
}

/**
 * Content container with the general information for each course on the platform. 
 * Content is drawn from firebase and is course-specific.
 * 
 * @returns {JSX.Element} AdminRichTextEditor with its firebase path pointing to "course/${courseId}/handouts/aboutcourse"
 */
const GeneralInformationContent = () => {
  const courseId = useCourseId()
  const forumLink = `${courseId}/forum`
  const tipTapPath = `course/${courseId}/handouts/aboutcourse`

  return ( <AdminRichTextEditor firebaseDocPath={tipTapPath} /> );
};

/**
 * Container for rendering default instructors of Chris Piech and Mehran Sahami 
 * using InstructorCards alongside divs to place them in a row.
 * 
 * @returns {JSX.Element} content container with default instructor cards featuring Chris and Mehran
 */
const TeachingTeamContent = () => {
  return (
    <>
      <h3>Teaching Team</h3>
      <div className="row mb-2">
        <div className="col-md-4 col-6 mb-3">
          <InstructorCard imagePath={"/people/chris.jpg"} instructorName={"Chris Piech"} />
        </div>

        <div className="col-md-4 col-6">
          <InstructorCard imagePath={"/people/mehran.jpg"} instructorName={"Mehran Sahami"} />
        </div>
      </div>
    </>
  )
};

/**
 * Checks to see if the user role has equivalent or higher authority to that
 * of a standard Student while not being a similarly-privileged role.
 * 
 * @param {Role} userRole - role of the user accessing the page
 * @returns {Boolean} true/false whether or not the user's role can see student course expectations
 */
const canSeeStudentExpectations = (userRole) => {
  return (isMinimumRole(userRole, Role.STUDENT) && userRole !== Role.EXPERIENCED_STUDENT);
};

/**
 * Checks to see if the user role has equivalent or higher authority to that
 * of an Experienced Student while not being a similarly-privileged role.
 * 
 * @param {Role} userRole - role of the user accessing the page
 * @returns {Boolean} true/false whether or not the user's role can see exp. student course expectations
 */
const canSeeExperiencedStudentExpectations = (userRole) => {
  return (isMinimumRole(userRole, Role.EXPERIENCED_STUDENT) && userRole !== Role.STUDENT);
};

/**
 * Checks to see if the user role has equivalent or higher authority to that
 * of a Section Leader while not being a similarly-privileged role.
 * 
 * @param {Role} userRole - role of the user accessing the page
 * @returns {Boolean} true/false whether or not the user's role can see SL course expectations
 */
const canSeeSLExpectations = (userRole) => {
  return (isMinimumRole(userRole, Role.SECTION_LEADER) && userRole !== Role.MENTOR);
};

/**
 * Checks to see if the user role has equivalent or higher authority to that
 * of a TeachNow 1-on-1 Mentor while not being a similarly-privileged role.
 * 
 * @param {Role} userRole - role of the user accessing the page
 * @returns {Boolean} true/false whether or not the user's role can see 1-on-1 mentor course expectations
 */
const canSeeMentorExpectations = (userRole) => {
  return (isMinimumRole(userRole, Role.MENTOR) && userRole !== Role.SECTION_LEADER);
};

/**
 * Content container that combines the general course information and
 * any provided role into one formatted JSX element for rendering.
 * 
 * @param {Object} props - componenet properties
 * @param {JSX.Element} [props.expectationsCard] - an optional JSX Element detailing course expectations for a singular role (usually a student)
 * @returns {JSX.Element} content containing general course information with a provided role's expectations embedded in it
 */
const SinglePageExpectationContent = ({expectationsCard}) => {
  return (
    <div className="mt-3">
      <h1>General Information</h1>
      <hr />
      <GeneralInformationContent />
      <hr />
      {expectationsCard}
      {expectationsCard && <hr />}
      <TeachingTeamContent />
    </div>
  )
}

/**
 * Wrapper for SinglePageExpectationContent that places it into a nice looking
 * CoursePageBodyContainer. We use this for any renders that don't use a tab
 * system to display multiple expectation cards.
 * 
 * @param {Object} props - component properies
 * @param {JSX.Element} [props.expectationsCard] - an optional JSX Element detailing course expectations for a singular role (usually a student)
 * @returns {JSX.Element} wraps the SinglePageExpectationContent container into a standard CoursePageBodyContainer JSX element
 */
const SinglePageExpectations = ({expectationsCard}) => {
  return (
    <CoursePageSingleCol
      column={<SinglePageExpectationContent expectationsCard={expectationsCard}/>}
    />
  )
}

/**
 * Returns the final JSX container for what to render on the /{courseId}/aboutcourse page.
 * For users in the student category, this is a single-page scrollable render with all relevant content.
 * For users in higher-privilege roles, this is a render containing a tab system that allows
 * for them to tab through all the different roles' expectations individually.
 * 
 * @returns {JSX.Element} what to render for a given user's /{courseId}/aboutcourse page
 * 
 * @note we assume that this is only called for courseId's that actually have an about course page.
 */
const ExpectationsPage = () => {
  const courseId = useCourseId();
  const { userData } = useContext(ProfileContext);
  const userRole = userData.courseRole;
  const userIsStaffRole = isMinimumRole(userRole, Role.TA);

  {/* Only display a singular page for students */}
  if (userRole === Role.STUDENT) {
    return <SinglePageExpectations expectationsCard={<StudentCard courseId={courseId} />}/>;
  }
  
  else if (userRole === Role.EXPERIENCED_STUDENT) {
    return <SinglePageExpectations expectationsCard={<ExperiencedStudentCard courseId={courseId} />}/>;
  };

  {/* For higher-privilege roles, display lower and non-similarily-privileged information as tabs  */}
  let tabs = [];

  tabs.push({ 
    id: tabs.length, 
    title: 'General Information', 
    content: <>
      <GeneralInformationContent />
      {/* <TeachingTeamContent /> */}
    </> 
  })

  {/* All roles beyond this point can should be able to see student expectations */}
  tabs.push({ 
    id: tabs.length,
    title: 'Student', 
    content: <><StudentCard courseId={courseId}/> </>
  });

  {/* All roles beyond this point can should be able to see experienced student expectations */}
  tabs.push({ 
    id: tabs.length,
    title: 'Experienced Student', 
    content: <ExperiencedStudentCard courseId={courseId} /> 
  });

  if (canSeeSLExpectations(userRole)) {
    tabs.push({ 
      id: tabs.length, 
      title: 'Section Leader', 
      content: <SectionLeaderCard courseId={courseId} /> 
    });
  }

  if (canSeeMentorExpectations(userRole)) {
    tabs.push({ 
      id: tabs.length, 
      title: '1-on-1 Mentor', 
      content: <MentorCard courseId={courseId} /> 
    });
  }

  if (userIsStaffRole) {
    tabs.push({ 
      id: tabs.length, 
      title: 'Head TA', 
      content: <AdminCard courseId={courseId} /> 
    });
  }

  {/* Return the entire tab system */}
  return (
    <div style={{borderRadius: '8px',}}>
      <TabSystem initialTabs={tabs} />
    </div>
  );
}