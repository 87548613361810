import {AssignmentId, AssignmentUnitTestType} from 'assignments/types';
import {CourseId} from 'types/common';
import {UNIT_TEST_TYPE_TO_SCHEMA} from './UnitTestSchemaRegistry';
import {useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import type { ProjectId } from 'projects/types';

interface EditUnitTestsProps {
  serverAssignmentData: any;
  courseId: CourseId;
  assignmentId: AssignmentId;
  unitTestType: AssignmentUnitTestType;
  isEditable: boolean;
}

/**
 * A wrapper for unit tests for creation, modification, and deletion.
 *
 * This component is responsible for rendering the appropriate unit test editor,
 * the actual logic of which is delegated to the appropriate subcomponent.
 */
export const UnitTestEditor = ({
  serverAssignmentData,
  courseId,
  assignmentId,
  unitTestType,
  isEditable,
}: EditUnitTestsProps) => {
  const unitTestSchema = UNIT_TEST_TYPE_TO_SCHEMA[unitTestType];
  const EditorComponent = unitTestSchema.EditorComponent;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // `any` is necessary here because the loaded data is schema-specific.
  const [loadedUnitTestData, setLoadedUnitTestData] = useState<any>();
  const loaderParameters = {
    serverAssignmentData,
    courseId,
    assignmentId,
  };
  useEffect(() => {
    unitTestSchema.loader(loaderParameters).then(loadedData => {
      setIsLoading(false);
      setLoadedUnitTestData(loadedData);
    });
  }, []);
  if (!EditorComponent) {
    return <></>;
  }
  if (isLoading) {
    return <Alert variant="warning">Loading...</Alert>;
  }
  return (
    <EditorComponent
      loadedData={loadedUnitTestData || {}}
      unitTestLoaderParameters={loaderParameters}
      isEditable={isEditable}
    />
  );
};
