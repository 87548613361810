import { deepCopy } from "@firebase/util";
import { Canvas } from "./GraphicsLib/graphics";
import { Karel } from "./KarelLib/karelapi";
import { ICanvasInfo, IKarelInfo, IPyTests, IPyStep } from './PyodideInterfaces'
import { NotOpenAiJsClient } from "components/pyodide/notOpenAiLib/notOpenAi";
import type { ProjectId } from "projects/types";
import type { CourseId } from "types/common";
/**
 * The TypeScript declaration for the Pyodide globals. One half of a
 * bi-directional communication channel between Pyodide and the rest of the page.
 * 
 * In TypeScript, we can access these globals via the `self` or `window` object.
 * In Python, we can access these globals via the `js` object.
 */
declare global {
  interface Window {
    /**
     * Details about the canvas (if it exists), only available in graphics
     * projects.
     */
    canvasInfo: ICanvasInfo;
    /**
     * The TypeScript implementation of the graphics API.
     */
    jsgraphics: any;
    /**
     * The Karel instance (if it exists), only available in Karel projects.
     */
    karelInfo: IKarelInfo;
    /**
     * The TypeScript implementation of the Karel API.
     */
    jskarel: any;
    /**
     * Details about the execution of the current program used by the
     * autograders.
     */
    testState: IPyTests;
    replInput: any;
     /**
      * The path of the current file, used by Pyodide.
      */
     currentFile: string;
     /**
      * Allows introspection of the program state. Enables getting/setting frame
      * info, Karel world, and Canvas state. Additionally allows karel to get
      * program file name.
      */
     jsPyQuery: any;
     /**
      * Details about the current step of the program such as the frame data,
      * stdout, and error state.
      */
     stepInfo: IPyStep;
     /**
      * The Not OpenAI API, so users can make a controlled OpenAI API call.
      */
     jsNotOpenAi: NotOpenAiJsClient;
     /**
      * The project ID of the currently running project.
      */
     projectId: ProjectId;
     /**
      * The course ID of the course in which the project is running.
      */
     courseId: CourseId;
  }
}


// Declare interfaces that pyodide interacts with
// Convention: js{interaction theme}
self.jsgraphics = {
  create_canvas: (width, height) => {
    self.canvasInfo.client = new Canvas(width, height, self.canvasInfo.getImage);
    self.canvasInfo.active = true;
    return self.canvasInfo.client
  },
  // retrieved in python trace function
  _getGraphicsState: () => {
    return deepCopy(self.canvasInfo.state)
  },
  canvasactive : () => {
    return self.canvasInfo.active
  },
  getCreateCanvasCount: () => {
    self.canvasInfo.initCount += 1
    return self.canvasInfo.initCount
  }
};

self.jskarel = {
  setup_karel : () => {
    self.karelInfo.client = new Karel(self.karelInfo.setState, self.karelInfo.startState)
    self.karelInfo.active = true
    return self.karelInfo.client
  },
  // retrieved in python trace function
  _getKarelState : () => {
    if(!self.karelInfo.client || !self.karelInfo.client.currentState){
      return null
    }
    return deepCopy(self.karelInfo.client.currentState)
  },
  karelactive: () => {
    return self.karelInfo.active;
  },
  setKarelActive: () => {
    self.karelInfo.active = true
  },
  getUserModName: () => {
    return self.currentFile.substring(0, self.currentFile.lastIndexOf('.')).replace(/\//g, ".");
  },
  getRkp: () => {
    return self.karelInfo.initCount
  },
  incRkp: () => {
    self.karelInfo.initCount += 1
    return self.karelInfo.initCount
  },
  getSleepTime() {
    return self.karelInfo.sleepTime;
  }
}

self.jsPyQuery = {
  getFileName : (): string => {
    return `/home/pyodide/${self.currentFile}`
  },
  setStep(lineno, codenm, locals) {
    const frame = {
      lineno: lineno,
      codenm: codenm,
      locals: locals.toJs(),
      logptr: self.stepInfo.currlp
    }
    if(self.karelInfo.active && self.karelInfo.client) {
      frame["karel"] = deepCopy(self.karelInfo.client.currentState)
    }
    if(self.canvasInfo.active && self.canvasInfo.client) {
      frame["graphics"] = deepCopy(self.canvasInfo.client.canvasObjects)
    }
    self.stepInfo.frames.push(frame)
    if(self.stepInfo.frames.length > 2000) {
      self.stepInfo.frames.shift()
    }
  },

  getStep() {
    if (self.karelInfo.active) {
      return ["karel", {
        state: deepCopy(self.karelInfo.client.currentState)
      }, self.stepInfo.currlp]
    } else if (self.canvasInfo.active) {
      return ["graphics", deepCopy(self.canvasInfo.client.canvasObjects), self.stepInfo.currlp]
    }

    return ["", {}, self.stepInfo.currlp]

  }
}

self.jsNotOpenAi = new NotOpenAiJsClient();

export {}
