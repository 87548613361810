import {httpsCallable} from 'firebase/functions';
import {functions} from 'firebaseApp';

const updateKarmaFirebase = httpsCallable(functions, 'updateKarma');

export const updateKarma = async (
  courseId,
  senderId,
  roomMembersDict,
  event,
) => {
  // Call firebase function to update karma
  console.log('Where are the missing perms!!');
  updateKarmaFirebase({
    courseId: courseId,
    senderId: senderId,
    roomMembersDict: roomMembersDict,
    event: event,
  }).then(() => {
    console.log('Updated Karma for users :)');
  });
};
