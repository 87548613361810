import React, {useState, useEffect, useRef, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';

import {LandingNav} from '../../../../landing/components/LandingNav';
import {useWindowSize} from 'react-use-size';
import {useUploadedVideo} from '../teachingDemo/useUploadedVideo';
import Loading from 'react-loading';
import {doc, getFirestore, setDoc, updateDoc} from 'firebase/firestore';
import {useUser, useUserId} from 'hooks/user/useUserId';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {ErrorPage} from 'components/errors/ErrorPage';
import {Role} from 'types/role';
import {getApp} from '@firebase/app';
import {CompletionContext} from 'contexts/CompletionContext';
import {useAppStatusServerRecorder} from 'hooks/application/useAppStatusServerRecorder';
import {EnrollmentContext} from 'contexts/EnrollmentContext';
import {useConfetti} from 'components/confetti/useConfetti';
import styled from 'styled-components';
import {ApplicationStatus} from '../../general/ApplicationStatus';
import {computeApplicationStatus} from 'course/application/general/checkStatus';
import {TodoCards} from 'course/application/general/TodoCards';
import {teachAppData} from './teachAppData';
import {getAnywhereOnEarthDateTextFromISO} from 'course/application/apputils';
import {AnywhereOnEarthCountdown} from 'components/reusableButtons/AOECountdown';
import {ReferAFriend} from 'course/application/studentApplication/splash/Referral';
import {useIsMobile} from 'utils/general';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useIsSectionLeaderApplicationAutoAcceptEligible} from 'course/application/general/useIsApplicationAutoAcceptEligible';
import {useSideConfetti} from 'components/confetti/useSideConfetti';
import Swal from 'sweetalert2';
import {logProductionErrorToFirestore} from 'components/errors/utils';

export const SectionLeaderApplication = () => {
  const {targetCourseId} = useParams();
  const userId = useUserId();
  const windowSize = useWindowSize();
  const {confettiParticles, fireConfetti} = useConfetti({windowSize});
  const {
    confettiParticles: sideConfettiParticles,
    fireConfetti: fireSideConfetti,
  } = useSideConfetti({windowSize});
  const {
    isUserApplicationAutoAcceptEligible,
    isLoading: isUserApplicationAutoAcceptEligibleLoading,
    error: isUserApplicationAutoAcceptEligibleError,
  } = useIsSectionLeaderApplicationAutoAcceptEligible();

  const db = getFirestore();
  const [videoUrl, isVideoLoading, isVideoError] = useUploadedVideo(userId);
  const [courseData, courseDataLoading, courseDataError] = useDocumentData(
    doc(db, `course/${targetCourseId}`),
  );
  const {isCompletionLoading} = useContext(CompletionContext);

  const appDataPath = `users/${userId}/${targetCourseId}/sectionLeaderApplication`;
  const appDocRef = doc(db, appDataPath);

  const [appData, appDataLoading, appDataError] = useDocumentData(appDocRef);

  if (
    isVideoLoading ||
    appDataLoading ||
    isCompletionLoading ||
    courseDataLoading ||
    isUserApplicationAutoAcceptEligibleLoading
  ) {
    return <Loading />;
  }
  if (appDataError || courseDataError) {
    return (
      <ErrorPage msg="Error loading application. Please try again later." />
    );
  }
  if (isUserApplicationAutoAcceptEligibleError) {
    logProductionErrorToFirestore(isUserApplicationAutoAcceptEligibleError);
  }

  return (
    <JoinOuter>
      {confettiParticles}
      {sideConfettiParticles}
      <LandingNav applicationType={'sectionLeader'} subpage={null} />
      <MainCardOuter>
        <MainCard
          videoUrl={videoUrl}
          appData={appData}
          appDataPath={appDataPath}
          courseData={courseData}
          fireConfetti={fireConfetti}
          fireSideConfetti={fireSideConfetti}
          isUserAutoAcceptEligible={
            !isUserApplicationAutoAcceptEligibleError &&
            isUserApplicationAutoAcceptEligible
          }
        />
      </MainCardOuter>
    </JoinOuter>
  );
};

const MainCard = ({
  videoUrl,
  appData,
  appDataPath,
  courseData,
  fireConfetti,
  fireSideConfetti,
  isUserAutoAcceptEligible,
}) => {
  // this is to make sure that we only app status to db when it changes
  const [onAppStatusUpdate] = useAppStatusServerRecorder(
    appData,
    appDataPath,
    'sl',
  );
  const {assnProgress, selfCheckProgress, lessonsProgress} =
    useContext(CompletionContext);
  const appDocRef = doc(getFirestore(getApp()), appDataPath);
  const onAutoAcceptButtonClick = () => {
    if (!isUserAutoAcceptEligible) {
      return;
    }
    setDoc(
      appDocRef,
      {
        hasRequestedAutoAccept: true,
      },
      {merge: true},
    )
      .then(() => {
        fireSideConfetti();
      })
      .catch(err => {
        Swal.fire({
          title: 'Error',
          text: 'Uh oh! Something went wrong with your request. Double check your internet connection and try again. If the problem persists, please reach out to us at codeinplace@stanford.edu',
          icon: 'error',
        });
        logProductionErrorToFirestore(err);
        console.error(err);
      });
  };
  const allData = {
    appData,
    videoUrl,
    assnProgress,
    selfCheckProgress,
    lessonsProgress,
    onAutoAcceptButtonClick,
  };
  const appStatus = computeApplicationStatus(allData, teachAppData);
  onAppStatusUpdate(appStatus);

  return (
    <JoinInner>
      <Header
        appData={appData}
        courseData={courseData}
        appStatus={appStatus}
        appDataPath={appDataPath}
        fireConfetti={fireConfetti}
      />
      <TodoCards
        applicationData={teachAppData}
        allAppData={allData}
        isUserAutoAcceptEligible={isUserAutoAcceptEligible}
      />
      <ReferAFriend showTeacherLink={false} />
    </JoinInner>
  );
};

const Header = ({
  appData,
  appStatus,
  appDataPath,
  courseData,
  fireConfetti,
}) => {
  const {targetCourseId} = useParams();
  const userId = useUserId();
  const user = useUser();
  const db = getFirestore(getApp());
  const isMobile = useIsMobile();
  const userRef = doc(db, `users/${userId}`);
  const [userData, userDataLoading, userDataError] = useDocumentData(userRef);
  const {roles, isLoadingRoles} = useContext(EnrollmentContext);

  console.log(courseData);
  useEffect(() => {
    const courseRole = roles?.[targetCourseId];
    const isSlOrMentorAdmit =
      courseRole === Role.SECTION_LEADER || courseRole === Role.MENTOR;
    if (isSlOrMentorAdmit) {
      fireConfetti();
    }
  }, [roles]);

  const appOpen = courseData?.slAppOpen;
  const dueDate = courseData?.slAppDueDate;

  if (userDataLoading) return <></>;
  if (isLoadingRoles) return <></>;

  // don't use the functions is Gate or ProfileUtil as this string
  // has not been processed by ProfileContext

  const courseRole = roles?.[targetCourseId];

  let userGreetingStr = getDisplayName(user, appData);
  const appStr = isMobile ? 'Application' : 'Application';

  return (
    <HeaderOuter>
      <div className="card">
        <div className="card-body">
          <h2>
            Section Leader {appStr}
            <br />
            {courseData?.name}
          </h2>

          <h4>{userGreetingStr}</h4>
          <DueDate appOpen={appOpen} courseData={courseData} />

          <ApplicationStatus appStatus={appStatus} appDataPath={appDataPath} />
        </div>
      </div>
    </HeaderOuter>
  );
};

const getBannerMessage = (
  appData,
  appStatus,
  userData,
  courseData,
  courseRole,
  targetCourseId,
) => {
  const appOpen = courseData?.slAppOpen;
  const decisionsLive = courseData?.slAppDecisionsLive;

  console.log(appData);

  // don't use the functions is Gate or ProfileUtil as this string
  // has not been processed by ProfileContext

  const isComplete = appStatus === 'complete';
  const isAdmit = courseRole === Role.SECTION_LEADER;
  const isMentorAdmit = courseRole === Role.MENTOR;
  const isStaff =
    courseRole === Role.ADMIN ||
    courseRole === Role.TA ||
    courseRole === Role.INSTRUCTOR;

  // Accepted as an SL
  if (isAdmit) {
    return (
      <>
        <div className=" mb-4">
          <p style={{textAlign: 'justify'}}>
            To {userData?.displayName ?? 'the applicant'},<br />
            <b className="text-primary">Congratulations!</b> You have been
            selected to be a Section Leader for Code in Place 2025. We were
            impressed by your application and look forward to teaching with you.
            Please confirm your place in the course by the end of the day{' '}
            <b>{courseData?.slAcceptDate}</b> by clicking the onboarding button:
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to={`/${targetCourseId}/onboarding/teach`}
              className="btn btn-primary btn-lg"
            >
              Continue to Section Leader Onboarding!
            </Link>
          </div>
        </div>
      </>
    );
    // Accepted as a mentor
  } else if (isMentorAdmit) {
    return (
      <>
        <div className=" mb-4">
          <p style={{textAlign: 'justify'}}>
            To {userData?.displayName ?? 'the applicant'},<br />
            <b className="text-primary">Congratulations!</b> You have been
            selected to be a Section Leader (Mentor) for Code in Place 2025. We
            were impressed by your application and look forward to teaching with
            you. We will send your more instructions over the weekend. For now
            there isn't anything you need to do!
          </p>
        </div>
      </>
    );
    // Already on staff (instructors, TAs, admins)
  } else if (isStaff) {
    return (
      <div className="bordered mb-4">
        Wahoo! You are on the staff team. Thank you for everything you do for
        Code in Place!
      </div>
    );
    // Decisions are live, but they didn't apply in time or didn't get in :(
  } else if (decisionsLive) {
    if (isComplete) {
      return (
        <div className="bordered mb-4">
          Thank you for taking the time to apply and for your desire to help
          other people. We do not require your volunteer effort at this time. As
          you can appreciate, there is a maximum number of section leaders that
          we can support. We understand this might be disappointing, but please
          know that your readiness to help is greatly valued. Thanks for doing
          what you do and keep rocking on. We wish you all the best on your
          teaching journey. Please continue your wonderful work in education,
          and don't hesitate to apply again in the future.
        </div>
      );
    } else {
      return (
        <div className="bordered mb-4">
          We already have enough volunteers. Feel free to explore the
          application and all the best on your teaching journey!
        </div>
      );
    }
    // Decisions aren't live yet, we're either still accepting applications or
    // we're reviewing them.
  } else {
    if (appOpen) {
      <p>
        We are recruiting anyone with CS experience and a love of teaching. Each
        volunteer will teach a small group of around 10 students once a week. As
        a team, we hope to instruct people around the world as part of a great
        experiment in human-centric learning at scale.
      </p>;
    } else {
      return (
        <div className="bordered mb-4">
          Section leader applications are closed and we are busy reviewing
          applications.
        </div>
      );
    }
  }
};

const DueDate = ({appOpen, courseData}) => {
  const isMobile = useIsMobile();
  if (appOpen) {
    return (
      <>
        Due{' '}
        <strong>
          {getAnywhereOnEarthDateTextFromISO(courseData?.slAppDueDate)}{' '}
        </strong>
        <OverlayTrigger
          delay={{show: 100, hide: 100}}
          overlay={props => (
            <Tooltip id="button-tooltip" {...props}>
              You can finish your application as long as there is a place on
              earth where the deadline hasn't passed.
            </Tooltip>
          )}
        >
          <span>
            {isMobile ? 'AoE' : 'Anywhere on Earth'}
            {/* <AOEExplainer /> */}
          </span>
        </OverlayTrigger>
        <br />
        <span>
          <i>
            in <AnywhereOnEarthCountdown dateStr={courseData?.slAppDueDate} />
          </i>
        </span>
      </>
    );
  } else {
    return <h4 className="">Applications Closed</h4>;
  }
};

function getDisplayName(user, aboutData) {
  if (aboutData && aboutData['name']) {
    return aboutData['name'];
  }

  return user.displayName;
}

const backgroundStyle = {
  height: '100vh',
  backgroundImage: 'url("/stanford/gates.jpg")',
  overflow: 'auto',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const makeWhiteAlpha = alpha => {
  return `rgba(255,255,255,${1 - alpha})`;
};

const JoinOuter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('/splash3.jpeg');
  overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: none;
  }
`;

const MainCardOuter = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-grow: 1;
  }
`;

const JoinInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  width: 680px;
  border-radius: 5px;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 10px;
    border-radius: 0px;
    flex-grow: 1;
  }
`;

const HeaderOuter = styled.div`
  width: 100%;
`;
