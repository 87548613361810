import { useEffect, useRef } from "react";
import { TerminalShell, TerminalPre, ConsoleInput, MobileTerminalShell } from "./TerminalStyles"
import { useIsMobile } from "utils/general";
import { TerminalToolbar } from "../TerminalPane";
import { FlexConsoleInput } from "./ConsoleInput";

// TODO: this component should be deleted and replaced with the IDETerminalView component
// the ide terminal view should then check if it doesn't have the IDEContext, and if so,
// it should ignore related features like step mode. The only (superficial) difference
// between this component and the IDETerminalView is that this component wraps an outer div
// and the users pass in props for that div. Instead those users should make their own outer div

export const NonIDETerminalView = (props) => {


  const isMobile = useIsMobile()
  const isTabletOrLarger = !isMobile
  // This is not a component, instead it is the class instance that manages the terminal
  const { terminalRef } = props;

  // References to console input and terminal elements
  const consoleInputRef = useRef(null);
  const terminalPreRef = useRef(null);
  const terminalContentRef = useRef(null);
  const shellRef = useRef(null);

  /**
   * Keydown handler:
   *  - If the user types while the shell is focused (not the input),
   *    automatically focus the console input.
   */
  useEffect(() => {
    const handleKeyDown = (e) => {
      // If user is pressing typical shortcut keys (meta/ctrl), let them copy/paste/etc.
      if (e.metaKey || e.ctrlKey) return;

      // If not in step mode and the console input isn't focused, focus it.
      if (document.activeElement !== consoleInputRef.current) {
        consoleInputRef.current.focus();
      }
    };

    const shellElement = shellRef.current;
    if (shellElement) {
      // We add the event listener on the shell so we capture keydown events
      shellElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (shellElement) {
        shellElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  /**
   * MouseUp handler:
   *  - If no text is selected (i.e., user just clicked rather than dragged a selection),
   *    focus the console input.
   */
  const handleShellMouseUp = () => {
    if (consoleInputRef.current) {
      const selectedText = window.getSelection().toString();
      // Only focus if nothing was selected
      if (!selectedText) {
        consoleInputRef.current.focus();
      }
    }
  };

  /**
   * Rendering logic:
   *  - For mobile layouts, we use `MobileTerminalShell`.
   *  - For tablet/desktop, use `TerminalShell`.
   *  - We attach the same event handling (mouse up for selection logic, keydown for auto‐focus).
   *  - `FlexConsoleInput` is only rendered if NOT in step mode.
   */
  if (!isTabletOrLarger) {
    return (
      <div {...props}>
      <MobileTerminalShell
        ref={shellRef}
        onMouseUp={handleShellMouseUp}
        tabIndex={0} // Allows the shell to receive keydown events
      >
        <TerminalToolbar {...props} />
        <TerminalPre ref={terminalPreRef} id="terminal-pre" aria-label="Terminal Content">
          <span ref={terminalContentRef} id="terminal"></span>

          <FlexConsoleInput
            consoleInputRef={consoleInputRef}
            terminalPreRef={terminalPreRef}
          />
        </TerminalPre>
      </MobileTerminalShell>
      </div>
    );
  }

  return (
    <div {...props}>
      <TerminalShell
        ref={shellRef}
        onMouseUp={handleShellMouseUp}
        tabIndex={0} // Allows the shell to receive keydown events

      >
        <TerminalToolbar {...props} />
        <TerminalPre ref={terminalPreRef} id="terminal-pre" aria-label="Terminal Content">
          <span ref={terminalContentRef} id="terminal"></span>

          <FlexConsoleInput
            consoleInputRef={consoleInputRef}
            terminalPreRef={terminalPreRef}
          />
        </TerminalPre>
      </TerminalShell>
    </div>
  );
}