import { httpsCallable } from 'firebase/functions';
import { functions } from 'firebaseApp';
import { useCourseId } from 'hooks/router/useUrlParams';
import { useUserId } from 'hooks/user/useUserId';
import { IDEContext } from 'ide/contexts/IDEContext';
import { useContext, useRef } from 'react';
import { FaCamera } from 'react-icons/fa'; // Import camera icon
import Swal from 'sweetalert2';
import { useIsMobile } from 'utils/general';
import { uploadFileToDatabase } from 'utils/uploadFile';

export const CodePhoto = () => {
  const { setCode, codeToRun } = useContext(IDEContext)
  const isMobile = useIsMobile()
  // Create a reference for the hidden file input
  const hiddenFileInput = useRef(null);
  const userId = useUserId()
  const imageIdRef = useRef(0)


  const onImageUploaded = (url) => {
    // Create a reference to your cloud function
    const imgToCode = httpsCallable(functions, 'imgToCode');
    const originalCode = codeToRun.current
    // Call the function with the image URL
    imgToCode({ 
      photoUrl: url, 
      imageId: imageIdRef.current,
      originalCode
    })
      .then((httpResult) => {
        // Read result of the Cloud Function.
        const { data } = httpResult;
        let newCodeRaw = data["result"];
        // If the response doesn't include the string python,
        // do nothing
        if (!newCodeRaw.includes("python")) {
          showErrorSwal()
          return;
        }


        // Remove the initial backticks and the word "python"
        newCodeRaw = newCodeRaw.replace("```python\n", "");
        // Remove the final backticks
        newCodeRaw = newCodeRaw.replace("\n```", "");
        setCode(newCodeRaw)
        Swal.fire({
          icon: 'success',
          position: 'top-end',
          title: 'Code successfully read',
          toast: true,
          confirmButtonText: 'Wahoo'
        })
      })
      .catch((error) => {
        // Getting the Error details.
        const { code, message, details } = error;
        console.error('Error calling imgToCode function:', message);
        console.log('Error code:', code, 'Error details:', details);
        showErrorSwal()
      });
  }

  const showErrorSwal = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'I was not able to read the code in that file',
    })
  }

  const updateLoading = (progress) => {
    console.log('progress', progress)
    if (progress > 0 && progress < 100) {
      
    }
  }

  const setErrorState = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'File was not uploaded',
    })
  }

  // Function to trigger the hidden file input click event
  const takePhoto = () => {
    Swal.fire({
      icon: 'info',
      title: 'Handwritten Code Reader',
      text: 'You take a photo of your handwritten code and the IDE will try to read it',
      showCancelButton: true,
      confirmButtonText: `Take photo`,
    }).then((result) => {
      if (result.isConfirmed) {
        hiddenFileInput.current.click();
      }
    })

  };

  // Function to handle the file input change event
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      imageIdRef.current = Date.now()
      const storagePath = `codePhotos/${userId}/${imageIdRef.current}`
      uploadFileToDatabase(file, storagePath, onImageUploaded, updateLoading, setErrorState)
      Swal.fire({
        icon: 'info',
        title: 'Scanning file...',
        text: 'Please wait while the image is processed',
        position: 'top-end',
        timer: 30000,
        timerProgressBar: true,
        toast: true,
        showConfirmButton: false
      })
    }
    // Reset the value of the input so the same file can be selected again
    event.target.value = null;
  };

  if(!isMobile) {
    return null
  }


  return (
    <>
      <button 
        style={{color: 'rgb(46, 114, 244)'}}
        className="btn btn-light" 
        onClick={takePhoto}
        aria-label="Take photo of handwritten code"
      >
        <FaCamera />
      </button>
      {/* Hidden file input */}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept="image/*"
        capture="environment"
      />
    </>
  );
};

function randomId() {
  return Math.random().toString(36).slice(2, 9);
}
