import { useContext, useCallback, useState, useEffect } from "react";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { ForumContext } from "course/forum/ForumContext";
import { getPostPaddingLeft, getPostWidth, postEntriesStyle } from "./utils";
import Gate from "contexts/Gate";
import Checkbox from "components/reusableButtons/Checkbox";
import { postFlaggedAlert, postNotFlaggedAlert, popLoadingAlert, errorToast } from "../forumGeneral";
import { getFunctions, httpsCallable } from "firebase/functions";
import { PostNotFoundSplash } from "../SplashPages/PostNotFoundSplash/PostNotFoundSplash";
import { Skeleton, Alert } from "antd";
import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { DiscussionButtonBar } from "../../../../components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { PostTags } from "../PostTags/PostTags";
import { useNavigate } from "react-router";
import { useDraftData } from "./PostViewerHooks";
import { Form } from "react-bootstrap";
import { getFirestore, writeBatch } from "firebase/firestore";
import Swal from "sweetalert2";
import "./PostViewer.css";
import { PostContext } from "course/forum/pages/forum/PostContext";
import { FaEyeSlash } from "react-icons/fa";
import { UserChip } from "../UserChip/UserChip";
import { auth } from "firebaseApp";


export const DraftViewer = (props: { draftId: string }) => {
  const { draftId } = props;
  const courseId = useCourseId();
  const { userData } = useContext(ProfileContext);
  const { forumType, forumId, setPinnedThreshold, isReporting } = useContext(ForumContext);
  const [offsetPixels, setOffsetPixels] = useState(0);
  const navigate = useNavigate();
  const functions = getFunctions();
  const requestForumPostFromFirestore = httpsCallable(functions, "requestForumPost");
  const canPin = Gate.hasRole(setPinnedThreshold)(userData);
  const { refreshDrafts, refreshPosts } = useContext(PostContext)
  const [isPublishing, setIsPublishing] = useState(false);


  const {
    isPostPosted,
    setPostContents,
    postTitle,
    postText,
    setPostTags,
    postTags,
    isPostPrivate,
    setIsPostPrivate,
    setIsPostPinned,
    postNotFound,
    setPostTitle,
    dataFetcher,
    onPublishUpdateMetadata,
    dataLoaded,
    isPostPinned,
    postAuthor,
    postAuthorId,
    isPostAnonymous,
    setIsPostAnonymous
  } = useDraftData({ draftId, isReporting });


  const handleUpdate = (json, html, text) => {
    const contents = { html, text }
    setPostContents(contents);
  };

  const updateTags = (tags: string[]) => {
    setPostTags(tags);
  };
  

  useEffect(() => {
    refreshDrafts();
  }, [])


  const deletePost = async () => {
    const result = Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });
    if(!(await result).isConfirmed) return;


    try {
      const batch = writeBatch(getFirestore());
      batch.delete(dataFetcher.get_tiptap_doc_ref(draftId));
      batch.delete(dataFetcher.get_post_doc_ref(draftId));
      await batch.commit();
      const draftBatch = writeBatch(getFirestore());
      draftBatch.delete(dataFetcher.get_draft_tiptap_doc_ref(draftId));
      draftBatch.delete(dataFetcher.get_draft_doc_ref(draftId));
      await draftBatch.commit();
      refreshDrafts()
      refreshPosts();
      navigate({
        pathname: location.pathname,
        search: ""
        })
    } catch(e) {
      console.log(e)
     }
  }

  const handleRect = useCallback((node) => {
    setOffsetPixels(node?.getBoundingClientRect().top);
  }, []);


  const requestPost = async (data: { fromEdit: boolean }) => {
    const { fromEdit } = data;
    setIsPublishing(true);
    if(!postTitle || !postText) {
      errorToast("Title and text fields cannot be empty")
      setIsPublishing(false);

      return;
    }
    const resolve = popLoadingAlert("Publishing Post");

    // First, update the post draft
    onPublishUpdateMetadata(fromEdit);


    const result = await requestForumPostFromFirestore({
      courseId,
      forumId,
      postId: draftId,
      postTitle,
      postText,
      tags: postTags,
    })
    resolve();
    refreshDrafts()
    refreshPosts()
    if (result) {
      await postNotFlaggedAlert();
      setIsPublishing(false);
      navigate({
        pathname: location.pathname,
        search: `post=${draftId}`
      })
      
    } else {
      setIsPublishing(false);
      postFlaggedAlert();
    }
  }



  if (!dataLoaded) {
    return (
      <div className="postContainer" style={postEntriesStyle(offsetPixels)}>
        <div
          className="post"
          style={{ width: getPostWidth(forumType), paddingLeft: getPostPaddingLeft(forumType) }}
        >
          <Skeleton avatar />
        </div>
      </div>
    );
  }


  if (dataLoaded && postNotFound) {
    return <PostNotFoundSplash />;
  }


  if(isPublishing) { 
    return (
      <PostPreview
        isPrivate={isPostPrivate}
        postTitle={postTitle}
        postTags={postTags}
        authorUid={postAuthorId}
        author={postAuthor}
        dt={new Date()}
        postId={draftId}
        dataFetcher={dataFetcher}
        forumType={forumType}
        offsetPixels={offsetPixels}
      />
    )
  }


  return (
    <div ref={handleRect} className="postContainer" style={postEntriesStyle(offsetPixels)}>
      <div className="post">
        <div className="mt-1">
          <Form>
            <Form.Group controlId="postTitle">
              <Form.Label className="visually-hidden">Title</Form.Label>
              <Form.Control
                type="text"
                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
                placeholder="Post Title"
              />
            </Form.Group>
            { !isReporting && <Form.Group controlId="postTags" className="mt-2">
              <Form.Label className="visually-hidden">Tags</Form.Label>
              <PostTags
                currentTags={postTags}
                editable={true}
                changeHandler={updateTags}
              />
            </Form.Group>}
          </Form>
        </div>

        <div className="editorContainer">
          <TipTap
            editable={true} // only admins can edit post contents
            firebaseDocPath={dataFetcher.get_draft_tiptap_doc_path(draftId)}
            onServerWrite={() => { }}
            handleUpdate={handleUpdate}
            buttonBar={DiscussionButtonBar}
            showLoadingSkeleton={false}
          />
        </div>
        <DraftActionsBar
          isPostPosted={isPostPosted}
          isPostPrivate={isPostPrivate}
          setIsPostPrivate={setIsPostPrivate}
          isPostPinned={isPostPinned}
          setIsPostPinned={setIsPostPinned}
          canPin={canPin}
          requestPost={requestPost}
          deletePost={deletePost}
          isPostAnonymous={isPostAnonymous}
          setIsPostAnonymous={setIsPostAnonymous}
        />

      </div>
    </div>
  )


}

const DraftActionsBar = ({isPostPosted, isPostPrivate, setIsPostPrivate, isPostPinned, isPostAnonymous, setIsPostAnonymous, setIsPostPinned, canPin, requestPost, deletePost}) => {
  const { isReporting } = useContext(ForumContext)


  return (
    <div className="d-flex justify-content-between mt-2">
    <div className="d-flex gap-2">
      <PublishButton
        isPostPosted={isPostPosted}
        requestPost={requestPost}
      />
      <DeleteButton
        deleteHandler={deletePost}
      />
    </div>
    <div className="d-flex gap-2">
      { !isReporting && <Checkbox
        value={isPostAnonymous}
        setValue={() => setIsPostAnonymous(!isPostAnonymous)}
        label={"Make Anonymous (Teachers and Staff can still see your name)"}
      />}
     { !isReporting &&  <Checkbox
        value={isPostPrivate}
        setValue={() => setIsPostPrivate(!isPostPrivate)}
        label={"Make Private"}
      />}
      {canPin && !isReporting && (
        <Checkbox
          value={isPostPinned}
          setValue={() => setIsPostPinned(!isPostPinned)}
          label={"Make Announcement"}
        />
      )}
    </div>

  </div>
  )
}


const PublishButton = ({ isPostPosted, requestPost }) => {


  const buttonTitle = isPostPosted ? "Done Editing" : "Publish";

  return (
    <button
      className="btn btn-primary mr-1"
      style={{maxHeight: "40px"}}
      onClick={() => requestPost({ fromEdit: isPostPosted })}
    >
      {buttonTitle}
    </button>
  )


}


const DeleteButton = ({ deleteHandler }) => {

  return (
    <button
      className="btn btn-danger mr-1"
      onClick={() => deleteHandler()}
      style={{maxHeight: "40px"}}
    >
      Delete
    </button>
  )

}





const PostPreview = ({isPrivate, postTitle, postTags, authorUid, author, dt, postId, dataFetcher, forumType, offsetPixels}) => {


  return (
    <div  className="postContainer" style={postEntriesStyle(offsetPixels)}>
    <div
      className="post"
      style={{ width: getPostWidth(forumType), paddingLeft: getPostPaddingLeft(forumType) }}
    >
    <div className="postContent">
          {isPrivate && (
            <Alert
              description={
                <span>
                  <strong>This post is private</strong>. Only you and the course
                  staff can see it.
                </span>
              }
              type="info"
              icon={<FaEyeSlash />}
              style={{ marginBottom: "15px" }}
              showIcon
            />
          )}
          <div className="post-header">
            <h2 className="postTitle">{postTitle}</h2>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <PostTags
              currentTags={postTags}
              editable={false}
            />
          </div>
          <div className="userAndTime">
            <UserChip showAvatar={true} uid={authorUid} name={author} />
            <div className="date">
              {dt.toLocaleString({
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })}
            </div>
          </div>
          <div className="editorContainer">
            <TipTap
              editable={false} // only admins can edit post contents
              firebaseDocPath={dataFetcher.get_draft_tiptap_doc_path(postId)}
              onServerWrite={() => { }}
              handleUpdate={() => {}}
              buttonBar={DiscussionButtonBar}
              showLoadingSkeleton={false}
            />
          </div>

        </div>
      </div>
    </div>
  )
  
}
