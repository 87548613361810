import {useCourseId} from 'hooks/router/useUrlParams';
import {signOut, getAuth} from 'firebase/auth';
import {useNavigate} from 'react-router';
import {CourseContext} from 'contexts/CourseContext';
import {useContext, useEffect, useState} from 'react';
import {Button, Col, Container, Placeholder, Row} from 'react-bootstrap';
import {ApplyLink} from 'landing/home/heroSection/v2/ApplyLink';
import {BlockedKarelWithText} from './BlockedKarel';
import {useIsMobile} from 'utils/general';
import {useGetUserEmail} from '../../firebase/functions/users';
import {useUserId} from 'hooks/user/useUserId';
import { ProfileContext } from 'contexts/ProfileContext';

const KAREL_DESKTOP_SCALE = 1.8;
const KAREL_MOBILE_SCALE = 1;

export const WaitPage = () => {
  const courseId = useCourseId();
  // TODO: #1028 - this value should be derived from firebase
  const isLiveCourse = courseId === 'cip5';
  const isMobile = useIsMobile();

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Occupies 50% of the screen height */}
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{flex: '0 0 50%'}}
      >
        <img
          src={process.env.PUBLIC_URL + '/stanford.png'}
          alt="Stanford Logo"
          style={{height: '100px', maxHeight: '300px', objectFit: 'contain'}}
        />
      </div>
      {/* Remaining space with Karel and content stacked together */}
      <div
        className="d-flex flex-column align-items-center justify-content-center p-2"
        style={{flex: '1 0 auto'}}
      >
        <div className="p-2 text-center">
          <BlockedKarelWithText
            scale={isMobile ? KAREL_MOBILE_SCALE : KAREL_DESKTOP_SCALE}
          />
        </div>
        <div className="p-2 text-center">
          {isLiveCourse ? (
            <LiveCourseAdvertisement />
          ) : (
            <StandardUnenrolledPage />
          )}
        </div>
      </div>
    </div>
  );
};

const LiveCourseAdvertisement = () => {
  const {courseName} = useContext(CourseContext);
  // TODO: #1028 - get the course id from firebase
  const liveCourseId = 'cip5';
  return (
    <Container>
      {/* Center the introductory message horizontally */}
      <Row>
        <Col className="text-center">
          <div className="text-break">
            {courseName ?? 'The course'} isn't open yet, but we're taking
            applications!
          </div>
        </Col>
      </Row>
      {/* Center the "Apply to Learn" button */}
      <Row>
        <Col className="text-center">
          <ApplyLink
            className="btn btn-lg btn-primary"
            to={`public/join/${liveCourseId}`}
          >
            Apply to Learn
          </ApplyLink>
        </Col>
      </Row>
      {/* Center the "Apply to Teach" button */}
      <Row>
        <Col className="text-center">
          <ApplyLink
            className="btn btn-lg btn-outline-primary mt-1"
            to={`public/applyteach/${liveCourseId}`}
          >
            Apply to Teach
          </ApplyLink>
        </Col>
      </Row>
    </Container>
  );
};

const StandardUnenrolledPage = () => {
  const courseId = useCourseId();
  const {courseName} = useContext(CourseContext);

  const auth = getAuth();
  const navigate = useNavigate();

  // Sign out and navigate back to the course homepage
  const signOutAndNav = () => {
    signOut(auth).then(() => {
      navigate(`/${courseId}`);
    });
  };


  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h1>We don't see you enrolled in {courseName}!</h1>
      <p>
        If you are using the wrong email account,{' '}
        <button className="btn btn-sm btn-secondary" onClick={signOutAndNav}>
          Sign Out
        </button>{' '}
        and try signing in with a different email.
      </p>
    </div>
  );
};
