import Swal from 'sweetalert2';
import {inferUnitTestType, runUnitTests} from './runUnitTests';
import {
  UnitTestResults,
  allTestsPassed,
  firstTestHasError,
} from 'assignments/unitTest/unitTestResults';
import {checkIsProjectConsole, isCreativeProject} from 'ide/utils/general';
import {doc, getFirestore, setDoc} from 'firebase/firestore';
import {
  AUTOGRADER_RUN_TOAST,
  logAiAutograderUserFeedback,
  AI_AUTOGRADER_NOT_CORRECT_SWAL,
  STUDENT_DISAGREE_SWAL,
} from '../../assignments/unitTest/ai/GptAutograderUtils';
import {CourseId, UserId} from 'types/common';
import {IDEContextData} from 'ide/contexts/IDEContext';
import {NavigateFunction} from 'react-router-dom';
import {AssignmentId, AssignmentUnitTestType} from 'assignments/types';
import {UNIT_TEST_TYPE_TO_SCHEMA} from 'assignments/unitTest/UnitTestSchemaRegistry';
import {ADVANCED_CONSOLE_UNIT_TEST_RUNNING_TOAST} from 'assignments/unitTest/advancedConsole/advancedConsoleUtils';

/*
 * function: runUnitTestsAndReportResults
 * --------------------------------------
 * This is the main entry point for anyone running unit tests. This function
 * combines running the tests and reporting the results, both to the user and
 * to the server. It is async so that when using AI autograders, we can keep
 * the UI responsive.
 *
 * This function is only called in two places: From the IDE and from the TestView
 */

interface UnitTestParams {
  /** The context of the IDE. */
  ideContext: IDEContextData;
  /** The function to navigate to a new URL. */
  navigate: NavigateFunction;
  /** The ID of the user running the tests. */
  userId: UserId;
  /** The ID of the course the user is in. */
  courseId: CourseId;
  /** The URL to navigate to after the tests are run. */
  onwardsUrl: string;
  /** Whether to run the tests silently (no UI feedback). */
  silent?: boolean;
}

export async function runUnitTestsAndReportResults(
  unitTestParams: UnitTestParams,
) {
  const {ideContext} = unitTestParams;
  const silent = unitTestParams.silent ?? false;

  // if the project is a creative project, don't run any tests
  if (isCreativeProject(ideContext.projectData)) {
    return;
  }
  const unitTestType = inferUnitTestType(ideContext.assnData);
  if (unitTestType === 'ai') {
    AUTOGRADER_RUN_TOAST.fire();
    ideContext.setAiAutograderRunning(true);
    // If using AI autograders, don't block execution
    runUnitTests(ideContext, silent).then(results => {
      ideContext.setAiAutograderRunning(false);
      // Set unittestresults here and then in style feedback can check if they have all passed
      ideContext.setUnitTestResults(results);
      reportUnitTestResults(results, unitTestParams, unitTestType);
    });
  } else {
    if (unitTestType === 'advanced_console' && !silent) {
      ADVANCED_CONSOLE_UNIT_TEST_RUNNING_TOAST.fire();
    }
    // If using non-AI graders, wait for the tests to complete
    const unitTestResults = await runUnitTests(ideContext, silent);
    if (unitTestType === 'advanced_console' && !silent) {
      ADVANCED_CONSOLE_UNIT_TEST_RUNNING_TOAST.close();
    }
    // Set unittestresults here and then in style feedback can check if they have all passed
    ideContext.setUnitTestResults(unitTestResults);
    if (unitTestResults.testsRun) {
      reportUnitTestResults(unitTestResults, unitTestParams, unitTestType);
    }
    return unitTestResults;
  }
}

/*
 * function: markAssnCorrect
 * -------------------------
 * Mark the assignment as correct in the database.
 */
export const markAssnCorrect = async (
  userId: UserId,
  courseId: CourseId,
  assnId: AssignmentId,
) => {
  const db = getFirestore();
  const firebasePath = `/users/${userId}/${courseId}/assnProgress`;
  const newCompletion = {};
  newCompletion[assnId] = true;
  const completeDoc = doc(db, firebasePath);
  await setDoc(completeDoc, newCompletion, {merge: true});
};

/****************************************************************************
 *                      Helper Functions                                    *
 ****************************************************************************/

function reportUnitTestResults(
  results: UnitTestResults,
  unitTestParams: UnitTestParams,
  unitTestType: AssignmentUnitTestType,
) {
  const {ideContext, navigate, userId, courseId, onwardsUrl} = unitTestParams;

  const silent = unitTestParams.silent ?? false; // silently run unit tests when assignment is submitted

  updateTestCenterView(ideContext, results);
  const passedAll = allTestsPassed(results);
  const assnId = ideContext.assnData?.metaData.uid;
  if (passedAll) {
    markAssnCorrect(userId, courseId, assnId).then(() => {
      if (!silent) {
        reportPassedUnitTests(ideContext, navigate, onwardsUrl);
      }
    });
  } else if (shouldReportFailedTest(results) && !silent) {
    reportFailedUnitTests(
      results,
      navigate,
      userId,
      courseId,
      assnId,
      onwardsUrl,
      unitTestType,
    );
  }
}

function shouldReportFailedTest(results: UnitTestResults) {
  if (firstTestHasError(results)) {
    return false;
  }
  return true;
}

function reportPassedUnitTests(
  ideContext: IDEContextData,
  navigate: NavigateFunction,
  onwardsUrl: string,
) {
  const assnData = ideContext.assnData;
  const assnId = assnData?.metaData?.uid;
  const assnsToExculde = ['warmup', 'housekarel', 'applyteach-fillkarel', 'doubleit']
  const shouldShowStyleFeedbackModal = ideContext.isTabletOrLarger && assnId && !assnsToExculde.includes(assnId);
  if (shouldShowStyleFeedbackModal) {
    showStyleFeedbackModal(ideContext);
  } else {
    Swal.fire({
      title: 'You did it!',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Onwards!',
      cancelButtonText: 'Keep Working',
      preConfirm: () => {
        navigate(onwardsUrl);
      },
    });
  }
}

function reportFailedUnitTests(
  results: UnitTestResults,
  navigate: NavigateFunction,
  userId: UserId,
  courseId: CourseId,
  assnId: AssignmentId,
  onwardsUrl: string,
  unitTestType: AssignmentUnitTestType,
) {
  if (unitTestType === 'ai') {
    AI_AUTOGRADER_NOT_CORRECT_SWAL.fire().then(result => {
      if (result.isDenied) {
        markAssnCorrect(userId, courseId, assnId);

        // record a disagreement (if GPT grader)
        logAiAutograderUserFeedback({results, userId, courseId, assnId});

        STUDENT_DISAGREE_SWAL.fire({
          preConfirm: () => {
            navigate(onwardsUrl);
          },
        });
      }
    });
    return;
  }
  const failureMessage =
    UNIT_TEST_TYPE_TO_SCHEMA[unitTestType].getFailureMessage(results);
  Swal.fire({
    text: failureMessage,
    icon: 'info',
    confirmButtonText: 'Keep it up!',
  });
}

function showStyleFeedbackModal(ideContext: IDEContextData) {
  Swal.fire({
    title: 'You did it!',
    icon: 'success',
    showCancelButton: true,
    confirmButtonText: 'Check Out Style Feedback!',
    cancelButtonText: 'Keep Working',
  }).then(result => {
    if (result.isConfirmed) {
      ideContext.setSelectedTab('Style Feedback');
      if (ideContext.leftColViewState === 'minimized') {
        ideContext.setLeftColViewState('standard');
      }
    }
  });
}

function updateTestCenterView(
  ideContext: IDEContextData,
  results: UnitTestResults,
) {
  const {setConsoleTestResults} = ideContext;
  const isConsole = checkIsProjectConsole(
    ideContext.projectData,
    ideContext.assnData,
  );
  if (!isConsole) {
    return;
  }
  const testResults = results.testResults;
  const rawResults = [];
  for (const result of testResults) {
    const rawResult = result.rawResult;
    rawResults.push({
      ...rawResult,
      state: result.isSuccess ? 'success' : 'failure',
    });
  }
  console.log('rawResults', rawResults);
  setConsoleTestResults(rawResults);
}
