import {useState, useEffect} from 'react';
import { doc, collection, getFirestore, where, query, getDocs, setDoc, runTransaction } from 'firebase/firestore';
import { getRoomMembersUidToDisplayName } from './Rooms';
import { useDocument } from 'react-firebase-hooks/firestore';
import { STUDENT_STATUSES, TEACHER_STATUSES } from 'course/carehours/types';

const db = getFirestore()


export const useTeachNowTeacherStatus = (uid, courseId) => {
    const teacherQueuePath = `teachnow/${courseId}/teachers/${uid}`
    const teacherDocRef = doc(db, teacherQueuePath)
    const [teacherData, teacherDataLoading, teacherDataError] = useDocument(teacherDocRef)
    return teacherData?.data()?.status ? teacherData?.data()?.status : TEACHER_STATUSES.EXITED
}

export const joinTeachNow = async (uid, courseId) => {
    const userTeacherStatusRef = doc(db, "teachnow", courseId, "teachers", uid)
    try {
        // Add myself to the queue in firstore /teachnow/{courseId}/userId {"status": "joined", "matched": false}
        const teacherDoc = {
            status: "joined",
            matched: false,
            nudgesDone: 0
        }
        await setDoc(userTeacherStatusRef, teacherDoc, { merge: true })
    } catch (error) {
        console.error("Error fetching nudgeable students:", error);
    }
}

export const leaveTeachNow = async (uid, courseId) => {
    const userTeacherStatusRef = doc(db, `teachnow/${courseId}/teachers/${uid}`)
    try {
        // Remove myself from the queue in firstore /teachnow/{courseId}/userId
        const teacherDoc = {
            status: "exited"
        }
        await setDoc(userTeacherStatusRef, teacherDoc, { merge: true })
    } catch (error) {
        console.error("Error fetching nudgeable students:", error);
    }
}

export const useTeachNowStudentStatus = (uid, courseId) => {
    const studentQueuePath = `teachnow/${courseId}/students/${uid}`
    const [studentTeachNowState, setStudentTeachNowState] = useState(undefined);
    const studentTeachNowStatusRef = doc(db, studentQueuePath);
    const [studentTeachNowStatus] = useDocument(studentTeachNowStatusRef)

    useEffect(() => {
                if(studentTeachNowStatus) {
                    const val = studentTeachNowStatus.data()
                    if(val) {
                        const studentStatus = val['status']
                        const studentTimeJoined = val['studentTimeJoined']
                        const nudgeId = val['nudgeDocId']
                        const roomId = val['roomId']
                        setStudentTeachNowState({studentStatus, studentTimeJoined, nudgeId, roomId})
                    } else {
                        setStudentTeachNowState(null)
                    }
                }
            }
            , [studentTeachNowStatus])

  return studentTeachNowState;
};

const _setStudentTeachNowResponse = async (uid, courseId, response, projId = null, displayName = null) => {
    const studentTeachNowStatusRef = doc(db, `teachnow/${courseId}/students/${uid}`);

    try {
        const resp = await runTransaction(db, async (transaction) => {
            const currentData = await transaction.get(studentTeachNowStatusRef);

            if (!currentData.exists()) {
                throw new Error(`Document does not exist at path: teachnow/${courseId}/students/${uid}`);
            }

            const data = currentData.data();

            if (data.status !== STUDENT_STATUSES.NUDGE) {
                // Do nothing if the status is not 'nudged'
                return;
            }

            // Prepare the updated data
            const updatedData = response === STUDENT_STATUSES.ACCEPTED
                ? { ...data, status: response, projId: projId, displayName: displayName }
                : { ...data, status: response };

            // Commit the updated data
            transaction.update(studentTeachNowStatusRef, updatedData);

            return updatedData; // Return the updated data if needed
        });

        console.log("Transaction successfully committed:", resp);
    } catch (error) {
        console.error(`Transaction failed for ${uid}:`, error);
    }
}

export const acceptTeachNowRequest = async (uid, courseId, projId, displayName) => {
    await _setStudentTeachNowResponse(uid, courseId, STUDENT_STATUSES.ACCEPTED, projId, displayName);
}

export const rejectTeachNowRequest = async (uid, courseId) => {
    await _setStudentTeachNowResponse(uid, courseId, STUDENT_STATUSES.REJECTED);
}

export const timeoutTeachNowRequest = async (uid, courseId) => {
    await _setStudentTeachNowResponse(uid, courseId, STUDENT_STATUSES.TIMEOUT);
}



//// All of these are old. TJ and Juliette re-wrote the ones above starting on December 12th 2024 


// const functions = getFunctions();

// const _joinTeachnow = httpsCallable(functions, 'joinTeachNow');
// const _leaveTeachnow = httpsCallable(functions, 'leaveTeachNow');

// export const joinTeachNow = async () => {
//     // log that the SL joined the queue
//     const result = await _joinTeachnow()
//     return result
// }

// export const leaveTeachNow = async () => {
//     // log that the SL left the queue
//     await _leaveTeachnow()
// }


// const _setStudentTeachNowResponse = async (uid, courseId, response, projId = null) => {
//     const studentTeachNowStatusRef = database.ref(`${courseId}/teachnow/queues/students/${uid}`);

//     const resp = await studentTeachNowStatusRef.transaction((currentData) => {
//         if (currentData === null) {
//             return currentData; // Initialize if null
//         }

//         if (currentData.studentStatus !== 'nudged') {
//             // Do nothing if status has already been updated e.g. timeout, un-nudge etc.
//             return currentData;
//         }

//         if (response === 'accepted') {
//             return { ...currentData, studentStatus: response, projId: projId };
//         } else {
//             return { ...currentData, studentStatus: response };
//         }
//     })

//     if (!resp.committed) {
//         console.error(`Transaction not committed whe updating ${uid} status to ${response}. Something went wrong`);
//     }

// }

// export const acceptTeachNowRequest = async (uid, courseId, projId) => {
//     await _setStudentTeachNowResponse(uid, courseId, 'accepted', projId);
// }

// export const rejectTeachNowRequest = async (uid, courseId) => {
//     await _setStudentTeachNowResponse(uid, courseId, 'rejected');
// }

// export const timeoutTeachNowRequest = async (uid, courseId) => {
//     await _setStudentTeachNowResponse(uid, courseId, 'timeout');
// }

// export const useTeachNowNumSessions = (uid, courseId) => {
//     const [numSessions, setNumSessions] = useState(undefined);

//     useEffect(() => {
//         if (uid && courseId) { // Ensures that both uid and courseId are provided
//             const roomsPath = `${courseId}/teachnow/learners/${uid}/rooms`;

//             const roomsRef = database.ref(roomsPath);
//             const listener = roomsRef.on('value', (snap) => {
//                 if (snap.exists()) {
//                     setNumSessions(snap.numChildren());
//                 } else {
//                     setNumSessions(0);
//                 }
//             }, (error) => {
//                 console.error("Error fetching number of sessions", error);
//                 setNumSessions(undefined); // Handle error by resetting state or setting to error state
//             });

//             return () => {
//                 roomsRef.off('value', listener);
//             };
//         }
//     }, [uid, courseId]); // Dependency array to re-run the effect when uid or courseId changes

//     return numSessions;
// };


// export const useTeachNowStudentStatus = (uid, courseId) => {
//     const studentQueuePath = `${courseId}/teachnow/queues/students/${uid}`
//     console.log("studentQueuePath", studentQueuePath)
//     const [studentTeachNowState, setStudentTeachNowState] = useState(undefined);

//     useEffect(() => {
//         if(uid) {
//             const studentTeachNowStatusRef = database.ref(studentQueuePath);
//             studentTeachNowStatusRef.on("value", (snap) => {
//                 const val = snap.exists() ? snap.val() : null;
//                 if(val) {
//                     const studentStatus = val['studentStatus']
//                     const studentTimeJoined = val['timeJoinedMS']
//                     const nudgeId = val['nudgeId']
//                     setStudentTeachNowState({studentStatus, studentTimeJoined, nudgeId})
//                 } else {
//                     setStudentTeachNowState(null)
//                 }
//             })
//             return () => {
//                 studentTeachNowStatusRef.off();
//             }
//         }
//     }, [uid, courseId])

//     return studentTeachNowState
// }

// export const useTeachNowTeacherStatus = (uid, courseId) => {
//     const teacherQueuePath = `${courseId}/teachnow/queues/teachers/${uid}`
//     console.log("teacherQueuePath", teacherQueuePath)
//     const [teacherTeachNowStatus, setTeacherTeachNowStatus] = useState(undefined);

//     useEffect(() => {
//         if(uid) {
//             const teacherTeachNowStatusRef = database.ref(teacherQueuePath);
//             console.log("teacherTeachNowStatusRef", teacherTeachNowStatusRef)
//             teacherTeachNowStatusRef.on("value", (snap) => {
//                 console.log('print inside listener!!!')
//                 const val = snap.exists() ? snap.val() : null;
//                 console.log("teacherTeachNowStatus", val)
//                 if(val) {
//                     const teacherStatus = val['teacherStatus']
//                     setTeacherTeachNowStatus(teacherStatus)
//                 } else {
//                     setTeacherTeachNowStatus(null)
//                 }
//             })
//             return () => {
//                 teacherTeachNowStatusRef.off();
//             }
//         }
//     }, [uid, courseId])

//     return teacherTeachNowStatus
// }

export const getNumTeachNowSessionsAndGratitude = async (uid, courseId) => {
  const db = getFirestore(); // Assumes Firebase has been initialized elsewhere
  const ticketsRef = collection(db, 'teachnow', courseId, 'tickets');
  const ticketsQuery = query(
    ticketsRef,
    where('teacherId', '==', uid),
    where('matched', '==', true),
  ); //, orderBy(new FieldPath("matchedInfo", "timeMatchedServer"), "desc"))

  try {
    const querySnapshot = await getDocs(ticketsQuery);
    const numSessions = querySnapshot.size;

    let sessionInfo = [];

    for (const doc of querySnapshot.docs) {
      const roomId = doc.id;

      const roomUidToDisplayName = await getRoomMembersUidToDisplayName(
        courseId,
        roomId,
      );

      // get a list of all display names except for the one with the key of uid param
      const otherDisplayNames = Object.keys(roomUidToDisplayName)
        .filter(key => key !== uid)
        .map(key => roomUidToDisplayName[key]);

      const ticketDocData = doc.data();
      const sessionTime = ticketDocData?.matchedInfo?.timeMatchedServer;
      const gratitudeRef = collection(doc.ref, 'gratitude');
      const gratitudeSnapshot = await getDocs(gratitudeRef);

      let currSessionInfo = {
        roomId: roomId,
        sessionTime: sessionTime,
        gratitudeResponses: [],
        studentDisplayNames: otherDisplayNames,
      };

      let gratitudeResponses = [];
      for (const gratitudeDoc of gratitudeSnapshot.docs) {
        const docId = gratitudeDoc.id; // This is the student userId
        const senderDisplayName = roomUidToDisplayName[docId];
        const gratitudeData = gratitudeDoc.data();
        if (gratitudeData.gratitude == 0) {
          continue;
        }

        const messageToSL = gratitudeData.messageToSL;
        gratitudeResponses.push({
          gratitudeMessageToSL: messageToSL,
          senderDisplayName: senderDisplayName,
        });
      }

      currSessionInfo.gratitudeResponses = gratitudeResponses;
      sessionInfo.push(currSessionInfo);
    }

    sessionInfo = sessionInfo.sort((a, b) => b.sessionTime - a.sessionTime);

        return { numSessions, sessionInfo };
    } catch (error) {
        console.error("Error fetching data:", error);
        return { numSessions: 0,  sessionInfo: [] }; // Return default in case of error
    }
}