import { useContext } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import { BookContext } from 'textbook/context/BookContext';

export const TitlePage = () => {
  return <TitleInner />;
};

const TitleInner = () => {
  const {bookTitle} = useContext(BookContext)

  return (
    <ContentWrapper>
      <Centered>
        <Title>{bookTitle}</Title>
        <Subtitle>For Code in Place Section Leaders</Subtitle>
        <Logo src={`/stanford.png`} alt="Stanford Logo" />
      </Centered>
      <InfoParagraph>
        Code in Place
        <br />
        Department of Computer Science
        <br />
        Stanford University
        <br />
        Jan 2025
        <br />V 2.0
      </InfoParagraph>
      <ExcludeFromPrint>
        <Link to={`counting`} className="btn btn-primary btn-lg" role="button">
          Get Started
        </Link>
      </ExcludeFromPrint>
      {/* <ExcludeFromPrint>
        <a
          target="_blank"
          href={`https://chrispiech.github.io/probabilityForComputerScientists/en`}
        >
          Old Book (Fall 2024)
        </a>
      </ExcludeFromPrint>
      <ExcludeFromPrint>
        <a
          href={`https://chrispiech.github.io/probabilityForComputerScientists/en/ProbabilityForComputerScientists.pdf`}
        >
          View Book as PDF
        </a>
      </ExcludeFromPrint> */}
      {/* <Acknowledgements>
        Acknowledgements: This book was written by{' '}
        <a href="https://stanford.edu/~cpiech/bio/index.html">Chris Piech</a>{' '}
        for Stanford's <a href="https://cs109.stanford.edu/">CS109 course</a>,
        Probability for Computer scientists. The course was originally designed
        by Mehran Sahami and followed the Sheldon Ross book Probability Theory
        from which we take inspiration. The course has since been taught by Lisa
        Yan, Jerry Cain, and David Varodayan, and their ideas and feedback have
        improved this reader.
      </Acknowledgements> */}
      {/* <Acknowledgements>
        This course reader is open to contributions. Want to make your mark? Keen to fix a typo?
        Download the{' '}
        <a href="https://github.com/chrispiech/probabilityForComputerScientists">GitHub project</a>{' '}
        and publish a pull request. We will credit all contributors. Thank you so much to folks who
        have contributed to editing the book:{' '}
        <a href="https://github.com/chrispiech/probabilityForComputerScientists/graphs/contributors">
          GitHub Contributors
        </a>.
      </Acknowledgements> */}
      {/* <AlertBox>
        <b>Recent Updates</b>:
        <ol>
          <li>
            <a href={`/examples/diversity_shapes/`}>Set Diversity</a>.{' '}
            <i>Oct 13th 2024</i>
          </li>
        </ol>
      </AlertBox> */}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  background-color: white;
  overflow: visible;
  flex-grow: 2;
  padding-left: 89px;
  padding-right: 89px;
  padding-bottom: 60px;
  font-family: 'Times New Roman', Times, serif;

  @media (max-width: 1100px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Centered = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  margin-top: 30px;
  font-size: 3.5rem;
  margin-bottom: 30px;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Logo = styled.img`
  max-width: 60%;
  height: auto;
`;

const InfoParagraph = styled.p`
  padding: 20px;
  text-align: center;
`;

const ExcludeFromPrint = styled.div`
  text-align: center;
  margin: 20px 0;
  a {
    text-decoration: none;
    font-size: 1.2rem;
  }
`;

const Acknowledgements = styled.p`
  font-style: italic;
  margin-top: 20px;
`;

const AlertBox = styled.div`
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;

  b {
    font-weight: bold;
  }

  ol {
    margin-top: 10px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;
