import {useState, useRef, useEffect} from 'react';
import {FaPalette} from 'react-icons/fa';
import styled from 'styled-components';

const predefinedColors = [
  'black',
  'white',
  'blue',
  'red',
  'deeppink', // hot pink
  '#A933FF', // purple
  '#33FFF5',
];

export const ColorPicker = ({editor}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [customHex, setCustomHex] = useState('');
  const textStyleColor = editor?.getAttributes('textStyle')?.color;
  const displayColor = textStyleColor || 'black';

  const dropdownRef = useRef(null); // Ref for the dropdown container

  const onColorChange = color => {
    if (editor.isActive('code')) {
      console.log('Updating code color:', color);
      editor
        .chain()
        .focus()
        .setMark('code', {style: `${color}`})
        .run();
    } else {
      console.log('Updating text color:', color);
      editor.chain().focus().setColor(color).run();
    }
    setShowColorPicker(false);
  };

  const onHexChange = e => {
    const input = e.target.value.trim();
    setCustomHex(input);

    // Apply only if the input is a valid 6-character hex code
    if (/^#([0-9A-Fa-f]{6})$/.test(input)) {
      onColorChange(input);
    }
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Container ref={dropdownRef}>
      {/* Main Button */}
      <button
        className="btn btn-sm btn-light"
        onClick={() => setShowColorPicker(prev => !prev)}
        aria-label="Open color picker"
      >
        <FaPalette color={displayColor} />
      </button>

      {/* Dropdown Color Picker */}
      {showColorPicker && (
        <Dropdown>
          <ColorGrid>
            {predefinedColors.map(color => (
              <ColorButton
                key={color}
                color={color}
                selected={textStyleColor === color}
                onClick={() => onColorChange(color)}
                aria-label={`Choose color ${color}`}
              />
            ))}
          </ColorGrid>
          {/* Hex Input */}
          <HexInput
            type="text"
            placeholder="Hex code"
            value={customHex}
            onChange={onHexChange}
          />
        </Dropdown>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const ColorButton = styled.button`
  background-color: ${props => props.color};
  width: 24px;
  height: 24px;
  border: ${props => (props.selected ? '2px solid #000' : '1px solid #ccc')};
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    border-color: #000;
  }
`;

const HexInput = styled.input`
  margin-top: 8px;
  padding: 4px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
`;
