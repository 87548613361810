import {Dispatch, SetStateAction} from 'react';

/**
 * Enum representing the possible visibility states for the developer button.
 */
export enum DeveloperButtonState {
  /**
   * The developer button is completely hidden.
   */
  HIDDEN = 'hidden',

  /**
   * The developer button is visible to the user.
   */
  VISIBLE = 'visible',

  /**
   * The developer button is hidden until the page reloads.
   */
  HIDDEN_UNTIL_RELOAD = 'hidden_until_reload',
}

/**
 * Interface defining the shape of the Developer Tools Context.
 */
export interface DeveloperToolsContextType {
  /**
   * Current visibility state of the developer button.
   */
  developerButtonVisibilityState: DeveloperButtonState;

  /**
   * React state setter for the developer button's visibility state.
   */
  setDeveloperButtonVisibilityState: Dispatch<
    SetStateAction<DeveloperButtonState>
  >;

  /**
   * Whether the developer tools modal should be displayed.
   */
  shouldShowModal: boolean;

  /**
   * React state setter to control the visibility of the developer tools modal.
   */
  setShouldShowModal: Dispatch<SetStateAction<boolean>>;
}
