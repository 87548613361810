import {
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  PyodideContext,
} from "../components/pyodide/PyodideProvider";
import { useNavigate, useParams } from "react-router-dom";
import { loadPublishedProject } from "./loadPublishedProject";
import { StartStopButton } from "../ide/NavIDE";
import { useComponentSize, useWindowSize } from "react-use-size";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import {
  checkIsProjectKarel
} from "../ide/utils/general";
import { KarelWorld, KarelWorldDebounced } from "../components/pyodide/KarelLib/KarelWorld";
import { getKarelStarterWorld } from "ide/contexts/IDEContext";
import { LikeButton } from "course/forum/components/LikeButton/LikeButton";
import { useUserId } from "hooks/user/useUserId";
import {
  FaCopy,
  FaCircle,
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import Swal from "sweetalert2";
import { useCourseId } from "hooks/router/useUrlParams";
import ContentEditable from "react-contenteditable";
import { useDebounce } from "use-debounce";
import { getApp } from "firebase/app";
import { Loading } from "components/loading/Loading";
import { usePyodideOnPublished, useUserInfo } from "./PublishedHooks";
import { usePublishedProjectMetrics } from "./MetricTracker";
import { RunsDisplay } from "./Runs";
import { NonIDETerminalView } from "ide/TerminalPane/GeneralTerminal/SimpleTerminalView";
import styled from "styled-components";
import { is } from "date-fns/locale";
import { MAX_CORNER_SIZE, MIN_CORNER_SIZE } from "components/pyodide/KarelLib/util";
import { set } from "date-fns";
import { DiverseInput } from "course/application/studentApplication/about/AboutFormInputs";


/**
 * @description Catches loading and error cases
 * @returns {JSX.Element}
 */
export const Published = () => {
  const { courseId, projectId } = useParams();

  // load all the code to be able to be run
  // This data comes from publishedProjects in firestore
  const [projectData, projectCode, error, loading] = loadPublishedProject(
    projectId,
    courseId
  );


  if (loading) { return <Loading /> }
  if (error) return <>{error}</>;

  if (projectData && projectCode) {
    return <PublishedWithData
      projectId={projectId}
      projectCode={projectCode}
      projectData={projectData}
    />
  }


  const ideUrl = `/${courseId}/ide/p/${projectId}`;
  return <div className="d-flex text-center">This project was never published. To publish the project go to the &nbsp;
    <a href={ideUrl}>Project Ide Page</a>&nbsp; and hit the Share button (top right corner of the page).</div>

};



export const PublishedWithData = ({ projectData, projectCode, projectId }) => {


  // Main Components
  const { isPyodideLoading } = useContext(PyodideContext);

  // Project Properties
  const isKarel =
    checkIsProjectKarel(projectData, projectData.assnData) ||
    (projectData && projectData.karelWorld); // || !projectData.assnData; < -- This breakes the published breakout example
  const showTerminal = projectData.showTerminal && !isKarel;
  const mainFileID = projectData.mainFile;
  // earlier version of publishing didn't use title but rather the name of the folder.
  // Those published projects won't have an explicit title
  const projectTitle = projectData.title || projectData.files[0].name;
  const numLikes = projectData.numLikes ?? 0;
  const numRuns = projectData.numRuns ?? 0;

  const codeToRun = useRef(projectCode[mainFileID].content);
  const defaultKarelWorldState = projectData.karelWorld
    ? projectData.karelWorld
    : getKarelStarterWorld(projectData.assnData);

  // States
  const [karelWorldState, setKarelWorldState] = useState(
    defaultKarelWorldState
  );

  // Fetch user information to determine if user is the owner

  const { userInfo, isOwner } = useUserInfo(projectData);
  const { runCode, isRunning, setRunning, pyodideClientRef } = usePyodideOnPublished(projectData, projectCode, projectId, codeToRun, isKarel, mainFileID, karelWorldState, setKarelWorldState);



  const startStopButtonClicked = async () => {
    if (isKarel) {
      if (!isRunning && !isPyodideLoading) {
        if (
          JSON.stringify(defaultKarelWorldState) ===
          JSON.stringify(karelWorldState)
        ) {
          // RUN CODE
          setRunning(true);
          await runCode();
        } else {
          // RESET
          setKarelWorldState(defaultKarelWorldState);
        }
      } else {
        // STOP
        pyodideClientRef.current.raisePyStopFlag();
      }
    } else {
      if (!isRunning) {
        // RUN CODE
        setRunning(true);
        await runCode();
      } else {
        // STOP
        setRunning(false);
        pyodideClientRef.current.raisePyStopFlag();
      }
    }
  };

  return (
    <div
      className="d-flex align-items-center w-100 flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <div className="flex-2 w-100 d-flex flex-column" style={{ flexGrow: 2, overflow: "auto" }}>
        <div className="pt-2 d-flex flex-column justify-content-center align-items-center w-100 text-center">
          {/* Name of Project */}
          <h1 className="fw-bold fs-1">{projectTitle}</h1>

          {/* User Information */}
          <UserInformation
            userInfo={userInfo}
            projectId={projectId}
            numLikes={numLikes}
            numRuns={numRuns}
          />

          <ProjectDescription
            projectData={projectData}
            isOwner={isOwner}
            projectId={projectId}
          />

          <div
            className="d-flex flex-row w-100 justify-content-center align-items-center"
            style={{
              paddingBottom: "9px",
              marginTop: isOwner ? "-10px" : "0px",
            }}
          >
            <StartStopButton
              isRunning={isRunning}
              startStopButtonClicked={startStopButtonClicked}
              isKarel={isKarel}
              karelWorldState={karelWorldState}
              defaultKarelWorldState={defaultKarelWorldState}
              editable={true}
            />
          </div>

        </div>

        <div style={{ flexGrow: 2 }} className="d-flex flex-column align-items-center justify-content-center w-100">
          <PublishedCanvas isKarel={isKarel} />
          {isKarel && <PublishedKarelWorld
            isRunning={isRunning}
            karelWorldState={karelWorldState}
          />}
          {showTerminal && <PublishedTerminal  />}
        </div>
      </div>
      <Footer />
    </div>
  );
};


/**
 * @name ProjectDescription
 */
const ProjectDescription = ({ projectData, isOwner, projectId }) => {
  const db = getFirestore();
  const courseId = useCourseId();
  const [note, setNote] = useState(
    projectData.note ?? "Hello! I made this for Code in Place!"
  );
  const [isDirty, setDirty] = useState(false);
  const [debouncedNote] = useDebounce(note, 250);


  // Save note to firestore
  async function saveNote() {
    const docRef = doc(
      db,
      `published/${courseId}/studentPublished/${projectId}`
    );
    await updateDoc(docRef, { note: debouncedNote });
    setDirty(false);
  }

  // Save changes and set Dirty bit
  useEffect(() => {
    if (note != projectData.note) {
      setDirty(true);
    }
  }, [note, projectData.note]);

  // Save note every 250 ms if changed
  useEffect(() => {
    if (debouncedNote && debouncedNote != projectData.note && isOwner) {
      saveNote();
    }
  }, [debouncedNote]);

  const savingWidth = 10;


  return (
    <div
      className="d-flex flex-column align-items-end"
      style={{ width: "min(calc(100% - 20px), 500px)" }}
    >
      <div
        style={{
          borderRadius: "10px",
          color: "white",
          background: "black",
          padding: "10px",
          marginTop: "10px",
          fontFamily: "menlo",
          width: "100%",
          fontSize: "14px",
          alignSelf: "center",
          textAlign: "left",
          marginBottom: "5px",
        }}
      >
        <ContentEditable
          style={{
            background: "black",
            border: "none",
            color: "white",
            textDecoration: "none",
            outline: "none",
            width: "100%",
            resize: "none",
          }}
          html={note} // innerHTML of the editable div
          disabled={!isOwner} // use true to disable editing
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
      <div
        className="d-flex"
        style={{ fontSize: "12px", marginTop: isOwner ? "-5px" : "0px" }}
      >
        {isOwner && <div className="muted mr-1">Editable (Owner)</div>}
        {isDirty ? (
          <div style={{ width: savingWidth }} className="muted">
            <FaCircle className="text-primary" />
          </div>
        ) : (
          <div style={{ width: savingWidth }}></div>
        )}
      </div>
    </div>
  );
};

const UserInformation = ({ userInfo, projectId, numLikes, numRuns }) => {

  const [userFirebaseDoc, setUserFirebaseDoc] = useState(null);
  const userId = useUserId();
  const courseId = useCourseId();
  const { isLiked, unlikeProject, likeProject } = usePublishedProjectMetrics(projectId, userId, courseId);
  const navigate = useNavigate();

  const toggleLike = () => {

    if (!userId) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You must be logged in to like a project!',
        confirmButtonText: 'Login',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/${courseId}`)
        }
      })
      return;
    }
    if (isLiked) {
      unlikeProject();
    } else {
      likeProject();
    }
  }



  useEffect(() => {
    if (!userInfo) return;
    async function getDisplayName() {
      const fetchedUserFirebaseDoc = (
        await getDoc(doc(getFirestore(getApp()), "users", userInfo.id))
      ).data();
      setUserFirebaseDoc(fetchedUserFirebaseDoc);
    }

    getDisplayName();
  }, [userInfo]);

  if (!userInfo || !userInfo.displayName) {
    return <></>;
  }

  const { displayName, photoURL } = userFirebaseDoc ?? {};




  return (
    <>
      <div className="d-flex gap-2 align-items-center justify-content-center text-center" >
        {photoURL && (
          <img
            src={photoURL}
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              borderRadius: "999px",
            }}
          />
        )}
        {displayName && <div>Created by {displayName}</div>}
        <LikeButton
          isLiked={isLiked}
          likeHandler={toggleLike}
          numLikes={numLikes}
          iconSize="15px"
          showNumber={numLikes > 0}
        />
        <RunsDisplay numRuns={numRuns} iconSize={"15px"} showNumber={numRuns > 0} />
      </div>
    </>
  );
};

// Reusable Social Media icon row
export const SocialMediaIconRow = ({ referralText }) => {
  return (
    <div className="d-flex align-items-center justify-content-center gap-3">
      {/* WhatsApp */}
      <a
        href={`https://wa.me/?text=${encodeURIComponent(referralText)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp style={{ color: '#25D366' }} />
      </a>

      {/* Instagram (no direct “share” URL, so we just link to instagram.com) */}
      <a
        href="https://www.instagram.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram style={{ color: '#C13584' }} />
      </a>

      {/* Twitter (X) */}
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          referralText
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ color: 'black' }}>𝕏</span>
      </a>

      {/* Facebook */}
      <a
        href={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          referralText
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook style={{ color: '#4267B2' }} />
      </a>
    </div>
  );
};

const Footer = () => {
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < 1000;

  const copyLink = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(window.location.href);

    // should be a toast
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      allowOutsideClick: true,
    });

    Toast.fire({
      icon: "success",
      title: "Link copied",
    });
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          padding: "10 0",
          width: "100%",
          background: "white",
          bottom: 0,
          left: 0,
        }}
        className="d-flex align-items-center justify-content-center flex-row mb-2 gap-3"
      >
        {/* Middle */}
        <div>
          <button
            className="btn d-flex justify-content-center align-items-center gap-3"
            style={{
              color: "grey",
            }}
            onClick={copyLink}
          >
            Copy shareable link <FaCopy />
          </button>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span style={{
            color: "grey",
          }}>Share on:</span>
          <SocialMediaIconRow referralText={window.location.href} />
        </div>
      </div>
    </>
  );
};

const PublishedKarelWorld = ({ karelWorldState, isRunning }) => {
  const windowWidth = useWindowSize().width;
  const outerSize = useComponentSize();
  const [targetSize, setTargetSize] = useState({ width: 0, height: 0 });
  const nRows = karelWorldState.nRows;
  const nCols = karelWorldState.nCols;
  const minCorner = MIN_CORNER_SIZE;
  const maxCorner = MAX_CORNER_SIZE

  useEffect(() => {
    if (outerSize.height > 2 && outerSize.width > 2) {

      const usableWidth = windowWidth - 18;
      const usableHeight = outerSize.height - 18;

      const cornerSizeByWidth = Math.floor(usableWidth / nCols);
      const cornerSizeByHeight = Math.floor(usableHeight / nRows);

      const maxPossibleCornerSize = Math.min(cornerSizeByWidth, cornerSizeByHeight);
      let finalCornerSize = Math.min(maxPossibleCornerSize, maxCorner);
      finalCornerSize = Math.max(finalCornerSize, minCorner);

      setTargetSize({
        width: finalCornerSize * nCols,
        height: finalCornerSize * nRows
      });

    }
  }, [outerSize.height, outerSize.width, windowWidth]);


  return <KarelWorldOuter ref={outerSize.ref}>
    <div style={{
      width: targetSize.width,
      height: targetSize.height,
      backgroundColor: "blue",
    }}>

      <KarelWorldDebounced
        worldState={karelWorldState}
        alignment="center"
        isRunning={isRunning}
        styleOverride={{ 
          width: targetSize.width,
          height: targetSize.height,
         }}
      />


    </div>
  </KarelWorldOuter>
}

const KarelWorldOuter = styled.div`
  
  flex: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`


const PublishedTerminal = ({ }) => {

  return <NonIDETerminalView
    style={{
      backgroundColor: "#2D2E2C",
      borderRadius: "10px",
      margin: "0 10px",
      width: "100%",
      maxWidth: "700px",
      height: "100%",
      minHeight: "300px",
      maxHeight: "800px",
      padding: "10px",
      overflow: "auto",
    }}
  />
};
export const PublishedCanvas = ({ isKarel }) => {
  const windowWidth = useWindowSize().width;
  const canvasSize = useComponentSize();

  const [canvasCreated, setCanvasCreated] = useState(false);
  const [targetSize, setTargetSize] = useState({ width: 0, height: 0, v: 0 });


  useEffect(() => {
    if (canvasSize.height > 2 && canvasSize.width > 2) {
      setCanvasCreated(true);

      const usableWidth = windowWidth - 18;

      if (usableWidth < canvasSize.width) {
        const ratio = canvasSize.width / canvasSize.height;
        const newTarget = {
          width: usableWidth,
          height: usableWidth / ratio,
          v: targetSize.v + 1,
        };

        const canvas = canvasSize.ref.current;
        canvas.style.width = newTarget.width + "px";
        canvas.style.height = newTarget.height + "px";
      }
    }
  }, [canvasSize.height, canvasSize.width, windowWidth]);

  if (isKarel) {
    return null;
  }

  return (
    <canvas
      ref={canvasSize.ref}
      style={{
        margin: targetSize.v,
        backgroundColor: "white",
        border: `solid 1px ${canvasCreated ? "" : "white"}`,
        borderRadius: "5px",
        width: "0px",
        height: "0px",
      }}
      id="canvas"
    ></canvas>
  );
};
