import {AnywhereOnEarthCountdown} from 'components/reusableButtons/AOECountdown';
import {EnrollmentContext} from 'contexts/EnrollmentContext';
import {getAnywhereOnEarthDateTextFromISO} from 'course/application/apputils';
import {ApplicationStatus} from 'course/application/general/ApplicationStatus';
import {getApp} from 'firebase/app';
import {doc, getFirestore} from 'firebase/firestore';
import {useUser, useUserId} from 'hooks/user/useUserId';
import {useContext} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';

import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Role} from 'types/role';
import {useIsMobile} from 'utils/general';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import LogOnClick from 'engagement/LogOnClick';

export const Header = ({
  appStatus,
  appDataPath,
  appOpen,
  courseData,
  allAppData,
}) => {
  const user = useUser();

  const isMobile = useIsMobile();

  const aboutData = allAppData?.appData;

  console.log('allAppData', allAppData);

  let userGreetingStr = getDisplayName(user, aboutData);
  const isComplete = appStatus === 'complete';

  const userId = useUserId();
  const db = getFirestore(getApp());
  const userRef = doc(db, `users/${userId}`);
  const [userData, userDataLoading, userDataError] = useDocumentData(userRef);

  if (userDataLoading) return <></>;

  const appStr = isMobile ? 'App' : 'Application';
  const yearStr = isMobile ? '5' : '2025';

  return (
    <HeaderOuter>
      <div className="card">
        <div className="card-body">
          {/* <h2>Student Application {userGreetingStr}</h2> */}
          <h2>
            Student {appStr} - Code in Place {yearStr}
          </h2>

          <h4>{userGreetingStr}</h4>
          <DueDate appOpen={appOpen} courseData={courseData} />
          <AdmissionMessage
            isComplete={isComplete}
            appOpen={appOpen}
            userData={userData}
            courseData={courseData}
          />

          {/* <br/><span>Welcome Chris Piech. We hope you enjoy learning.</span> */}
          {/* 
          <ReferAFriend/> */}

          <ApplicationStatus appStatus={appStatus} appDataPath={appDataPath} />
          <AppInfo
            appOpen={appOpen}
            isComplete={isComplete}
            courseData={courseData}
          />
        </div>
      </div>
    </HeaderOuter>
  );
};

const AdmissionMessage = ({appOpen, isComplete, userData, courseData}) => {
  const {targetCourseId} = useParams();

  const {roles} = useContext(EnrollmentContext);

  const courseRole = roles?.[targetCourseId];
  console.log(courseRole);
  // don't use the functions in Gate or ProfileUtil as this string
  // has not been processed by ProfileContext
  const isAdmit = courseRole === Role.STUDENT;
  const isExpStudent = courseRole === Role.EXPERIENCED_STUDENT;
  const isStaff =
    courseRole === Role.ADMIN ||
    courseRole === Role.TA ||
    courseRole === Role.INSTRUCTOR;

  if (isExpStudent) {
    return (
      <div className="bordered">
        {/* <p>Hi {userData?.displayName},</p> */}
        <p>
          Wow! It looks like you are *already* an amazing programmer. We would
          like to welcome you to Code in Place 2024 in the special{' '}
          <b>Experienced Student</b> track. There will be no live meetings for
          this track, but you can participate in all of the other parts of the
          course, including getting a course certificate at the end.
        </p>
        <LogOnClick
          eventName="select_content"
          eventParams={{
            content_type: 'button',
            content_id: 'experienced_student_onboarding_button',
          }}
        >
          <Link
            to={`/${targetCourseId}/onboarding/experiencedstudent`}
            className="btn btn-primary"
          >
            Experienced Student Onboarding
          </Link>
        </LogOnClick>
      </div>
    );
  }

  if (isAdmit) {
    return (
      <div className="bordered">
        {/* <p>Hi {userData?.displayName},</p> */}
        <p>
          Congratulations! You have been accepted to Code in Place. We were
          impressed by your application and look forward to having you in class.
          Please confirm your place in the course by the end of the day by noon
          on Sun April 23rd, 2023 <a href="https://time.is/PT">Pacific Time</a>.
          Section sign ups are first come, first serve, so come join as soon as
          you can.
        </p>
        <LogOnClick
          eventName="select_content"
          eventParams={{
            content_type: 'button',
            content_id: 'student_onboarding_button',
          }}
        >
          <Link
            to={`/${targetCourseId}/onboarding/student`}
            className="btn btn-primary"
          >
            Student Onboarding
          </Link>
        </LogOnClick>
      </div>
    );
  }
  // if(!isStaff && !isAdmit && isComplete){
  //   return <div className="bordered">
  //     Thank you for taking the time to apply.
  //     The course filled up before we could get to your application.
  //     As you can appreciate, there is a maximum
  //     number of students that we can support.
  //     While this might not be the result you wanted, we would like to gently note that there are many wonderful place where you can go learn python.
  //   </div>
  // }
  // if (!isStaff && !isAdmit && !isComplete) {
  //   return <div className="bordered">
  //     We already have a full class. Feel free to explore the application and all the best on your learning journey!
  //   </div>
  // }
  if (isStaff) {
    <div className="bordered">Wahoo! You are on the staff team.</div>;
  }
  return <> </>;
};

const AppInfo = ({appOpen, isComplete, courseData}) => {
  const studentAcceptanceDate = courseData?.studentResultsDate;
  // Create date string that returns the date without correcting for timezone
  const date = new Date(studentAcceptanceDate);

  // get date string in the format "Month Day, Year"
  const studentAcceptanceDateAsStr = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  if (appOpen) {
    if (!isComplete) {
      return <></>;
    }
    return (
      <>
        Check this page on{' '}
        <span className="">{studentAcceptanceDateAsStr}</span> to see if your
        application was selected. If you would like to go ahead and start
        learning, check out the{' '}
        <LogOnClick
          eventName="select_content"
          eventParams={{
            content_type: 'button',
            content_id: 'self_guided_course_button',
          }}
        >
          <Link to="/public">Self-Guided Course</Link>
        </LogOnClick>
        .
      </>
    );
  }
  return (
    <>
      <div className="">
        <p>
          <span>
            Applications are closed and we have already downloaded applications
            for review. Check this page on{' '}
            <span className="badge bg-primary">
              {studentAcceptanceDateAsStr}
            </span>{' '}
            to see if your application was selected.
          </span>
        </p>
        {/* Apply to be part of {courseName} as a student. We hope you have fun. */}
      </div>
    </>
  );
};

{
  /* <AOEExplainer/> */
}

const DueDate = ({appOpen, courseData}) => {
  const isMobile = useIsMobile();
  if (appOpen) {
    return (
      <>
        Due{' '}
        <strong>
          {getAnywhereOnEarthDateTextFromISO(courseData?.studentAppDueDate)}{' '}
        </strong>
        <OverlayTrigger
          delay={{show: 100, hide: 100}}
          overlay={props => (
            <Tooltip id="button-tooltip" {...props}>
              You can finish your application as long as there is a place on
              earth where the deadline hasn't passed.
            </Tooltip>
          )}
        >
          <span>
            {isMobile ? 'AoE' : 'Anywhere on Earth'}
            {/* <AOEExplainer /> */}
          </span>
        </OverlayTrigger>
        <br />
        <span>
          <i>
            in{' '}
            <AnywhereOnEarthCountdown dateStr={courseData?.studentAppDueDate} />
          </i>
        </span>
      </>
    );
  } else {
    return <h4 className="">Applications Closed</h4>;
  }
};

function getDisplayName(user, aboutData) {
  if (aboutData && aboutData['name']) {
    return aboutData['name'];
  }

  return user.displayName;
}

const HeaderOuter = styled.div`
  width: 100%;
`;
