import { FAQAccordion } from "components/FAQ/FAQ";
import { AO } from "country-flag-icons/react/3x2";
import {getAnywhereOnEarthDateTextFromISO} from 'course/application/apputils';
import {AOEExplainer} from 'course/application/studentApplication/components/AOEExplainer';
import {doc, getFirestore} from 'firebase/firestore';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {useParams} from 'react-router-dom';


export const FAQ = ({}) => {
    const db = getFirestore();
    const {targetCourseId} = useParams();
    const [courseData] = useDocumentData(
        doc(db, `course/${targetCourseId}`),
    );

    {/* Manually append the admission day pulled from firestore into the FAQ  */}
    const resultsDateISO = courseData?.studentResultsDate || "";
    const resultsDateStr = getAnywhereOnEarthDateTextFromISO(resultsDateISO);
    const faqContentWithResultsDay = [
        ...faqContent, 
        {
            q: "When will I know if I'm accepted as a student for Code in Place 2025?",
            a: <span>{resultsDateStr} at the latest! <AOEExplainer /></span>
        }
    ]

   return (<div className="landing-container d-flex w-100">
        <div className="row flex-grow-1">
            <div className="col">
                <h2 style={{ paddingTop: "20px" }}>Frequently Asked Questions</h2>
                <FAQAccordion content={faqContentWithResultsDay}/>
            </div>
        </div>
    </div>)
}

const faqContent =[
    {
        q:"Why should I learn to code?",
        a:`It is fun and you will learn a new way of thinking! Coding is perhaps the closest thing we have in this
        world to magic -- you get to use the power of computers to make your imagination come to life. What a great joy and useful skill.`
    },
    {
        q:`Is it truly free?`,
        a:`Yes.`
    },
    {
        q:`What is the "live section" about?`,
        a:`You are part of the group of students following along the course as
        it progresses. Following along means you get to join in on live
        conversations, ask questions about lecture and section.`
    },
    {
        q:`Is this class right for me?`,
        a:`Yes! Unless you already know how to code. We have taught similar
        material all around the world, to people of all backgrounds.`
    },
    {
        q:`Why are you doing this?`,
        a:`It seems like the right thing to do. We also love to teach.`
    },
    {
        q:`Isn't the COVID-19 pandemic over?`,
        a:`Code in Place did start in response to the pandemic. It was a community service built to give high quality education to the world for free. Through the journey, we fell in love with teaching with people from around the world, and to such wonderful students. So we are going to keep going.`
    },
    {
        q:`What are the prerequisites?`,
        a:`There are none! Well actually, you need to be able to turn on a
         computer. Or, honestly you probably only need to be able to
         recognize if a computer is on. It is helpful if you 
         already know some basic arithmetic, e.g. what is multiplication?`
    },
    {
        q:`What language is the course in?`,
        a:`The primary content is in English, and sections will be in English too. But there have been students in our course with a wide range of English background, from beginners to native speakers. We are very into internationalization and in the future hope to make Code in Place work in any language.`
    },
    {
        q:`Is this a MOOC?`,
        a:`No. The main difference is that we are not massive, and our space is
           limited by availibility. Code in Place is a relatively
           different experience, involving live interactive teaching in small groups.`
    },
    {
        q:`When will my section be?`,
        a:`Our students are all over the world, so there's always someone
           learning in Code in Place. We'll have sections running at all hours
           between Wednesday and Friday, and you can sign up for whichever time
           best suits your schedule.`
    },
    {
      q:`Will I get a course certificate?`,
      a:`Yes. If you attend your sections and complete your work.`
  }
]
