export default function KarelSvgGroup({
  translateX = 0,
  translateY = 0,
}: {
  translateX?: number;
  translateY?: number;
}) {
  return (
    <g transform={`translate(${translateX ?? 0}, ${translateY ?? 0})`}>
      <path d="M167.1,158.3h-48v8c7.4,0,14.7.1,22.1.1.1,2.1,0,11.5-.1,13.9h-34.5c0,0,0,0-.5-22h-30.5L58.1,140.8v-21.5h-13v17h-13v-31h26v-86h83.5l25.5,25.5v113.5Zm-5-4v-105.5L136.6,23.3h-74.5v112.5l19.5,18.5c11.7.2,38.3.1,40.9.1c13.3,0,26.4-.2,39.6-.1Z" />
      <path
        d="M162.1,154.3v-105.5L136.6,23.3h-74.5v112.5l19.5,18.5c11.7.2,38.3.1,40.9.1c13.3,0,26.4-.2,39.6-.1Zm-30-118v74h-52v-74h52Z"
        fill="#f8feff"
      />
      <path d="M106.1,132.3v-5h33.5c1.9,0,1.9,5,0,5h-33.5Z" />
      <path d="M132.1,36.3v74h-52v-74h52Zm-48,70h42.5l1.5-1.5v-63l-1.5-1.5h-42.5v66Z" />
    </g>
  );
}
