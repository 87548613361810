import styled from 'styled-components';

export const SidebarFooter = () => {
  return (
    <StyledSidebarFooter>
      {/* <FooterText>Written by Chris Piech</FooterText> */}
      {/* <AuthorImg src={"https://stanford.edu/~cpiech/bio/images/people/chrisP.jpg"} /> */}
      <StanfordOuter>
        <StanfordLogoSeal src="/stanford.png" alt="Stanford" />
        <StanfordLogoText src="/stanfordtextwhite.png" alt="Stanford" />
      </StanfordOuter>
    </StyledSidebarFooter>
  );
};

const StanfordLogoText = styled.img`
  height: 18px;
`;

const StanfordLogoSeal = styled.img`
  height: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const StanfordOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
  margin-left: -4px;
`;

const FooterText = styled.span`
  font-size: 1em;
  line-height: 1em;
  color: #ddd;
`;

const StyledSidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: white;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const AuthorImg = styled.img`
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
