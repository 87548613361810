import { useContext, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaBookOpen, FaFilePdf, FaInfo, FaLanguage, FaYoutube } from "react-icons/fa";
import YouTube from "react-youtube";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useIsMobile } from "utils/general";
import { LessonContext } from "../LessonContext";

const ENGLISH = 'English'
const SPANISH = 'Español'
const languages = [ENGLISH]

export const YouTubeLesson = ({ onComplete, data }) => {
  const [currLang, setCurrLang] = useState(ENGLISH)
  const [videoRef, videoSize] = useSize();
  const isMobile = useIsMobile();

  const { player, setPlayer } = useContext(LessonContext);

  useEffect(() => {
    if (currLang === ENGLISH) {
      return
    }
    const videoId = getVideoId(data, currLang)
    if (player) {
      player.loadVideoById(videoId);
    }
  }, [currLang, player])


  // Calculate maximum video dimensions while maintaining a 16:9 aspect ratio
  const maxWidthFromHeight = videoSize.height * (16 / 9); // Maximum width based on height
  const maxHeightFromWidth = videoSize.width * (9 / 16); // Maximum height based on width

  let videoWidth, videoHeight;


  if (maxWidthFromHeight <= videoSize.width) {
    // If the maxWidthFromHeight is within the container's width, use it
    videoWidth = maxWidthFromHeight;
    videoHeight = videoSize.height;
  } else {
    // Otherwise, use maxHeightFromWidth
    videoWidth = videoSize.width;
    videoHeight = maxHeightFromWidth;
  }

  const onPlayerReady = (event) => {
    // Auto-play the video when the player is ready
    event.target.playVideo();
    setPlayer(event.target);

    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.focus();
    }
  };

  const langCode = currLang === SPANISH ? 'es' : 'en'

  const opts = {
    height: videoHeight,
    width: videoWidth,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 1,
      modestbranding: 1,
      rel: 0,
      hl: langCode,
      cc_load_policy: 1,
    },
  };

  if (isMobile) {
    return <>
      <div ref={videoRef} style={VIDEO_STYLE_OUTER}>

        {videoWidth > 0 &&
          <YouTube
            videoId={data.videoId}
            opts={opts}
            onReady={onPlayerReady}
            onEnd={onComplete}

          />}

        <Footer
          data={data}
          currLang={currLang}
          setCurrLang={setCurrLang}
        />
      </div>
    </>
  }

  return (
    <>
      <div ref={videoRef} style={VIDEO_STYLE_OUTER}>

        {videoWidth > 0 &&
          <YouTube
            videoId={data.videoId}
            opts={opts}
            onReady={onPlayerReady}
            onEnd={onComplete}

          />}


      </div>

      <Footer
        data={data}
        currLang={currLang}
        setCurrLang={setCurrLang}
      />
    </>
  )
}

const Disclaimer = ({ currLang }) => {
  const onClick = () => {
    Swal.fire({
      icon: 'info',
      title: 'Aviso',
      position: 'top-end',
      toast: true,
      text: 'El audio original estaba en inglés. Este vídeo ha sido traducido automáticamente.'
    })
  }
  if (currLang === SPANISH) {
    return <button
      onClick={onClick}
      className="btn btn-sm btn-primary">
      <FaInfo />
    </button>
  }
  return <></>
}


const Footer = ({ data, currLang, setCurrLang }) => {

  const clearServiceWorkers = async () => {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
      await registration.unregister();
    }
    window.location.reload();
  }

  useEffect(() => {
    // pop swal in top right corner asking if the video is not working
    // Swal.fire({
    //   title:"Video not working?",
    //   position: 'top-end',
    //   confirmButtonText: 'Reload',
    //   cancelButtonText: 'Ignore',
    //   toast: true,
    //   showCancelButton: true,
    //   icon:"info",
    //   preConfirm:() => {
    //     clearServiceWorkers()
    //   }   
    // })
  }, [])
  return <>

    <FooterOuter>
      {/* <a className="ml-1 mr-1 btn btn-light" target="_blank" href={`https://www.youtube.com/watch?v=${data.videoId}`}><FaYoutube /> View on Youtube</a> */}

      {data?.slides && <a
        href={data?.slides}
        target="_blank"
        className="ml-1 mr-1 btn btn-light"
      ><FaFilePdf /> Slides</a>}
      <Readings readingList={data.readings} />
      {/* <VideoLanguage
        data={data}
        currLang={currLang}
        setCurrLang={setCurrLang}
      /> */}
      {/* <button className="ml-1 mr-1 btn btn-light" href=""><FaFile /> Transcript</button> */}
      {/* <Disclaimer currLang={currLang} /> */}
    </FooterOuter>
  </>
}

const VideoLanguage = ({ data, currLang, setCurrLang }) => {

  const hasTranslation = data?.translations?.es
  if (!hasTranslation) return <></>



  return <Dropdown className="mr-1 ml-1">
    <Dropdown.Toggle variant="light" id="dropdown-basic">
      <FaLanguage /> {currLang}
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {
        languages.map((language, i) => {
          if (!language) return <></>
          return <Dropdown.Item
            key={language}
            onClick={() => setCurrLang(language)}>{language}</Dropdown.Item>
        })
      }

    </Dropdown.Menu>
  </Dropdown>

}

const Readings = ({ readingList }) => {

  const readings = readingList ? readingList : []
  if (readings.length == 0) return <></>
  return <Dropdown className="mr-1 ml-1">
    <Dropdown.Toggle variant="light" id="dropdown-basic">
      <FaBookOpen /> Textbook
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {
        readings.map((readingItem, i) => {
          const reading = readingItem.value
          const title = reading.title;
          if (!title) return <></>
          return <Dropdown.Item
            key={reading.title}
            onClick={() => openInNewTab(reading.url)}>{reading.title}</Dropdown.Item>
        })
      }

    </Dropdown.Menu>
  </Dropdown>
}

function getVideoId(data, lang) {
  if (lang === SPANISH) {
    return data.translations?.es
  }
  return data.videoId
}

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

function useSize() {
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0].target) {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref]);

  return [ref, size];
}
const VIDEO_STYLE_OUTER = {
  width: '100%',
  height: 'calc(100% - 80px)',
  display: 'flex',
  paddingTop: 20,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}

const FooterOuter = styled.div`
  margin-top: 20px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

