import NoAccess from 'components/errors/NoAccess';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {
  SelectDropdown,
  SelectOption,
} from 'components/reusableButtons/SelectDropdown';
import {ProfileContext} from 'contexts/ProfileContext';
import {isAdmin} from 'contexts/profileUtil';
import {
  addNamespace,
  DEFAULT_LANGUAGE_CODE,
  FALLBACK_NAMESPACE,
  useNamespaceTranslations,
  useTranslateFunction,
  useTranslationContext,
  useTranslationUploader,
} from 'contexts/TranslationContext';
import {SetStateAction, useContext, useEffect, useState} from 'react';
import {EditableMap} from '../common/EditableMap';
import {Alert, Button, Col, Container, Row} from 'react-bootstrap';
import {CourseId} from 'types/common';
import Swal from 'sweetalert2';
import {
  FALLBACK_COURSE_ID,
  LANGUAGE_CODE_TO_NAME,
  LanguageCode,
  NamespaceId,
} from '../../../components/translation/utils';
import {
  getTranslationCollection,
  getTranslationDocument,
} from '../../../firebase/models/translation';
import {deleteDoc, getDoc, getDocs} from 'firebase/firestore';
import {useCourseIdWithoutFallback} from 'hooks/router/useUrlParams';

export const TranslationEditor = () => {
  const {userData} = useContext(ProfileContext);
  if (!isAdmin(userData?.courseRole)) {
    return <NoAccess />;
  }
  return (
    <>
      <CoursePageBodyContainer
        mainColumn={<TranslationEditorMain />}
        singleColumn={<TranslationEditorMain />}
      />
    </>
  );
};

const TranslationEditorMain = () => {
  const {useSupportedLanguages, useSupportedNamespaces} =
    useTranslationContext();
  const {supportedLanguages, updateSupportedLanguages} =
    useSupportedLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    supportedLanguages.length > 0 ? supportedLanguages[0] : '',
  );
  const courseId = useCourseIdWithoutFallback() ?? FALLBACK_COURSE_ID;
  const {supportedNamespaces} = useSupportedNamespaces(
    courseId,
    selectedLanguage,
  );
  const [selectedNamespace, setSelectedNamespace] = useState(
    supportedNamespaces.length > 0 ? supportedNamespaces[0] : '',
  );

  useEffect(() => {
    if (supportedNamespaces.length === 0) {
      setSelectedNamespace('');
    } else if (
      !supportedNamespaces.find(namespace => namespace === selectedNamespace)
    ) {
      setSelectedNamespace(supportedNamespaces[0]);
    }
  }, [supportedNamespaces]);
  return (
    <>
      <TranslationEditorHeader
        allNamespaces={supportedNamespaces}
        selectedNamespace={selectedNamespace}
        setSelectedNamespace={setSelectedNamespace}
        supportedLanguages={supportedLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        updateSupportedLanguages={updateSupportedLanguages}
        courseId={courseId}
      />
      <br />
      <TranslationEditorBody
        courseId={courseId}
        selectedLanguage={selectedLanguage}
        selectedNamespace={selectedNamespace}
      />
    </>
  );
};

interface TranslationEditorBodyProps {
  courseId: CourseId;
  selectedLanguage: string;
  selectedNamespace: string;
}

const TranslationEditorBody = ({
  courseId,
  selectedLanguage,
  selectedNamespace,
}: TranslationEditorBodyProps) => {
  const {
    namespaceTranslations,
    namespaceTranslationsLoading,
    namespaceTranslationsError,
    updateNamespaceTranslations,
  } = useNamespaceTranslations(courseId, selectedLanguage, selectedNamespace);
  const setCurrentMapping = (data: object) => {
    updateNamespaceTranslations(new Map(Object.entries(data)));
  };
  if (namespaceTranslationsLoading) {
    return <Alert variant="warning">{'Loading...'}</Alert>;
  } else if (namespaceTranslationsError) {
    return <Alert variant="danger">{namespaceTranslationsError.message}</Alert>;
  } else if (!selectedNamespace) {
    return (
      <Alert variant="warning">
        {
          'No namespaces found for this language. Please add a namespace to edit.'
        }
      </Alert>
    );
  }
  return (
    <EditableMap
      currentMapping={Object.fromEntries(namespaceTranslations)}
      setCurrentMapping={setCurrentMapping}
    />
  );
};

interface TranslationEditorHeaderProps {
  courseId: CourseId;
  allNamespaces: string[];
  selectedNamespace: string;
  setSelectedNamespace: (namespace: string) => void;
  supportedLanguages: string[];
  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;
  updateSupportedLanguages: (
    newSupportedLanguages: SetStateAction<LanguageCode[]>,
  ) => Promise<void>;
}

const TranslationEditorHeader = ({
  courseId,
  allNamespaces,
  selectedNamespace,
  setSelectedNamespace,
  supportedLanguages,
  selectedLanguage,
  setSelectedLanguage,
  updateSupportedLanguages,
}: TranslationEditorHeaderProps) => {
  const selectableNamespaceOptions: SelectOption[] = allNamespaces.map(
    namespace => {
      return {label: namespace, value: namespace};
    },
  );
  const selectableLanguageOptions: SelectOption[] = supportedLanguages.map(
    language => {
      return {label: language, value: language};
    },
  );
  const selectedLanguageOption = selectableLanguageOptions.find(
    option => option.value === selectedLanguage,
  );
  const selectedNamespaceOption = selectableNamespaceOptions.find(
    option => option.value === selectedNamespace,
  );
  return (
    <Container>
      <Row>
        <Col>
          <h1>Translation Editor</h1>
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col xs="auto" className="align-self-center">
          <strong>Language:</strong>
        </Col>
        <Col xs="auto">
          <SelectDropdown
            selectableOptions={selectableLanguageOptions}
            selectedOption={
              selectedLanguageOption ?? {
                label: 'No language selected.',
                value: '',
              }
            }
            setSelectedOption={option => setSelectedLanguage(option.value)}
          />
        </Col>
        <Col xs="auto" className="align-self-center">
          <strong>Namespace:</strong>
        </Col>
        <Col xs="auto">
          <SelectDropdown
            selectableOptions={selectableNamespaceOptions}
            selectedOption={
              selectedNamespaceOption ?? {
                label: 'No namespace selected.',
                value: '',
              }
            }
            setSelectedOption={option => setSelectedNamespace(option.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <AddLanguageButton
            updateSupportedLanguages={updateSupportedLanguages}
            courseId={courseId}
          />
        </Col>
        <Col xs="auto">
          <DeleteLanguageButton
            supportedLanguages={supportedLanguages}
            updateSupportedLanguages={updateSupportedLanguages}
          />
        </Col>
        <Col xs="auto">
          <AddNamespaceButton courseId={courseId} language={selectedLanguage} />
        </Col>
        <Col xs="auto">
          <DeleteNamespaceButton
            courseId={courseId}
            language={selectedLanguage}
            namespaces={allNamespaces}
          />
        </Col>
      </Row>
      {selectedLanguageOption &&
        selectedLanguageOption.value !== DEFAULT_LANGUAGE_CODE && (
          <Row className="mt-1">
            <Col xs="auto">
              <AutotranslateNamespaceButton
                courseId={courseId}
                language={selectedLanguage}
                namespace={selectedNamespace}
              />
            </Col>
          </Row>
        )}
    </Container>
  );
};

interface AutotranslateNamespaceButtonProps {
  courseId: CourseId;
  language: LanguageCode;
  namespace: NamespaceId;
}
// Auto-translate from English to the selected language.
function AutotranslateNamespaceButton({
  courseId,
  language,
  namespace,
}: AutotranslateNamespaceButtonProps) {
  const t = useTranslateFunction('translationEditor');
  const {writeTranslationsToFirebase} = useTranslationUploader();
  const [translationsUploading, setTranslationsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    if (!translationsUploading) {
      return;
    }
    Swal.fire({
      title: t("Auto-translation in progress... please don't reload!"),
      toast: true,
      position: 'bottom',
      icon: 'info',
      showConfirmButton: false,
    });
  }, [translationsUploading]);
  useEffect(() => {
    if (!uploadSuccess) {
      return;
    }
    Swal.fire({
      title: t('Auto-translation complete!'),
      icon: 'success',
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });
    setUploadSuccess(false);
  }, [uploadSuccess]);
  const onClick = async () => {
    const result = await Swal.fire({
      title: t('Import from English') as string,
      text: t(
        'Are you sure you want to auto-translate this namespace from English? This will not overwrite existing translations.',
      ) as string,
      icon: 'warning',
      showCancelButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    const defaultLanguageDocumentReference = getTranslationDocument(
      courseId,
      DEFAULT_LANGUAGE_CODE,
      namespace,
    );
    const defaultLanguageDocument = await getDoc(
      defaultLanguageDocumentReference,
    );
    const defaultLanguageTranslations =
      defaultLanguageDocument.data() ?? new Map<string, string>();
    await writeTranslationsToFirebase(
      {
        [courseId]: {
          [namespace]: Array.from(defaultLanguageTranslations.keys()),
        },
      },
      [language],
      setTranslationsUploading,
      () => {},
      () => {
        setUploadSuccess(true);
      },
    );
  };
  return (
    <Button variant="primary" onClick={onClick}>
      Auto-translate from English
    </Button>
  );
}

interface AddLanguageButtonProps {
  updateSupportedLanguages: (
    newSupportedLanguages: SetStateAction<LanguageCode[]>,
  ) => Promise<void>;
  courseId: CourseId;
}
function AddLanguageButton({
  updateSupportedLanguages,
  courseId,
}: AddLanguageButtonProps) {
  const t = useTranslateFunction('translationEditor');
  const {writeTranslationsToFirebase} = useTranslationUploader();
  const [translationsUploading, setTranslationsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  useEffect(() => {
    if (!translationsUploading) {
      return;
    }
    Swal.fire({
      title: t("Auto-translation in progress... please don't reload!"),
      toast: true,
      position: 'bottom',
      icon: 'info',
      showConfirmButton: false,
    });
  }, [translationsUploading]);
  useEffect(() => {
    if (!uploadSuccess) {
      return;
    }
    Swal.fire({
      title: t('Auto-translation complete!'),
      icon: 'success',
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
    });
    setUploadSuccess(false);
  }, [uploadSuccess]);
  const onClick = async () => {
    const result = await Swal.fire<{
      addedLanguageCode: string;
      shouldImport: boolean;
    }>({
      title: t('Add Language'),
      html: `
          <label htmlFor="add-language-name" className="swal2">${t(
            'Language Code',
          )}: </label>
          <input
            type="text"
            id="add-language-name"
            name="add-language-name"
            className="swal2-input"
          />
          <br />
          <div data-tooltip="If checked, this language will be auto-translated from the default language." data-tooltip-id="add-language-should-import-tooltip">
          <label htmlFor="add-language-should-import">
            ${t('Import and auto-translate from English data?')}
          </label>
          <input
            type="checkbox"
            id="add-language-should-import"
            name="add-language-should-import"
            className="swal2-input"
              checked=true
            />
          </div>
          `,
      showCancelButton: true,
      preConfirm: () => {
        return {
          addedLanguageCode:
            (document.getElementById('add-language-name') as HTMLInputElement)
              ?.value ?? '',
          shouldImport:
            (
              document.getElementById(
                'add-language-should-import',
              ) as HTMLInputElement
            )?.checked ?? false,
        };
      },
    });
    if (!result.isConfirmed) {
      return;
    }
    const {addedLanguageCode, shouldImport} = result.value;
    if (!addedLanguageCode) {
      return;
    }
    await updateSupportedLanguages(languages => {
      if (languages.includes(addedLanguageCode)) {
        return [...languages];
      }
      return [...languages, addedLanguageCode];
    });
    if (shouldImport) {
      const defaultLanguageNamespaceDocuments = await getDocs(
        getTranslationCollection(courseId, DEFAULT_LANGUAGE_CODE),
      );
      const defaultLanguageKeys: Record<NamespaceId, string[]> = {};
      defaultLanguageNamespaceDocuments.forEach(document => {
        defaultLanguageKeys[document.id] = Array.from(
          (document.data() ?? new Map<string, string>()).entries(),
        ).map(([key]) => key);
      });
      await writeTranslationsToFirebase(
        {[courseId]: defaultLanguageKeys},
        [addedLanguageCode],
        setTranslationsUploading,
        () => {},
        () => {
          setUploadSuccess(true);
        },
      );
    }
  };
  return (
    <Button variant="primary" onClick={onClick}>
      Add Language
    </Button>
  );
}

interface DeleteLanguageButtonProps {
  supportedLanguages: LanguageCode[];
  updateSupportedLanguages: (
    newSupportedLanguages: SetStateAction<LanguageCode[]>,
  ) => Promise<void>;
}
function DeleteLanguageButton({
  supportedLanguages,
  updateSupportedLanguages,
}: DeleteLanguageButtonProps) {
  const onClick = async () => {
    const result = await Swal.fire({
      title: 'Delete Language',
      text: 'Enter the language code for the language to delete, this WILL delete all translations for that language.',
      input: 'select',
      inputOptions: Object.fromEntries(
        supportedLanguages.map(languageCode => [
          languageCode,
          `${languageCode} ${
            LANGUAGE_CODE_TO_NAME?.[languageCode] ?? languageCode
          }`,
        ]),
      ),
      showCancelButton: true,
    });
    if (!result.isConfirmed) {
      return;
    }
    const languageToDelete = result.value;
    if (!languageToDelete) {
      return;
    }
    await updateSupportedLanguages(languages => {
      return languages.filter(language => language !== languageToDelete);
    });
  };
  return (
    <Button variant="primary" onClick={onClick}>
      Delete Language
    </Button>
  );
}

interface AddNamespaceButtonProps {
  courseId: CourseId;
  language: LanguageCode;
}

function AddNamespaceButton({courseId, language}: AddNamespaceButtonProps) {
  const onClick = async () => {
    const result = await Swal.fire({
      title: 'Add Namespace',
      text: 'Enter the name of the new namespace.',
      input: 'text',
      inputPlaceholder: 'Enter namespace name',
      showCancelButton: true,
      inputValidator: value => {
        if (!value) {
          return 'Namespaces cannot be empty';
        } else if (value.includes('/')) {
          return 'Namespaces cannot contain "/"';
        }
      },
    });
    if (!result.isConfirmed) {
      return;
    }
    addNamespace(courseId, language, result.value);
  };
  return (
    <Button variant="primary" onClick={onClick}>
      Add Namespace
    </Button>
  );
}

interface DeleteNamespaceButtonProps {
  courseId: CourseId;
  language: LanguageCode;
  namespaces: NamespaceId[];
}
function DeleteNamespaceButton({
  courseId,
  language,
  namespaces,
}: DeleteNamespaceButtonProps) {
  const onClick = async () => {
    const result = await Swal.fire({
      title: 'Delete Namespace',
      text: 'Select the namespace to delete.',
      input: 'select',
      inputOptions: Object.fromEntries(
        namespaces.map(namespace => [namespace, namespace]),
      ),
      showCancelButton: true,
    });
    if (!result.isConfirmed || !result.value) {
      return;
    }
    if (result.value === FALLBACK_NAMESPACE) {
      const confirmedDelete = await Swal.fire({
        title: 'Are you sure?',
        text: 'This will delete the fallback namespace, are you sure?',
        icon: 'warning',
        showCancelButton: true,
      });
      if (!confirmedDelete.isConfirmed) {
        return;
      }
    }
    const namespaceToDelete = getTranslationDocument(
      courseId,
      language,
      result.value,
    );
    deleteDoc(namespaceToDelete);
  };
  return (
    <Button variant="primary" onClick={onClick}>
      Delete Namespace
    </Button>
  );
}
