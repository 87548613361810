import React from 'react';
import {getAuth} from 'firebase/auth';
import {ResetPasswordHandler} from './ResetPasswordHandler';
import {VerifyEmailHandler} from './VerifyEmailHandler';
import {RecoverEmailHandler} from './RecoverEmailHandler';
import {useShowDeveloperButtonIfHidden} from 'contexts/DeveloperToolsContext';
export const EmailActionHandler: React.FC = () => {
  const auth = getAuth();

  const urlParams = new URLSearchParams(window.location.search);
  useShowDeveloperButtonIfHidden();

  const mode = urlParams.get('mode');
  const actionCode = urlParams.get('oobCode');
  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      return <ResetPasswordHandler auth={auth} actionCode={actionCode} />;
    case 'recoverEmail':
      // Display email recovery handler and UI.
      return <RecoverEmailHandler auth={auth} actionCode={actionCode} />;
    case 'verifyAndChangeEmail':
      // Display email verification handler and UI.
      return <VerifyEmailHandler auth={auth} actionCode={actionCode} />;
    default:
      return <> Something went wrong! </>;
  }
};
