import styled, {keyframes} from 'styled-components';
import KarelSvgGroup from './KarelSvgGroup';

/**
 * The original width of the KarelSpinningAnimation SVG.
 */
const ORIGINAL_WIDTH = 200;

/**
 * The original height of the KarelSpinningAnimation SVG.
 */
const ORIGINAL_HEIGHT = 200;

/**
 * The time it takes for the KarelSpinningAnimation to complete one full rotation.
 */
const ROTATION_TIME_MS = 1100;

/**
 * The spinning animation keyframes.
 */
const spinKarel = keyframes`
  0% {
    transform: rotate(0deg);
    animation-timing-function: step-end;
  }
  25% {
    transform: rotate(270deg);
    animation-timing-function: step-end;
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: step-end;
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
`;

/**
 * The styled SVG component for the KarelSpinningAnimation.
 */
const StyledSVG = styled.svg<{scale: number}>`
  width: ${({scale}) => `${ORIGINAL_WIDTH * scale}px`};
  height: ${({scale}) => `${ORIGINAL_HEIGHT * scale}px`};

  .karelSpin {
    animation: ${spinKarel} ${ROTATION_TIME_MS}ms linear infinite normal
      forwards;
    transform-origin: center;
    transform-box: fill-box;
  }
`;

export function KarelSpinningAnimation({scale = 1}: {scale?: number}) {
  return (
    <StyledSVG
      scale={scale}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g className="karelSpin">
        {/* Retain translation to align the path within the SVG, but not for centering in parent */}
        <KarelSvgGroup />
      </g>
    </StyledSVG>
  );
}
