/**
 * @fileoverview General tab-system component that allows for developers to create tabs
 * which can be navigated to and opened via click (or tabbing and hitting enter)
 * on the specified tab title. Opening the tab renders the provided JSX element.
 * Only one tab is visible to the user at a time and it acts very similarly to browser tabs.
 */

import React, {useState, CSSProperties} from 'react';

{
  /* Stylization for the tab system */
}

/**
 * Overarching container style for the entire tab system page
 * in lieu of using the standard CoursePageBodyContainer component.
 *
 * @type {CSSProperties}
 */
const TAB_SYS_CONTAINER_STYLE: CSSProperties = {
  margin: '20px 0',
};

/**
 * Stylization for the content in the tabs themselves.
 *
 * @type {CSSProperties}
 */
const TAB_CONTENT_STYLE: React.CSSProperties = {
  // padding: '16px',
  borderTop: '1px solid #ccc',
  paddingTop: '5px',
};

/**
 * Function returning stylization for a tab varying based on whether or not
 * its state is set to active.
 *
 * @param {Boolean} active - true/false on whether or not the given tab is open
 * @returns {CSSProperties} general structuring of the actual tab "button"
 */
const buildTabContainerStyle: (active: boolean) => CSSProperties = active => ({
  padding: '8px 12px',
  marginRight: '4px',
  border: active ? '2px solid #007bff' : '1px solid #ccc',
  borderRadius: '4px',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: active ? '#e7f1ff' : '#fff',
});

/**
 * Properties that are passed in to create a tab.
 */
interface TabProps {
  /** the title to display on the tab itself. */
  title: string;

  /** indication on the focus state of the tab (e.g. opened or unopened) */
  active: boolean;

  /** callback function to handle tab clicks */
  onClick: () => void;
}

/**
 * Container component for a clickable tab button.
 *
 * @param {TabProps} props - properties for the given tab component.
 * @returns {JSX.Element} a single rendered tab component
 */
const Tab = ({title, active, onClick}: TabProps) => {
  return (
    <button style={buildTabContainerStyle(active)} onClick={onClick}>
      <span>{title}</span>
    </button>
  );
};

/**
 * Properties that are passed in when we want to render the actual content of a tab when clicked.
 */
interface TabContentProps {
  /** the actual content we want to render within our tab when focused */
  content: JSX.Element;
}

/**
 * @param {TabContentProps} content - the actual content we want to render within our tab when focused
 * @returns {JSX.Element} the internal tab content we want to render using its associated stylization as a JSX elem
 */
const TabContent = ({content}: TabContentProps) => {
  return <div style={TAB_CONTENT_STYLE}>{content}</div>;
};

/**
 * Props for tabs which are manually created and appended on to the tab system.
 */
interface InitializedTab {
  /** the id associated with a tab; important for actually locating and making the tab active */
  id: number;

  /** the displayed title of a tab; will be displayed at all times and used for navigation via click */
  title: string;

  /** the actual content from a tab we want to show when the tab is active */
  content: JSX.Element;
}

/**
 * Props for rendering all relevant tabs for a given user.
 *
 * @note we are using initialTabs as there may be some case in the future where people may want to make
 *       their own alternative roles via some front-end UI. initialTabs would be for the baseline roles
 *       we would want to render.
 */
interface TabSystemProps {
  /** the tabs we want to render our tab system to start with */
  initialTabs: Array<InitializedTab>;
}

/**
 * Component responsible for organizing, managing, and rendering user-specified tabs
 * from an input list of tabs of the format specified by the initializedTab interface.
 *
 * @param {TabSystemProps} initialTabs - the tabs we want to render our tab system to start with
 * @returns {JSX.Element} a tab management system for a set of initialTabs
 *
 * @note assumes all tabs are manually created elsewhere, valid, and appended to an array to be passed as a prop
 */
export const TabSystem = ({initialTabs}: TabSystemProps) => {
  {
    /* Use the passed tabs for initial state */
  }
  const [tabs, setTabs] = useState(initialTabs);
  const [activeTabId, setActiveTabId] = useState<number | null>(
    initialTabs.length ? initialTabs[0].id : null,
  );

  const activeTab = tabs.find(tab => tab.id === activeTabId);

  return (
    <div style={TAB_SYS_CONTAINER_STYLE}>
      <div style={{marginBottom: '8px'}}>
        {tabs.map(tab => (
          <Tab
            key={tab.id}
            title={tab.title}
            active={tab.id === activeTabId}
            onClick={() => setActiveTabId(tab.id)}
          />
        ))}
      </div>
      {activeTab ? (
        <TabContent content={activeTab.content} />
      ) : (
        <div>No tabs open</div>
      )}
    </div>
  );
};