import {getAuth, type User} from 'firebase/auth';
import type {UserId} from 'types/common';

// returns null if the user is not logged in
export function useUserId(): UserId | null {
  try {
    const auth = getAuth();

    // be really careful!
    return auth?.currentUser?.uid ?? null;
  } catch (e) {
    console.trace('Error in useUserId', e);
    return null;
  }
}

export function useUser(): User | null {
  try {
    const auth = getAuth();
    return auth?.currentUser ?? null;
  } catch (e) {
    console.trace('Error in useUser', e);
    return null;
  }
}
