import * as React from 'react';

// Error Boundary Component to catch rendering errors
export class RenderErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, errorMessage: null};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return {hasError: true, errorMessage: error.message};
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here.
    console.error('Error rendering the component:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error is caught.
      return (
        <div style={{color: 'red'}}>
          <strong>Error rendering the component:</strong>{' '}
          {this.state.errorMessage}
        </div>
      );
    }

    // If no error, render children components.
    return this.props.children;
  }
}
