
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getRoomsPath, useRoomInfoOnce, ROOM_EXITED, ROOM_FORCE_EXITED } from "../../firebase/realtime/Rooms";

import { useActivePresence } from "components/presence/ActivePresence";
import { PyodideContext } from "components/pyodide/PyodideProvider";
import { ProfileContext } from "contexts/ProfileContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { IDEWithData } from "ide/IDE";
import { IDEContext } from "ide/contexts/IDEContext";
import { PeerSessionProvider } from "ide/contexts/PeerSessionContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { EndOfSessionContainer } from "ide/EndOfPeerSesson";
import JWTestZoomMeeting from "videoMeeting/ZoomMeeting";
import { Modal } from "react-bootstrap";
import { Loading } from "components/loading/Loading";
import { FaMeetup, FaVideo } from "react-icons/fa";


const ZOOM_MEETING_SDK = "vz2vapx4Tfm_riIymGMquQ"
const IDLE_TIMEOUT_MINS = 5;

export const PairProgramWithZoom = () => {
  const courseId = useCourseId()
  const userId = useUserId();
  const { roomId } = useParams();
  // const roomStatus = useRoomStatus(courseId, roomId, userId);
  const dbRoomInfo = useRoomInfoOnce(courseId, roomId)
  // Teachnow TODO: write useRole to have the correct role and check the exact types we need 
  const meetingRole = "sl" // useRole(courseId, roomId, userId)

  if(!dbRoomInfo) {
    return <></>
  }


  const zoomInfo = dbRoomInfo.zoomInfo

  const roomInfo = {
    zoomInfo: {
      sdkKey: ZOOM_MEETING_SDK,
      meetingNumber: zoomInfo.meetingId,
      ...zoomInfo,
    },
    displayNames: ["Student 1", "Student 2"],
  }


  return (
    <PeerSessionProvider
      meetingType="teachnow"
      onEndSession={async () => {
        // Teachnow TODO: remove active room from the user and set endSesionTime in the room_logs ?? 
        // await setRoomStatus(userId, courseId, roomId, ROOM_EXITED)
        console.log("Ending session")
      }}
      meetingRole={meetingRole}
      roomInfo={roomInfo}
    >
      <PairProgramWithZoomWithData roomInfo={roomInfo} />
    </PeerSessionProvider>
  )
}


const JoinMeetingModal = ({cameraOn, setCameraOn, microphoneOn, setMicrophoneOn, handleJoinMeeting}) => {
  const { userData } = useContext(ProfileContext)

  // if (userZoomState !== "in-meeting") {
    return (
      <Modal show={true}
      >
        <div className="modal-body">
            <h2>Start the Zoom Meeting</h2>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={cameraOn}
                  onChange={() => setCameraOn((prev) => !prev)}
                />
                Camera On
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={microphoneOn}
                  onChange={() => setMicrophoneOn((prev) => !prev)}
                />
                Microphone On
              </label>
            </div>
            </div>
            <button
              style={{
                backgroundColor: 'blue',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                margin: '10px 20px',
              }}
              onClick={handleJoinMeeting}
            >
              Join Meeting <FaVideo/>
            </button>
          </Modal>
    )
  // }

  // return (
  //   <JWTestZoomMeeting 
  //       roomInfo={roomInfo}
  //       userZoomState={userZoomState}
  //       setUserZoomState={setUserZoomState}
  //       displayName={userData.displayName}
  //     />
  // )
}


// const ACCOUNT_ID = "cqgtOcKtRrqIEy0b2Pz6lQ";
// const CLIENT_ID = "V8AtOJKSTRa4TDAFeXe3dA";
// const SECRET = "8AtqYZSPegfxzXDJjKB5HG74yP6wN6HP";
// const ZOOM_API_URL = "https://api.zoom.us/v2";


// // for the zoom web sdk
// function getZoomSignature(meetingNumber: number, role: number): any {
//   const expirationSeconds = 60 * 60 * 2
//   const iat = Math.floor(Date.now() / 1000)
//   const exp = expirationSeconds ? iat + expirationSeconds : iat + 60 * 60 * 2
//   const oHeader = { alg: "HS256", typ: "JWT" }

//   const oPayload = {
//     appKey: SDK_CLIENT,
//     sdkKey: SDK_CLIENT,
//     mn: meetingNumber.toString(),
//     role,
//     iat,
//     exp,
//     tokenExp: exp
//   }

//   const sHeader = JSON.stringify(oHeader)
//   const sPayload = JSON.stringify(oPayload)
//   const sdkJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, SDK_SECRET)
//   return sdkJWT
// }

// export const getToken = async () => {
//   const url = "https://zoom.us/oauth/token";
//   const auth = {
//       username: CLIENT_ID,
//       password: SECRET,
//   };
//   const body = new URLSearchParams({
//       grant_type: "account_credentials",
//       account_id: ACCOUNT_ID,
//   });

//   try {
//       const response = await axios.post(url, body, { auth });
//       const token = response.data.access_token;
//       return token;
//   } catch (error) {
//       console.error("Error getting token:", error.response.data);
//       console.error("Error status code:", error.response.status);
//       console.error("Error headers:", error.response.headers);
//       throw error;
//   }
// }

// export const getAuthHeader = (token) => {
//   return {
//       Authorization: "Bearer " + token,
//       "Content-Type": "application/json",
//   };
// }

// export const getMeetings = async (zoomUserId, type) => {
//   const token = await getToken();
//   const url = `${ZOOM_API_URL}/users/${zoomUserId}/meetings?type=${type}`;
//   const headers = getAuthHeader(token);

//   const response = await axios.get(url, {headers});

//   return response.data;
// };

// export const getMeetingData = async (meetingId) => {
//   const token = await getToken();
//   const url = `${ZOOM_API_URL}/meetings/${meetingId}`;
//   const headers = getAuthHeader(token);
//   const response = await axios.get(url, {headers});
//   return response.data;
// };

const PairProgramWithZoomWithData = ({roomInfo}) => {
  const courseId = useCourseId()
  const userId = useUserId();
  const { roomId } = useParams();
  // const presencePath = `${getRoomsPath(courseId)}/${roomId}`
  const { projectData, assnData } = useContext(IDEContext);
  const { isPyodideLoading } = useContext(PyodideContext);


  // this is pre-join on render so they set up their video and mic preferences, 
  // then when they click the join meeting button it goes to in-meeting
  // when this is in-meeting, the zoom component will call the joinMeeting function and set all of their preferences 
  const [userZoomState, setUserZoomState] = useState('pre-join'); 
  const [microphoneOn, setMicrophoneOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);
  const { userData } = useContext(ProfileContext)
  const displayName = userData.displayName







  // useActivePresence({
  //   data: { meetingViewState: 'colab', },
  //   idleTiemoutMS: 60 * 1000 * IDLE_TIMEOUT_MINS,   // 5 mins
  //   firebasePath: presencePath,
  // })

  if (!projectData) return <Loading />;


  if(userZoomState !== "in-meeting") {
    return <JoinMeetingModal
    cameraOn={cameraOn}
    setCameraOn={() => setCameraOn((prev) => !prev)}
    microphoneOn={microphoneOn}
    setMicrophoneOn={() => setMicrophoneOn((prev) => !prev)}
    handleJoinMeeting={() => setUserZoomState('in-meeting')}
  />

  }


    return <ZoomIdeOuter>

      <JWTestZoomMeeting 
        roomInfo={roomInfo}
        userZoomState={userZoomState}
        setUserZoomState={setUserZoomState}
        displayName={displayName}
      />
      <IDEWithData
        projectData={projectData}
        assnData={assnData}
        isPyodideLoading={isPyodideLoading}
      />
    </ZoomIdeOuter>
};




const ZoomIdeOuter = styled.div`
  height: 100%;
  overflow: hidden;
`;

