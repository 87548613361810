import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export const FlexConsoleInput = ({
  consoleInputRef,
  terminalPreRef = null,
  terminalContentRef = null,
}) => {
  const [value, setValue] = useState('');
  const measureRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(Infinity);

  useEffect(() => {
    if (!measureRef.current) return;
    // Add a small buffer (e.g., 50px) to give some extra space
    // this prevents flickering
    setWidth(measureRef.current.offsetWidth + 50);
  }, [value]);

  // Measure leftover space next to terminalPreRef (the "old" logic)
  useEffect(() => {
    if(!terminalPreRef.current) return;
    if(!consoleInputRef.current) return;

    const updateMaxWidth = () => {
      console.log('updateMaxWidth');

      const terminalPreRect = terminalPreRef.current.getBoundingClientRect();
      const consoleInputRect = consoleInputRef.current.getBoundingClientRect();
      const xOffset = consoleInputRect.x - terminalPreRect.x;
      const leftoverSpace = terminalPreRect.width - xOffset - 30;
      setMaxWidth(leftoverSpace > 0 ? leftoverSpace : 0);
      
    };

    // Observe size changes
    const resizeObserver = new ResizeObserver(updateMaxWidth);
    resizeObserver.observe(terminalPreRef.current);
    resizeObserver.observe(consoleInputRef.current);

    // Initial measure
    updateMaxWidth();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [terminalPreRef, consoleInputRef]);

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <Wrapper>
      {/* Hidden measure span – mirrors input's text */}
      <MeasureSpan ref={measureRef} aria-hidden="true" style={{maxWidth}}>
        {/* Use a placeholder space if input is empty, so we measure correctly */}
        {value || ' '}
      </MeasureSpan>

      <Input
        type="text"
        onChange={handleChange}
        style={{ width, maxWidth }}
        autoFocus
        ref={consoleInputRef}
        id="terminal-input"
        name="input"
        autoComplete="off"
        aria-label="Terminal Input"

      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Input = styled.input`
  font-family: inherit;
  font-size: 1em;
  background-color: #2d2e2c;
  color: white;
  border: none;
  overflow-x: scroll !important;
  margin: 0;
  &:focus {
    outline: none;
  }
`;

const MeasureSpan = styled.span`
  /* Make it invisible but measurable */
  position: absolute;
  visibility: hidden;
  white-space: pre;
  overflow:hidden !important;
  font-family: inherit;
  font-size: 1em;
  pointer-events: none;
  user-select: none;
`;

const FlexConsoleInputOld = ({
  consoleInputRef,
  terminalPreRef,
  terminalContentRef,
}) => {
  const [dimensions, setDimensions] = useState({
    terminalPreWidth: 0,
    terminalPreX: 0,
    consoleInputWidth: 0,
    consoleInputX: 0,
    offsetFromTerminalPreLeft: 0,
  });

  useEffect(() => {
    if (!terminalPreRef.current || !consoleInputRef.current) return;

    const measure = () => {
      const terminalPreRect = terminalPreRef.current.getBoundingClientRect();
      const consoleInputRect = consoleInputRef.current.getBoundingClientRect();

      setDimensions({
        terminalPreWidth: terminalPreRect.width,
        terminalPreX: terminalPreRect.x,
        consoleInputWidth: consoleInputRect.width,
        consoleInputX: consoleInputRect.x,
        offsetFromTerminalPreLeft: consoleInputRect.x - terminalPreRect.x,
      });
    };

    // Observe size changes on both elements
    const resizeObserver = new ResizeObserver(() => {
      measure();
    });
    resizeObserver.observe(terminalPreRef.current);
    resizeObserver.observe(consoleInputRef.current);

    // Measure once on mount
    measure();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [terminalPreRef, consoleInputRef]);

  useEffect(() => {
    console.log('dimensions updated:', dimensions);
  }, [dimensions]);

  console.log(dimensions);

  const padding = 20;
  const inputWidth =
    dimensions.terminalPreWidth -
    dimensions.offsetFromTerminalPreLeft -
    padding;

  return (
    <StyledInput
      ref={consoleInputRef}
      autoFocus
      type="text"
      id="terminal-input"
      name="input"
      autoComplete="off"
      aria-label="Terminal Input"
      style={{ width: inputWidth }}
    />
  );
};

export const StyledInput = styled.input`
  background-color: #2d2e2c;
  color: white;
  flex: 1;
  flex-grow: 1;
  margin: 0;
  font-size: 1em;
  &:focus {
    outline: none;
  }
`;
