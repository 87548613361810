import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { getApp } from "firebase/app"
import { collection, getFirestore } from "firebase/firestore"
import { useCollectionDataOnce, useCollectionOnce } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"
import {lazy} from 'react';


const Plot = lazy(() => import('react-plotly.js'));


export const MonitorApplications = () => {
  return <CoursePageBodyContainer
      mainColumn={<MonitorAppsMain />}
      rightColumn={<></>}
      singleColumn={<MonitorAppsMain />}
    />
}

const MonitorAppsMain = ({  }) => {
  // ok to assume that the user is an admin
  // only admin can download apps from server
  // and only admin have a button to this page

  return <>
    <h3>Student Apps</h3>
    <DisplayAppProgress collectionId={"applications-student"}/>
    <h3>SL Apps</h3>
    <DisplayAppProgress collectionId={"applications-sl"}/>
  </>
}

const DisplayAppProgress = ({ collectionId }) => {
  const {targetCourseId} = useParams()
  const db = getFirestore(getApp())
  const path = `/course/${targetCourseId}/${collectionId}/`
  const [applications, loading, error] = useCollectionDataOnce(
    collection(db, path)
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const countMap = parseShards(applications)

  // sort the keys in the table alphabetically
  const sortedKeys = Object.keys(countMap).sort()

  const totalSum = Object.values(countMap).reduce((acc, curr) => acc + curr, 0)
  
  // render the countMap in a bootstrap table
  return <>
    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th>Status</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {sortedKeys.map((status, i) => {
          return <tr key={i}>
            <td>{status}</td>
            <td>{countMap[status]}</td>
          </tr>
        })}
        <tr>
          <td>Total Started</td>
          <td>{totalSum}</td>
        </tr>
      </tbody>
    </table>
    <MonitorApplicationsPlot courseId={targetCourseId} db={db}
      studentOrSl={collectionId.split("-")[1]}
    />
  </>
}

function parseShards(applications) {
  const countMap = {}
  applications.forEach(shard => {
    // shard is a dictionary from studentId -> applicationStatus (string)
    // we want to make a countMap of applicationStatus -> count
    Object.values(shard).forEach(status => {
      if (countMap[status]) {
        countMap[status] += 1
      } else {
        countMap[status] = 1
      }
    })
  })
  return countMap
}




const MonitorApplicationsPlot = ({courseId, db, studentOrSl}) => {
  const collectionPath = `metrics/${courseId}/${studentOrSl}AppCount`
  const [appCount, loadingAppCount, errorAppCount] = useCollectionOnce(
    collection(db, collectionPath)
  )


  if (loadingAppCount) return <p>Loading...</p>

  if (errorAppCount) return <p>Error: {errorAppCount.message}</p>

  const appData = appCount.docs.map(doc => doc.data())
  const dates = appCount.docs.map(doc => (new Date(doc.data().timestamp)))


  const allYs = {}


  for(let i = 0; i < appData.length; i++) {
    for (let j of Object.keys(appData[i])) {
      if(j==="timestamp") {
        continue
      }
      if (j in allYs) {
        allYs[j].push(appData[i][j])
      }
      else {
        allYs[j] = [appData[i][j]]
      }
    }
  }


  const generateData = (ys, x) => {
    const data =  Object.keys(ys).map(key => {
      return {
        x: x,
        y: ys[key],
        type: 'scatter',
        mode: 'lines+markers',
        name: key
      }
  })
  console.log(data)
  return data
  }




  return (
    <div>
      <Plot 
        data={
          [
            ...generateData(allYs, dates),
          ]
        }
        layout={{
          title: 'App Progress',
          xaxis: {
            title: 'Date'
          },
          yaxis: {
            title: 'Count'
          }
        }}
      />
    </div>
  )





}