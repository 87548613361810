import React, {useCallback, useEffect, useState, createContext} from 'react';

import 'firebase/compat/auth';
import {getAuth} from 'firebase/auth';
import {getApp} from 'firebase/app';
import {getUserDocumentRef, getUserRoleRef} from '../firebase/models';
import {
  useCollection,
  useCollectionOnce,
  useDocumentData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import {useCourseId} from '../hooks/router/useUrlParams';
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
  DocumentData,
} from 'firebase/firestore';

import type {
  DocumentReference,
  FirestoreError,
  SetOptions,
} from 'firebase/firestore';
import type {User as FirestoreUser, Section} from '../firebase/models';
import type {User as AuthUser} from 'firebase/auth';
import {firebaseStringToRole} from './profileUtil';
import {useEngagement} from 'engagement/EngagementHook';
import {Role, RoleFirebaseString} from 'types/role';

/**
 * CourseUser
 * A materialized view of the logged in User in the current course. Joins
 * together properties from the Firebase Auth object with the Firestore User
 * document, and precomputes some authentication values.
 */

export type CourseUser = {
  id: string;
  /* Contains the highest role the User has in the current course */
  courseRole: Role;
  /* Contains the sections the User is related to in the current course.
   * TODO: should this be references to the sections or the full section? */
  courseSections: Array<DocumentReference<Section>>;

  /* the actual dictionary on the user document */
  sections: any;

  // some people are not 18 at the start of the course!
  isMinor: boolean;

  // portfolio projects
  portfolio: Array<string>;
  okToEmail: boolean;
  okToSendNotifications: boolean;
  sentFirstEmail: boolean;

  // connections
  aboutMe: string;
  goals: string;
  wantToConnect: boolean;

  // final project groups
  finalProjectCommunities: any;

  // i18n settings
  preferredLanguage: string;
} & Omit<FirestoreUser, 'roles' | 'sections'>;

export type SetCourseUserFunction = (
  fields: Partial<FirestoreUser>,
  options?: Partial<SetOptions>,
) => Promise<void> | undefined;

const USER_DEFAULTS: Omit<CourseUser, 'id'> = {
  photoURL: '',
  firstName: '',
  lastName: '',
  displayName: '',
  city: '',
  country: '',
  isMinor: false,
  email: '',
  shareChatGPTConvos: false,
  shareCode: false,
  sharePeerLearn: false,
  courseRole: Role.UNREGISTERED,
  courseSections: [],
  confirmedSectionLeader: {},
  confirmedStudent: {},
  smallGroups: {},
  sections: {},
  portfolio: [],
  okToEmail: true,
  okToSendNotifications: true,
  sentFirstEmail: false,
  aboutMe: '',
  goals: '',
  wantToConnect: true,
  finalProjectCommunities: {},
  preferredLanguage: 'en',
};

const defaultData: ProfileContextData = {
  userData: undefined,
  setUserData: undefined,
  loading: true,
  error: undefined,
  sectionData: undefined,
  sectionDataIsLoading: true,
  setSectionData: undefined,
  setCachedUserData: undefined,
  userProtectedData: undefined,
  userAge: undefined,
};

export type ProfileContextData = {
  /**
   * Materialized view of the logged-in user of the current class. "Best-effort"
   * at showing up-to-date status. It doesn't subscribe to notifications from
   * Firestore, meaning the data may be stale if the user is operating in another
   * instance of the site, but reflects any mutations from the current session
   * via an internal cache.
   */
  userData: CourseUser | undefined;

  /**
   * The user has a collection of sub docs (possibly with different write permissions)
   * This includes things like uiTraining, protected etc. Keys are doc-ids, values
   * are the docs
   */
  userProtectedData: any;

  /**
   * Update properties of the user on the database. Also updates the internal cache
   * if the request is successful.
   *
   * NOTE: In the long-term, we should not be managing this cache ourselves; we should
   * use something like swr-firestore (https://github.com/nandorojo/swr-firestore) to
   * manage our queries and caches. Deferring until later to avoid adding another
   * query pattern to the codebase.
   */
  setUserData: SetCourseUserFunction;
  /**
   * The current status of the initial request to load the user.
   */
  loading: boolean;

  /**
   * If the initial request to load the us
   */
  error: FirestoreError | undefined;

  /**
   * If the section data has loaded
   */
  sectionDataIsLoading: boolean;

  /**
   * All the meta data for the sections
   */
  sectionData: Section[] | undefined;

  /**
   * In case we need to update the sectionData
   */
  setSectionData: React.Dispatch<React.SetStateAction<Section[] | undefined>>;

  setCachedUserData: React.Dispatch<
    React.SetStateAction<CourseUser | undefined>
  >;

  /** integer number of years old. Based on userProtectedData */
  userAge: number;
};
export const ProfileContext = createContext<ProfileContextData>(defaultData);

type ProfileProviderProps = {
  children?: React.ReactNode;
};
export const ProfileProvider = ({children}: ProfileProviderProps) => {
  const auth = getAuth();
  const authUser = auth.currentUser;
  const courseId = useCourseId();

  const userId = authUser?.uid ?? 'null';

  console.log('userId', userId);

  const db = getFirestore(getApp());

  // this allows for pages which can optionally be signed in to.
  // there is be a better way (which avoid the useDocumentDataOnce)
  // on a path we know will error

  const userDataRef = getUserDocumentRef(userId);
  const userRoleRef = getUserRoleRef(authUser.uid, courseId);

  // this is a collection of docs associated with the user. They should all be readable
  // by the user

  const [userData, setCachedUserData] = useState<CourseUser | undefined>(
    undefined,
  );
  const [userRole, userRoleIsLoading, userRoleError] =
    useDocumentDataOnce(userRoleRef);
  const [dbUser, dbUserIsLoading, dbUserError] = useDocumentData(userDataRef);
  const [sectionData, setSectionData] = useState<Section[]>([]);
  const [sectionDataIsLoading, setSectionDataIsLoading] =
    useState<boolean>(true);
  const [userProtectedData, userProtectedLoading] = useDocumentData(
    doc(db, `users/${userId}/docs/protected`),
  );

  // Because we calculate derived data from the database user, we can't
  // pass the loading state down directly into the context values -
  // otherwise children will begin to render as soon as the query has
  // completed, but before the derived data has been calculated (so context.userData
  // will be undefined)
  const [userDataIsLoading, setUserDataIsLoading] =
    React.useState<boolean>(true);

  useEngagement(userDataIsLoading, userData);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (!user) {
        setCachedUserData(undefined);
      }
    });
  }, []);

  useEffect(() => {
    if (!dbUserIsLoading && !userRoleIsLoading && !userProtectedLoading) {
      // If the user doesn't exist
      if (dbUserError || userRoleError || !authUser?.uid) {
        setUserDataIsLoading(false);
        return;
      }
      const userData = createUserData(courseId, dbUser, authUser, userRole);
      setCachedUserData(userData);
      setUserDataIsLoading(false);
    }
  }, [dbUser, dbUserIsLoading, userRoleIsLoading, userProtectedLoading]);

  useEffect(() => {
    if (userData) {
      for (const section of userData.courseSections) {
        
        loadSingleSection(section);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (allSectionsLoaded()) {
      setSectionDataIsLoading(false);
    }
  }, [sectionData]);

  const setUserData = useCallback(
    async (
      fields: Partial<Omit<FirestoreUser, 'roles' | 'sections'>>,
      options: Partial<SetOptions> = {merge: true},
    ) => {
      await setDoc(userDataRef, fields, options);
      // Jenky hack to update boolean field from true to false
      // TODO(Matt): should not be a noticeable performance hit, but should be fixed if it is
      await updateDoc(userDataRef, fields);
      setCachedUserData({...userData, ...fields});
    },
    [userDataRef],
  );

  const loadSingleSection = async (
    sectionRef: DocumentReference<Section> | DocumentReference<DocumentData>,
  ) => {
    if (sectionRef.path) {
      sectionRef = doc(db, sectionRef.path);
    }
    const section = await getDoc(sectionRef);
    if (section.exists()) {
      setSectionData(prev => {
        return {
          ...prev,
          [section.id]: section.data(),
        };
      });
    }
  };

  const allSectionsLoaded = () => {
    if (!userData?.courseSections) return true;
    if (!sectionData) return false;

    // for each section that the user is in...
    for (const section of userData?.courseSections) {
      const sectionPath = section.path;
      // get the last part of the path
      const sectionId = sectionPath.split('/').pop();
      // check if its been loaded
      if (!sectionData[sectionId]) return false;
    }
    return true;
  };

  return (
    <ProfileContext.Provider
      value={{
        userData,
        userProtectedData,
        setUserData,
        setCachedUserData,
        loading: userDataIsLoading,
        error: dbUserError,
        sectionData,
        sectionDataIsLoading,
        setSectionData,
        userAge: calculateUserAge(userProtectedData),
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const isProfileComplete = (
  profileContext: ProfileContextData,
): boolean => {
  const userData = profileContext.userData;
  const userProtectedData = profileContext.userProtectedData;

  const hasDisplayName =
    userData.displayName &&
    userData.displayName.length > 0 &&
    userData.displayName !== 'Anon';
  const hasCountry = userData.country && userData.country.length > 0;
  const hasDob = userProtectedData?.dateOfBirth !== undefined;

  const hasFields = hasDisplayName && hasCountry && hasDob;
  if (!hasFields) {
    return false;
  }

  const requiredDobFields = ['day', 'month', 'year'];
  const dobFields = userProtectedData.dateOfBirth;
  const hasDobFields = requiredDobFields.every(
    field => dobFields[field] !== undefined,
  );

  return hasDobFields;
};

function createUserData(
  courseId: string,
  dbUser: FirestoreUser,
  authUser: AuthUser,
  userRole: RoleFirebaseString,
): CourseUser {
  // computerUserData incrementally collects fields we compute from the underlying
  // db and auth user objects
  const computedUserData: Partial<CourseUser> = {};

  // Objects on the authUser - such as the display name, email, and
  // photo URL - are prioritized over the global defaults in USER_DEFAULTS
  // but under values that have been set in the Firestore document
  const authDisplayName = authUser?.displayName;
  if (authDisplayName) {
    const parts = authDisplayName.split(' ');
    if (parts.length >= 2) {
      const first = parts[0];
      const last = parts[parts.length - 1];
      computedUserData.displayName = authDisplayName;
      computedUserData.firstName = first;
      computedUserData.lastName = last;
    }
  }

  // Precompute authorization values for ease of client use
  const {sections, ...dbUserRest} = dbUser || {};
  // default is unregistered
  let courseRole = firebaseStringToRole(userRole);

  if (dbUser?.photoURL) {
    computedUserData.photoURL = dbUser.photoURL;
  }
  if (authUser?.email) {
    computedUserData.email = authUser.email;
  }

  computedUserData.courseRole = courseRole;
  computedUserData.courseSections = sections?.[courseId] || [];
  computedUserData.displayName = dbUser?.displayName || 'Anon';
  computedUserData.sections = dbUser?.sections || {};
  computedUserData.portfolio = dbUser?.portfolio || [];
  computedUserData.okToEmail = dbUser?.hasOwnProperty('okToEmail')
    ? dbUser.okToEmail
    : true;
  computedUserData.okToSendNotifications = dbUser?.hasOwnProperty(
    'okToSendNotifications',
  )
    ? dbUser.okToSendNotifications
    : true;
  computedUserData.sentFirstEmail = dbUser?.sentFirstEmail || false;
  computedUserData.aboutMe = dbUser?.aboutMe || '';
  computedUserData.goals = dbUser?.goals || '';
  computedUserData.wantToConnect = dbUser?.wantToConnect || true;
  computedUserData.preferredLanguage = dbUser?.preferredLanguage || '';
  // dbUserRest.displayName = computedUserData.displayName

  // The resulting computed object prioritizes values that have been manually
  // set on the Firestore user (which happens when the user updates their
  // profile) over any defaults or derived values. The one exception is roles,
  // which always uses the computed values.
  return {
    id: authUser.uid,
    ...USER_DEFAULTS,
    ...dbUserRest,
    ...computedUserData,
  };
}

export function getCourseRoleNumber(courseRoleStr) {
  if (!courseRoleStr) return Role.UNREGISTERED;
  switch (courseRoleStr) {
    case 'student':
      return Role.STUDENT;
    case 'sl':
      return Role.SECTION_LEADER;
    case 'admin':
      return Role.ADMIN;
    case 'ta':
      return Role.TA;
    case 'instructor':
      return Role.INSTRUCTOR;
    case 'experiencedstudent':
      return Role.EXPERIENCED_STUDENT;
    case 'mentor':
      return Role.MENTOR;
  }
  return Role.UNREGISTERED;
}

function processUserDocs(userDocsCollection) {
  if (!userDocsCollection) {
    console.log('no user docs collection');
    return undefined;
  }
  console.log('user docs collection');
  const userDocsData = {};
  userDocsCollection.forEach(doc => {
    userDocsData[doc.id] = doc.data();
  });
  return userDocsData;
}

function calculateUserAge(userProtectedData) {
  if (!userProtectedData) return undefined;
  const dob = userProtectedData.dateOfBirth;
  if (!dob) return undefined;
  const day = dob.day; // number
  const month = dob.month; // number 1 = "Jan"
  const year = dob.year; // number

  // Get current date
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // getMonth returns 0 for January, 1 for February, etc.
  const currentDay = today.getDate();

  // Compute age
  let age = currentYear - year;
  if (currentMonth < month || (currentMonth === month && currentDay < day)) {
    // If the current month is before the birth month or
    // if it's the same month but the day hasn't come yet, subtract one year from the age
    age--;
  }

  return age;
}
