export const STARTER_CODE = `import { useState, useEffect } from 'react';
import styled from 'styled-components';

export const InteractiveDemo = () => {
  return <>
  Your code here
  </>;
};

const ButtonBar = styled.div\`
  display:flex;
  gap:20px;
  margin-bottom:10px;
\`;`;
