import {collection, getFirestore} from 'firebase/firestore';
import {Timestamp} from 'firebase/firestore';
import {addDoc} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {isDevelopmentEnvironment} from 'utils/general';

/**
 * Reports an error in the production environment to firebase.
 *
 * Attaches metadata about the user and site when the error occurred. In
 * development, this function does nothing.
 *
 * @param error - The error to report.
 */
export const logProductionErrorToFirestore = async (error: Error) => {
  const isDevelopment = isDevelopmentEnvironment();
  const userId = useUserId();
  const courseId = useCourseId();
  if (isDevelopment) {
    return;
  }

  const db = getFirestore();
  const stackTrace = getStackTrace(error);
  await addDoc(collection(db, 'errorLogs'), {
    url: window.location.href,
    errorMessage: error.message,
    errorStack: stackTrace,
    userId,
    courseId,
    createdAt: Timestamp.now(),
  });
};

/**
 * Get the stack trace of an error.
 * @param error - The error to get the stack trace of.
 * @returns The stack trace of the error.
 */
export function getStackTrace(error: Error): string {
  if (Error.captureStackTrace) {
    let stackTrace = '';
    Error.captureStackTrace(error);
    if (error.stack) {
      stackTrace = error.stack;
    }
    return stackTrace;
  } else {
    return '';
  }
}
