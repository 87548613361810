import {Node} from '@tiptap/core';

export const BorderedBox = Node.create({
  inline: false,
  atom: true,
  draggable: false,
  selectable: false,

  name: 'borderedBox',

  group: 'block', // Allows it to behave as a block element
  content: 'block*', // Accepts other block elements as its content

  addOptions() {
    return {
      HTMLAttributes: {}, // Allows additional HTML attributes
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.bordered-box', // Matches `<div class="bordered-box">`
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return ['div', {class: 'bordered-box', ...HTMLAttributes}, 0]; // Outputs a `<div>` with a class
  },
});
