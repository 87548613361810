import {FaReact} from 'react-icons/fa';

export function ReactInTipTapButton({editor}) {
  return (
    <button
      onClick={() =>
        editor
          .chain()
          .focus()
          .insertContent('<react-in-tip-tap></react-in-tip-tap>')
          .run()
      }
      className="btn btn-sm btn-light "
    >
      <FaReact />
    </button>
  );
}
