import KarelPythonCompiler from "./karelCompiler/karelPythonCompiler";
import { useContext } from "react";



export function compileKarelCode (code, karelWorldState) {
    const compiler = new KarelPythonCompiler(karelWorldState);
    let compileResult = compiler.compile(code);

    if (compileResult.status === "error") {
      
        compileResult.error = JSON.parse(
          compileResult.error.toString().split("Error: ")[1]
        );
      
    }
    return compileResult
}



export const allUnitTestsPassed = (unitTestResults, results) => {
    return (unitTestResults &&
        unitTestResults.length > 0 &&
        unitTestResults.every((result) => result.state === "success")) ||
      (unitTestResults &&
        unitTestResults.length === 0 &&
        (results.error === undefined || results.error.length === 0));
}