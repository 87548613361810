/**
 * =============================================================================
 * IMPORTANT, READ THIS MESSAGE BEFORE EDITING THIS FILE.
 *
 * This file needs to be kept tightly in sync with its corresponding version in
 * the frontend. If you are changing this file, please also make the
 * corresponding changes in:
 * `cip-backend:functions/src/projects/projectDocument.ts` and submit both PRs
 * simultaneously.
 *
 * Be careful because import paths may be different. Don't forget to double
 * check that you update the comments in `cip-backend` as well.
 * =============================================================================
 */

import type {IKarelState} from 'components/pyodide/KarelLib/karelInterfaces';
import {
  doc,
  type DocumentReference,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore';
import {firestore} from '../firebaseApp';
import type {UserId} from 'types/common';
import type {
  ProjectFileData,
  ProjectFileStructure,
  ProjectId,
} from 'projects/types';
import type {AssignmentType} from 'assignments/types';

/** ============== COPY EVERYTHING STARTING HERE TO cip-backend ============= */
/**
 * The document that represents a project in the database.
 */
export interface ProjectDocument {
  /**
   * The associated assignment, if any.
   */
  assnId?: string;
  /**
   * The current session ID, if any, formatted as an ISO 8601 timestamp.
   */
  currentSessionId?: string;
  /**
   * A list of Firebase IDs of users who are allowed to edit the project.
   */
  editors?: UserId[];
  /**
   * The file structure of the project.
   */
  files?: ProjectFileStructure;
  /**
   * The last opened file, if any.
   */
  lastOpenedFile?: ProjectFileData;
  /**
   * The title of the project.
   */
  title?: string;
  /**
   * The type of the project.
   */
  type: AssignmentType;
  /**
   * The Karel state of the user, (only used for Karel projects).
   */
  userKarel?: IKarelState;
  /**
   * The unique ID of the project.
   */
  uid?: ProjectId;
  /**
   * The Yjs document for the project, if any.
   */
  ydoc?: {
    /**
     * A raw byte encoded blob of the Yjs document.
     */
    _byteString?: Uint8Array;

  };

  whatsappKey?: string;

}

const projectDocumentConverter: FirestoreDataConverter<ProjectDocument> = {
  toFirestore: (project: unknown) => {
    const data = project as ProjectDocument;
    return data;
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<ProjectDocument>) => {
    const data = snapshot.data();
    data.uid = data.uid ?? snapshot.id;
    return data as ProjectDocument;
  },
};

/**
 * Get a reference to the project document for the given project ID.
 * @param projectId - The ID of the project.
 * @returns A reference to the project document.
 */
export function getProjectDocumentRef(
  projectId: ProjectId,
): DocumentReference<ProjectDocument> {
  return doc(firestore, `projects/${projectId}`).withConverter(
    projectDocumentConverter,
  );
}
