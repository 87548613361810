import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {CompletionContext} from 'contexts/CompletionContext';
import {ProfileContext} from 'contexts/ProfileContext';
import {isMentor, isAdmin} from 'contexts/profileUtil';
import {TeachNowButtonWrapper} from 'course/carehours/CareHoursPage';
import {AdminRichTextEditor} from 'course/components/AdminRichTextEditor';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useContext, useEffect, useState} from 'react';
import {FaHome} from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import {TeachNowImpactButtonLink} from './NewTeachingHome';

export const MentorHome = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<MentorSplashMain />}
      rightColumn={
        <>
          {' '}
          <TeachNowImpactButtonLink />{' '}
        </>
      }
      singleColumn={<MentorSplashMain />}
    />
  );
};

const MentorSplashMain = () => {
  const {userData} = useContext(ProfileContext);
  const {
    setTeachNowTrainingSlideCompleted,
    isReadAboutTeachNowCompleted,
    isTeachNowTrainingCompleted,
  } = useContext(CompletionContext);
  const displayName = userData?.displayName;
  const courseId = useCourseId();
  const navigate = useNavigate();
  const [teachNowDisabled, setTeachNowDisabled] = useState(true);
  const [teacherPuzzlesDisabled, setTeacherPuzzlesDisabled] = useState(true);

  const isMentorRole = isMentor(userData.courseRole);
  const isAdminRole = isAdmin(userData.courseRole);

  const styleRetButton = {
    borderRadius: 5,
    padding: '10px',
    textDecoration: 'none',
  };

  useEffect(() => {
    const checkReadAboutTeachNow = async () => {
      const enabled = await isReadAboutTeachNowCompleted();
      setTeacherPuzzlesDisabled(!enabled);
    };
    const checkTeachNowTraining = async () => {
      const enabled = await isTeachNowTrainingCompleted();
      setTeachNowDisabled(!enabled);
    };
    checkTeachNowTraining();
    checkReadAboutTeachNow();
  }, []);

  const onLearnAboutTeachNowClick = async () => {
    await setTeachNowTrainingSlideCompleted('readAboutTeachNow');
    navigate(`/${courseId}/teachnow`);
  };

  // Gate this page to only Mentors and Admins (not Section Leaders) - this is so we don't confused the section leaders
  if (!isMentorRole && !isAdminRole) {
    return (
      // make this centered in the page
      <div style={{textAlign: 'center', marginTop: '20px', fontSize: '20px'}}>
        <div>Sorry, you do not have access to this page.</div>
        <div className="d-flex justify-content-center">
          <Link
            to={`/${courseId}`}
            className="text-purple text-center"
            style={{...styleRetButton, color: '#b757f6'}}
          >
            <FaHome size={50} />
            <div>Go to home page</div>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <img
        src="/stanford/jacaranda.jpg"
        alt="Mentorship"
        className="img-fluid w-100 p-1 rounded"
        style={{
          maxHeight: '300px',
          objectFit: 'cover',
          objectPosition: 'bottom center',
        }}
      />
      <h4>Section Leader (Mentor) Home</h4>
      <p>Welcome {displayName ? displayName : 'Mentor'}!</p>
      <AdminRichTextEditor
        firebaseDocPath={`/course/${courseId}/handouts/mentorhome`}
      />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{marginBottom: '50px'}}
      >
        <button
          className="btn btn-lg btn-primary m-3"
          onClick={onLearnAboutTeachNowClick}
        >
          Learn About Teach Now!
        </button>
        <button
          disabled={teacherPuzzlesDisabled}
          className="btn btn-lg btn-warning m-3"
          onClick={() => navigate(`/${courseId}/teachnowtraining`)}
        >
          Start Training
        </button>
        {/* {teachNowDisabled ? <></> : <TeachNowButtonWrapper buttonOnly={true} />} */}
      </div>
    </div>
  );
};
