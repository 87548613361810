import {TimeContext, useServerTime} from 'contexts/TimeContext';
import {useContext, useEffect, useState} from 'react';

// dateStr with format "YYYY-MM-DDTHH:MM:SS"
export const AnywhereOnEarthCountdown = ({
  dateStr,
  minDaysToShowHours = 30,
  minDaysToShowMinutes = 7,
  minDaysToShowSeconds = 1,
}) => {
  // Remove
  // timeDelta will be in the form {days: 0, hours: 0, minutes: 0, seconds: 0}
  // Remove useEffects, swap with useServerTime, dynamic variable
  const [timeDelta, setTimeDelta] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const date = new Date(dateStr);
  const servertimeMs = useServerTime();

  useEffect(() => {
    if (!servertimeMs) return;
    const delta = date.getTime() - servertimeMs;
    const days = Math.floor(delta / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((delta % (1000 * 60)) / 1000);
    setTimeDelta({days, hours, minutes, seconds});
  }, [servertimeMs]);

  return (
    <span className="">
      {!!servertimeMs ? (
        <>
          {getStringFromTimeDelta(
            timeDelta,
            minDaysToShowHours,
            minDaysToShowMinutes,
            minDaysToShowSeconds,
          )}
        </>
      ) : (
        <></>
      )}
    </span>
  );
};

const getStringFromTimeDelta = (
  timeDelta,
  minDaysToShowHours,
  minDaysToShowMinutes,
  minDaysToShowSeconds,
) => {
  let strInProgress = '';

  if (!timeDelta) return;
  if (timeDelta.days > 30) {
    strInProgress += `${Math.floor(timeDelta.days / 30)} ${
      Math.floor(timeDelta.days / 30) > 1 ? 'months' : 'month'
    }`;
    return strInProgress;
  }
  if (timeDelta.days > 1) {
    strInProgress += `${timeDelta.days} days`;
  } else if (timeDelta.days === 1) {
    strInProgress += `1 day`;
  }

  if (timeDelta.days < minDaysToShowHours && timeDelta.hours > 1) {
    strInProgress += ` ${timeDelta.hours} hours`;
  } else if (timeDelta.days < minDaysToShowHours && timeDelta.hours === 1) {
    strInProgress += ` 1 hour`;
  }

  if (timeDelta.days < minDaysToShowMinutes && timeDelta.minutes > 1) {
    strInProgress += ` ${timeDelta.minutes} minutes`;
  } else if (timeDelta.days < minDaysToShowMinutes && timeDelta.minutes === 1) {
    strInProgress += ` 1 minute`;
  }

  if (timeDelta.days < minDaysToShowSeconds && timeDelta.seconds > 1) {
    strInProgress += ` ${timeDelta.seconds} seconds`;
  }

  return strInProgress;
};
