import { Stack } from 'react-bootstrap';
import { CodeTipPanel, CODING_TIPS } from './CodingTips';
import { KarelLoadingAnimation } from './KarelLoadingAnimation';
import { useWindowSize } from 'react-use-size';
import { useIsMobile } from 'utils/general';
import styled from 'styled-components';

// Constants from your existing code
const HIDE_TIP_HEIGHT = 400;
const DESKTOP_LOADING_ANIMATION_SCALE = 0.6;
const MOBILE_LOADING_ANIMATION_SCALE = 0.4;

export const StandardLoading = () => {
  const { height } = useWindowSize();
  const isMobile = useIsMobile();

  // Otherwise, render the standard "Karel" loading screen
  const shouldHideTip = height < HIDE_TIP_HEIGHT;
  const codingTipIdx = Math.floor(Math.random() * CODING_TIPS.length);

  return (

    <Stack className="align-items-center justify-content-center">
      <h1 className="text-center">Loading...</h1>
      <KarelLoadingAnimation
        scale={
          isMobile
            ? MOBILE_LOADING_ANIMATION_SCALE
            : DESKTOP_LOADING_ANIMATION_SCALE
        }
      />
      {!shouldHideTip && (
        <CodeTipPanel>
          <b>Coding Pro Tip:</b> {CODING_TIPS[codingTipIdx]}
        </CodeTipPanel>
      )}
    </Stack>
  );
};


