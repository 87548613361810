import {useState, useEffect} from 'react';
import {getStorage, ref, getDownloadURL} from 'firebase/storage';

export const reactInTipTapStorageRoot = 'richText/reactFiles';

// Cache to store promises for file content
const fileCache = new Map();
const imageUrlCache = new Map();

// If this breaks, blame the machine.
async function getFileFromCache(fileId) {
  if (!fileId) {
    return null;
  }
  try {
    if (!fileCache.has(fileId)) {
      const storage = getStorage();
      const fileRef = ref(storage, `uploads/${fileId}`);
      console.log(`Fetching: uploads/${fileId}`);
      const fetchPromise = getDownloadURL(fileRef)
        .then(async url => {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
          return response.text();
        })
        .catch(err => {
          fileCache.delete(fileId);
          throw err;
        });
      fileCache.set(fileId, fetchPromise);
    }
    const fileContent = await fileCache.get(fileId);
    return fileContent;
  } catch (err) {
    console.error(err);
  }
}

async function getImageUrlFromCache(fileId) {
  if (!fileId) {
    return null;
  }
  try {
    // If we haven't cached the promise for this image yet, do so now
    if (!imageUrlCache.has(fileId)) {
      const storage = getStorage();
      const fileRef = ref(storage, `${reactInTipTapStorageRoot}/${fileId}`);
      console.log(`Fetching image URL: ${reactInTipTapStorageRoot}/${fileId}`);
      const fetchPromise = getDownloadURL(fileRef).catch(err => {
        imageUrlCache.delete(fileId);
        throw err;
      });
      imageUrlCache.set(fileId, fetchPromise);
    }

    // Return the resolved promise (the actual URL)
    return await imageUrlCache.get(fileId);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export const useImageUrl = fileId => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (!fileId) {
        setLoading(false);
        return;
      }
      try {
        const downloadUrl = await getImageUrlFromCache(fileId);
        setUrl(downloadUrl);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchImageUrl();
  }, [fileId]);

  return [url, loading, error];
};

export const downloadFile = async fileId => {
  const rawContent = await getFileFromCache(fileId);
  if (!rawContent) {
    console.error('No file content available.');
    return;
  }

  let blob;
  if (rawContent instanceof Blob) {
    blob = rawContent; // Use rawContent if it's already a Blob
  } else {
    blob = new Blob([rawContent], {type: 'application/octet-stream'}); // Convert to Blob if needed
  }

  const blobUrl = URL.createObjectURL(blob);
  console.log('Blob URL:', blobUrl);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = 'countData.json';
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};

export const useFile = fileId => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      if (!fileId) {
        setLoading(false);
        return;
      }
      try {
        const content = await getFileFromCache(fileId);
        setContent(content);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchContent();
  }, [fileId]);

  useEffect(() => {
    if (content) {
      setLoading(false);
    }
  }, [content]);

  return [content, loading, error];
};
