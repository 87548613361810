import {useContext} from 'react';
import {CourseContext} from 'contexts/CourseContext';

export const MinAgeBirthday = () => {
  const {courseStartDate} = useContext(CourseContext);

  // Use courseStartDate if available, otherwise use today's date
  const referenceDate = courseStartDate
    ? new Date(courseStartDate)
    : new Date();

  // Calculate date 16 years before reference date
  referenceDate.setFullYear(referenceDate.getFullYear() - 16);

  // Format the date as "April 21, 2009"
  const month = referenceDate.toLocaleString('en-US', {month: 'long'});
  const day = referenceDate.getDate();
  const year = referenceDate.getFullYear();

  return `${month} ${day}, ${year}`;
};
