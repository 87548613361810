import { LandingNav } from "landing/components/LandingNav"
import { Takeaways } from "./Takeaways";
import { LearnQuotes } from "./LearnQuotes";
import { FAQ } from "./FAQ";
import { useNavigate, useParams } from "react-router-dom";
import { useUserId } from "hooks/user/useUserId";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Coverage, Qualifications, Responsibilities } from "./SimpleOverview";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useWindowSize } from "react-use-size";
import { AgreeButtonButton, NextButtonButton } from "course/application/sectionLeaderApplication/aboutJob/Explore";


export const ExploreCourse = () => {
    return <div>
        <LandingNav applicationType={"student"} subpage={"About the course"}/>
        <Details />
    </div>
}

const Details = () => {

    return (
     <div>
        <AgreeButton/>
        <Takeaways />
        <LearnQuotes/>
      
        
        <div className="d-flex flex-row justify-content-center w-100">
          <div style={{padding:'10px'}}>
            <div style={{maxWidth:'700px'}} id="fp-7">
            <Responsibilities />
            <div style={{height:'20px'}}/>
            <Coverage/>
            <div style={{height:'20px'}}/>
            <Qualifications/>
            
            <div style={{height:'20px'}} id="fp-8"/>
            <FAQ/>
            <div style={{height:'80px'}}/>
            </div>
          </div>

        </div>
      </div>
    );
  }




  const AgreeButton = () => {
    const navigate = useNavigate();
    const userId = useUserId()
    const {targetCourseId} = useParams()
    const firebasePath = `users/${userId}/${targetCourseId}/studentApplication`
    const db = getFirestore();
    const docRef = doc(db, firebasePath);
    const [didHitResponsibilities, setDidHitResponsibilities] = useState(false);
  
    const [didHitBottom, setDidHitBottom] = useState(false);
    const [btnText, setBtnText] = useState("Keep Scrolling");
  
    async function onClick() {
  
      await setDoc(docRef, {hasReadAboutCourse: true}, { merge: true });
      navigate(`/public/join/${targetCourseId}`)
      return;
  
    }
  
    useEffect(() => {
      // listen to see if we hit the bottom
      function handleScroll() {
        console.log("scrolling")
        if(!document.getElementById("fp-7")) return;
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;
        const bottom = scrollHeight - scrollTop - clientHeight;
        // check if past fp-7
  
        if (bottom < 300) {
          setDidHitBottom(true);
          setDidHitResponsibilities(true);
          setBtnText("Mark Complete");
        } else if (bottom < document.getElementById("fp-7").offsetHeight) {
          setDidHitResponsibilities(true);
          setDidHitBottom(false);
  
          setBtnText("Keep Scrolling");
        } else {
          setDidHitResponsibilities(false);
          setDidHitBottom(false);
        }
      }
      window.addEventListener('scroll', handleScroll);

      return () => {
         console.log("remove listener")
        window.removeEventListener('scroll', handleScroll);
      }
    }, [])
  
  
    return  <AgreeButtonButton
        onClick={onClick}
        className={`btn btn-success btn-lg ${!didHitResponsibilities ? "d-none" : ""}`}
        disabled={!didHitBottom}
      >
        {btnText}
      </AgreeButtonButton>
  
  }
  
  
  export const NextButton = () => {
    const userId = useUserId()
    const {targetCourseId} = useParams()
    const firebasePath = `users/${userId}/${targetCourseId}/sectionLeaderApplication`
    const db = getFirestore();
    const docRef = doc(db, firebasePath);
    
    const scrollToList = [
      "fp-1",
      "fp-2",
      "fp-3",
      "fp-4",
      "fp-5",
      "fp-6",
      "fp-7",
      "fp-8",
    ]
  
  
    async function onClick() {
  
      // find closest div in scrollToList thats below the current scroll position
      let closestDiv = null;
      let closestDistance = null;
  
      for (let i = 0; i < scrollToList.length; i++) {
        const id = scrollToList[i];
        const div = document.getElementById(id);
        if (!div) return;
        const divTop = div.getBoundingClientRect().top - 57;
        // const topDelta = divTop - currentScroll;
        if (divTop > 100 && (!closestDistance || divTop < closestDistance)) {
          closestDistance = divTop;
          closestDiv = div;
          break;
        }
      }
  
  
      if (!closestDiv) return;
      window.scrollTo({
        top: closestDiv.offsetTop - 57,
        behavior: 'smooth'
      });
  
    }
  
  
    return  <NextButtonButton
        onClick={onClick}
        className={`btn btn-primary btn-lg`}
      >
        Next
      </NextButtonButton>
  
  }
