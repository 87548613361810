import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { functions } from "firebaseApp";




export const useServerTime = (refreshRate = 1000) => {
  const {serverTimeDelta} = useContext(TimeContext);
  const [serverTimeMs, setServerTimeMs] = useState(undefined);


  // once we have the delta, start updating the time
  useEffect(() => {
    if(serverTimeDelta === undefined) {

      return;
    }
    setServerTimeMs(Date.now() + serverTimeDelta);
    const interval = setInterval(() => {
      setServerTimeMs(Date.now() + serverTimeDelta);
    }, refreshRate); // refresh every second
    return () => clearInterval(interval);
  }, [serverTimeDelta]);

  return serverTimeMs;
}




export const useServerTimeOnce = () => {
  const {getServerTimeMs} = useContext(TimeContext);
  const [serverTimeMs, setServerTimeMs] = useState(undefined);

  useEffect(() => {
    if(getServerTimeMs === undefined) {
      return;
    }
    setServerTimeMs(getServerTimeMs());
  }
  ,[getServerTimeMs])


  return serverTimeMs;
}

/**
 * This context provides the server time to the client.
 * Caution: because of internet delays it could be incorrect up to 
 * about 1 second. Do not use it for anything which needs more precise
 * timing.
 * 
 * Stores the server time in the serverDelta variable. It is the difference
 * in ms between the server time and the client time.
 */

const defaultData = {
  getServerTimeMs: undefined,
  serverTimeDelta: undefined
};



export const TimeContext = React.createContext(defaultData);
export const TimeProvider = ({children}) => {

  const [serverTimeDelta, setServerDelta] = useState(undefined);

  useEffect(()=> {
    const getServerTime = functions.httpsCallable('getServerTime');
    getServerTime().then((result) => {
      const serverTime = result.data.serverTime;
      const clientTime = Date.now();
      const serverDelta = serverTime - clientTime;
      setServerDelta(serverDelta)
    });
  },[])


  const getServerTimeMs = () => {
    if(serverTimeDelta === undefined) {
      return undefined;
    }
    return Date.now() + serverTimeDelta;
  }
  


  return (
    <TimeContext.Provider
      value={{
        getServerTimeMs,
        serverTimeDelta
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};
