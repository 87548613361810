import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { useContext, useEffect, useRef, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ChapterTitle } from './ChapterTitle';
import { BookContext } from 'textbook/context/BookContext';
import { ChapterBodyOuter, ChapterTitleInner } from 'textbook/layout/BookLayout';
import { useUser } from 'hooks/user/useUserId';
import { TipTap } from 'components/richTextEditor/TipTap/TipTap';
import { HandoutButtonBar } from 'components/richTextEditor/TipTap/buttonbars/HandoutButtonBar';
import { TextbookButtonBar } from 'components/richTextEditor/TipTap/buttonbars/TextbookButtonBar';

export const TextbookChapter = () => {
  return <ChapterInner />;
};

const ChapterInner = () => {
  const { chapterId } = useParams();

  const { bookChaptersLoading, bookOutlineLoading, getChapterTitle } =
    useContext(BookContext);

  if (!chapterId) return <></>;
  if (bookChaptersLoading || bookOutlineLoading) return <></>;

  const chapterTitle = getChapterTitle(chapterId);

  return (
    <div className="book">
      <ChapterTitleInner>
        <ChapterTitle chapterTitle={chapterTitle}></ChapterTitle>
        <hr />
      </ChapterTitleInner>
      <ChapterBodyOuter>
        <ChapterBody />
      </ChapterBodyOuter>
    </div>
  );
};

const ChapterBody = ({ }) => {
  const user = useUser();
  const { chapterId } = useParams();
  const { bookFirebaseRoot, editing } = useContext(BookContext);
  const firebaseDocPath = `${bookFirebaseRoot}/chapters/${chapterId}/doc`;

  return (
    <TipTap
      buttonBar={TextbookButtonBar}
      editable={editing}
      firebaseDocPath={firebaseDocPath}
      user={user}
    />
  );
};
