import Collaboration from '@tiptap/extension-collaboration';
import {getApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {useEffect, useRef} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import {editorExtensions} from './EditorExtensions';
import {EditorContent, useEditor} from '@tiptap/react';
import {uploadImg} from './extensions/Image';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import {randomUserColor} from './TipTapUtil';
import {useEditorNavigation} from './hooks/useEditorNavigation';
import {handleDrop} from './hooks/handleDrop';
import {e} from 'mathjs';

// a map from file id to link
export const fileMap = new Map();

/**
 * This is the actual editor
 */
export const TipTapEditor = ({
  provider,
  onUpdate,
  editable,
  collaborative,
  serverData,
  buttonBar,
  height,
  firebaseDocPath,
}) => {
  const [user] = useAuthState(getAuth(getApp()));

  const editorRef = useRef(null);

  let extensions = editorExtensions;
  if (collaborative) {
    const colabExtensions = [
      Collaboration.configure({
        document: provider ? provider.doc : null,
      }),

      CollaborationCursor.configure({
        provider: provider,
        user: {
          name: user?.displayName ?? 'Anonymous',
          color: randomUserColor(),
          // this does not update, but it is a promising idea...
          editable: editable,
        },
      }),
    ];
    // @ts-ignore
    // there is a type script error about extension type and colaboration type
    extensions = [...extensions, ...colabExtensions];
  }

  const editerParams = {
    extensions: extensions,
    editorProps: {},
    editable: editable,
  };

  if (!collaborative) {
    // there are two ways to injest data, via a YJS provider
    // or via setting the content field. We use the later
    // if and only if we are not using YJS
    if (!serverData || !serverData.content) {
      editerParams.content = '';
    } else {
      editerParams.content = serverData.content;
    }
  }
  const editor = useEditor(editerParams);

  useEditorNavigation(editor);

  useEffect(() => {
    const assignChildrenIds = (parent, prefix) => {
      if (!parent) return;
      const children = parent.children;
      if (!children || children.length === 0) {
        return;
      }
      for (let i = 0; i < children.length; i++) {
        if (!children[i].id) {
          children[i].id = prefix + '-' + i;
        }
        if (children[i].children.length > 0) {
          assignChildrenIds(children[i], prefix + '-' + i);
        }
      }
    };

    const parentElement = document.getElementById('tiptap-editor-container'); // Select the parent element
    assignChildrenIds(parentElement, `tiptap-${firebaseDocPath}`); // Assign the ids
  }, [editor]);

  useEffect(() => {
    // this is a hack, but it is one I ran through the discussion
    // forum of tiptap. I believe since I brought it up they
    // may have fixed it so that the editable field of the editor
    // is reactive (children can listed to it)
    if (editor) {
      editor.setEditable(editable);
      editor.chain().focus().insertContent('').run();
    }
    if (collaborative) {
      // update the user's editable status
    }
  }, [editable]);

  useEffect(() => {
    if (!editor) return;
    editorRef.current = editor;
    editor.on('update', ({editor}) => {
      let json = editor.getJSON();
      let html = editor.getHTML();
      let text = editor.getText();
      onUpdate(json, html, text);
    });
  }, [editor]);

  let tiptapStyle = outerStyle;
  if (height) {
    tiptapStyle = {
      ...outerStyle,
      height: height,
    };
  }

  // Handle drag-and-drop
  const handleDragOver = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  return (
    <div
      style={tiptapStyle}
      onDragOver={handleDragOver}
      onDrop={e => handleDrop(e, editor, editable)}
    >
      {buttonBar({editor, editable})}
      {/* classname editor is the parent to prosemirror styles */}
      <div
        className={editable ? 'editor' : ''}
        style={editable ? innerStyle : {}}
        onClick={() => {
          if (editable) {
            // Bug: this didn't work (it kept stealing focus from ace editor)
            // if (!editor.view.hasFocus()) {
            //   editor.chain().focus().run();
            // }
          }
        }}
        id="tiptap-editor-container"
      >
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

const outerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const innerStyle = {
  padding: '10px',
  flex: 1,
  overflow: 'auto',
};
