import {useContext} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {bookMenuBackground} from './MenuItemStyles';
import {BookContext} from 'textbook/context/BookContext';

export const SidebarHeader = () => {
  const {bookUrlRoot, isDraft, bookTitle} = useContext(BookContext);
  return (
    <StyledSidebarHeader>
      <Title to={`${bookUrlRoot}`}>
        {bookTitle}
      </Title>
      <Subtitle>Code in Place</Subtitle>
      {isDraft && (
        <Link
          className="btn btn-secondary btn-sm"
          to={`${bookUrlRoot}/outline`}
        >
          Edit Outline
        </Link>
      )}
      <EditingToggle />
      <LiveDemos />

      <TitleHr />
    </StyledSidebarHeader>
  );
};

const LiveDemos = () => {
  const {liveReact, isDraft, setLiveReact} = useContext(BookContext);
  const buttonTxt = liveReact ? 'turn off' : 'turn on';

  if (!isDraft) return <></>;

  return <EditingBar></EditingBar>;
};

const EditingToggle = () => {
  const {editing, setEditing, isDraft, bookUrlRoot} = useContext(BookContext);

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const buttonTxt = editing ? 'turn off' : 'turn on';

  if (!isDraft) return <></>;

  return (
    <EditingBar>
      <span>Editing: </span>
      <button onClick={toggleEditing} className="btn btn-secondary btn-sm">
        {buttonTxt}
      </button>
    </EditingBar>
  );
};

const Typography = ({children, ...rest}) => {
  return <span {...rest}>{children}</span>;
};

const StyledSidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: white;
  font-size: 26px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const Title = styled(Link)`
  color: white;
  background-color: ${bookMenuBackground};
  text-decoration: none;
  font-size: 1.05em;
  line-height: 1.05em;
  // text-transform: uppercase;
  font-weight: 550;
  margin-bottom: 5px;
  margin-top: 8px;
  font-family: Source Sans 3;
  // make the text centere
`;

const Subtitle = styled(Typography)`
  font-size: 0.8em;
  color: #ddd;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const EditingBar = styled.div`
  display: flex;
  align-items: center;
  color: #ddd;
  font-size: 0.8em;
  gap: 5px;
  margin-top: 5px;
  font-size: 18px;
`;

const EditingButtonsOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TitleHr = styled.hr`
  margin-top: 8px;
  margin-bottom: 8px;
  border-top: 1px solid #333;
`;
