import {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
  createContext,
  MutableRefObject,
} from 'react';
import useEffectExceptOnMount from '../hooks/useEffectExceptOnMount';
import {checkIsProjectKarel, checkIsProjectConsole, checkIsProjectGraphics} from '../utils/general';
import {ProjectFilesCode, ProjectFileStructure} from 'projects/types';
import {getDefaultWorldState} from 'components/pyodide/KarelLib/util';
import useMediaQuery, {Breakpoints} from 'utils/useMediaQuery';
import {AssnContext} from 'course/contexts/AssnContext';
import {useIDEChatLoaders} from 'components/duolessons/lessonchats/IDEChatHooks';
import {useCourseId} from 'hooks/router/useUrlParams';
import {getAuth} from '@firebase/auth';
import {useMoodSurveyLoadersAssn} from 'components/duolessons/lessonchats/MoodSurveyHooks';
import {
  EMPTY_TEST_RESULT,
  UnitTestResults,
} from 'assignments/unitTest/unitTestResults';
import {inferUnitTestType} from 'ide/UnitTest/runUnitTests';
import {UNIT_TEST_TYPE_TO_SCHEMA} from 'assignments/unitTest/UnitTestSchemaRegistry';
import {
  IKarelState,
  isIKarelState,
} from 'components/pyodide/KarelLib/karelInterfaces';
import {CourseId} from 'types/common';
import {TermModel} from 'ide/TerminalPane/GeneralTerminal/Model';
import {TerminalView} from 'ide/TerminalPane/XTerm/XTermModel';
import {
  isKarelUnitTest,
  KarelUnitTest,
} from '../../assignments/models/unitTests/karel';
import {PyodideClient} from 'components/pyodide/PyodideProvider';
import {editor} from 'monaco-editor';
import {ProjectFileData} from 'projects/types';
import {CodeInPlaceTerminal} from 'ide/TerminalPane/types';
import type {ProjectDocument} from 'projects/projectDocument';
export interface IDEContextData {
  projectData: ProjectDocument | null;
  assnData: any;
  stepMode: boolean;
  editable: boolean;
  setEditable: (val: boolean) => void;
  setStepMode: (val: boolean) => void;
  lineNo: number;
  setLineNo: (val: number) => void;
  stepPtr: number;
  setStepPtr: (val: number) => void;
  stepData: Map<string, any>;
  setStepData: (val: Map<string, any>) => void;
  hasHistory: boolean;
  setHasHistory: (val: boolean) => void;
  /** A ref to the Monaco editor. */
  editorRef: MutableRefObject<editor.IStandaloneCodeEditor>;
  /** Whether the Monaco editor has been loaded. */
  editorLoaded: boolean;
  /** Sets the editorLoaded state. */
  setEditorLoaded: (val: boolean) => void;
  /** A ref to the current terminal. */
  terminalRef: MutableRefObject<CodeInPlaceTerminal>;
  /** A ref to the current Pyodide client. */
  pyodideClientRef: MutableRefObject<PyodideClient>;
  /** The Karel world state displayed in the IDE. */
  karelWorldState: IKarelState;
  /** Sets the displayed Karel world state. */
  setKarelWorldState: (val: IKarelState) => void;
  stepList: any;
  setStepList: (val: any) => void;
  stepLogs: any;
  setStepLogs: (val: any) => void;
  helpMode: boolean;
  setHelpMode: (val: boolean) => void;
  setKarelSleepTime: (val: number) => void;
  karelSleepTime: number;
  runErrorOccurred: boolean;
  setRunErrorOccurred: (val: boolean) => void;
  runSuccessOccurred: boolean;
  setRunSuccessOccurred: (val: boolean) => void;
  loadingError: any;
  isProjectLoading: boolean;
  terminalViewState: string;
  canvasViewState: string;
  leftColViewState: string;
  setTerminalViewState: (val: string) => void;
  setCanvasViewState: (val: string) => void;
  lastRunPassed: boolean;
  setLastRunPassed: (val: boolean) => void;
  consoleTestResults: any;
  setConsoleTestResults: (val: any) => void;
  isRunning: boolean;
  setIsRunning: (val: boolean) => void;
  /** The code to run when the user clicks run or "Check Correct". Typically
   * this is the code in the current file. */
  codeToRun: MutableRefObject<string>;
  /** The default Karel world state. Reverts to this state when the user
   * clicks "Reset". */
  defaultKarelWorldState: IKarelState;
  /** Sets the default Karel world state. */
  setDefaultKarelWorldState: (val: IKarelState) => void;
  selectedErrorMessageType: string;
  setSelectedErrorMessageType: (val: string) => void;
  forumLinks: any;
  errorLineNo: number;
  editorFontSize: number;
  setEditorFontSize: (val: number) => void;
  setErrorLineNo: (val: number) => void;
  selectedTab: string;
  setSelectedTab: (val: string) => void;
  isTabletOrLarger: boolean;
  setLeftColViewState: (val: string) => void;
  stepSaveOn: boolean;
  setStepSaveOn: (val: boolean) => void;
  isDiagnostic: boolean;
  showSoln: boolean;
  setShowSoln: (val: boolean) => void;
  screenReadableEditorOn: boolean;
  setScreenReadableEditorOn: (val: boolean) => void;
  karelType: string;
  setKarelType: (val: string) => void;
  isKarelSelectOpen: boolean;
  setIsKarelSelectOpen: (val: boolean) => void;
  initSetCode: (val: (v: any) => void) => void;
  setCode: (val: any) => void;
  courseId: CourseId;
  aiAutograderRunning: boolean;
  setAiAutograderRunning: (val: boolean) => void;
  chatType: number;
  chatMessages: any;
  tempMessages: any;
  setTempMessages: (val: any) => void;
  unreadMessageFlag: boolean;
  setUnreadMessageFlag: (val: boolean) => void;
  setChatTimestamp: () => void;
  runMoodSurvey: () => Promise<void>;
  loadChat: () => Promise<void>;
  unitTestResults: UnitTestResults;
  setUnitTestResults: (val: UnitTestResults) => void;
  karelWorldEditMode:boolean;
  setKarelWorldEditMode: (val: boolean) => void;
  isSubmitted: boolean;
  setIsSubmitted: (val: boolean) => void;
  /** Any data required to run the unit tests for the project currently loaded
   * in the IDE. This data should always be loaded using an
   * {@link assignments/unitTest/UnitTestSchema#UnitTestSchema | UnitTestSchema}
   * loader function.
   */
  loadedUnitTestData: any;
  /** Sets the loaded unit test data. */
  setLoadedUnitTestData: (val: any) => void;
  /** Maps project file ids to their corresponding code. */
  filesCode: ProjectFilesCode;
  /** Sets the filesCode map. */
  setFilesCode: (val: ProjectFilesCode) => void;
  /** The file that is currently open in the IDE. */
  currentFile: ProjectFileData | null;
  /** Sets the current file. */
  setCurrentFile: (val: ProjectFileData | null) => void;
  /** The file tree structure of the project.  */
  fileStructure: ProjectFileStructure;
  /** Sets the file structure. */
  setFileStructure: (val: ProjectFileStructure) => void;

  whatsappProjectKey: string;
  setWhatsappProjectKey: (val: string) => void;

  selectedMobileTab: string;
  setSelectedMobileTab: (val: string) => void;

  selectedMobileOutputTab: string;
  setSelectedMobileOutputTab: (val: string) => void;
}

const defaultData: IDEContextData = {
  projectData: null,
  assnData: null,
  stepMode: false,
  editable: true,
  setEditable: () => {},
  setStepMode: () => {},
  lineNo: 0,
  setLineNo: () => {},
  stepPtr: 0,
  setStepPtr: () => {},
  stepData: new Map(),
  setStepData: () => {},
  hasHistory: false,
  setHasHistory: () => {},
  editorRef: {current: null},
  editorLoaded: false,
  setEditorLoaded: () => {},
  terminalRef: {current: null},
  pyodideClientRef: {current: null},
  karelWorldState: null,
  setKarelWorldState: () => {},
  stepList: null,
  setStepList: () => {},
  stepLogs: null,
  setStepLogs: () => {},
  helpMode: false,
  setHelpMode: () => {},
  setKarelSleepTime: () => {},
  karelSleepTime: 0.2,
  runErrorOccurred: false,
  karelWorldEditMode: false,
  setKarelWorldEditMode: () => {},
  setRunErrorOccurred: () => {},
  runSuccessOccurred: false,
  setRunSuccessOccurred: () => {},
  loadingError: null,
  isProjectLoading: true,
  terminalViewState: 'standard',
  canvasViewState: 'minimized',
  leftColViewState: 'standard',
  setTerminalViewState: () => {},
  setCanvasViewState: () => {},
  lastRunPassed: false,
  setLastRunPassed: () => {},
  consoleTestResults: null,
  setConsoleTestResults: () => {},
  isRunning: false,
  setIsRunning: () => {},
  codeToRun: {current: null},
  defaultKarelWorldState: null,
  setDefaultKarelWorldState: () => {},
  selectedErrorMessageType: 'default',
  setSelectedErrorMessageType: () => {},
  forumLinks: null,
  errorLineNo: 0,
  editorFontSize: 14,
  setEditorFontSize: () => {},
  setErrorLineNo: () => {},
  selectedTab: null,
  setSelectedTab: () => {},
  isTabletOrLarger: true,
  setLeftColViewState: () => {},
  stepSaveOn: true,
  setStepSaveOn: () => {},
  isDiagnostic: false,
  showSoln: false,
  setShowSoln: () => {},
  screenReadableEditorOn: false,
  setScreenReadableEditorOn: () => {},
  karelType: null,
  setKarelType: () => {},
  isKarelSelectOpen: false,
  setIsKarelSelectOpen: () => {},
  initSetCode: fn => {},
  setCode: () => {},
  courseId: null,
  aiAutograderRunning: false,
  setAiAutograderRunning: () => {},
  chatType: undefined,
  chatMessages: [],
  tempMessages: [],
  setTempMessages: () => {},
  unreadMessageFlag: false,
  setUnreadMessageFlag: () => {},
  setChatTimestamp: () => {},
  runMoodSurvey: async () => {},
  loadChat: async () => {},
  unitTestResults: EMPTY_TEST_RESULT,
  setUnitTestResults: () => {},
  isSubmitted: false,
  setIsSubmitted: () => {},
  loadedUnitTestData: null,
  setLoadedUnitTestData: val => {},
  filesCode: null,
  setFilesCode: () => {},
  currentFile: null,
  setCurrentFile: () => {},
  fileStructure: null,
  setFileStructure: () => {},
  whatsappProjectKey: null,
  setWhatsappProjectKey: () => {},
  selectedMobileTab: 'Problem',
  setSelectedMobileTab: () => {},
  selectedMobileOutputTab: 'Console',
  setSelectedMobileOutputTab: () => {},
};
export const IDEContext = createContext(defaultData);

interface IDEProviderProps {
  /**
   * A function that returns a tuple of [projectData, assnData, loadingError, isProjectLoading].
   * @param assnContext An object containing the assignment context.
   * @returns A tuple of [projectData, assnData, loadingError, isProjectLoading].
   */
  useProject: (assnContext: any) => [ProjectDocument, any, string, boolean];
  /**
   * Whether the project is a diagnostic project. Defaults to false.
   */
  isDiagnostic?: boolean;
  /**
   * The children components of the IDEProvider.
   */
  children: React.ReactNode;
}
export const IDEProvider = ({children, useProject, isDiagnostic = false}: IDEProviderProps) => {
  const assnContext = useContext(AssnContext);

  const [projectData, assnData, loadingError, isProjectLoading] =
    useProject(assnContext);
  const isTabletOrLarger = useMediaQuery(Breakpoints.TAB);
  const courseId = useCourseId();

  // Replay-related states
  const [hasHistory, setHasHistory] = useState(defaultData.hasHistory);
  const [stepMode, setStepMode] = useState(defaultData.stepMode);
  const [lineNo, setLineNo] = useState(defaultData.lineNo);
  const [stepPtr, setStepPtr] = useState(defaultData.stepPtr);
  const [stepData, setStepData] = useState(defaultData.stepData);
  const [stepList, setStepList] = useState(defaultData.stepList);
  const [stepLogs, setStepLogs] = useState(defaultData.stepLogs);
  const [stepSaveOn, setStepSaveOn] = useState(defaultData.stepSaveOn);
  const [isSubmitted, setIsSubmitted] = useState(defaultData.isSubmitted);
  const [whatsappProjectKey, setWhatsappProjectKey] = useState(
    defaultData.whatsappProjectKey,
  );

  // Editor view
  // TODO: these could load from the database ("users", userId, "settings", "ide").
  const [screenReadableEditorOn, setScreenReadableEditorOn] = useState(
    defaultData.screenReadableEditorOn,
  );
  const [editorFontSize, setEditorFontSize] = useState(
    defaultData.editorFontSize,
  );

  // Left col
  const [selectedTab, setSelectedTab] = useState(null);
  const [editable, setEditable] = useState(defaultData.editable);
  const [helpMode, setHelpMode] = useState(defaultData.helpMode);
  const [showSoln, setShowSoln] = useState(defaultData.showSoln);
  const [karelType, setKarelType] = useState(defaultData.karelType);
  const [isKarelSelectOpen, setIsKarelSelectOpen] = useState(
    defaultData.isKarelSelectOpen,
  );

  // Desktop view
  // "standard" "minimized" or "expanded"
  const [leftColViewState, setLeftColViewState] = useState('standard');
  const [terminalViewState, setTerminalViewState] = useState(
    defaultData.terminalViewState,
  );
  const [canvasViewState, setCanvasViewState] = useState(
    defaultData.canvasViewState,
  );

  // Mobile view
  const [selectedMobileTab, setSelectedMobileTab] = useState(
    assnData ? 'Problem' : 'Code',
  );
  const [selectedMobileOutputTab, setSelectedMobileOutputTab] = useState(
    'Console',
  );

  const [lastRunPassed, setLastRunPassed] = useState(defaultData.lastRunPassed);
  const [consoleTestResults, setConsoleTestResultsState] = useState(
    defaultData.consoleTestResults,
  );
  const [isRunning, setIsRunning] = useState(defaultData.isRunning);


  // Error messages
  const [selectedErrorMessageType, setSelectedErrorMessageType] = useState(
    defaultData.selectedErrorMessageType,
  );
  // const [forumLinks] = useCollection(collection(db, 'forumLinks'));
  const forumLinks = null;
  const [errorLineNo, setErrorLineNo] = useState(defaultData.errorLineNo);
  const [runErrorOccurred, setRunErrorOccurred] = useState(
    defaultData.runErrorOccurred,
  );
  const [runSuccessOccurred, setRunSuccessOccurred] = useState(
    defaultData.runSuccessOccurred,
  );

  // Karel
  const [karelSleepTime, setKarelSleepTime] = useState(
    defaultData.karelSleepTime,
  );
  const [karelWorldState, setKarelWorldState] = useState(
    defaultData.karelWorldState,
  );
  const [defaultKarelWorldState, setDefaultKarelWorldState] = useState(
    defaultData.defaultKarelWorldState,
  );
  const [karelWorldEditMode, setKarelWorldEditMode] = useState(false);

  // Unit test data (needed to run unit tests)
  const [loadedUnitTestData, setLoadedUnitTestData] = useState(
    defaultData.loadedUnitTestData,
  );
  const unitTestType = inferUnitTestType(assnData);
  const unitTestSchema = UNIT_TEST_TYPE_TO_SCHEMA[unitTestType];

  // Local file structure/data
  const [filesCode, setFilesCode] = useState(defaultData.filesCode);
  // File that is currently open; you can only open a single file at any given time
  const [currentFile, setCurrentFile] = useState<ProjectFileData | null>(
    defaultData.currentFile,
    // projectData?.lastOpenedFile ?? getDefaultFile(projectData),
  );
  // Current file structure in nested dictionary format
  const [fileStructure, setFileStructure] = useState<ProjectFileStructure>(
    defaultData.fileStructure,
    // projectData?.files ?? [],
  );

  // Unit test results. Juliette adding here for style feedback
  const [unitTestResults, setUnitTestResults] = useState(EMPTY_TEST_RESULT);

  // Refs
  const editorRef = useRef(defaultData.editorRef.current);
  const [editorLoaded, setEditorLoaded] = useState(defaultData.editorLoaded);
  const terminalRef = useRef<CodeInPlaceTerminal>(
    defaultData.terminalRef.current,
  );
  const pyodideClientRef = useRef(defaultData.pyodideClientRef.current);
  const codeToRun = useRef<string>(defaultData.codeToRun.current);

  // Ai Autograder
  const [aiAutograderRunning, setAiAutograderRunning] = useState(
    defaultData.aiAutograderRunning,
  );

  // Set code function
  const [setCode, setCodeUpdater] = useState<(v: any) => void>(() => {});

  const {
    chatType,
    chatMessages,
    tempMessages,
    setTempMessages,
    unreadMessageFlag,
    setUnreadMessageFlag,
    setChatTimestamp,
    loadChat,
  } = useIDEChatLoaders({selectedTab, courseId});

  const {runMoodSurvey} = useMoodSurveyLoadersAssn();

  useEffect(() => {
    if (isProjectLoading || !projectData) {
      return;
    }
    if (projectData.editors) {
      const userId = getAuth().currentUser.uid;
      setEditable(projectData.editors.includes(userId));
    }
    setCurrentFile(projectData.lastOpenedFile ?? getDefaultFile(projectData));
    setFileStructure(projectData.files ?? []);
    setWhatsappProjectKey(projectData.whatsappKey ? projectData.whatsappKey : '');
  }, [isProjectLoading]);

  useEffect(() => {
    // gather relevant karel data
    if (checkIsProjectKarel(projectData, assnData)) {
      let karelStarterWorld = getKarelStarterWorld(assnData, projectData);
      setDefaultKarelWorldState(karelStarterWorld);
      setTerminalViewState('minimized');
      setKarelWorldState(karelStarterWorld);
    }

    // no unit tests
    // const unitTests = assnData?.unitTests?.unitTests
    // const noUnitTests = !unitTests || unitTests?.length === 0
    const assnId = assnData?.metaData?.uid;
    const isAssnSubmitted = assnData?.submissionData?.isSubmitted;
    setIsSubmitted(isAssnSubmitted);

    const noUnitTests = isDiagnostic || !assnId;
    if (checkIsProjectConsole(projectData, assnData) && noUnitTests) {
      setCanvasViewState('minimized');
    }

    if (checkIsProjectGraphics(projectData, assnData)) {
      setCanvasViewState('standard');
    }
  }, [assnData, projectData]);

  useEffectExceptOnMount(() => {
    // Upon leaving step mode
    if (terminalRef.current) {
      if (stepMode) {
        terminalRef.current.enterStep();
      } else {
        terminalRef.current.leaveStep();
      }
    }
  }, [stepMode]);

  useEffect(() => {
    if (!unitTestSchema) {
      return;
    }
    const assignmentId = assnData?.metaData?.uid;
    if (!assignmentId) {
      return;
    }
    unitTestSchema
      .loader({
        serverAssignmentData: assnData,
        courseId,
        assignmentId,
        projectId: projectData?.uid,
      })
      .then(data => {
        setLoadedUnitTestData(data);
      })
      .catch(error => {
        console.error('Error loading unit test data', error);
      });
  }, [assnData, projectData]);

  const setConsoleTestResults = val => {
    // if the val array is empty do nothing
    if (!val || val.length != 0) {

      // open the test result right pane
      setCanvasViewState('standard');

      // open the test result in the mobile view
      setSelectedMobileOutputTab('Visual'); // dont ask why its called Visual. It may never change.
      setSelectedMobileTab('Output');
    } 
    setConsoleTestResultsState(val);
  };

  const initSetCode = useCallback(updateFn => {
    setCodeUpdater(() => updateFn);
  }, []);

  return (
    <IDEContext.Provider
      value={{
        projectData,
        assnData,
        stepMode,
        setStepMode,
        lineNo,
        setLineNo,
        stepPtr,
        setStepPtr,
        stepData,
        setStepData,
        hasHistory,
        setHasHistory,
        terminalRef,
        editorRef,
        editorLoaded,
        setEditorLoaded,
        pyodideClientRef,
        karelWorldState,
        setKarelWorldState,
        editable,
        setEditable,
        stepList,
        setStepList,
        stepLogs,
        setStepLogs,
        helpMode,
        setHelpMode,
        karelSleepTime,
        setKarelSleepTime,
        setDefaultKarelWorldState,
        defaultKarelWorldState,
        runErrorOccurred,
        setRunErrorOccurred,
        runSuccessOccurred,
        setRunSuccessOccurred,
        loadingError,
        isProjectLoading,
        terminalViewState,
        setTerminalViewState,
        canvasViewState,
        setCanvasViewState,
        lastRunPassed,
        setLastRunPassed,
        consoleTestResults,
        setConsoleTestResults,
        isRunning,
        setIsRunning,
        codeToRun,
        selectedErrorMessageType,
        setSelectedErrorMessageType,
        forumLinks,
        errorLineNo,
        setErrorLineNo,
        selectedTab,
        setSelectedTab,
        isTabletOrLarger,
        leftColViewState,
        setLeftColViewState,
        stepSaveOn,
        setStepSaveOn,
        isDiagnostic,
        showSoln,
        setShowSoln,
        screenReadableEditorOn,
        setScreenReadableEditorOn,
        editorFontSize,
        setEditorFontSize,
        karelType,
        setKarelType,
        isKarelSelectOpen,
        setIsKarelSelectOpen,
        setCode,
        initSetCode,
        courseId,
        aiAutograderRunning,
        setAiAutograderRunning,
        chatType,
        chatMessages,
        tempMessages,
        setTempMessages,
        unreadMessageFlag,
        setUnreadMessageFlag,
        setChatTimestamp,
        runMoodSurvey,
        loadChat,
        unitTestResults,
        setUnitTestResults,
        isSubmitted,
        setIsSubmitted,
        loadedUnitTestData,
        setLoadedUnitTestData,
        filesCode,
        setFilesCode,
        currentFile,
        setCurrentFile,
        fileStructure,
        setFileStructure,
        whatsappProjectKey,
        setWhatsappProjectKey,
        karelWorldEditMode,
        setKarelWorldEditMode,
        selectedMobileTab,
        setSelectedMobileTab,
        selectedMobileOutputTab,
        setSelectedMobileOutputTab,
      }}
    >
      {children}
    </IDEContext.Provider>
  );
};

export function getDefaultFile(projectData: any): ProjectFileData | null {
  const files = projectData?.files;
  if (!files || !Array.isArray(files) || files.length === 0) return null;
  const rootFolder = files[0];
  // If there is a main.py file, use that as the default file
  const mainFile = rootFolder.files.find(file => file.name === 'main.py');
  if (mainFile) {
    return {...mainFile};
  }
  // Otherwise, use the first Python file in the root folder
  for (let file of rootFolder.files) {
    const name = file.name;
    const isPython = name.endsWith('.py');
    if (isPython) {
      return {...file};
    }
  }
  return null;
}

export function getKarelStarterWorld(assnData, projectData): IKarelState {
  let unitTests: KarelUnitTest[] =
    assnData?.unitTests?.unitTestsKarel ?? assnData?.unitTests?.unitTests ?? [];
  unitTests = unitTests.filter(unitTest => {
    if (!isKarelUnitTest(unitTest)) {
      console.warn('Non-Karel unit test found', unitTest);
      return false;
    }
    return true;
  });

  if (unitTests.length > 0) {
    return unitTests[0].pre;
  }

  if (isIKarelState(projectData?.userKarel)) {
    return projectData.userKarel;
  }

  console.warn('No Karel starter world found', {
    allUnitTests:
      assnData?.unitTests?.unitTestsKarel ?? assnData?.unitTests?.unitTests,
    filteredUnitTests: unitTests,
    userKarel: projectData?.userKarel,
  });
  return getDefaultWorldState(10, 10);
}
