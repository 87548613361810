import {useContext, useEffect} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useNavigate} from 'react-router-dom';

export const useEditorNavigation = editor => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!editor) return;

    const handleClick = event => {
      const link = event.target.closest('a'); // Target links with `data-navigate`
      if (link && !editor.isEditable) {
        // Check if the link's href starts with 'blob:'
        if (link.href.startsWith('blob:')) {
          // Allow default behavior for blob URLs (trigger download)
          return;
        }

        // Parse the link's URL
        const urlObj = new URL(link.href, window.location.origin); // Ensure relative URLs are handled

        const isExternal = urlObj.origin !== window.location.origin;

        if (isExternal) {
          // External link: Allow default behavior
          return;
        }

        event.preventDefault();

        // Check if the link points to the current page
        const isSamePage =
          urlObj.origin === window.location.origin &&
          urlObj.pathname === window.location.pathname;

        if (isSamePage && urlObj.hash) {
          // If the hash is present, try to find the corresponding element and scroll to it
          const targetElement = document.querySelector(urlObj.hash);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth', // Smooth scroll for a better user experience
              block: 'start', // Scroll element to the center of the viewport
            });
          }
        }

        // Continue with navigation logic if not the same page or no matching element
        let url = link.href;

        // Remove the protocol (http/https)
        url = url.replace(/https?:\/\//, '');

        // Strip the origin if present
        const strippedOrigin = window.location.origin
          .replace(/\/$/, '')
          .replace(/https?:\/\//, '');
        if (url.includes(strippedOrigin)) {
          url = url.replace(strippedOrigin, '');
        }

        navigate(url);
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [editor, navigate]);
};

export const composeUrl = (href, bookUrlRoot, origin) => {
  const pathToRootKey = '{{pathtoroot}}'.toLowerCase();
  href = href.toLowerCase();

  if (!href.includes(pathToRootKey)) {
    return href;
  }

  // strip off https:// and http://
  let strippedOrigin = origin.replace(/https?:\/\//, '');
  let strippedHref = href.replace(/https?:\/\//, '');

  // strip off trailing slashes
  let strippedBookUrlRoot = bookUrlRoot.replace(/\/$/, '');
  strippedOrigin = strippedOrigin.replace(/\/$/, '');
  strippedHref = strippedHref.replace(/\/$/, '');

  // replace {{pathToRoot}} with the bookUrlRoot
  const inner = strippedHref.replace(pathToRootKey, strippedBookUrlRoot);
  return `${inner}`;
};
