import { MinAgeBirthday } from "course/application/general/MinAgeBirthday";

export const JobQualifications = () => {
  return (
    <div>
      <h1>Qualifications</h1>

      <ol>
        <li>Speak english proficiently enough to hold a real-time conversation.</li>
        <li>Have an internet connection stable enough for a video
        call.</li>
        <li>Know how to program in standard Python up to and including the use of dictionaries.</li>
        <li>Over 16 years old. In other words: you were born before the <MinAgeBirthday/></li>
      </ol>


      <p> Know how to program but don't have much teaching experience? Please apply. We learned from past years that new teachers were amazing and inspiring. We would like to help you become the great instructor you can be.</p>

      <p>We encourage everyone to apply. In the past we have had successful section leaders from over 350 cities all over the planet, who spoke more 30 different languages, and who came from every walk of life. This includes folks in industry, university students who were a few years into their studies, retired teachers, soon-to-be teachers and even past Code in Place students.</p>


    </div>
  );
};