import {
  FaBold,
  FaCode,
  FaImage,
  FaItalic,
  FaLink,
  FaQuoteLeft,
  FaRemoveFormat,
} from "react-icons/fa";
import { onInsertImage } from "../extensions/Image";
import { useUserId } from "hooks/user/useUserId";
import { EditLinkButton } from "./buttons/EditLinkButton";

export const ReviewButtonBar = ({ editor, editable }) => {
  // users need to be logged in only if they are going to upload images
  const userId = useUserId();

  const textStyleColor = editor?.getAttributes("textStyle")?.color;
  const dividerRight = { borderRight: "1px solid lightgrey" };
  if (!editable) return <></>;
  return (
    <div
      className="discussion-button-bar d-flex align-items-center"
      style={{
        top: "0",
        background: "white",
        border: "1px solid lightgrey",
        borderRadius: "5px 5px 0px 0px",
      }}
    >
      

      {/* Bold, italic, underline */}
      <div style={dividerRight}>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className="btn btn-sm btn-light"
        >
          <FaBold />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className="btn btn-sm btn-light"
        >
          <FaItalic />
        </button>

        <input
          className="btn btn-sm btn-light"
          type="color"
          style={{
            width: 32,
            height: 31,
            paddingTop: "9px",
            paddingBottom: "5px",
          }}
          onInput={(event) =>
            editor.chain().focus().setColor(event.target.value).run()
          }
          value={textStyleColor ? textStyleColor : "black"}
        />

        <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="btn btn-sm btn-light"
        >
          <FaRemoveFormat />
        </button>
      </div>

      {/* fancy tools */}

      <EditLinkButton editor={editor}/>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={"btn btn-sm btn-light "}
      >
        <FaCode />
      </button>

      <div style={dividerRight}>
        <button
          onClick={() => onInsertImage(editor, userId)}
          className="btn btn-sm btn-light"
        >
          <FaImage />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={"btn btn-sm btn-light "}
        >
          <FaQuoteLeft />
        </button>

      
      </div>



      {/* <button onClick={() => editor.chain().focus().insertContent('<karelworld></karelworld>').run()}className='btn btn-sm btn-light'><FaPaintBrush/></button> */}

      {/* see the final example here https://casesandberg.github.io/react-color/ */}

      {/* <input
        type="color"
        style={{width:25,height:25}}
        onInput={event => editor.chain().focus().setColor(event.target.value).run()}
        value={textStyleColor ? textStyleColor : 'black'}
      /> */}

      {/* <Dropdown>
          <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
            <FaHeading />
          </Dropdown.Toggle>

          <Dropdown.Menu >
            <Dropdown.Item
              onClick={() =>
                editor.chain().focus().insertContent("<h1></h1>").run()
              }
            >
              Title
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                editor.chain().focus().insertContent("<h3></h3>").run()
              }
            >
              Subtitle
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
    </div>
  );
};
