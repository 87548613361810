import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { useNavigate, useParams } from "react-router-dom"
import { deleteDoc, doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { TEACHER_STATUSES } from "./types";
import { useContext, useEffect } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { useDocumentData } from "react-firebase-hooks/firestore";



const TestTeachNowQueueInner = () => {
    const db = getFirestore()
    const courseId = useCourseId()
    const userId = useUserId()
    const { userData } = useContext(ProfileContext);
    const displayName = userData?.displayName
    const userTeacherStatusRef = doc(db, "teachnow", courseId, "teachers", userId)
    const [teacherStatusData, teacherStatusLoading] = useDocumentData(userTeacherStatusRef)
    const navigate = useNavigate()
    // /teachnow/cip5/teachers/Test
    /*
    * Struct teacherDoc
    * Status = "joined" | "exited"
    * matched: boolean
    * changedStatusAt: timestamp
    */

    const handleLeaveTeachNow = async () => {
        try {
            await deleteDoc(userTeacherStatusRef)
        }
        catch (error) {
            console.error("Error fetching nudgeable students:", error);
        }
    }; 
    

    const handleStartTeachNow = async () => {
        try {
            const teacherDoc = {
                status: TEACHER_STATUSES.JOINED,
                matched: false,
                displayName: displayName,
                teacherJoinedTime: serverTimestamp()
            }
            await setDoc(userTeacherStatusRef, teacherDoc, { merge: true })
            console.log("setting", userId)
        } catch (error) {
            console.error("Error fetching nudgeable students:", error);
        }
    };

    const handleTestNudgeTeachNow = async ({testNudgeId}) => {
        try {
            const teacherDoc = {
                status: TEACHER_STATUSES.TEST_NUDGE,
                matched: false,
                displayName: displayName,
                teacherJoinedTime: serverTimestamp(),
                testNudgeId: testNudgeId
            }
            await setDoc(userTeacherStatusRef, teacherDoc, { merge: true })
            console.log("setting", userId)
        } catch (error) {
            console.error("Error fetching nudgeable students:", error);
        }
    };


    useEffect(() => {
        if (!teacherStatusData || !("status" in teacherStatusData)) { return; }
        const status = teacherStatusData.status
        if(status === TEACHER_STATUSES.MATCHED) {
            if (!("roomId" in teacherStatusData)) { 
                // TODO: navigate to the room
                return; 
            }
            navigate(`/${courseId}/ide/t/teachnow?r=${teacherStatusData.roomId}`)
        }
    }, [teacherStatusData])


    return (
        <div>
          <h1>Test Teach Now</h1>
          <button className="btn btn-primary" onClick={handleStartTeachNow}>Add myself to teacher queue</button>
          <button className="btn btn-secondary" onClick={handleLeaveTeachNow}>Remove myself from teacher queue</button>
        </div>
    );
};


export const TestTeachNowQueue = () => {
    const { courseId } = useParams()
    const firebaseDocPath = `/course/${courseId}/handouts/whatiscarehours`
    return (
        <CoursePageBodyContainer
        mainColumn={<TestTeachNowQueueInner/>}
        rightColumn={<></>}
        singleColumn={<TestTeachNowQueueInner/>}
        />
    )
}