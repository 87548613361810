import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {CourseSidebar} from './sidebar/CourseSidebar';

import {CourseNavbar} from './CourseNavbar';
// import CourseNavbar from "../../components/Navbar"
import {NoAccess} from 'components/errors/NoAccess';
import {Page404} from 'components/errors/Page404';
import {CompletionProvider} from 'contexts/CompletionContext';
import {isMinimumRole, isProfileComplete} from 'contexts/profileUtil';
import {courseMaxWidth} from 'course/constants/courseContants';
import {ConnectionsProvider} from 'course/contexts/ConnectionsContext';
import {LessonsProvider} from 'course/contexts/LessonsContext';
import {RoadmapProvider} from 'course/contexts/RoadmapContext';
import {TrainingProvider} from 'course/contexts/TrainingContext';
import {ProSidebarProvider, useProSidebar} from 'react-pro-sidebar';
import {useWindowSize} from 'react-use-size';
import {getApprovedCourses} from 'utils/general';
import {isProduction} from '../../config';
import {ProfileContext, ProfileProvider} from '../../contexts/ProfileContext';
import {Role} from 'types/role';
import {AssnProvider} from '../contexts/AssnContext';
import {CompleteProfile} from './CompleteProfile';
import {FinalProjectProvider} from 'course/finalproject/portal/FinalProjectContext';
import {LanguageSelectorModal} from 'components/translation/LanguageSelector';
import {useDeveloperToolsContext} from 'contexts/DeveloperToolsContext';
import {DeveloperButtonState} from 'contexts/developerToolsContextUtils';
import { Loading } from 'components/loading/Loading';

/**
 * This is the main wrapper for the course! All subpages will
 * go through this wrapper. This is helpful for several reasons:
 * 1. We can set up the sidebar in just one place
 * 2. The sidebar doesn't re-render when you navigate
 * 3. We can load data that will persist between page changes.
 */

const DESKTOP_BREAKPOINT = 1200;
const SINGLE_COL_BREAKPOINT = 1075;
const SINGLE_COL_COLLAPSE_BREAKPOINT = 800;
const MOBILE_BREAKPOINT = 575;

// later this will be in the server
// export const APPROVED_COURSES = {
//   cip3: Role.STUDENT,
//   test: '',
//   spr23: ''
// }

export const CoursePage = props => {
  const {courseId} = useParams();
  const [approvedCourses, setApprovedCourses] = useState({});
  const [loaded, setLoaded] = useState(false);

  // make sure its a real course
  useEffect(() => {
    const getAc = async () => {
      const ac = await getApprovedCourses();
      setApprovedCourses(ac);
      setLoaded(true);
    };
    getAc();
  }, []);

  if (!(courseId in approvedCourses) && loaded) {
    return <Page404 message={`Unknown url path: ${courseId}`} />;
  }

  if (!loaded) {
    return <Loading />;
  }

  return (
    <div key={courseId}>
      <ProfileProvider>
        <CompletionProvider courseId={courseId}>
          <RoadmapProvider courseId={courseId}>
            <AssnProvider courseId={courseId}>
              <LessonsProvider courseId={courseId}>
                <TrainingProvider courseId={courseId}>
                  <ConnectionsProvider>
                    <FinalProjectProvider>
                      <CoursePageWithContext
                        {...props}
                        approvedCourses={approvedCourses}
                      />
                    </FinalProjectProvider>
                  </ConnectionsProvider>
                </TrainingProvider>
              </LessonsProvider>
            </AssnProvider>
          </RoadmapProvider>
        </CompletionProvider>
      </ProfileProvider>
    </div>
  );
};

const CoursePageWithContext = ({noSidebar, approvedCourses}) => {
  const {courseId} = useParams();
  const navigate = useNavigate();
  const {loading: userIsLoading, userData} = useContext(ProfileContext);
  if (userIsLoading) return <Loading />;

  // does the user have the minimum role?
  const courseRole = userData.courseRole;
  const requiredRole = approvedCourses[courseId];

  if (requiredRole) {
    if (!isMinimumRole(courseRole, requiredRole)) {
      // if user role unassigned, redirect to student application
      if (!courseRole) {
        console.log('redirecting to student application');
        console.log('courseRole', courseRole);
        console.log('requiredRole', requiredRole);
        navigate(`/${courseId}/holding`);
        return <></>;
      }
      // only admins can see the course page for now
      if (isProduction) {
        return <NoAccess />;
      }
    }
    if (courseRole === Role.SECTION_LEADER) {
      // you need to make sure that they have confirmedSectionLeader, this courseId
      // in their user doc
      let confirmed = userData?.confirmedSectionLeader?.[courseId];
      if (!confirmed) {
        navigate(`/public/applyteach/${courseId}`);
        return <></>;
      }
    }
  }

  return (
    <ProSidebarProvider>
      <CourseView />
    </ProSidebarProvider>
  );
};

const CourseView = () => {
  const profileContext = useContext(ProfileContext);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] =
    useState(false);
  const {developerButtonVisibilityState, setDeveloperButtonVisibilityState} =
    useDeveloperToolsContext();
  const isFocusPage = checkFocusPage(location.pathname);
  // Only show the developer button if the page doesn't have the course navbar
  // (i.e. it's a focus page) and the developer button hasn't already been
  // dismissed by the user.
  useEffect(() => {
    // If the page is a focus page, we should show the developer button if it's
    // hidden because the navbar is not shown.
    if (
      isFocusPage &&
      developerButtonVisibilityState === DeveloperButtonState.HIDDEN
    ) {
      setDeveloperButtonVisibilityState(DeveloperButtonState.VISIBLE);
    } else if (
      !isFocusPage &&
      developerButtonVisibilityState === DeveloperButtonState.VISIBLE
    ) {
      // If the page is not a focus page, we should hide the developer button if
      // it's visible because the navbar is shown.
      setDeveloperButtonVisibilityState(DeveloperButtonState.HIDDEN);
    }
  }, [isFocusPage, developerButtonVisibilityState]);

  if (isFocusPage) {
    return <Outlet />;
  }

  if (!isProfileComplete(profileContext)) {
    return <CompleteProfile />;
  }

  return (
    <>
      <CourseNavbar
        setShowLanguageSelectorModal={setShowLanguageSelectorModal}
      />
      <LanguageSelectorModal
        show={showLanguageSelectorModal}
        onHide={() => setShowLanguageSelectorModal(false)}
      />
      <div
        className="d-flex flex-1 justify-content-center"
        style={{width: '100vw', backgroundColor: '#f9f9f9'}}
      >
        <div
          className="flex-1 w-100"
          style={{maxWidth: courseMaxWidth, backgroundColor: 'white'}}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 'calc(100vh - 46px)',
              overflow: 'hidden',
            }}
          >
            <CourseContainer />
          </div>
        </div>
      </div>
    </>
  );
};

const CourseContainer = () => {
  const {collapseSidebar} = useProSidebar();
  const windowSize = useWindowSize();

  useEffect(() => {
    const w = windowSize.width;

    const collapseForTwoCols =
      w > SINGLE_COL_BREAKPOINT && w < DESKTOP_BREAKPOINT;
    const collapseForOneCol =
      w < SINGLE_COL_COLLAPSE_BREAKPOINT && w > MOBILE_BREAKPOINT;
    const isCollapsed = collapseForTwoCols || collapseForOneCol;
    collapseSidebar(isCollapsed);
  }, [windowSize]);
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexGrow: 2,
        overflow: 'auto',
      }}
    >
      <CourseSidebar />
      <main className="courseContentContainer">
        <Outlet />
      </main>
    </div>
  );
};

export function checkFocusPage(pathname: string): boolean {
  // This means that the sidebar and navbar are not shown

  const pathSegments = pathname.split('/').filter(segment => segment !== '');

  const subPage = pathSegments[1].toLowerCase();
  const nSegments = pathSegments.length;

  const fullPageSubPages = [
    'ide',
    'join',
    'applyteach',
    'peer',
    'teacherpuzzles',
    'teachnowtraining',
    'teachnow',
    'teachnowfeedback',
    'whatsapp'
  ];

  if (fullPageSubPages.includes(subPage)) {
    return true;
  }

  if (subPage === 'learn' && nSegments >= 3) {
    return true;
  }
  if (subPage === 'training' && nSegments >= 3) {
    return true;
  }
  if (subPage === 'reviewteachnowstudents' && nSegments >= 3) {
    return true;
  }
  return false;
}
