import {IDEContext} from 'ide/contexts/IDEContext';
import {Canvas} from 'ide/RightPane/Canvas';
import {GraphicsCanvas} from 'ide/RightPane/GraphicsCanvas';
import {TestList} from 'ide/RightPane/TestView';

import {checkIsProjectConsole, checkIsProjectKarel} from 'ide/utils/general';
import {useContext} from 'react';
import {FaRunning, FaWalking} from 'react-icons/fa';

export const VisualOutput = ({}) => {
  const {
    projectData, 
    assnData, 
    setKarelSleepTime, 
    karelSleepTime,
    selectedMobileOutputTab,
  } = useContext(IDEContext);
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);

  return (
    <div
      className={`w-ful ${
        selectedMobileOutputTab == 'Visual' && !isKarel
          ? 'd-block'
          : selectedMobileOutputTab == 'Visual' && isKarel
            ? 'd-flex flex-column gap-4 justify-content-center align-items-center pt-4 overflow-hidden'
            : 'd-none'
      }`}
    >
      {isConsole ? (
        <>
          <TestList />
        </>
      ) : isKarel ? (
        <>
          <Canvas />
          {isKarel ? (
            <div className="d-flex align-items-center content-center gap-2 ml-2 mt-3 w-full">
              <FaWalking />
              <input
                id="stepslider"
                min={0}
                max={1}
                step={0.1}
                type="range"
                aria-label="Steps Slider"
                onChange={e => {
                  setKarelSleepTime(1 - Number(e.target.value));
                }}
                value={1 - karelSleepTime}
              ></input>

              <FaRunning />
            </div>
          ) : null}
        </>
      ) : (
        <GraphicsCanvas />
      )}
    </div>
  );
};
