import {IDEContext} from 'ide/contexts/IDEContext';
import {checkIsProjectConsole, checkIsProjectKarel} from 'ide/utils/general';
import {useContext} from 'react';

export const MobileOutputTabs = ({}) => {
  const {
    projectData, 
    assnData,
    selectedMobileOutputTab,
    setSelectedMobileOutputTab
  } = useContext(IDEContext);
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);

  return (
    <div className="d-flex flex-row">
      <span
        style={{
          background: selectedMobileOutputTab == 'Visual' ? '' : '#eeeeee',
        }}
        className={`flex-grow-1 h-1 p-3 btn`}
        onClick={() => {
          setSelectedMobileOutputTab('Visual');
        }}
      >
        {isConsole ? 'Unit Tests' : isKarel ? 'Karel World' : 'Canvas'}
      </span>
      <span
        style={{
          background: selectedMobileOutputTab == 'Console' ? '' : '#eeeeee',
        }}
        className={`flex-grow-1 h-1 p-3 btn`}
        onClick={() => {
          setSelectedMobileOutputTab('Console');
        }}
      >
        Console
      </span>
    </div>
  );
};
