import styled from 'styled-components';

// Our loading “IDE” component
export const IDELoading = () => {
  // div that fills page
  return (
    <div style={{height: '100vh', width:'100vw', position: 'relative'}}>
      {/* top bar with logo and buttons */}
      <nav
        className="navbar navbar-expand-lg "
        style={{
          height: '60px',
          backgroundColor: 'rgb(208, 224, 255)',
        }}
      >
        <div className="d-flex justify-content-around w-100">
          <SkeletonLine width="200px" />
          <SkeletonLine width="100px" />
        </div>
      </nav>
      {/*  left col with file tree */}

      <div className="d-flex" style={{height: 'calc(100vh - 60px)'}}>
        <div style={{borderRight: '1px solid #e2e2e2', width: '50px'}}>
          <SkeletonBlock height="100%" backgroundColor="#cbc3e3" />
        </div>
        <div className="col-3" style={{borderRight: '1px solid #e2e2e2'}}>
          <SkeletonBlock height="100%" backgroundColor="white">
            <SkeletonLine width="200px" />
            <SkeletonLine width="100px" />
          </SkeletonBlock>
        </div>
        <div className="col-5">
          <SkeletonBlock height="100%" backgroundColor="white">
            <div style={{padding: '1em'}}>
              <SkeletonLine width="50px" backgroundColor="rgb(46, 114, 244)" />
            </div>
            <SkeletonLine width="100px" />
            <SkeletonLine width="300px" />
            <SkeletonLine width="100px" />
            <SkeletonLine width="200px" />
            <SkeletonLine width="150px" />
            {/*  spacer */}
            <div style={{height: '20px'}} />
            <SkeletonLine width="400px" />
            <SkeletonLine width="300px" />
            <SkeletonLine width="200px" />
            <SkeletonLine width="100px" />
            <SkeletonLine width="150px" />
          </SkeletonBlock>
        </div>
        <div className="col-3" style={{borderLeft: '1px solid #e2e2e2'}}>
          <SkeletonBlock height="100%" backgroundColor="white">
            <div
              style={{
                padding: '1em',
                margin: '1em',
                backgroundColor: '#e2e2e2',
                borderRadius: '4px',
                width: '80%',
                height: '200px',
              }}
            />
          </SkeletonBlock>
        </div>
      </div>
    </div>
  );
};

interface SkeletonLineProps {
  width?: string;
  backgroundColor?: string;
}

interface SkeletonBlockProps {
  height?: string;
  backgroundColor?: string;
}

// Reusable skeleton line
const SkeletonLine = styled.div<SkeletonLineProps>`
  background-color: ${props => props.backgroundColor || '#e2e2e2'};
  height: 1em;
  margin: 0.5em;
  width: ${props => props.width || '100%'};
  border-radius: 4px;
  padding: 0.5em;
`;

// A skeleton block for code area, console, etc.
const SkeletonBlock = styled.div<SkeletonBlockProps>`
  background-color: ${props => props.backgroundColor || '#e2e2e2'};
  border-radius: 4px;
  width: 100%;
  height: ${props => props.height || '200px'};
  margin-bottom: 1em;
`;
