import {getCornerSize, getCornerX, getCornerY, makeWallStr} from '../util';
import {deepCopy} from '@firebase/util';

export const EditableWalls = ({state, setWorldState, isEditable}) => {
  const worldState = state.worldState;
  var walls = [];
  for (let r = 0; r < worldState.nRows; r++) {
    for (let c = 0; c < worldState.nCols; c++) {
      const northExists = hasWall(worldState, r, c, 'North');
      if (r > 0) {
        walls.push(
          <EditableNorthWall
            key={`${r}-${c}-north`}
            r={r}
            c={c}
            state={state}
            exists={northExists}
            toggleWall={(e, w) =>
              isEditable ? toggleWall(e, w, setWorldState) : null
            }
          />,
        );
      }
      const eastExists = hasWall(worldState, r, c, 'East');
      if (c < worldState.nCols - 1) {
        walls.push(
          <EditableEastWall
            key={`${r}-${c}-east`}
            r={r}
            c={c}
            state={state}
            exists={eastExists}
            toggleWall={(e, w) =>
              isEditable ? toggleWall(e, w, setWorldState) : null
            }
          />,
        );
      }
    }
  }
  return <div>{walls}</div>;
};

function toggleWall(e, wall, setWorldState) {
  const wallString = makeWallStr(wall.r, wall.c, wall.d);
  setWorldState(oldWorldState => {
    const newWorldState = deepCopy(oldWorldState);
    // careful about getting the new beepers state
    if (!('walls' in newWorldState)) {
      newWorldState['walls'] = {};
    }
    const walls = newWorldState['walls'];
    const oldValue = wallString in walls ? walls[wallString] : false;
    const newValue = !oldValue;
    walls[wallString] = newValue;
    return newWorldState;
  });
  e.stopPropagation();
}

const EditableNorthWall = ({toggleWall, r, c, state, exists}) => {
  const height = getWallThickness(state);
  let x = getCornerX(state, r, c) - 1;
  let y = getCornerY(state, r, c) - 3;
  const selected = exists ? '-selected' : '';
  return (
    <div
      onClick={e => toggleWall(e, {r, c, d: 'North'})}
      className={'karel-editable-wall' + selected}
      style={{
        marginLeft: x,
        marginTop: y,
        width: getCornerSize(state) - 2,
        height,
      }}
    ></div>
  );
};

const getWallThickness = (state) => {
  const cornerSize = getCornerSize(state);
  return cornerSize > 20 ? 4 : 2;
}

const EditableEastWall = ({toggleWall, r, c, state, exists}) => {
  const cornerSize = getCornerSize(state);
  let x = getCornerX(state, r, c + 1) - 3;
  let y = getCornerY(state, r, c) - 1;
  const width = getWallThickness(state);
  const selected = exists ? '-selected' : '';
  return (
    <div
      onClick={e => toggleWall(e, {r, c, d: 'East'})}
      className={'karel-editable-wall' + selected}
      style={{
        marginLeft: x,
        marginTop: y,
        width,
        height: getCornerSize(state) - 2,
      }}
    ></div>
  );
};

function hasWall(worldState, r, c, d) {
  if (!worldState.walls) {
    return false;
  }
  const wallStr = makeWallStr(r, c, d);
  if (!(wallStr in worldState.walls)) {
    return false;
  }
  return worldState.walls[wallStr];
}
