import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/functions";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./config";

const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const database = firebase.database();
const functions = firebase.app().functions("us-central1");
const auth = firebase.auth;
const analytics = getAnalytics(app);

export { firestore, database, auth, functions, analytics };
