import React, { useContext, useState, useEffect } from "react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import {
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { getApp } from "firebase/app";
import { ProfileContext } from "contexts/ProfileContext";
import { CourseContext } from "contexts/CourseContext";
import { useCourseId } from "hooks/router/useUrlParams";
import {
  getLocalUTCTimezone,
  formateDateWithSuffix,
  getTimeUntil,
} from "components/timezones/timezoneHelper";
import Alert from 'react-bootstrap/Alert';
import { useUserId } from "hooks/user/useUserId";



const getTime = (date) => {
    const hour = date.getHours()
    const minute = date.getMinutes().toString().padStart(2, '0')
    // get am/pm
    const ampm = hour >= 12 ? 'pm' : 'am'

    // convert to 12 hour time
    const hour12 = hour % 12 || 12
    return `${hour12}:${minute} ${ampm}`

}

export const NextSectionWidget = () =>{
    const { userData } = useContext(ProfileContext)
    const { nCourseWeeks, firstSectionTimestamp, getNextSectionDate, getNextSectionIndex } = useContext(CourseContext)
    const courseId = useCourseId()
    const sectionRef = userData?.sections[courseId] ? userData?.sections[courseId][0] : null// NOTE WE ASSUME ONE SECTION PER USER HERE!
    if (!sectionRef) {
        // no section data
        return <></>
    }

    const db = getFirestore(getApp());
    const [sectionData, sectionDataIsLoading, sectionDataError] = useDocumentDataOnce(sectionRef);

    const [timeUntil, setTimeUntil] = useState('')
    const [sectionNumber, setSectionNumber] = useState(-1)
    const [dateWithSuffix, setDateWithSuffix] = useState('')
    const [timeOfDay, setTimeOfDay] = useState('')

    useEffect(() => {
        if (!sectionData || !firstSectionTimestamp) {
            return
        }
        const userTz = getLocalUTCTimezone();
        const nextSectionDate = getNextSectionDate(userTz, sectionData.timeIndex);
        if (isNaN(nextSectionDate.getTime())) {
            return
        }
        setSectionNumber(getNextSectionIndex(userTz, sectionData.timeIndex) + 1)
        setDateWithSuffix(formateDateWithSuffix(nextSectionDate))
        setTimeUntil(getTimeUntil(nextSectionDate))
        setTimeOfDay(getTime(nextSectionDate))
    }, [firstSectionTimestamp, sectionData]);   

    if (sectionDataIsLoading || !firstSectionTimestamp) {
        return <div>Loading...</div>
    }
    if (sectionDataError) {
        return <div>Error</div>
    }
    if (!sectionData ) {
        return <div>No section data</div>
    }

    if(!dateWithSuffix) {
        return <></>
    }

    if (sectionNumber > nCourseWeeks) {
        return <></>
    }


    return <Alert variant={'info'}> Next <strong>Section</strong> {timeUntil}, on {dateWithSuffix} at {timeOfDay}</Alert> 
}



export const NextTrainingWidget = () =>{
    const userId = useUserId()
    const courseId = useCourseId()
    const db = getFirestore()
    const docRef = doc(db, "users", userId, courseId, "slonboarding");
    const [onboardingData, onboardingDataIsLoading, onboardingDataError] = useDocumentDataOnce(docRef);
    if (onboardingDataIsLoading) {
        return <></>
    }


    if (onboardingDataError) {
        return <></>
    }

    const trainingTime = onboardingData?.trainingTime

    if (!trainingTime) {
        return <></>
    }

    // get days until training
    const today = new Date()
    const trainingDate = new Date(trainingTime)
    if (isNaN(trainingDate.getTime())) {
        return
    }
    const trainingDate2 = new Date(trainingTime)
    const trainingTimeWithSuffix = formateDateWithSuffix(trainingDate)
    const trainingTimeWithSuffix2 = formateDateWithSuffix(trainingDate2)


    // week after trainingTime
    trainingDate2.setDate(trainingDate2.getDate() + 7)

    const diffTime = trainingDate.getTime() - today.getTime()
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
    const diffTime2 = trainingDate2.getTime() - today.getTime()
    const diffDays2 = Math.round(diffTime2 / (1000 * 60 * 60 * 24))
    const diffDaysTime = getTime(trainingDate)
    const diffDaysTime2 = getTime(trainingDate2)

    console.log(diffDays, diffDays2, diffDaysTime, diffDaysTime2)

    if (diffDays <= 0 && diffDays2 <= 0) {
        return <></>
    }

    if (diffDays <= 0) {
        return <Alert variant={'danger'}> Next <strong>Live Section Practice</strong> in {diffDays2} days, on {trainingTimeWithSuffix2} at {getTimeUntil(trainingDate2)}</Alert>
    }

    return <Alert variant={'danger'}> Next <strong>Live Section Practice</strong> in {diffDays} days, on {trainingTimeWithSuffix} {getTimeUntil(trainingDate)}</Alert>

}


export const NextOrientationWidget = () => {
    const orientation = "2024-04-19T04:00:00.000Z"
    const altOrientation = "2024-04-19T16:00:00.000Z"
    const orientationDate = new Date(orientation)
    const altOrientationDate = new Date(altOrientation)
    const courseId = useCourseId()

    if(courseId !== "cip4") {
        return <></>
    }


    const today = new Date()

    const diffTime = orientationDate.getTime() - today.getTime()

    const diffTimeAlt = altOrientationDate.getTime() - today.getTime()

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const diffDaysTime = getTime(orientationDate)
    const diffDaysAlt = Math.ceil(diffTimeAlt / (1000 * 60 * 60 * 24))
    const diffDaysTimeAlt = getTime(altOrientationDate)


    if (diffDays < 0 && diffDaysAlt < 0) {
        return <></>
    }

    const orientationWithSuffix = formateDateWithSuffix(orientationDate)
    const orientationTimeString = `${orientationDate.getHours()}:${orientationDate.getMinutes()} `
    const altOrientationWithSuffix = formateDateWithSuffix(altOrientationDate)

    return <Alert variant={'warning'}> Next <strong>All Section Leader Meeting</strong> in {diffDays} days, on {orientationWithSuffix} at {diffDaysTime} (Alternative orientation in {diffDaysAlt} days, on {altOrientationWithSuffix} at {diffDaysTimeAlt})</Alert>





}