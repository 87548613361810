import {Card} from 'react-bootstrap';

import {useStudentAppAboutStructure} from './AboutForm';
import {FormixFormWithData} from 'components/Forms/FormixFormWithData';
import {useParams} from 'react-router';
import { Loading } from 'components/loading/Loading';

export const AboutWithData = ({aboutData, setAboutData}) => {
  const {targetCourseId} = useParams();
  const backUrl = `/public/applyteach/${targetCourseId}`;
  const {formItems: studentAppAboutStructure, loading} =
    useStudentAppAboutStructure(targetCourseId);

  if (loading) {
    return <Loading/>;
  }

  return (
    <>
      <Card style={{margin: 'auto', padding: '20px'}}>
        <h1>Tell us about yourself</h1>
        <p>
          We want to get to know you better; tell us a little about yourself! We
          will use the answers in this form to figure out your background and
          determine whether this class is a good fit for you.
        </p>
        <div>
          <FormixFormWithData
            serverData={aboutData}
            backUrl={backUrl}
            formFormat={studentAppAboutStructure}
            onSubmit={(e, cb) => setAboutData(e)}
          />
        </div>
      </Card>
    </>
  );
};
