import {useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Link} from 'react-router-dom';
import {AssnMetadataType} from '../../contexts/AssnContext';
import {FaEdit, FaTrash} from 'react-icons/fa';
import {Button} from 'react-bootstrap';
import Swal from 'sweetalert2';
import {deleteAssignment} from './AssignmentEditor';

export const AssignmentsTable = ({courseId, assnMetaData}) => {
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const columns = [
    {dataField: 'assnId', text: 'ID', sort: true},
    {dataField: 'title', text: 'Title', sort: true},
    {dataField: 'type', text: 'Type', sort: true},
    {dataField: 'editButton', text: 'Edit', sort: false},
    {dataField: 'deleteButton', text: 'Delete', sort: false},
  ];

  const deleteCallback = () => {
    setUpdateTrigger(updateTrigger + 1);
    Swal.fire('Success!', 'Assignment deleted.', 'success');
  };

  const onDelete = async (courseId, assnId) => {
    Swal.fire({
      title:
        'Are you sure you want to delete the assignment with ID ' +
        assnId +
        '?',
      text: 'This action is irreversible!',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete this assignment!',
      icon: 'warning',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteAssignment(courseId, assnId, deleteCallback);
        } catch (e) {
          Swal.fire('Oh no...', 'Something went wrong!', e.message);
        }
      }
    });
  };

  const data = Object.entries(assnMetaData).map(
    ([assnId, assnData]: [string, AssnMetadataType]) => {
      const editLink = `/${courseId}/assnEditor/${assnId}`;
      return {
        assnId: assnId,
        title: assnData.title,
        type: assnData.type,
        editButton: (
          <Button variant="primary">
            <Link to={editLink} className="text-white">
              <FaEdit />
            </Link>
          </Button>
        ),
        deleteButton: (
          <>
            <Button
              onClick={() => {
                onDelete(courseId, assnId);
              }}
              variant="danger"
              className="text-white"
            >
              <FaTrash className="" />
            </Button>
          </>
        ),
      };
    },
  );

  return (
    <>
      <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">
        All Assignments
      </div>
      <BootstrapTable
        key={updateTrigger}
        data={data}
        columns={columns}
        bordered={false}
        striped={true}
        hover={true}
        bootstrap4={true}
        keyField="assnId"
        wrapperClasses="table-wrap"
        headerWrapperClasses="table-head"
      />
    </>
  );
};
