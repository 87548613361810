import React, {ReactNode} from 'react';
import {ErrorPage} from './ErrorPage';

/**
 * The message to display on the 404 error page.
 */
interface Page404Props {
  /**
   * The message to display on the 404 error page.
   */
  message?: string | React.ReactNode;
}

/**
 * A component that displays a 404 error page.
 */
export const Page404 = ({message}: Page404Props) => {
  const displayedMessage: ReactNode = (
    <>
      <p>
        {' '}
        Oops! You hit a{' '}
        <a
          href="https://en.wikipedia.org/wiki/HTTP_404"
          target="_blank"
          rel="noopener noreferrer"
        >
          404 error
        </a>
        .
      </p>
      {message}
    </>
  );
  return <ErrorPage title="404: Page not found" msg={displayedMessage} />;
};

export default Page404;
