import {useIsMobile} from 'utils/general';

export const getAnywhereOnEarthDateTextFromISO = (isoDate: string): string => {
  const isMobile = useIsMobile();

  const date = new Date(isoDate);

  const shortLong = isMobile ? 'short' : 'long';

  // get the date in Anywhere on Earth time
  const aoeDate = new Date(date.setHours(date.getHours() - 11));
  return (
    aoeDate.toLocaleDateString('en-US', {
      weekday: shortLong,
      year: 'numeric',
      month: shortLong,
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    }) +
    ' ' +
    timezoneMatcher(isoDate)
  );
};

export const timezoneMatcher = (date: string): string => {
  // get last 5 characters of date
  const timezone = date.slice(-6);

  // if they match the pattern +/=##:##, return the timezone
  if (timezone.match(/[+-]\d{2}:\d{2}/)) {
    switch (timezone) {
      case '-11:00':
        return '';
      case '-07:00':
        return 'PDT';
      case '-08:00':
        return 'PST';
      case '-04:00':
        return 'EDT';
      case '-05:00':
        return 'EST';
      default:
        return timezone;
    }
  }
};
