import { useLoadingContext } from "contexts/LoadingContext";
import { useEffect } from "react";
import { IDELoading } from "./IdeLoading";
import { StandardLoading } from "./StandardLoading";
import VisuallyHidden from "components/VisuallyHidden";
import { KarelSpinningAnimation } from "./KarelSpinningAnimation";

export function Loading() {
  const { showLoading, hideLoading } = useLoadingContext();

  useEffect(() => {
    showLoading();

    // Clean up when unmounted => hide the loading
    return () => {
      hideLoading();
    };
    // Intentionally only run once on mount/unmount
  }, [showLoading, hideLoading]);

  // We render nothing, because the actual spinner is in the global overlay.
  return null;
}

export const PartialLoading = () => {
  return (
    <>
      <VisuallyHidden>Loading...</VisuallyHidden>
      <KarelSpinningAnimation scale={0.5} />
    </>
  );
};

export const LoadingComponent = () => {
  const isIde = window.location.pathname.includes('ide');

  // If IDE mode, render the IDE skeleton/spinner
  if (isIde) {
    return <IDELoading />;
  }

  return <StandardLoading />
}