import KarelVM from "./karel/KarelVM.js";
import KarelPythonParser from "./karel/KarelPythonParser.js";
import KarelAstParser from "./karel/KarelAstParser.js";
import { ReturnIns } from "./vm/VM.js";
/**
 * Class: KarelCompiledEngine
 * --------------------------
 * This class is in charge of compiling a piece of Karel
 * code into some abstraction such this it can execute
 * the program one step at a time. Implements the same
 * interface as the karelEvalEngine.
 */

/**
 * Warning: Dec 2019
 * When porting Karel to React I implemented the world using
 * the react "state" model. This made "stepping" truly complex.
 * to avoid changing the underlying code too much I implemented
 * a work around. The step function doesn't call its callback
 * until both: the state has been updated, and the compiler has
 * finished doing its thing. There is likely a more elegant
 * solution. To execute this properly with methods (think supporting
 * recursion) we are still going to need some version of call stack
 */
class KarelPythonCompiler {
  constructor(karel) {
    this.vm = new KarelVM(karel);
  }

  stop() {
    if (this.stepTimeout != null) {
      clearTimeout(this.stepTimeout);
    }
  }

  // public: run this to compile a string to be executed
  compile(text) {
    try {
      this._compileHelper(text);
      return {
        status: "success",
      };
    } catch (e) {
      return {
        status: "error",
        error: e,
      };
    }
  }

  // public: run this to execute the next step. Works
  // with a callbackFn which will be called when the step
  // is done. The callbackFn will be told if the program
  // is finished and what line number was just executed.
  executeStep(callbackFn, errorCallbackFn) {
    // we need to keep track of the callback function
    this.callbackFn = callbackFn;
    this.errorCallbackFn = errorCallbackFn;
    this.isEngineFinished = false;
    if (!this.vm.cf) {
      this.callbackFn({ isDone: true });
    } else {
      // first, assume this you haven't changed world state
      this.vm.changedWorld = false;
      var running = true;
      while (running) {
        if (this.vm.atStatementBoundary()) running = false;
        // make the vm step
        this.vm.step();
      }
      this.isEngineFinished = true;
      // at this point there are two cases. If you didn't
      // change the world, then you can directly call the callback
      if (!this.vm.changedWorld) {
        this.callbackFn({
          isDone: false,
          lineNumber: this.vm.getCurrLineNum(),
        });
      }
      // if you made a change to the world, you have to
      // wait for the callback. Programmers of KarelWorld
      // are responsible for calling the callback.
    }
  }

  onKarelError() {
    this.errorCallbackFn();
  }

  // if the world "state" is changed, then it will call this function
  // once react has finished with the state update.
  worldStepFinished() {
    // to avoid a deadlock condition, you must busy wait
    // if the engine hasn't finished
    if (this.isEngineFinished) {
      this.callbackFn({
        isDone: false,
        lineNumber: this.vm.getCurrLineNum(),
      });
    } else {
      this.stepTimeout = setTimeout(() => this.worldStepFinished(), 100); // TODO: Create way to cancel this when necessary
    }
  }

  _compileHelper(text) {
    this.vm.resetTempCounter();
    var parser = new KarelPythonParser();
    parser.setInput(text);
    parser.readImport();
    var karelClass = parser.readClass();
    // var baseClass = karelClass[2];
    var functionMap = karelClass[3];
    var hasBoilerplate = karelClass[4];

    var functions = [];
    var functionNames = [];
    for (var fnName in functionMap) {
      var fn = functionMap[fnName];
      functionNames.push(fnName);
      functions.push(fn);
    }

    // populate this.vm.functions which is a map between
    // function names and a simple code stack

    this.vm.setUserFnNames(functionNames);
    for (var i = 0; i < functions.length; i++) {
      var fn = functions[i];
      var code = [];
      this.vm.compile(fn[2], code);
      code.push(new ReturnIns());
      this.vm.functions[fn[1]] = code;
    }
    this.vm.reset();
    this.vm.startCheck();

    // if there is no boilerplate, then you need to raise an error
    if (!hasBoilerplate) {
      const lastLineNumber = text.split("\n").length - 1;
      let e = {
        msg: "You are missing the boilerplate code\n\rif __name__ == '__main__':\n\r    main()",
        lineNumber: lastLineNumber,
      };
      throw new Error(JSON.stringify(e));
    }

    return functions;
  }
}

export default KarelPythonCompiler;
