import {DeveloperToolsBadge} from 'components/developerTools/DeveloperToolsBadge';
import {DeveloperToolsModal} from 'components/developerTools/DeveloperToolsModal';
import {useCourseIdWithoutFallback} from 'hooks/router/useUrlParams';
import {isDevelopmentEnvironment} from 'utils/general';
import {
  DeveloperButtonState,
  DeveloperToolsContextType,
} from './developerToolsContextUtils';
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';

/**
 * Default context data for the DeveloperToolsContext.
 */
const DEFAULT_CONTEXT_DATA: DeveloperToolsContextType = {
  developerButtonVisibilityState: DeveloperButtonState.HIDDEN,
  setDeveloperButtonVisibilityState: () => {},
  shouldShowModal: false,
  setShouldShowModal: () => {},
};

/**
 * The DeveloperToolsContext is used to provide data about developer tool visibility,
 * including the developer button's state and the developer tools modal's visibility.
 */
const DeveloperToolsContext =
  createContext<DeveloperToolsContextType>(DEFAULT_CONTEXT_DATA);

/**
 * DeveloperToolsProvider is a React Context Provider that manages developer
 * tool states in a development environment. In production environments,
 * it simply renders its children without additional functionality.
 *
 * @param props - Props for the provider
 * @param props.children - The child nodes to render
 * @returns A JSX element containing the provider and any children, with developer tools
 * conditionally appended if in a development environment.
 */
export const DeveloperToolsProvider = ({children}: {children: ReactNode}) => {
  const courseId = useCourseIdWithoutFallback();
  const [developerButtonVisibilityState, setDeveloperButtonVisibilityState] =
    useState<DeveloperButtonState>(DeveloperButtonState.HIDDEN);
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

  if (!isDevelopmentEnvironment()) {
    return <>{children}</>;
  }

  const contextData: DeveloperToolsContextType = {
    developerButtonVisibilityState,
    setDeveloperButtonVisibilityState,
    shouldShowModal,
    setShouldShowModal,
  };

  return (
    <DeveloperToolsContext.Provider value={contextData}>
      {children}
      {/* <DeveloperToolsBadge developerToolsContext={contextData} /> */}
      {/* In the development environment, the developer tools modal must always
      be available. */}
      {/* <DeveloperToolsModal /> */}
    </DeveloperToolsContext.Provider>
  );
};

/**
 * A hook that reveals the developer button if it is hidden. If the developer
 * button's state is not hidden, this function does nothing.
 */
export function useShowDeveloperButtonIfHidden() {
  const {developerButtonVisibilityState, setDeveloperButtonVisibilityState} =
    useDeveloperToolsContext() ?? {
      developerButtonVisibilityState: null,
      setDeveloperButtonVisibilityState: () => {},
    };

  useEffect(() => {
    if (
      !developerButtonVisibilityState ||
      developerButtonVisibilityState !== DeveloperButtonState.HIDDEN
    ) {
      return;
    }
    setDeveloperButtonVisibilityState(DeveloperButtonState.VISIBLE);
  }, [developerButtonVisibilityState]);
}

/**
 * A custom hook to retrieve the DeveloperToolsContext's value, containing
 * the state of the developer button's visibility and the developer tools
 * modal's visibility, along with functions to update them.
 *
 * @returns The current developer tools context value.
 */
export const useDeveloperToolsContext = (): DeveloperToolsContextType => {
  return useContext(DeveloperToolsContext);
};
