const PYODIDE_BUILD = '/pyodide/build/';

export const BLOCKLISTED_IMPORTS = new Set(['js', 'webbrowser', 'micropip', 'pyodide', 'importlib']);

/** Start setup for Pyodide autograder */

// Primary setup script. Sets up variables and functions available to grading scripts
const AUTOGRADE_SETUP = `
# **** Variables to track state between input and output callbacks ****
# Running history of stdouts
__autograde_outputs = []

# For autograde scripts to store data that must be persisted between
# calls to grade_input and grade_output
__autograde_aux_data = {} 

# Relevant if using the autograder for randomness:
# Variables that have been registered to have their distributions checked over many iterations.
# At the end of the script, these variables are transfered to a JS object that accumulates
# their observed values for every iteration.
# Format:
# {
#   "var_name": {
#     "dist": "uniform", (more support coming!)
#     "params": {...}, (depends on distribution, {"min": int, "max": int} for "uniform")
#     "outputs": [] (the values seen for this var in this program),
#     "error_str": ""
#   }
# }
__autograde_registered_vars = {}

# Stores the error state for the autograder
__autograde_error_state = {
  "has_error": False
}

# **** @joshdelg Functions exposed to autograding scripts ****

# Inovked by autograding script to update the error state when its clear a student has failed the test.
# Updates the error state ONLY ONCE. This prevents a meaningful error from being replaced by a Pyodide/Unthrow
# exception that was caused by the meaningful one.

def autograde_error(message):
  if not __autograde_error_state["has_error"]:
    __autograde_error_state["has_error"] = True
    __autograde_error_state["message"] = message

    raise Exception(f"Autograder detected failed test: {message}")


# Invoked by autograding script to:
# 1) Define a random variable that we will check the distribution of
# 2) Add a particular value to the observed values for a variable.
# 3) Handle Early Stopping: if the observed value is illegal for the variable type
# (i.e. min is 10 and we see 5), an exception is raised and testing stops.
# Right now, only the "uniform" distribution is implemented.

def register_random(rv, var_name, dist=None, params=None, error_str=None):
  # If registering for the first time, add to dict. Otherwise, just add value to outputs
  if var_name not in __autograde_registered_vars:
    if dist is None and params is None:
      raise Exception("If adding new variable, must provide distribution and parameters")
    
    __autograde_registered_vars[var_name] = {
      "dist": dist, "params": params, "outputs": [rv], "error_str": error_str
    }
  
  else:
    __autograde_registered_vars[var_name]["outputs"].append(rv)

  # Early stopping on illegal values
  cur_var = __autograde_registered_vars[var_name]

  if cur_var["dist"] == "uniform":
    if rv < cur_var["params"]["min"]:
      autograde_error(f"Expected variable to have minimum {cur_var['params']['min']} but found {rv}")
    
    if rv > cur_var["params"]["max"]:
      autograde_error(f"Expected variable to have maximum {cur_var['params']['max']} but found {rv}")


# **** Additional unthrow handler to run autograde script on stdout ****

# Override print function to call handler (only in test mode. \`*stdout\` because print takes varargs.)
if unthrow.test_mode:
  print=lambda *stdout : cip___throwOutput(stdout=stdout)

# Output handler that:
# 1) Runs output side of random autograding script through \`grade_output\` (modifies __autograde_aux_data).
# 2) Appends output to __autograde_outputs
# 3) Call the normal print function
# 4) OPTIONALLY yield to JS but only to view aux data/outpus for debugging (because its really slow). 
def cip___throwOutput(stdout):
    global __unthrowActiveCommand__

    # Join potential varargs into one string (empty goes to "")
    out = " ".join([str(s) for s in stdout])
    __autograde_outputs.append(out)
    
    # Side Effect: modifies __autograde_aux_data
    grade_output(__autograde_outputs, __autograde_aux_data)
    
    __builtin__.print(*stdout)
    
    # Set to True and print out \`data\` in unthrow handler to debug. Not necessary for autograder. And slow.
    if False:
      __unthrowActiveCommand__["cmd"] = "output"
      __unthrowActiveCommand__["data"] = {
        "output_vars": __autograde_aux_data,
        "outputs": __autograde_outputs
      }
      unthrow.stop("output")

    __unthrowActiveCommand__ = dict()
`;

// Small modification to Unthrow throwInput handler to run the grade_input script.
const AUTOGRADE_INPUT_INJECTION = `
    # **** Modification to throwInput handler for Pyodide Autograder ****
    # Instead of yielding to JS for input, compute it from our grade_input function.
    # If nothing returning, fall through to JS
    
    dynamic_input = grade_input(__autograde_outputs, __autograde_aux_data)
    if dynamic_input:
      if not isinstance(dynamic_input, str):
        raise Exception(f"grade_input must return a str or None! Instead, got {dynamic_input}, {type(dynamic_input)}")

      return dynamic_input
`;

/** End setup for Pyodide autograder. */

/**
 * Builds the sandbox for the Python code that runs in the Pyodide environment.
 * @param stepMode - Whether to run in step mode.
 * @param testMode - Whether to run in test mode.
 * @param uninterrupted - The number of uninterrupted steps before
 * escaping via unthrow.
 * @param inputSize - The size of the input.
 * @param isAdvancedAutograder - Whether to setup the advanced
 * autograder's functionality.
 * @param gradingScripts - The advanced autograder scripts.
 * @param projectId - The project ID.
 * @param courseId - The course ID.
 * @returns {string} - The setup script.
 */
const SETUP_SCRIPT = (
  stepMode,
  testMode,
  uninterrupted,
  inputSize = 0,
  isAdvancedAutograder,
  gradingScripts = null,
  projectId,
  courseId,
) => {
  return `
from __future__ import print_function
try:
    import __builtin__
except ImportError:
    # Python 3
    import builtins as __builtin__
import time
import sys
finished = False
import unthrow
import pyodide
import js
____step_freq = ${uninterrupted}
unthrow.step_mode = ${stepMode ? 'True' : 'False'}
unthrow.test_mode = ${testMode ? 'True' : 'False'}
unthrow.term_log = []
unthrow.max_lines = 100000
unthrow.step_list = []
unthrow.max_sl_size = 1000
unthrow.rec_depth = 800
js.projectId = "${projectId}"
js.courseId = "${courseId}"
time.sleep=lambda seconds: cip___throwSleep(seconds)
input=lambda printMessage="" : cip___throwInput(printMessage=printMessage)
exec=lambda code="" : cip___exec(code=code)
eval=lambda code="" : cip___eval(code=code)
__import__=lambda name, globals=None, locals=None, fromlist=(), level=0: cip____import__(name=name, globals=globals, locals=locals, fromlist=fromlist, level=level)

__input_ctr_test__ = 0
__input_sze_test__ = ${inputSize}
__resumer=unthrow.Resumer()
__resumer.set_interrupt_frequency(____step_freq);
__unthrowActiveCommand__ = dict()
____unthrowActiveInput = ''
def cip___awaitclick():
    global __unthrowActiveCommand__
    if unthrow.test_mode:
        return 0
    __unthrowActiveCommand__["cmd"] = "awaitclick"
    unthrow.stop("awaitclick")
    __unthrowActiveCommand__ = dict()
def cip___noai_chat(request):
    """Allows the user to call the NotOpenAI API from the main script. 
    """ 
    global __unthrowActiveCommand__
    __unthrowActiveCommand__["cmd"] = "noai_chat"
    # N.b. Pyodide converts the Python dict into a Node Map.
    __unthrowActiveCommand__["data"] = request
    unthrow.stop("noai_chat")
    # Once we resume, the response should be set in the global \`jsNotOpenAi.response\`
    # or an error should be set in \`jsNotOpenAi.error\`
    __unthrowActiveCommand__ = dict()
    if js.jsNotOpenAi.error:
        raise js.jsNotOpenAi.error
    return js.jsNotOpenAi.response
def cip___throwSleep(seconds):
    global __unthrowActiveCommand__
    if unthrow.test_mode:
        return 0
    __unthrowActiveCommand__["cmd"] = "sleep"
    __unthrowActiveCommand__["data"] = seconds
    unthrow.stop("sleep")
    __unthrowActiveCommand__ = dict()
def cip___throwInput(printMessage):
    # Pyodide autograder adds a cip__throwOutput exception to run
    # the grade_output autograding script. We must call \`print\` before throwing
    # input exception so we can first throw output exception.

    print(printMessage)

    # Interpolate the modification to input exception needed for autograder (if we
    # provided autograding scripts). Returns, will not run rest of this function!
    ${isAdvancedAutograder ? AUTOGRADE_INPUT_INJECTION : ''}

    global __unthrowActiveCommand__
    __unthrowActiveCommand__["cmd"] = "input"
    __unthrowActiveCommand__["data"] = printMessage
    
    if unthrow.test_mode:
        global __input_sze_test__, __input_ctr_test__
        if  __input_ctr_test__ >= __input_sze_test__:
            return ""
        __input_ctr_test__+=1
    unthrow.stop("input")
    __unthrowActiveCommand__ = dict()
    return ____unthrowActiveInput
def cip___exec(code):
    raise Exception("The exec() function is not supported")
def cip___eval(code):
    raise Exception("The eval() function is not supported")
def cip____import__(name, globals=None, locals=None, fromlist=(), level=0):
    raise Exception("The __import__() function is not supported")

# Bind the functions to the unthrow object so they can be called by library functions
unthrow.cip___awaitclick = cip___awaitclick
unthrow.cip___noai_chat = cip___noai_chat

# **** @joshdelg BEGIN PYODIDE AUTOGRADER FUNCTIONALITY ****

# grade_input runs every time the student's code calls "input". Inside the function, you
# have access to several variables and functions.

# grade_output runs every time the student's code calls "print". Inside the function, you
# have access to several variables and functions.

# Both functions have access to the following functions and variables:
#   - "outputs": this is a list storing the history of stdout from the program so far. Typically, 
#   you'll want to retrieve the latest output (the one that called the grade_output function) through
#   outputs[-1]
#   - "output_vars": this is a dictionary storing any data stored from previous grade_output calls that
#   we want to use in a later grade_output call or grade_input call. Ex. In Khansole Academy, we store 
#   the two random numbers generated, so that we can access them in grade input and calculate the sum
#   to compare it to what the student outputted for their sum.
#   - "autograde_error(message, test_failed)": this is a function that should be called under any condition
#   that qualifies as "failing" (not matching an expected output, outputting an incorrect sum). "message" is
#   what will be outputted on the failed test dialogue, and name signifies what type of random test failed.
#   The options right now are "Random Formatting" (failing output regex), "Random Validation" (not matching a sum),
#   and "Random Range" (seeing 5 for a variable when the min should be 10). The function raises an error that causes
#   the program to yield back to NodeJS and passes the error state back to the webapp to be displayed.
#   - "register_random_variable(rv, var_name, dist=None, params=None, tolerance=0.0001)": this is a function used
#   to indicate that the "rv" is one observation of a random variable "var_name" who's distribution we'd like to
#   later check. Calling it with a particular "var_name" for the first time "instantiates" the variable by adding 
#   all parameters to __autograde_registered_vars. Subsequent calls to the same variable name only add the observation,
#   "rv", to the list of observations for that variable.

${isAdvancedAutograder ? gradingScripts.onInput : ''}
${isAdvancedAutograder ? gradingScripts.onOutput : ''}
${isAdvancedAutograder ? gradingScripts.onFinish : ''}

${isAdvancedAutograder ? AUTOGRADE_SETUP : ''}

# **** @End Pyodide autograder functionality ****
`;
};

const RUN_MAINAPP = isAdvancedAutograder => `
if not __resumer.finished:
    __resumer.run_once(mainApp, "x")
else:
    finished = True
    ${
      isAdvancedAutograder
        ? 'grade_finish(__autograde_outputs, __autograde_aux_data)'
        : ''
    }
`;

const RESET_MODULES = `
__resumer.cancel()
modules = []
for module in sys.modules:
  if "/home/pyodide" in str(sys.modules[module]):
    modules.append(module)
for module in modules:
  del sys.modules[module]
      `;

const RESET_REPL = `
sys.settrace(None)
modules = []
for module in sys.modules:
  if "/home/pyodide" in str(sys.modules[module]):
    modules.append(module)
for module in modules:
  del sys.modules[module]
      `;

const RESET_SCOPE = `
# if there is a current scope present
# transfer all of its variables in the current
# globals scope somewhere else
curr_scope = py_scope['current_scope']
# Always (almost) true
if curr_scope and curr_scope != new_scope:
    copy = py_scope['copy']
    py_scope['scopes'][curr_scope] = copy.copy(globals())
    # reset to init values first
    for var in list(globals().keys()):
        should_be_variables = list(py_scope['init_vars'].keys())
        should_be_variables += ['curr_scope', 'new_scope', 'copy', 'var']
        if var not in should_be_variables:
            del globals()[var]
    # add variables of the new scope if they exist
    globals().update(py_scope['scopes'].setdefault(new_scope, {}))
    del copy
    py_scope['current_scope'] = new_scope
del new_scope
del curr_scope
`;

/*
await micropip.install(
  '/pyodide/karel-0.0.1-py3-none-any.whl'
)
*/
const INIT_SYSTEM_CODE = `
import sys
import micropip
# from js import graphics
await micropip.install(
  '/pyodide/graphics-0.0.4-py3-none-any.whl'
)
await micropip.install(
  '/pyodide/karel-0.0.1-py3-none-any.whl'
)
await micropip.install(
  '/pyodide/notopenai-0.0.1-py3-none-any.whl'
)
del micropip
`;

const SET_STEPPING_LISTS = `
step_list = unthrow.step_list`;

const REPL_SETUP = `
import sys
from pyodide import to_js
from pyodide.console import PyodideConsole, repr_shorten
import __main__
import js
import os
pyconsole = PyodideConsole(__main__.__dict__)
import builtins
async def await_fut(fut):
  res = await fut
  if res is not None:
    builtins._ = res
  return to_js([res], depth=1)
def clear_console():
  pyconsole.buffer = []
__line_cnt__ = 0
__repl_input__ = ""
__std__input = builtins.input
def __cip_repl_input(prompt=''):
    data = js.replInput(prompt)
    return data

builtins.input = __cip_repl_input
`;

const REPL_TRACE = `
__tf_ctr__ = 0
def trace_function(frame, event, arg):
    global __tf_ctr__
    __tf_ctr__  += 1

    if __tf_ctr__ > 110000:
        raise Exception("Stopping... The CIP Repl will only let you run about 100,000 total lines. Check to make sure all of your loops stop.")

    return trace_function

sys.settrace(trace_function)

`;

export const CHECK_IMPORTS = mainCodeString => {
  const escapeString = str => {
    return str
      .replace(/\\/g, '\\\\') // Escape backslashes
      .replace(/\n/g, '\\n') // Escape newlines
      .replace(/"/g, '\\"') // Escape double quotes
      .replace(/'/g, "\\'") // Escape single quotes
      .replace(/\t/g, '\\t') // Escape tabs
      .replace(/\r/g, '\\r'); // Escape carriage returns
  };

  const squashedCode = escapeString(mainCodeString);

  const codeString = `
import pyodide
user_code = "${squashedCode}"
____imports_list____ = pyodide.find_imports(user_code)
del user_code
del pyodide
____imports_list____
`;
  return codeString;
};

export {
  SETUP_SCRIPT,
  RUN_MAINAPP,
  RESET_MODULES,
  RESET_SCOPE,
  INIT_SYSTEM_CODE,
  PYODIDE_BUILD,
  SET_STEPPING_LISTS,
  REPL_SETUP,
  REPL_TRACE,
  RESET_REPL,
};
