import { useCourseId } from "hooks/router/useUrlParams"
import { Link, useLocation } from "react-router-dom"
import { iconCompleteColor, iconIncompleteColor, todoCircleDiameter, todoConnectorWidth } from "../constants"
import { useContext } from "react"
import { ProfileContext } from "contexts/ProfileContext"
import { RoadmapContext } from "course/contexts/RoadmapContext"
import { TodoModule } from "./todos/TodoModule"
import { TodoIcon } from "components/completion/TodoIcon"
import { CompletionContext } from "contexts/CompletionContext"
import { useCompletionMap } from "hooks/user/useCompletion"
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate"
import { ROADMAP } from "course/editors/roadmapEditor/RoadmapSchema"

export const TodoListSection = ({ isStudent }) => {
  const { userData } = useContext(ProfileContext)
  const userRole = userData?.courseRole
  const { roadmapItemMap } = useContext(RoadmapContext);


  return <div style={todoOuter}>
    {
      // Sort the modules by startDate (in the form "YYYY-MM-DD")
      Object.keys(roadmapItemMap)
        .filter((key) => userRole === roadmapItemMap[key]["roadmapType"])
        .sort((a, b) => {
          const aDate = new Date(roadmapItemMap[a]["startDate"])
          const bDate = new Date(roadmapItemMap[b]["startDate"])
          if (aDate > bDate) {
            return -1
          } else if (aDate < bDate) {
            return 1
          } else {
            return 0
          }
        })
        .map((key, index) => {
          return <TodoModule
            moduleId={key}
            moduleItem={roadmapItemMap[key]}
            key={key}
          />
        })
    }
  </div>
}

export const TodoList = ({ todoItemList }) => {
  const { completionMap } = useCompletionMap(todoItemList)


  return <div style={{ position: 'relative' }}>
    <TodoConnector todoList={todoItemList} completionMap={completionMap} />
    {todoItemList.map((todo, index) => {
      return <TodoItem key={todo.id} todo={todo} isComplete={completionMap[todo.id]} />
    })}
  </div>
}



// Don't touch
const TodoConnector = ({ todoList, completionMap }) => {
  const diameter = todoCircleDiameter
  const width = todoConnectorWidth
  const radius = diameter / 2
  const gapX = 10
  const gapY = 15

  const left = radius + gapX - width / 2
  const top = radius + gapY
  const height = (diameter + gapY)

  const segments = []

  // Add a segment for each todo in between"
  for (let i = 0; i < todoList.length - 1; i++) {
    const todo = todoList[i]
    const nextTodo = todoList[i + 1]
    const isComplete = completionMap[todo.id]
    const nextIsComplete = completionMap[nextTodo.id]
    const isLast = i === todoList.length - 2

    let segment = (
      <div key={i} style={{
        position: 'absolute',
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor: isComplete && nextIsComplete ? iconCompleteColor : iconIncompleteColor,
        marginLeft: `${left}px`,
        marginTop: `${top + i * height}px`,
        zIndex: '1'
      }}></div>
    )
    segments.push(segment)
  }
  return (
    <>
      {segments}
    </>
  )
}


const TodoItem = ({ todo, isComplete }) => {
  const courseId = useCourseId()
  const toUrl = makeLink(todo, courseId)
  const fromUrl = useLocation().pathname
  const navigate = useHistoryNavigate()
  const historyNavigate = useHistoryNavigate()
  const { setSelfCheckCompleted } = useContext(CompletionContext);

  const handleClick = async (event) => {
    // Prevent default link behavior
    event.preventDefault();

    // if this is a self-check, mark it as complete
    if (todo.itemType === ROADMAP.COMPLETION_LINK.key) {
      await setSelfCheckCompleted(todo.completionId || todo.urlId)
    }

    // now you can navigate
    if (toUrl.startsWith("http")) {
      window.open(toUrl, '_blank')
    } else {
      // TODO: this is not necessary, historyNavigate is sufficient!!
      // if the url is to a creative project
      if (toUrl.includes('ide/c')) {
        navigate(toUrl, { state: { from: fromUrl } })
      } else {
        historyNavigate(toUrl)
      }
    }
  };

  return <Link onClick={handleClick} to={toUrl} style={todoContainerStyle}>
    <TodoCircle iconData={todo} isComplete={isComplete} />
    <TodoLabel iconData={todo} />
  </Link>
}


const TodoCircle = ({ iconData, isComplete }) => {

  const style = {
    ...todoCircleStyle(isComplete)
    // borderRadius: isActive ? '50%': '20px',
  }

  if (iconData.itemType === ROADMAP.COMPLETION_LINK.key || iconData.itemType === ROADMAP.UNTIED_LINK.key) {
    return <button className="btn btn-primary btn-sm " style={style}>
      <TodoIcon icon={iconData.itemType} isComplete={isComplete} />
    </button>
  }
  return <button className="btn btn-primary btn-sm " style={style}>
    <TodoIcon icon={iconData.itemType} isComplete={isComplete} />
  </button>
}


const TodoLabel = ({ iconData }) => {
  return (
    <>
      <div style={todoLabelStyle}>
        {iconData.title}
      </div>
    </>
  )
}

const makeLink = (todoItem, courseId) => {
  const location = useLocation().pathname.toLowerCase()
  // does the location have 'teachingHome' in it?
  const isTeacherTraining = location.includes('teachinghome')
  const lessonKey = isTeacherTraining ? 'training' : 'learn'

  switch (todoItem.itemType) {
    case ROADMAP.LESSON.key:
      return `/${courseId}/${lessonKey}/${todoItem.urlId}`
    case ROADMAP.ASSIGNMENT.key:
      return `/${courseId}/ide/a/${todoItem.urlId}`
    case ROADMAP.UNTIED_LINK.key:
    case ROADMAP.COMPLETION_LINK.key:
      return `${todoItem.urlId}`;
    case ROADMAP.SECTION.key:
      return `/${courseId}/section`
    case ROADMAP.AUTO_CHECK.key:
      return `${todoItem.urlId}`
  }
  return `/${courseId}`
}

const todoContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '10px',
  marginTop: '15px',
  zIndex: 3
}

const todoLabelStyle = {
  marginLeft: '10px',
  fontSize: '16px',
  color: "rgba(70, 120, 178, 1)",
  cursor: 'pointer'
}

const todoCircleStyle = (isComplete) => {
  return {
    width: `${todoCircleDiameter}px`,
    height: `${todoCircleDiameter}px`,
    borderRadius: '50%',
    backgroundColor: isComplete ? iconCompleteColor : iconIncompleteColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    zIndex: 2
  }
}

export const todoOuter = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginBottom: '50px'
}
