import {serverTimestamp } from 'firebase/database';
import {database} from 'firebaseApp';
import {useState, useEffect} from 'react';
import {ERROR_CODES} from './Errors';
import {FBReturn, Message} from './types';
import {getRoomsPath} from './Rooms';
import { addDoc, collection, getFirestore, onSnapshot } from 'firebase/firestore';


const getMessagesPath = (courseId, roomId) => {
  return `teachnow/${courseId}/room_logs/${roomId}/messages`
}

export const useMessages = (roomId: string, courseId: string): Message[] => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // TODO: pagination and make this only on child added
    if (roomId) {
      const messagesPath = getMessagesPath(courseId, roomId);
      const messagesRef = collection(getFirestore(), messagesPath)

      const unsubscribe = onSnapshot(messagesRef, (snap) => {
        // get new docs
        const newMessages = snap.docs.map(doc => doc.data());
        setMessages((oldMessages) => {
          return [
            ...newMessages
          ]
        });

      });

      return () => {
        unsubscribe();
      };
    }
  }, [roomId, courseId]);
  return messages;
};


export const addMessage = async (
  message: Message,
  roomId: string,
  courseId: string,
): Promise<FBReturn> => {
  const msg = message.text;
  if (msg.length === 0) {
    return {
      success: false,
      code: ERROR_CODES.CHAT.EMPTY_MESSAGE,
      error: 'Message is empty',
    };
  }

  try {
    const messagesPath = getMessagesPath(courseId, roomId);
    console.log('messagesPath', messagesPath)
    const messagesColRef = collection(getFirestore(), messagesPath)
    await addDoc(messagesColRef, {
      ...message
    })

    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      code: ERROR_CODES.GENERIC,
      error: 'Generic error: ' + error,
    };
  }
};
