import React from 'react';
import {ErrorPage} from './ErrorPage';

interface NoAccessProps {
  /**
   * The message to display on the error page.
   */
  message?: string | React.ReactNode;
}

/**
 * A component that displays a message indicating that the user does not have access to the page.
 */
export const NoAccess = ({message}: NoAccessProps) => {
  return <ErrorPage title="Unable to access this page" msg={message} />;
};

export default NoAccess;
