import { useContext, useEffect, useState } from 'react';
import { KarelWorld, KarelWorldDebounced } from '../../components/pyodide/KarelLib/KarelWorld';
import { IDEContext } from '../contexts/IDEContext';
import {
  checkIsProjectConsole,
  checkIsProjectGraphics,
  checkIsProjectKarel,
} from '../utils/general';
import { GraphicsCanvas } from './GraphicsCanvas';
import { TestList } from 'ide/RightPane/TestView';
import { WorldEditor } from 'components/pyodide/KarelLib/WorldEditor';
import styled from 'styled-components';
import { inferUnitTestType } from 'ide/UnitTest/runUnitTests';
import { useDebounce } from 'use-debounce';

interface CanvasProps {
  /** If true, the karel world will be editable. */
  karelWorldEditMode?: boolean;
}
export const Canvas = ({ karelWorldEditMode }: CanvasProps) => {
  const {
    assnData,
    projectData,
    karelWorldState,
    defaultKarelWorldState,
    setDefaultKarelWorldState,
    setKarelWorldState,
    isRunning
  } = useContext(IDEContext);


  if (checkIsProjectKarel(projectData, assnData)) {
    if (karelWorldEditMode) {
      return (
        <KarelWorldOuter>
          <WorldEditor
            worldState={defaultKarelWorldState}
            setWorldState={karelState => {
              setKarelWorldState(karelState);

              setDefaultKarelWorldState(karelState);
            }}
            isEditable={karelWorldEditMode}
          />
        </KarelWorldOuter>
      );
    }
    return (
      <KarelWorldOuter>
        <KarelWorldDebounced 
          worldState={karelWorldState} 
          isRunning={isRunning}
        />
      </KarelWorldOuter>
    );
  } else if (checkIsProjectConsole(projectData, assnData)) {
    return (
      <div>
        <TestList />
      </div>
    );
  } else if (checkIsProjectGraphics(projectData, assnData)) {
    return <GraphicsCanvas />;
  } else {
    return <></>;
  }
};



const KarelWorldOuter = styled.div`
  width: 100%;
  display: flex;
  flex:1;
`;
