import styled from 'styled-components';

/**
 * List of coding tips to display in the code tip panel. Go ahead, add a couple
 * more!
 */
export const CODING_TIPS: string[] = [
  'Take time off the screen to think about the problem you are trying to solve.',
  'Break down complex problems into smaller, manageable steps.',
  'Write down a plan for your solution before you start writing code.',
  'Test your code frequently!',
  'Use descriptive variable names to make your code more readable.',
  'Add comments to explain complex logic and help others understand your code.',
  'Take regular breaks to avoid burnout and maintain focus.',
  'Ask for help when stuck - programming is collaborative!',
  'Error messages are your friends - they help guide you to solutions!',
  'Keep learning - programming is a journey of continuous improvement!',
];

/**
 * Styled component for the code tip panel.
 */
export const CodeTipPanel = styled.div`
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  margin: 2rem auto;
  width: 90%;
  max-width: 400px; /* optional, for a nice narrower tip area */
`;
