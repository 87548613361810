import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded"

import { useContext, useEffect, useRef, useState } from "react";
import { useCipHttpsCallable } from "../firebase/functions/use_cip_https_callable";
import { ZoomMeetingId } from "./types";
import { CollabContext } from "ide/sync/CollabContext";
import { memo } from "react";
import { RoomData } from "ide/sync/utils";

// ZoomMtg.prepareWebSDK();
type GetSignedZoomMeetingRequest = Record<string, never>;
type LaunchSignedZoomMeetingRequest = Record<string, never>;
type LaunchSignedZoomMeetingResponse = Promise<void>;
type EndSignedZoomMeetingRequest = Record<string, never>;
type EndSignedZoomMeetingResponse = Promise<void>;

interface GetSignedZoomMeetingResponse {
  signature: string;
  meetingId: ZoomMeetingId;
  password: string;
  joinUrl: string;
}
const useLaunchSignedZoomMeeting = () =>
  useCipHttpsCallable<
    LaunchSignedZoomMeetingRequest,
    LaunchSignedZoomMeetingResponse
  >("launchsignedzoommeeting");


const useEndSignedZoomMeeting = () =>
  useCipHttpsCallable<
    EndSignedZoomMeetingRequest,
    EndSignedZoomMeetingResponse
  >("endsignedzoommeeting");

  const ZOOM_MEETING_SDK = "vz2vapx4Tfm_riIymGMquQ"


const useGetSignedZoomMeeting = () =>
  useCipHttpsCallable<
    GetSignedZoomMeetingRequest,
    GetSignedZoomMeetingResponse
  >("getsignedzoommeeting");

  // MOVE TO DEPRECATED
export default function JWTestZoomMeeting({ roomInfo, userZoomState, setUserZoomState, displayName}) {
  const zoomComponentRef = useRef();
  const clientRef = useRef(ZoomMtgEmbedded.createClient());
  
  const [embeddedClientLoaded, setEmbeddedClientLoaded] = useState(false);
  const [hasJoinedMeeting, setHasJoinedMeeting] = useState(false);
  const [meetingId, setMeetingId] = useState<ZoomMeetingId | null>(null);
  const [meetingPassword, setMeetingPassword] = useState<string | null>(null);
  const [meetingSignature, setMeetingSignature] = useState<string | null>(null);
  const [joinUrl, setJoinUrl] = useState<string | null>(null);
  const [zoomInfoLoaded, setZoomInfoLoaded] = useState(false);



  function leaveMeeting() {
    console.log("LEAVING MEETING", hasJoinedMeeting, embeddedClientLoaded)
    // if (!hasJoinedMeeting || !embeddedClientLoaded) return;
    clientRef.current
      .leaveMeeting()
      .then(() => {
        setHasJoinedMeeting(false);
      })
      .catch((error) => {
      });
  }



  


  useEffect(() => {
    const zoomInfo = roomInfo.zoomInfo;
    if (zoomInfo === null) return;
    setMeetingId(zoomInfo.meetingId);
    setMeetingPassword(zoomInfo.password);
    setMeetingSignature(zoomInfo.signature);
    setJoinUrl(zoomInfo.joinUrl);
    setZoomInfoLoaded(true);
  }, [roomInfo]);


  useEffect(() => {
    const setupZoomMeeting = async () => {
      clientRef.current
      .init({
        zoomAppRoot: zoomComponentRef.current,
        language: "en-US",
        patchJsMedia: true,
        leaveOnPageUnload: true,
        customize: {
          toolbar: {
            buttons: [
              {
                "className": "btn btn-small btn-danger",
                "text": "Leave",
                "onClick": () => {
                  leaveMeeting();
                }
              }
            ]
          }
        }
      })
      setEmbeddedClientLoaded(true);

    }

    setupZoomMeeting()
  }, [zoomInfoLoaded]);



  useEffect(() => {
    const join = async () => {
      setHasJoinedMeeting(true);
      clientRef.current
      .join({
        sdkKey: ZOOM_MEETING_SDK,
        signature: meetingSignature,
        meetingNumber: String(meetingId),
        userName: displayName,
        password: meetingPassword,
        error: (error) => {
          console.log("there's been an error in joining the meeting")
          console.log("Said error", error)
        }
      })
    }
    if ( embeddedClientLoaded && !hasJoinedMeeting) {
      // join()
    }
  }, [embeddedClientLoaded])




  return (
    <div
    ref={zoomComponentRef}
    id="meetingSDKElement"
    style={{
      position: "fixed",
      top: 100,
      left: 200,
      zIndex: 1000,
    }}
  ></div>
  );
}


function ConnectionStatus({ label, isConnected }) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <span>{label}</span>
        <div
          style={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: isConnected ? "#4caf50" : "#e0e0e0",
            transition: "background-color 0.3s ease",
          }}
        />
      </div>
    );
  }




  export function ZoomMeetingModal() {
    const { roomData, roomLoaded, roomExists, endZoomRef } = useContext(CollabContext)   

    if (!roomExists || !roomLoaded) {
      return <></>
    }
  

    return (
      <ZoomModalInner roomData={roomData} endZoomRef={endZoomRef}/>
    )
  
  
  }



  const ZoomModalInner = memo(({roomData, endZoomRef} : {
    roomData: RoomData,
    endZoomRef: any
  }) => {
    const zoomComponentRef = useRef();
    const clientRef = useRef(null);



    function leaveMeeting() {
      // if (!hasJoinedMeeting || !embeddedClientLoaded) return;
      if(!clientRef.current) return;
      clientRef.current
        .leaveMeeting()
    }



    useEffect(() => {
      const setupZoomMeeting = async () => {
        clientRef.current = ZoomMtgEmbedded.createClient()
        await clientRef.current
        .init({
          zoomAppRoot: zoomComponentRef.current,
          language: "en-US",
          patchJsMedia: true,
          leaveOnPageUnload: true,
          customize: {
            toolbar: {
              video: {
                isResizable: true,
                viewSizes: {
                  ribbon: {
                    height: 100,
                    width: 100
                  },
                  default: {
                    height: 200,
                    width: 200
                  }
                }
              },
              buttons: [
                {
                  "className": "btn btn-small btn-danger",
                  "text": "Leave",
                  "onClick": () => {
                    leaveMeeting();
                  }
                }
              ],
              
            }
          }
        })
        endZoomRef.current = leaveMeeting
  
      }
      const join = async () => {
        console.log(ZOOM_MEETING_SDK,"<sdk|",  roomData.signature,"<sig|", roomData.meetingId, "<mtgId|",roomData.userName,"<usr|", roomData.meetingPassword, "<pwd|")
        await clientRef.current
        .join({
          sdkKey: ZOOM_MEETING_SDK,
          signature: roomData.signature,
          meetingNumber: String(roomData.meetingId),
          userName: roomData.userName,
          password: roomData.meetingPassword,
          error: (error) => {
            console.log("there's been an error in joining the meeting")
            console.log("Said error", error)
          }
        })
      }

      console.log("SETTING UP ZOOM MEETING")
      setupZoomMeeting()
        .then(() => join().then(() => console.log("JOINED MEETING")))
    }, []);
  
  
  

    
    console.log("RENDERING ZOOM MEETING")

    return (
      <>
      <div
      ref={zoomComponentRef}
      id="meetingSDKElement"
      style={{
        position: "fixed",
        top: 100,
        left: 200,
        zIndex: 1050,
      }}
      key={"zoomMeeting"}
    ></div>   
     {/* <ZoomHelper /> */}
    </>
    );



  })
  


