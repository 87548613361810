import {
  MutableRefObject,
  Dispatch,
  SetStateAction,
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
  useRef,
} from 'react';
import {PyodideClient} from 'components/pyodide/PyodideProvider';
import {CodeInPlaceTerminal} from 'ide/TerminalPane/types';
import {editor} from 'monaco-editor';
import useMediaQuery, {Breakpoints} from 'utils/useMediaQuery';
import {MobileTab} from './MobileView';
import {SlideNodeType} from './SlideTree';

interface CodeAdventureContextType {
  prevSlideNodes: MutableRefObject<SlideNodeType[]>;
  prevSlideNodesCodes: MutableRefObject<string[]>;
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  isRunning: boolean;
  setRunning: Dispatch<SetStateAction<boolean>>;
  currentSlideNode: SlideNodeType;
  setCurrentSlideNode: Dispatch<SetStateAction<SlideNodeType>>;
  slideRootRef: MutableRefObject<SlideNodeType>;
  slideNodes: MutableRefObject<Record<string, SlideNodeType>>;
  pyodideClientRef: MutableRefObject<PyodideClient>;
  terminalRef: MutableRefObject<CodeInPlaceTerminal>;
  editorRef: MutableRefObject<editor.IStandaloneCodeEditor>;
  editorLoaded: boolean;
  setEditorLoaded: Dispatch<SetStateAction<boolean>>;
  decorations: editor.IEditorDecorationsCollection | null;
  setDecorations: Dispatch<
    SetStateAction<editor.IEditorDecorationsCollection | null>
  >;
  selectedMobileTab: MobileTab;
  setSelectedMobileTab: Dispatch<SetStateAction<MobileTab>>;
  isTabletOrLarger: boolean;
}

const CodeAdventureContext = createContext<CodeAdventureContextType | null>(
  null,
);

export const CodeAdventureProvider: FC<{data, children: ReactNode}> = ({
  data,
  children,
}) => {
  const [code, setCode] = useState<string>(data.starterCode || '');
  const prevSlideNodes = useRef<SlideNodeType[]>([]);
  const prevSlideNodesCodes = useRef<string[]>([]);
  const [isRunning, setRunning] = useState<boolean>(false);
  const [currentSlideNode, setCurrentSlideNode] = useState<SlideNodeType>(null);
  const slideRootRef = useRef<SlideNodeType>(null);
  const slideNodes = useRef<Record<string, SlideNodeType>>({});
  const pyodideClientRef = useRef<PyodideClient>(null);
  const terminalRef = useRef<CodeInPlaceTerminal>(null);
  const editorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
  const [decorations, setDecorations] =
    useState<editor.IEditorDecorationsCollection>();
  const [selectedMobileTab, setSelectedMobileTab] = useState<MobileTab>(
    MobileTab.PROBLEM,
  );
  const isTabletOrLarger = useMediaQuery(Breakpoints.TAB);

  return (
    <CodeAdventureContext.Provider
      value={{
        code,
        setCode,
        prevSlideNodes,
        prevSlideNodesCodes,
        isRunning,
        setRunning,
        currentSlideNode,
        setCurrentSlideNode,
        slideRootRef,
        slideNodes,
        pyodideClientRef,
        terminalRef,
        editorRef,
        editorLoaded,
        setEditorLoaded,
        decorations,
        setDecorations,
        selectedMobileTab,
        setSelectedMobileTab,
        isTabletOrLarger,
      }}
    >
      {children}
    </CodeAdventureContext.Provider>
  );
};

export const useCodeAdventure = (): CodeAdventureContextType => {
  const context = useContext(CodeAdventureContext);
  if (!context) {
    throw new Error(
      'useCodeAdventure must be used within a CodeAdventureProvider',
    );
  }
  return context;
};
