import {Outlet} from 'react-router-dom';
import {isDevelopmentEnvironment} from 'utils/general';
import {Page404} from './errors/Page404';

/**
 * A component that only renders its children if the current environment is
 * development. Useful for developer-only features like developer tooling.
 */
export default function DevelopmentOnly({
  children,
}: {
  children: React.ReactNode;
}) {
  if (isDevelopmentEnvironment()) {
    return <>{children}</>;
  }
  return <></>;
}

/**
 * Adapts the DevelopmentOnly component to work with react-router.
 *
 * If a route renders `DevelopmentOnlyRoute` as its `element`, then all children
 * of the route will be rendered only in the development environment and display
 * a 404 page in production.
 */
export function DevelopmentOnlyRoute() {
  if (isDevelopmentEnvironment()) {
    return <Outlet />;
  }
  return <Page404 message={`page not found: ${window.location.href}`} />;
}
