import {useComponentSize} from 'react-use-size';
import {Forum} from './Forum';
import {useContext} from 'react';
import styled from 'styled-components';
import {ForumContext} from '../../ForumContext';

export const ForumPage = ({}) => {
  const {forumId} = useContext(ForumContext);

  const forumPageSize = useComponentSize();

  return (
    <ForumOuter ref={forumPageSize.ref} key={`${forumId}-forum-id`}>
      <Forum />
    </ForumOuter>
  );
};

const ForumOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  max-height: calc(100% - 1px);
  overflow-y: hidden;
`;
