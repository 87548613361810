/// this is temporary until we can put it on the server

export const teachAppData = {
  cards: [
    {
      title: "Let's get to know each other",
      todo: [
        {
          id: 'aboutJob',
          mobileTitle: 'About the job',
          title: 'Learn about the job',
          type: 'aboutJob',
          to: `/public/applyteach/cip5/aboutjob`,
        },
        {
          id: 'about',
          title: 'Tell us about yourself',
          type: 'form',
          to: `/public/applyteach/cip5/aboutyou`,
        },
      ],
    },
    {
      title: 'Make a teaching demo',
      todo: [
        {
          id: 'welcome-to-karel',
          title: 'Create a teaching demo',
          type: 'record',
          to: `/public/applyteach/cip5/teachingdemo`,
        },
      ],
    },
    {
      title: 'Solve some problems',
      todo: [
        {
          id: 'applyteach-fillkarel',
          title: 'Fill Karel',
          type: 'code',
          to: `/public/ide/a/applyteach-fillkarel`,
        },
        {
          id: 'doubleit',
          title: 'Double It',
          type: 'code',
          to: `/public/ide/a/doubleit`,
        },
        {
          id: 'debugging',
          title: 'Debugging',
          type: 'debugging',
          to: `/public/applyteach/cip5/debugging`,
        },
      ],
    },
  ],
};
