import {useEffect} from 'react';
import {useRouteError} from 'react-router-dom';

import {ErrorPage} from 'components/errors/ErrorPage';
import {logProductionErrorToFirestore} from 'components/errors/utils';

/**
 * A component that displays an error page when an error occurs. Should be used with
 * Suspense.
 */
const ErrorBoundary = () => {
  const error = useRouteError() as Error;

  useEffect(() => {
    console.error(error);

    logProductionErrorToFirestore(error);
  }, [error]);

  return (
    <ErrorPage
      title="Something went wrong"
      msg="A log has been sent to the course staff"
    />
  );
};

export default ErrorBoundary;
