import {FaCircle, FaQuestion} from 'react-icons/fa';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export const AOEExplainer = () => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{show: 100, hide: 100}}
      overlay={props => (
        <Tooltip id="button-tooltip" {...props}>
          11:59 PM Anywhere on Earth
        </Tooltip>
      )}
    >
      <span
        className={`btn btn-light rounded-circle`}
        style={{
          verticalAlign: 'top',
          width: '18px',
          height: '18px',
          padding: 0,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.8rem',
        }}
        onClick={() => {
          window.open('https://www.timeanddate.com/time/zones/aoe', '_blank');
        }}
      >
        <FaQuestion />
      </span>
    </OverlayTrigger>
  );
};
