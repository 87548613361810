import {useState} from 'react';
import Confetti from 'react-confetti';

/**
 * The duration of the confetti animation in milliseconds.
 */
const DURATION_MS = 0.5 * 1000;
/**
 * The number of particles to use in the confetti animation.
 */
const PARTICLE_COUNT = 2500;

interface UseSideConfettiProps {
  /**
   * The size of the window.
   */
  windowSize: {
    /**
     * The width of the window.
     */
    width: number;
    /**
     * The height of the window.
     */
    height: number;
  };
}

interface UseSideConfettiResult {
  /**
   * The confetti particles, include this element in the JSX of the component
   * displaying the confetti.
   */
  confettiParticles: React.ReactNode;
  /**
   * Fires the confetti.
   */
  fireConfetti: () => void;
}

/**
 * Fires confetti from both sides of the screen towards the center.
 * @param __namedParameters See {@link UseSideConfettiProps}.
 * @returns See {@link UseSideConfettiResult}.
 */
export const useSideConfetti = ({
  windowSize,
}: UseSideConfettiProps): UseSideConfettiResult => {
  const [confettiState, setConfettiState] = useState('off');

  function fireConfetti() {
    setConfettiState('on');
    // turn off the confetti after 5 seconds
    setTimeout(() => {
      setConfettiState('finishing');
    }, DURATION_MS);
  }

  const confettiParticles = (
    <>
      <Confetti
        run={confettiState !== 'off'}
        width={windowSize.width}
        height={windowSize.height}
        recycle={confettiState === 'on'}
        numberOfPieces={PARTICLE_COUNT}
        confettiSource={{
          x: 0,
          y: windowSize.height * 0.8,
          w: 0,
          h: windowSize.height,
        }}
        initialVelocityY={30}
        initialVelocityX={20}
        gravity={0.2}
      />
      <Confetti
        run={confettiState !== 'off'}
        width={windowSize.width}
        height={windowSize.height}
        recycle={confettiState === 'on'}
        numberOfPieces={PARTICLE_COUNT}
        confettiSource={{
          x: windowSize.width,
          y: windowSize.height * 0.8,
          w: 0,
          h: windowSize.height,
        }}
        initialVelocityY={30}
        initialVelocityX={-20}
        gravity={0.2}
      />
    </>
  );

  return {confettiParticles, fireConfetti};
};
