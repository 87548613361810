import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../firebaseApp';

const CipAnalyticsWrapperRoute = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      console.log('logging screen view');
      logEvent(analytics, 'screen_view');
    } catch (error) {
      console.trace('Error logging screen view', error);
    }
  }, [location.key]);

  return <Outlet />;
};

export default CipAnalyticsWrapperRoute;
