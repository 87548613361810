/// this is temporary until we can put it on the server

export const studentAppData = {
  cards: [
    {
      title: "Let's get to know each other",
      todo: [
        {
          id: 'aboutCourse',
          mobileTitle: 'Learn about the Course',
          title: 'Learn about Code in Place 2025',
          type: 'aboutCourse',
          to: `/public/join/cip5/aboutCourse`,
        },
        {
          id: 'about',
          title: 'Tell us about yourself',
          type: 'form',
          to: `/public/join/cip5/about`,
        },
      ],
    },
    {
      title: 'Learn Karel basics',
      todo: [
        {
          id: 'welcome-to-karel',
          title: 'Welcome to Karel',
          type: 'lesson',
          to: `/public/learn/welcome-to-karel`,
        },
      ],
    },
    {
      title: 'Solve some problems',
      todo: [
        {
          id: 'warmup',
          title: 'Warmup',
          type: 'code',
          to: `/public/ide/a/warmup`,
        },
        {
          id: 'housekarel',
          title: "Karel's Home",
          type: 'code',
          to: `/public/ide/a/housekarel`,
        },
      ],
    },
  ],
};
