import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useCourseId } from "hooks/router/useUrlParams";
import { useChatLoaders } from "./ChatHooks";
import { NavigationHistoryContext } from "contexts/NavigationContext";
import { CompletionContext } from "contexts/CompletionContext";
import { useContentHelpers } from "./lessonchats/ContentHelpers";
import { useMoodSurveyLoaders } from "./lessonchats/MoodSurveyHooks";

const defaultData = {
  lessonId: undefined,
  lessonData: undefined,
  slides: undefined,
  currSlideData: undefined,
  currSlideIndex: 0,
  currSlideId: undefined,
  nextSlide: () => { },
  prevSlide: () => { },
  gotoSlide: (id) => { },
  closeLesson: () => { },
  setChatViewState: (e) => { },
  chatViewState: undefined,
  chatType: undefined,
  chatMessages: [],
  tempMessages: [], // Messages that are not yet in the database
  setTempMessages: (e) => { },
  unreadMessageFlag: false,
  setUnreadMessageFlag: (e) => { },
  setChatTimestamp: () => { },
  getOutlineSlideId: (e) => { },
  player: null,
  setPlayer: (e) => { },
  getCurrentContent: async () => { return "" },
  loadChat: async () => { }
};

export const LessonContext = React.createContext(defaultData);

export const LessonProvider = ({ lessonData, startSlideId, children }) => {
  const navigate = useNavigate();

  /**
   * slideIndex vs slideId
   * Note to the user: there are two ways of knowing where you are:
   * slideIndex and slideId. If the author of the lesson choses to change
   * the order of things (eg by inserting a new slide early on), slideId 
   * will be preserved, but slideIndex will not. As such, only use slideIndex
   * for local session state.
   * 
   * page or slide?
   * In different parts of the code, we refer to the lesson slides as pages.
   * We are trying to migrate everything to slides...
   * 
   * slideId in the outline?
   * Not all slides will show up in the outline. If you are in a slide that 
   * does not show up in the outline, it can be helpful to know the last slide
   * which does. For that you can use getOutlineSlideId(index)
   */

  const {getPreviousLocation} = useContext(NavigationHistoryContext)

  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
  const isStudentFacing = pathSegments[1]

  const slides = lessonData?.slides;
  const lessonId = lessonData?.id;
  const startSlideIndex = getStartSlideIndex(slides, startSlideId)

  const [currSlideIndex, setCurrSlideIndex] = useState(startSlideIndex);
  const [chatViewState, setChatViewState] = useState('minimized');
  const previousLocation = getPreviousLocation()
  const courseId = useCourseId();

  // The video player for the lesson
  const [player, setPlayer] = useState(null);

  const currSlideData = slides[currSlideIndex];
  const currSlideId = currSlideData?.id;

  const {setLessonCompleted } = useContext(CompletionContext);

  useEffect(() => {
    // add the slideId to the URL
    if (lessonId && currSlideId) {
      const path = `/${courseId}/${isStudentFacing}/${lessonId}/${currSlideId}`;
      if (window.location.pathname !== path) {
        navigate(path, { replace: true });
      }
    }

    const isLastSlide = currSlideIndex === slides.length - 1;
    if (isLastSlide) {
      setLessonCompleted(lessonId);
    }
  }, [currSlideId])

  useEffect(() => {
    // if the startSlideId changes (eg a new URL) we should react
    // by changing the current slide index
    const startSlideIndex = getStartSlideIndex(slides, startSlideId)
    setCurrSlideIndex(startSlideIndex);
  }, [startSlideId])
  

  const {
    chatType,
    chatMessages,
    tempMessages,
    setTempMessages,
    unreadMessageFlag,
    setUnreadMessageFlag,
    setChatTimestamp,
    loadChat
  } = useChatLoaders({ currSlideId, chatViewState, courseId });

  useMoodSurveyLoaders({ lessonId, currSlideId });

  const { getCurrentContent } = useContentHelpers({ player, lessonData, currSlideId, currSlideData });

  const closeLesson = () => {
    console.log('previousLocation', previousLocation)
    const onwardsUrl = previousLocation || `/${courseId}/home`
    navigate(onwardsUrl, { replace: true });
  };

  const nextSlide = () => {
    const nextIndex = currSlideIndex + 1;
    if (nextIndex >= slides.length) {
      Swal.fire({
        title: 'Congratulations!',
        text: 'You have completed the lesson!',
        icon: 'success',
        confirmButtonText: 'Wahoo!'
      }).then(() => {
        setLessonCompleted(lessonId)
        closeLesson();
      })
    } else {
      setCurrSlideIndex(currSlideIndex + 1);
    }
  }

  const gotoSlide = (targetId) => {
    // loop over all the pages
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].id === targetId) {
        setCurrSlideIndex(i);
        return;
      }
    }
  }

  const prevSlide = () => {
    setCurrSlideIndex(currSlideIndex - 1);
  }

  const getOutlineSlideId = (startIndex) => {
    /** 
     * Not all slides will show up in the outline. If you are in a slide that 
     * does not show up in the outline, it can be helpful to know the last slide
     * which does have an Id (for example when highlighting the current slide 
     * in the LessonDrawer). 
     */
    let i = startIndex;
    // some error cases
    if (!slides || i < 0 || i >= slides.length) {
      return undefined
    }

    // find the first slide that is not hidden from the outline
    while(i >= 0) {
      const slideData = slides[i]?.data
      const hideFromOutline = slideData?.hideFromOutline
      if (!hideFromOutline) {
        return slides[i]?.id;
      }
      i--;
    }
    
    // if there does not exist an answer
    return undefined;
  }

  return (
    <LessonContext.Provider
      value={{
        lessonId,
        lessonData,
        slides,
        currSlideData,
        currSlideId,
        currSlideIndex,
        getOutlineSlideId,
        nextSlide,
        prevSlide,
        gotoSlide,
        closeLesson,
        setChatViewState,
        chatViewState,
        chatType,
        chatMessages,
        tempMessages,
        setTempMessages,
        unreadMessageFlag,
        setUnreadMessageFlag,
        setChatTimestamp,
        player,
        setPlayer,
        getCurrentContent,
        loadChat
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};

function getStartSlideIndex(slides, startSlideId) {
  if (!slides || !startSlideId) {
    return 0;
  }
  for (let i = 0; i < slides.length; i++) {
    if (slides[i].id === startSlideId) {
      return i;
    }
  }
  return 0;
}