import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import {useParams} from 'react-router';
import {TimeContext} from './TimeContext';
import {RoleFirebaseString} from 'types/role';
import type {CourseId} from 'types/common';

interface CourseContextType {
  courseId: CourseId;
  courseName: string;
  courseDescription: string;
  courseTimezone: string;
  courseStartDate: string;
  courseEndDate: string;
  nCourseWeeks: number;
  currCourseWeek: number;
  currSectionWeek: number;
  firstSectionTimestamp: string;
  lastSectionTimestamp: string;
  courseType: string;
  sectionTimeDelta: number;
  courseVideoId: string;
  isCourseAsynchronous: boolean;
  isFeatureEnabled: (featureName: string, isSl?: boolean) => boolean;
  getSectionsSnapshot: () => any;
  getFirstDayOfSection: (timezoneString: string, sectionIndex: number) => Date;
  getNextSectionIndex: (timezoneString: string, sectionIndex: number) => number;
  getNextSectionDate: (timezoneString: string, sectionIndex: number) => Date;
  courseFeatures: string[];
  slFeatures: string[];
  rosterTypesenseIndex: string;
  storiesTypesenseIndex: string;
  roadmapList: RoleFirebaseString[];
  courseCanvasAuthLink: string;
  isCodeInPlaceCourse: boolean;
  usesKarel: boolean;
  usesTeachNow: boolean;
  courseHasGrades: boolean;
  isFoothill: boolean;
}

const defaultData: CourseContextType = {
  courseId: '',
  courseName: '',
  courseDescription: '',
  courseTimezone: '',
  courseStartDate: '',
  courseEndDate: '',
  nCourseWeeks: 0,
  firstSectionTimestamp: '',
  lastSectionTimestamp: '',
  courseType: '',
  currCourseWeek: 0, // resets on monday
  currSectionWeek: 0, // resets on friday
  courseFeatures: [],
  courseVideoId: '',
  sectionTimeDelta: 0,
  slFeatures: [],
  isCourseAsynchronous: false,
  isFeatureEnabled: (featureName: string, isSl?: boolean) => false,
  getSectionsSnapshot: () => {
    return null;
  },
  getFirstDayOfSection: (timezoneString: string, sectionIndex: number) => {
    return null;
  },
  getNextSectionIndex: (timezoneString: string, sectionIndex: number) => {
    return 0;
  },
  getNextSectionDate: (timezoneString: string, sectionIndex: number) => {
    return null;
  },
  rosterTypesenseIndex: '',
  storiesTypesenseIndex: '',
  roadmapList: [],
  courseCanvasAuthLink: '',
  isCodeInPlaceCourse: false,
  usesKarel: false,
  usesTeachNow: false,
  courseHasGrades: false,
  isFoothill: false,
};

/**
 * TODO: there are two big refactors (one easy, one hard)
 *
 * 1. in some places in the database, weeks are 0 indexed, in others they are 1 indexed.
 *    This should be consistent (likely 0 indexed). When displaying to the user, we can add 1
 *
 * 2. This context uses a long list of useStates (each with their one setState). There is a
 *    great chance to reduce the amount of code.
 */

export const CourseContext = createContext<CourseContextType>(defaultData);

/**
 * Reads and parses course data for the given courseId.
 */
export function useCourseContextForCourse(
  courseId: CourseId,
): CourseContextType {
  const {getServerTimeMs} = useContext(TimeContext);

  const [courseName, setCourseName] = useState<string>('');
  const [courseDescription, setCourseDescription] = useState<string>('');
  const [courseTimezone, setCourseTimezone] = useState<string>('');
  const [courseStartDate, setCourseStartDate] = useState<string>('');
  const [courseEndDate, setCourseEndDate] = useState<string>('');
  const [nCourseWeeks, setnCourseWeeks] = useState<number>(0);
  const [firstSectionTimestamp, setFirstSectionTimeStamp] =
    useState<string>('');
  const [courseType, setCourseType] = useState<string>('');
  const [courseCanvasAuthLink, setCanvasCourseAuthLink] = useState<string>('');
  const [courseHasGrades, setCourseHasGrades] = useState<boolean>(false);
  const [isCourseAsynchronous, setIsCourseAsynchronous] =
    useState<boolean>(false);
  const [courseFeatures, setCourseFeatures] = useState<string[]>([]);
  const [slFeatures, setSlFeatures] = useState<string[]>([]);
  const [courseVideoId, setCourseVideoId] = useState<string>('');
  const [sectionTimeDelta, setSectionTimeDelta] = useState<number>(0);
  const [rosterTypesenseIndex, setRosterTypesenseIndex] = useState<string>('');
  const [storiesTypesenseIndex, setStoriesTypesenseIndex] =
    useState<string>('');
  const [roadmapList, setRoadmapList] = useState<RoleFirebaseString[]>([
    'student',
    'sl',
  ]);
  const [isCodeInPlaceCourse, setIsCodeInPlaceCourse] =
    useState<boolean>(false);
  const [usesKarel, setUsesKarel] = useState<boolean>(false);
  const [usesTeachNow, setUsesTeachNow] = useState<boolean>(false);
  const isFoothill = ['foothill-cs49', 'cs49-f24', 'cs49-w24'].includes(
    courseId,
  );

  const db = getFirestore();
  const courseDocRef = doc(db, `course/${courseId}`);

  const getFirstDayOfSection = (timezoneString, sectionIndex) => {
    // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
    if (!firstSectionTimestamp) {
      throw new Error('Timestamp not loaded yet');
    }
    const firstSectionDate = new Date(firstSectionTimestamp);
    firstSectionDate.setHours(firstSectionDate.getHours() + sectionIndex);
    return firstSectionDate;
  };

  function getNextSectionIndex(timezoneString, sectionIndex) {
    // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
    const firstSection = getFirstDayOfSection(timezoneString, sectionIndex);
    let currSection = firstSection;
    const oneHourInMilliseconds = 60 * 60 * 1000;
    let weekIndex = 0;
    while (
      new Date() >= new Date(currSection.getTime() + oneHourInMilliseconds)
    ) {
      currSection = new Date(currSection.getTime() + 7 * 24 * 60 * 60 * 1000);
      weekIndex += 1;
    }
    return weekIndex;
  }

  function getNextSectionDate(timezoneString, sectionIndex) {
    // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
    const firstSection = getFirstDayOfSection(timezoneString, sectionIndex);
    let currSection = firstSection;
    const oneHourInMilliseconds = 60 * 60 * 1000;
    while (
      new Date() >= new Date(currSection.getTime() + oneHourInMilliseconds)
    ) {
      currSection = new Date(currSection.getTime() + 7 * 24 * 60 * 60 * 1000);
    }
    return currSection;
  }

  useEffect(() => {
    const getCourseData = async () => {
      if (!courseId) return;
      const courseDoc = await getDoc(courseDocRef);
      if (courseDoc.exists()) {
        const courseData = courseDoc.data();
        const safeCourseFeatures = courseData.features ?? [];
        const safeSlFeatures = courseData.slFeatures ?? [];
        setCourseName(courseData.name ?? '');
        setCourseDescription(courseData.description ?? '');
        setCourseTimezone(courseData.timezone ?? '');
        setCourseStartDate(courseData.startDate ?? '');
        setnCourseWeeks(courseData.nWeeks ?? 0);
        setFirstSectionTimeStamp(
          courseData.firstSectionTimestamp
            ? `${courseData.firstSectionTimestamp}-07:00`
            : '',
        );
        setCourseEndDate(courseData.endDate ?? '');
        setCourseType(courseData.type ?? '');
        setCourseVideoId(courseData.videoId ?? '');
        setCourseFeatures(safeCourseFeatures);
        setSlFeatures(safeSlFeatures);
        setSectionTimeDelta(courseData.sectionTimeDelta ?? 0); // the number of hours of section a week
        setRosterTypesenseIndex(courseData.rosterTypesenseIndex ?? '');
        setStoriesTypesenseIndex(courseData.storiesTypesenseIndex ?? '');
        setCanvasCourseAuthLink(courseData.canvasAuthLink ?? '');
        setCourseHasGrades(safeSlFeatures.includes('grades'));
        const verboseRoadmapList = [
          'student',
          'sl',
          ...(courseData.roadmapList ?? ['student', 'sl']),
        ];
        const uniqueRoadmap = array =>
          array.filter((v, i) => array.indexOf(v) === i);
        setRoadmapList(uniqueRoadmap(verboseRoadmapList));
        setIsCodeInPlaceCourse(
          'isCodeInPlaceCourse' in courseData
            ? courseData.isCodeInPlaceCourse
            : true,
        );
        setUsesKarel('usesKarel' in courseData ? courseData.usesKarel : true);
        setUsesTeachNow(
          'usesTeachNow' in courseData ? courseData.usesTeachNow : true,
        );

        setIsCourseAsynchronous(
          courseData.type ? courseData.type === 'asynchronous' : false,
        );
      }
    };
    getCourseData();
  }, [courseId]);

  const isFeatureEnabled = (featureName: string, isSl: boolean = false) => {
    const features = isSl ? slFeatures : courseFeatures;
    return features.includes(featureName);
  };

  const getSectionsSnapshot = async () => {
    // fetches sections for this course
    const sectionRef = collection(db, 'sections');
    const q = query(sectionRef, where('courseId', '==', courseId));
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  };

  // These are "one indexed" in that the first week of class is 1
  const currSectionWeek = calcCurrWeek(
    firstSectionTimestamp,
    getServerTimeMs(),
  );
  const currCourseWeek = calcCurrWeek(courseStartDate, getServerTimeMs());

  // lastSectionTimestamp is an ISO string
  const lastSectionTimestamp = calcLastSectionTimestamp(
    firstSectionTimestamp,
    sectionTimeDelta,
  );

  return {
    courseId,
    courseName,
    courseDescription,
    courseTimezone,
    courseStartDate,
    courseEndDate,
    nCourseWeeks,
    firstSectionTimestamp,
    lastSectionTimestamp,
    courseType,
    courseFeatures,
    slFeatures,
    courseVideoId,
    currSectionWeek,
    currCourseWeek,
    isCourseAsynchronous,
    isFeatureEnabled,
    getSectionsSnapshot,
    sectionTimeDelta,
    getFirstDayOfSection,
    getNextSectionDate,
    getNextSectionIndex,
    rosterTypesenseIndex,
    storiesTypesenseIndex,
    roadmapList,
    courseCanvasAuthLink,
    isCodeInPlaceCourse,
    usesKarel,
    usesTeachNow,
    courseHasGrades,
    isFoothill,
  };
}

/**
 * Provides a CourseContext for the user's current course.
 * This is a wrapper around the useCourseContextForCourse hook.
 */
export function CourseProvider({children}: {children: React.ReactNode}) {
  const {courseId} = useParams();
  const courseContext = useCourseContextForCourse(courseId);

  return (
    <CourseContext.Provider value={courseContext}>
      {children}
    </CourseContext.Provider>
  );
}

/**
 * If you are before the start timestamp, it will return 0
 * If you are right after the timestamp, it will return 1
 * Therfore, consider it one indexed as the first week of sections will be 1
 * @param startTimestamp - ISO string
 * @param serverTimestamp - ISO string
 */
export function calcCurrWeek(startTimestamp: string, serverTimestamp: string) {
  // calculate the current week
  const startDate = new Date(startTimestamp);
  const serverDate = new Date(serverTimestamp);
  const oneHourInMilliseconds = 60 * 60 * 1000;
  let currDate = startDate;
  let weekIndex = 0;
  while (serverDate >= new Date(currDate.getTime() + oneHourInMilliseconds)) {
    // add 7 days to the current section
    currDate = new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    weekIndex += 1;
  }
  return weekIndex;
}

const calcLastSectionTimestamp = (firstSectionTimestamp, sectionTimeDelta) => {
  if (!firstSectionTimestamp) {
    return new Date().toISOString();
  }

  const startDate = new Date(firstSectionTimestamp);
  const deltaHours = sectionTimeDelta * 60 * 60 * 1000;
  const lastSectionTimestamp = new Date(startDate.getTime() + deltaHours);
  return lastSectionTimestamp.toISOString();
};
