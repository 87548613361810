import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {FaGripVertical, FaTrash} from 'react-icons/fa';

const SortableChapter = ({
  id, // Use a stable identifier for the Sortable component
  chapterKey,
  title,
  onSetTitle,
  onSetKey,
  onDelete,
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({
      id, // Use the stable identifier here
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="d-flex align-items-center mb-2"
    >
      <div {...attributes} {...listeners} className="me-2">
        <FaGripVertical />
      </div>
      <input
        type="text"
        className="form-control me-2"
        value={chapterKey}
        onChange={e => onSetKey(e.target.value)}
        placeholder="Key"
      />
      <input
        type="text"
        className="form-control me-2"
        value={title}
        onChange={e => onSetTitle(e.target.value)}
        placeholder="Title"
      />
      <button className="btn btn-danger btn-sm" onClick={onDelete}>
        <FaTrash />
      </button>
    </div>
  );
};

export default SortableChapter;
