import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';
import {
  FaBook,
  FaCheck,
  FaPen,
  FaPython,
  FaRocket,
  FaVideo,
} from 'react-icons/fa';
import styled from 'styled-components';
import {useIsMobile} from 'utils/general';
import {checkComplete} from './checkStatus';

export const TodoCards = ({
  applicationData,
  allAppData,
  isUserAutoAcceptEligible,
}) => {
  const buildTodoCard = (card, index) => {
    return (
      <TodoCard
        key={index}
        title={card.title}
        content={card.todo}
        allAppData={allAppData}
      />
    );
  };
  if (isUserAutoAcceptEligible) {
    const biographicalDataCard = applicationData.cards.filter(
      card => card.title === "Let's get to know each other",
    )[0];
    if (!biographicalDataCard) {
      // Something is wrong, let's just show all the cards
      console.error('no biographical data card');
      return applicationData.cards.map(buildTodoCard);
    }
    const isBiographicalDataCardComplete = useIsTodoCardComplete({
      todoCardData: biographicalDataCard,
      allAppData,
    });
    return [
      <SlAutoAcceptCard
        onAutoAcceptButtonClick={allAppData?.onAutoAcceptButtonClick}
        appStatus={allAppData?.appData?.appStatus}
        hasRequestedAutoAccept={allAppData?.appData?.hasRequestedAutoAccept}
        isBiographicalDataCardComplete={isBiographicalDataCardComplete}
        key={0}
      />,
      buildTodoCard(biographicalDataCard, 1),
    ];
  }
  return <>{applicationData.cards.map(buildTodoCard)}</>;
};

export const SlAutoAcceptCard = ({
  onAutoAcceptButtonClick,
  appStatus,
  hasRequestedAutoAccept,
  isBiographicalDataCardComplete,
}) => {
  let displayedContent;
  const AutoAcceptButton = () => {
    return (
      <div onClick={onAutoAcceptButtonClick} className="btn btn-primary">
        I will section lead for Code in Place 2025!
      </div>
    );
  };
  if (appStatus === 'complete') {
    displayedContent = (
      <p>
        Wahoo! Thanks for confirming your section leader status. You don't need
        to do anything else, we'll see you for training! If you can no longer
        teach for the course, please click the "Withdraw" button above.
      </p>
    );
  } else if (hasRequestedAutoAccept && !isBiographicalDataCardComplete) {
    displayedContent = (
      <p>
        You're almost done! Please fill out the section labeled "Let's get to
        know each other".
      </p>
    );
  } else if (!hasRequestedAutoAccept && isBiographicalDataCardComplete) {
    displayedContent = (
      <>
        <p>
          You're almost done! Please click the button below to complete your
          application.
        </p>
        <AutoAcceptButton />
      </>
    );
  } else {
    displayedContent = (
      <>
        <p>
          Welcome back! Your spot as a section leader is waiting for you. Please
          fill out the section labeled "Let's get to know each other" and then
          click the button below to complete your application.
        </p>
        <AutoAcceptButton />
      </>
    );
  }
  return (
    <div className="d-flex justify-content-center w-100">
      <div className="card mt-3 w-100" style={{maxWidth: innerWidth}}>
        <div className="card-body">
          <h4 className="mb-3">Returning Section Leader</h4>
          {displayedContent}
        </div>
      </div>
    </div>
  );
};

const useIsTodoCardComplete = ({todoCardData, allAppData}) => {
  return todoCardData.todo.every(item => checkComplete(item, allAppData));
};

export const TodoCard = ({title, content, allAppData}) => {
  return (
    <div className="d-flex justify-content-center w-100">
      <div className="card mt-3 w-100" style={{maxWidth: innerWidth}}>
        <div className="card-body">
          <h4 className="mb-3">{title}</h4>
          {content.map((item, index) => {
            return <TodoItem key={index} item={item} allAppData={allAppData} />;
          })}
        </div>
      </div>
    </div>
  );
};

const TodoItem = ({item, allAppData}) => {
  const isComplete = checkComplete(item, allAppData);
  return (
    <TodoItemOuter>
      <ItemLink item={item} />
      {isComplete && <FaCheck className="ml-2 text-success" />}
    </TodoItemOuter>
  );
};

const ItemLink = ({item}) => {
  const isMobile = useIsMobile();
  const icon = getIcon(item.type);
  console.log(icon);
  const navigate = useHistoryNavigate();
  let title = item.title;
  if (isMobile && item.mobileTitle) {
    title = item.mobileTitle;
  }
  const linkText = (
    <ItemLinkOuter>
      {icon}
      <span className="ml-2">{title}</span>
    </ItemLinkOuter>
  );

  // make it look like a link
  const linkStyle = {
    fontWeight: 500,
    cursor: 'pointer',
    color: '#337ab7',
  };

  async function onClick(e) {
    e.preventDefault();
    await item.preClick();

    // open item.to in new tab
    window.open(item.to, '_blank');
  }

  if (item.preClick) {
    return (
      <a style={linkStyle} href={item.to} onClick={e => onClick(e)}>
        {linkText}
      </a>
    );
  }

  return (
    <a style={linkStyle} onClick={() => navigate(item.to)} tabIndex={0}>
      {linkText}
    </a>
  );
};

const getIcon = type => {
  switch (type) {
    case 'aboutCourse':
      return <FaRocket />;
    case 'aboutJob':
      return <FaRocket />;
    case 'form':
      return <FaPen />;
    case 'lesson':
      return <FaBook />;
    case 'code':
      return <FaPython />;
    case 'record':
      return <FaVideo />;
    case 'debugging':
      return <FaPython />;
    default:
      return null;
  }
};

const ItemLinkOuter = styled.span`
  display: flex;
  align-items: center;
`;

const TodoItemOuter = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3em;
`;
