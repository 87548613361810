// @ts-ignore
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { LandingNav } from '../../../../landing/components/LandingNav';
// @ts-ignore
import { useWindowSize } from 'react-use-size';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import { isApplicationLive } from '../../../../config';
import { Authenticated } from 'components/auth/Authenticated';
import { useUser } from 'hooks/user/useUserId';
import styled from 'styled-components';
import { CompletionContext } from 'contexts/CompletionContext';
import { useAppStatusServerRecorder } from 'hooks/application/useAppStatusServerRecorder';
import { useReferralHook } from '../hooks/referralHook';
import { useConfetti } from 'components/confetti/useConfetti';
import { Role } from 'types/role';
import { EnrollmentContext } from 'contexts/EnrollmentContext';
import { Header } from './Header';
import { TodoCards } from 'course/application/general/TodoCards';
import { computeApplicationStatus } from 'course/application/general/checkStatus';
import { studentAppData } from './studentAppData';
import { ReferAFriend } from './Referral';
import { Loading } from 'components/loading/Loading';

const innerWidth = '640px';
export const StudentApplication = () => {
  if (!isApplicationLive) {
    return <UnreleasedAlert />;
  }
  return <Authenticated component={StudentApplicationMain} />;
};

export const StudentApplicationMain = () => {
  const user = useUser();
  const { targetCourseId } = useParams();
  const { isCompletionLoading } = useContext(CompletionContext);
  useReferralHook();

  const fbStudentApplicationPath = `users/${user.uid}/${targetCourseId}/studentApplication`;

  const db = getFirestore();
  const appDocRef = doc(db, fbStudentApplicationPath);

  const [appData, appDataLoading, appDataError] = useDocumentData(appDocRef);

  const dataPath = `course/${targetCourseId}`;

  const windowSize = useWindowSize();
  const { confettiParticles, fireConfetti } = useConfetti({ windowSize });
  const { roles } = useContext(EnrollmentContext);
  useEffect(() => {
    const courseRole = roles?.[targetCourseId];
    const isAdmit = courseRole === Role.STUDENT;
    if (isAdmit) {
      fireConfetti();
    }
  }, [roles]);

  const [courseData, courseDataLoading, courseDataError] = useDocumentData(
    doc(db, dataPath),
  );

  if (appDataLoading || isCompletionLoading || courseDataLoading) {
    return <Loading/>;
  }

  if (appDataError || courseDataError) {
    return <>Error loading application. Please try again later</>;
  }

  if (!courseData) {
    return <>Error: unknown course {targetCourseId}</>;
  }


  const appOpen = courseData?.studentAppOpen;
  return (
    <JoinOuter>
      {confettiParticles}
      <LandingNav applicationType={'student'} subpage={null} />
      <MainCardOuter>
        <MainCard
          appData={appData}
          appDataPath={fbStudentApplicationPath}
          appOpen={appOpen}
          courseData={courseData}
        />
      </MainCardOuter>
    </JoinOuter>
  );
};

const MainCard = ({ appData, appDataPath, appOpen, courseData }) => {
  const { assnProgress, lessonsProgress } = useContext(CompletionContext);
  const [onAppStatusUpdate] = useAppStatusServerRecorder(
    appData,
    appDataPath,
    'student',
  );

  const allData = { appData, assnProgress, lessonsProgress };
  const appStatus = computeApplicationStatus(allData, studentAppData);
  onAppStatusUpdate(appStatus);

  return (
    <JoinInner>
      <Header
        allAppData={allData}
        appStatus={appStatus}
        appDataPath={appDataPath}
        appOpen={appOpen}
        courseData={courseData}
      />
      <TodoCards
        applicationData={studentAppData}
        allAppData={allData}
      />
      <ReferAFriend showTeacherLink={false} />
    </JoinInner>
  );
};

const JoinOuter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('/splash3.jpeg');
  overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: none;
  }
`;

const MainCardOuter = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-grow: 1;
  }
`

const JoinInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  width: 680px;
  border-radius: 5px;
  align-items:flex-start;

  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 10px;
    border-radius: 0px;
    flex-grow: 1;
  }
`;

const backgroundStyle = {};

const UnreleasedAlert = () => {
  return (
    <div style={backgroundStyle}>
      <LandingNav applicationType={'student'} subpage={null} />
      <div className="d-flex justify-content-center">
        <div className="container mb-2 mt-3" style={{ maxWidth: innerWidth }}>
          <div className="row mb-3 mt-3">
            <div className="col">
              <div className="alert alert-primary" role="alert">
                <b>Coming soon!</b> Check back next week.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
