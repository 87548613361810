export const ERROR_CODES = {
  QUEUE: {
    ALREADY_IN_QUEUE: 'queue/already-in-queue',
    NOT_IN_QUEUE: 'queue/not-in-queue',
    QUEUE_CLOSED: 'queue/closed',
  },
  ROOMS: {
    INVALID_STATUS: 'rooms/invalid-room-status',
    INVALID_ROOM: 'rooms/invalid-room',
  },
  CHAT: {
    EMPTY_MESSAGE: 'chat/empty-message',
  },
  GENERIC: 'generic',
};
