import {useContext, useState} from 'react';
import {Form} from 'react-bootstrap';
import {FaLink, FaMagic} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {BookContext} from 'textbook/context/BookContext';
import {
  ChapterTitleStyle,
  ChapterTitleWrapper,
} from 'textbook/layout/BookLayout';

export const ChapterTitle = ({chapterTitle}) => {
  const {chapterId} = useParams();
  const {editing} = useContext(BookContext);
  const relativeUrl = `/book/${chapterId}`;
  const absoluteUrl = `${window.location.origin}${relativeUrl}`;

  const [showLinkIcon, setShowLinkIcon] = useState(false);

  const copyLink = () => {
    const linkUrl = editing ? relativeUrl : absoluteUrl;
    navigator.clipboard
      .writeText(linkUrl)
      .then(() => {
        Swal.fire({
          title: 'Link Copied!',
          icon: 'success',
          text: linkUrl,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          width: 500,
          timerProgressBar: true,
        });
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'Failed to copy the link. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <ChapterTitleWrapper
      onMouseEnter={() => setShowLinkIcon(true)}
      onMouseLeave={() => setShowLinkIcon(false)}
    >
      <ChapterTitleStyle>
        {/* left */}
        <LeftTitleButtonGroup></LeftTitleButtonGroup>

        {/* center */}
        <span dangerouslySetInnerHTML={{__html: chapterTitle}} />

        {/* right */}
        <RightTitleButtonGroup></RightTitleButtonGroup>

        {showLinkIcon && (
          <LinkIconWrapper
            onClick={copyLink}
            title="Copy link to this chapter"
            role="button"
            tabIndex="0"
            className="btn btn-link"
            onKeyPress={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                copyLink();
              }
            }}
            aria-label="Copy link to this chapter"
          >
            <FaLink />
          </LinkIconWrapper>
        )}
      </ChapterTitleStyle>
    </ChapterTitleWrapper>
  );
};

const InteractiveToggle = ({isInteractive, setIsInteractive}) => {
  return (
    <>
      <MagicLink
        className="btn btn-link btn-lg"
        onClick={() => setIsInteractive(!isInteractive)}
      >
        <FaMagic />
      </MagicLink>
      <Form.Check // prettier-ignore
        type="switch"
        id="interactive-switch"
        label=""
        size={16}
        checked={isInteractive}
        onChange={e => setIsInteractive(e.target.checked)}
      />
    </>
  );
};

const MagicLink = styled.button`
  padding: 0;
  padding-right: 5px;
`;

const TitleButtonGroup = styled.div`
  display: flex;
  margin-right: 10px;
  width: 50px;
  font-size: 18px;
  align-items: center;
`;

const RightTitleButtonGroup = styled(TitleButtonGroup)`
  justify-content: flex-end;
`;

const LeftTitleButtonGroup = styled(TitleButtonGroup)`
  justify-content: flex-start;
`;

const LinkIconWrapper = styled.button`
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-right: -15px;
  color: grey;
  opacity: 0;
  transition:
    opacity 0.2s ease,
    color 0.2s ease,
    text-decoration 0.2s ease;

  /* Reserve space for the icon and make it appear on hover */
  ${ChapterTitleWrapper}:hover & {
    opacity: 0.7;
  }
`;
