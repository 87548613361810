import {TextbookButtonBar} from 'components/richTextEditor/TipTap/buttonbars/TextbookButtonBar';
import {TipTap} from 'components/richTextEditor/TipTap/TipTap';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useContext, useState} from 'react';
import {FaBars, FaEdit} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {getChapterLocation} from './context/TextbookUtil';
import {useProSidebar} from 'react-pro-sidebar';
import {isMinimumRole} from 'contexts/profileUtil';
import styled from 'styled-components';

export const TextbookChapterOld = ({chapterData}) => {
  const {userData} = useContext(ProfileContext);
  const [editing, setEditing] = useState(false);
  const courseId = useCourseId();
  const chapterId = useParams()?.chapterId;
  const [sectionIndex, chapterIndex] = getChapterLocation(
    chapterData,
    chapterId,
  );
  const chapterTitle =
    chapterData?.outline[sectionIndex]?.chapters[chapterIndex]?.title;

  const tipTapPath = `/textbook/${courseId}/chapters/${chapterId}`;

  const courseRole = userData?.courseRole;

  const editable = isMinimumRole(courseRole, Role.TA);
  console.log(userData);
  return (
    <TextbookChapterOuter>
      <div className="d-flex justify-content-between">
        <div style={{fontSize: '1.875rem'}}>{chapterTitle}</div>
        <div className="d-flex">
          {editable && (
            <button
              style={{marginTop: '-5px'}}
              className="ml-2 btn btn-lg btn-light"
              onClick={() => setEditing(!editing)}
              aria-label="edit"
            >
              <FaEdit />
            </button>
          )}
          <ToggleAside />
        </div>
      </div>
      <hr />
      <div className="textbook" style={tiptapWrapperStyle}>
        <TipTap
          editable={editing}
          firebaseDocPath={tipTapPath}
          collaborative={editable}
          buttonBar={TextbookButtonBar}
          showLoadingSkeleton={true}
          height="100%"
        />
      </div>
    </TextbookChapterOuter>
  );
};

const ToggleAside = props => {
  const {broken, toggleSidebar} = useProSidebar();
  return (
    <div>
      {broken && (
        <button
          className="btn btn-lg btn-light"
          onClick={() => toggleSidebar()}
          aria-label="menu"
        >
          <FaBars />
        </button>
      )}
    </div>
  );
};

const tiptapWrapperStyle = {
  height: 'calc(100vh)',
  maxWidth: '780px',
};

const TextbookChapterOuter = styled.div`
  padding: 20px;
  overflow: auto;
  height: 100%;
  width: 100%;
  max-width: 780px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
