import React, { type CSSProperties } from "react";
import { FlagWithoutOverlay} from "components/countries/LocationInfo";
import { getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getUserDocumentRef } from "../../../firebase/models";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { useNavigate } from "react-router";

export const SectionMember = ({memberId}) => {
  const courseId = useCourseId()
  const navigate = useNavigate()
  const memberRef = getUserDocumentRef(memberId)
  const [memberData, isLoading, dbUserError] = useDocumentDataOnce(memberRef);



  const handleClick = () => navigate(`/${courseId}/user/${memberId}`);

  if(isLoading) {
    return <div style={memberStyle}/>
  }
  if(dbUserError){
    console.error(dbUserError)
    return <div style={memberStyle}/>
  }
  const displayName = memberData?.displayName
  const countryCode= memberData?.country
  const displayTag = countryCode ?
    <><span className="mr-1">{displayName}</span> <FlagWithoutOverlay country={countryCode} height={15}/></>
    : displayName


  return  <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 100 }}
      overlay={
        (props) => (
          <Tooltip id="button-tooltip" {...props}>
            {displayTag}
          </Tooltip>
        )
      }
    ><div onClick={handleClick}>
    <SectionMemberInner memberId={memberId} memberData={memberData}/>
    </div>
    </OverlayTrigger>
  
}

const SectionMemberInner = ({memberId,memberData}) => {
  const db = getFirestore(getApp())
  const courseId = useCourseId()
  const navigate = useNavigate()
  
  
  

  const photoURL = memberData?.photoURL
  const displayName = memberData?.displayName
  
  if(!displayName || displayName.length == 0){
    return <NameOverlay 
      displayName={"Anonymous"}
      component={<NoImagePlaceholder displayName={"Anonymous"}/>}
      courseId={courseId}
      memberId={memberId}
      navigate={navigate}
    />
  }

  if(!photoURL) {
    return <NoImagePlaceholder displayName={displayName}/>
  }

  



  return <div style={memberStyle}>
    <img src={photoURL} style={memberImageStyle}/>
  </div>
  // return <div style={memberStyle}>
  //   <img src={memberData.photoURL} style={memberImageStyle}/>
  // </div>
}

const NoImagePlaceholder = ({ displayName }) => {
  const firstLetter = displayName[0].toUpperCase();

  const continerStyle = {
    ...memberStyle,
    backgroundColor: 'salmon',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const textStyle = {
    fontSize: '36px',
    color: 'white',
    fontWeight: 'bold',
  }

    return <div style={continerStyle}>
      <span style={textStyle}>{firstLetter}</span>
    </div>
};

const NameOverlay = ({displayName, component, courseId, memberId, navigate}) => {

  const handleClick = () => navigate(`/${courseId}/user/${memberId}`);

  const wrappedComponent = React.cloneElement(
    component,
    {
      onClick: handleClick
    }
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 100 }}
      overlay={
        (props) => (
          <Tooltip id="button-tooltip" {...props}>
            {displayName}
          </Tooltip>
        )
      }
    >
      {wrappedComponent}
    </OverlayTrigger>
  );
}

const memberImageStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '10px'
}


const memberStyle: CSSProperties = {
  width: '80px',
  height: '80px',
  backgroundColor: 'white',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',
  fontWeight: 'bold',
  cursor: 'pointer',
  fontSize: '1.5rem',
  margin: '0.5rem',
}