import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  TextInput,
  TextAreaInput,
  SelectInput,
  MultipleCheckbox,
  AgreeCheckbox,
  Checkbox,
  DateOfBirth,
} from "components/Forms/FormixComponents";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";


const escapeStringRegexp = require("escape-string-regexp");

const DEFAULT = {
  signature1: "",
};

const CODE_OF_CONDUCT_URL =
  "https://docs.google.com/document/d/11v-1jWJemYkE9DRgD4_64GyX2JidTJkRkhjQt_VQ50M/edit?usp=sharing";

// const SignatureInput = ({ name, label, required_name }) => {
//   return <TextInput name={name} placeholder={required_name} type="text" />;
// };

export const SignatureInput = ({ name, label, required_name }) => <TextInput
  label={label}
  name={name}
  type="text"
  placeholder={required_name}
/>

function VolunteerAgreementForm(props) {
    const required_name = props.displayName;
    const name_regex = escapeStringRegexp(required_name);
    const tipTapPath = `course/${props.courseId}/docs/volunteer-agreement/role/sl`

    let initSignature = props.agreementData?.signature1 || "";
    let initValues = { signature1: initSignature };

    return (
      <div>
        {<AdminRichTextEditor firebaseDocPath={tipTapPath} />}

        <Formik
          initialValues={initValues}
          validationSchema={Yup.object({
            signature1: Yup.string()
            .matches(
              name_regex,
              ' '
            )
              .required(""),
          })}
          onSubmit={(values, { setSubmitting }) => {
            props.onSubmit(values);
          }}
        >
          {({ errors, touched, values, validateField, validateForm }) => {
            const enteredSignature = values?.signature1;
            const isCorrect = enteredSignature === required_name;
            return <Form style={{ width: "100%" }}>
              <SignatureInput
                name="signature1"
                label="Code of Conduct"
                required_name={required_name}
              />

              {isCorrect ? null : (
                <div style={{ color: "salmon" }} >
                  Signature must match "{required_name}"
                </div>
              )}
              <Button
                className="btn btn-primary mt-3"
                type="submit"
                disabled={false}
                onClick={()=>{props.onSubmit(values);}}
              >
                Save
              </Button>

              <Link className="btn btn-secondary ml-2 mt-3" to={`/${props.courseId}/onboarding/teach`}>
                Back
              </Link>
            </Form>
          }}
        </Formik>
      </div>
    );
}

export default VolunteerAgreementForm;
