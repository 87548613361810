import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {Role} from 'types/role';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {TipTap} from 'components/richTextEditor/TipTap/TipTap';
import {HandoutButtonBar} from 'components/richTextEditor/TipTap/buttonbars/HandoutButtonBar';
import {FaEdit} from 'react-icons/fa';
import {useShowDeveloperButtonIfHidden} from 'contexts/DeveloperToolsContext';
export const BlogPage = () => {
  useShowDeveloperButtonIfHidden();
  return (
    <CoursePageBodyContainer
      mainColumn={<HandoutInner />}
      rightColumn={<></>}
      singleColumn={<HandoutInner />}
    />
  );
};

const HandoutInner = () => {
  const blogId = useParams().blogId;

  return (
    <div style={{marginTop: 20}}>
      <BlogRichTextEditor firebaseDocPath={`blog/${blogId}`} />
    </div>
  );
};

interface BlogRichTextEditorProps {
  firebaseDocPath: string;
}

const BlogRichTextEditor = ({firebaseDocPath}: BlogRichTextEditorProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const viewerCanEdit = false;
  return (
    <div className="handout-tiptap">
      <TipTap
        firebaseDocPath={firebaseDocPath}
        editable={isEditing}
        collaborative={isEditing}
        buttonBar={HandoutButtonBar}
        showLoadingSkeleton={true}
      />
      {viewerCanEdit && (
        <button
          className="btn btn-light"
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? 'Done' : <FaEdit />}
        </button>
      )}
    </div>
  );
};
