import {ProfileContext} from 'contexts/ProfileContext';
import {useContext} from 'react';
import {Navigate} from 'react-router';
import {useCourseId} from 'hooks/router/useUrlParams';

export const SectionForumRedirect = ({}) => {
  const {userData} = useContext(ProfileContext);

  const courseId = useCourseId();

  // this is a dictionary of courseId: list of sectionRefs
  // I want to get the list of sectionRefs
  const sectionRefs = userData.sections[courseId];
  const nSections = sectionRefs ? sectionRefs.length : 0;

  if (nSections > 1) {
    return <>You are in multiple sections!</>;
  }

  if (nSections === 1) {
    const sectionRef = sectionRefs[0];
    const sectionId = sectionRef.id;
    return <Navigate to={`/${courseId}/sectionforum/${sectionId}`} />;
  }

  return <>You are not in a section yet!</>;
};
