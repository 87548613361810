import {IDEContext} from 'ide/contexts/IDEContext';
import {useContext} from 'react';
import {FaFlag, FaLaptopCode, FaPlay, FaPlus} from 'react-icons/fa';
import styled from 'styled-components';
import useMediaQuery, {Breakpoints} from 'utils/useMediaQuery';

export const BottomNavBarItem = ({selected, text, icon, onClick}) => {
  const isMobile = useMediaQuery(Breakpoints.XS);

  return (
    <div
      onClick={onClick}
      className={`d-flex flex-column justify-content-center align-items-center my-2 btn`}
      style={{
        opacity: selected ? 1 : 0.4,
        background: selected ? '#1b2126' : '',
        borderRadius: '20px',
      }}
    >
      {icon}
      <span
        className={`${selected ? 'fw-bold' : ''} text-white mt-1`}
        style={{
          fontSize: isMobile ? '14px' : '10px',
        }}
      >
        {text}
      </span>
    </div>
  );
};
export const BottomNavBar = ({hasAssn, bottomBarRef, hasMore}) => {
  const {selectedMobileTab, setSelectedMobileTab} = useContext(IDEContext);
  return (
    <NavOuter className="navbar navbar-light bg-dark" ref={bottomBarRef}>
      {hasAssn ? (
        <BottomNavBarItem
          selected={selectedMobileTab === 'Problem'}
          text="Problem"
          icon={<FaFlag color="white" size={20} />}
          onClick={() => {
            setSelectedMobileTab('Problem');
          }}
        />
      ) : null}
      <BottomNavBarItem
        text="Code"
        icon={<FaLaptopCode color="white" size={20} />}
        selected={selectedMobileTab === 'Code'}
        onClick={() => {
          setSelectedMobileTab('Code');
        }}
      />
      <BottomNavBarItem
        text="Output"
        icon={<FaPlay color="white" size={20} />}
        selected={selectedMobileTab === 'Output'}
        onClick={() => {
          setSelectedMobileTab('Output');
        }}
      />
      {hasMore ? (
        <BottomNavBarItem
          text="More"
          icon={<FaPlus color="white" size={20} />}
          selected={selectedMobileTab === 'More'}
          onClick={() => {
            setSelectedMobileTab('More');
          }}
        />
      ) : null}
    </NavOuter>
  );
};

const NavOuter = styled.nav`
  height: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
`;
