import React, { useRef, createContext, useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { collection, doc, getDoc, getFirestore, onSnapshot, onSnapshotsInSync, writeBatch } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { useRoomInfoOnce } from "firebase/realtime/Rooms";
import { RoomData, unpackRoomData } from "./utils";
import { ProfileContext } from "contexts/ProfileContext";
import { useUserId } from "hooks/user/useUserId";

const ZOOM_MEETING_SDK = "vz2vapx4Tfm_riIymGMquQ"



type CollabContextType = {
    sessionRef: any,
    isSessionRefLoaded: boolean,
    setIsSessionRefLoaded: (val: boolean) => void,
    collaborators: any,
    setCollaborators: (val: any) => void,
    clientId: string,
    setClientId: (val: string) => void,
    mice: any,
    setMice: (val: any) => void,
    children?: React.ReactNode,
    roomData: RoomData,
    roomLoaded: boolean,
    roomExists: boolean,
    messages: any[],
    leaveZoomMeeting: () => void,
    clearActiveRoomAndTeachNowDoc: () => Promise<boolean>,
    endForTeacher: () => void,
    removeSearchParamsAndReset: () => void,
    endZoomRef: any,
    roomId: string
}

const defaultValues = {
    sessionRef: null,
    isSessionRefLoaded: false,
    setIsSessionRefLoaded: (val) => {},
    collaborators: {},
    setCollaborators: (val) => {},
    clientId: "",
    setClientId: (val) => {},
    mice: {},
    setMice: (val) => {},
    roomData: {
        projId: "",
        teacherId: "",
        studentId: "",
        meetingId: "",
        meetingPassword: "",
        signature: "",
        userName: "",
        joinUrl: "",
        studentLeft: false,
        loading: true
    },
    roomLoaded: false,
    roomExists: false,
    messages: [],
    // endSession: async () => {},
    leaveZoomMeeting: () => {},
    clearActiveRoomAndTeachNowDoc: async () => {return false},
    removeSearchParamsAndReset: () => {},
    endForTeacher: () => {},
    endZoomRef: null,
    roomId: ""
}



export const CollabContext = createContext<CollabContextType>(defaultValues);

export const CollabProvider: React.FC<any> = ({ children }) => {
    const db = getFirestore();
    const sessionRef = useRef(null);
    const [isSessionRefLoaded, setIsSessionRefLoaded] = useState(defaultValues.isSessionRefLoaded);
    const [collaborators, setCollaborators] = useState(defaultValues.collaborators);
    const [clientId, setClientId] = useState(defaultValues.clientId);
    const [mice, setMice] = useState(defaultValues.mice);
    const [roomId, setRoomId] = useState("");
    const [messages, setMessages] = useState(defaultValues.messages);
    const courseId = useCourseId();
    const [roomExists, setRoomExists] = useState(defaultValues.roomExists);
    const [roomLoaded, setRoomLoaded] = useState(defaultValues.roomLoaded);
    const [roomData, setRoomData] = useState<RoomData>(defaultValues.roomData);
    const endZoomRef = useRef(null);
    const { userData } = useContext(ProfileContext)
    const userId = useUserId()
    const navigate = useNavigate();
    

    // get search params
    const [searchParams, setSearchParams] = useSearchParams();

    const leaveZoomMeeting = () => {
        if (!roomLoaded) return;
        if (endZoomRef.current) {
            endZoomRef.current()
        }
    }

    const clearActiveRoomAndTeachNowDoc = async () => {
        if (!roomLoaded) return;
        // const roomDocRef = doc(db, "teachnow", courseId, "room_logs", roomId)
        const activeRoomdocRef = doc(db, "teachnow", courseId, "active_rooms", userId)
        let userTeachNowDocRef;
        if (userId === roomData.studentId) {
            userTeachNowDocRef = doc(db, "teachnow", courseId, "students", userId)
        } else {
            userTeachNowDocRef = doc(db, "teachnow", courseId, "teachers", userId)
        }

        const batch = writeBatch(db)
        batch.delete(activeRoomdocRef)
        batch.delete(userTeachNowDocRef)
        await batch.commit()
        return userId === roomData.studentId;
    }

    const removeSearchParamsAndReset = () => {
        if (userId === roomData.studentId) {
            // remove search params
            searchParams.delete("r")
            setSearchParams(searchParams)
            setRoomLoaded(false)
            setRoomExists(false)
            setRoomData(defaultValues.roomData)
            setMessages(defaultValues.messages)
            
        } else {
            // TODO

        }
    }

    const endForTeacher = () => {
        navigate(`/${courseId}/teachnowfeedback?r=${roomId}`)
    }

    useEffect(() => {
        if (searchParams.has("r")) {
            setRoomId(searchParams.get("r") as string)
        }
    }, [searchParams])

    useEffect(() => {
        let roomUnsubscribe = () => {}
        let messagesUnsubscribe = () => {}
        if (roomId) {
            const setUpRoom = async () => {
                const roomDocRef = doc(db, "teachnow", courseId, "room_logs", roomId)

                const roomUnsub = onSnapshot(roomDocRef, (doc) => {
                    if (doc.exists()) {
                        const roomData = unpackRoomData(doc.data(), userData.displayName)
                        console.log(roomData)
                        setRoomData((prev) => ({...roomData}))
                        setRoomExists(true)
                        if (roomData.studentLeft && userId !== roomData.studentId) {
                            if(endZoomRef.current) {
                                endZoomRef.current()
                            }
                            endForTeacher()
                        }
                    } else {
                        setRoomExists(false)
                    }
                })

                
                const messagesColRef = collection(db, "teachnow", courseId, "room_logs", roomId, "messages")
                const messagesUnsub = onSnapshot(messagesColRef, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            setMessages((prev) => [...prev, change.doc.data()])
                        }
                    })
                })
                return {
                    roomUnsub,
                    messagesUnsub
                }
            }

            setUpRoom()
                .then((unsubs) => {
                    messagesUnsubscribe = unsubs.messagesUnsub
                    roomUnsubscribe  = unsubs.roomUnsub
                    setRoomLoaded(true)
                })



            return () => {
                roomUnsubscribe();
                messagesUnsubscribe()
            }
        }

    }, [roomId])


    return (
        <CollabContext.Provider value={{ 
            
            sessionRef, 
            isSessionRefLoaded, 
            setIsSessionRefLoaded, 
            collaborators, 
            setCollaborators, 
            clientId, 
            setClientId,
            mice,
            setMice,
            roomData,
            roomLoaded,
            roomExists,
            messages,
            leaveZoomMeeting,
            clearActiveRoomAndTeachNowDoc,
            endForTeacher,
            removeSearchParamsAndReset,
            endZoomRef,
            roomId
            }}>
            {children}
        </CollabContext.Provider>
    )
}
