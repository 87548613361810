import {useRef, CSSProperties, useEffect} from 'react'; // every page needs to import react
import 'react-tabs/style/react-tabs.css';

import {
  AssnPrompt,
  LessonCodeEditor,
  AdventureControls,
  CodeAdventureTerminal,
  KarelSpeechBubble,
} from './CodeAdventureLesson';

import {useCodeAdventure} from './CodeAdventureContext';
import styled from 'styled-components';
import {FaLaptopCode, FaPlay} from 'react-icons/fa';
import { BottomNavBar } from 'ide/mobile/bottomBar/BottomNavBar';

export enum MobileTab {
  PROBLEM = 'Problem',
  CODE = 'Code',
  OUTPUT = 'Output',
}
const {PROBLEM, CODE, OUTPUT} = MobileTab;

export const MobileView = ({
  title,
  tasks,
  onBack,
  actionWidgetProps,
}) => {
  const {selectedMobileTab, setSelectedMobileTab} = useCodeAdventure();

  useEffect(() => {
    console.log('selecting PROBLEM');
    setSelectedMobileTab(PROBLEM);
  }, []);

  const bottomBarRef = useRef(null);
  const contentInnerStyle: CSSProperties = {
    paddingBottom: bottomBarRef.current?.clientHeight || 0,
  };
  const inTextBootstrapClassName =
    'bg-black border border-black border-2 rounded';
  return (
    <ContentOuter>
      <ContentInner style={contentInnerStyle} className="d-flex flex-column">
        {selectedMobileTab === PROBLEM ? (
          <div className="d-flex flex-column h-100 p-3">
            <AssnPrompt tasks={tasks} title={title} />
            <KarelSpeechBubble>
              <h6 className="text-white my-0">
                Enter the{' '}
                <FaLaptopCode className={inTextBootstrapClassName} size={20} />{' '}
                Code tab below to get started and see your program run in the{' '}
                <FaPlay className={inTextBootstrapClassName} size={20} /> Output
                tab!
              </h6>
            </KarelSpeechBubble>
          </div>
        ) : selectedMobileTab === CODE ? (
          <MobileTopContent>
            <LessonCodeEditor />
          </MobileTopContent>
        ) : null}
        <MobileTopContent
          className={selectedMobileTab === OUTPUT ? 'd-block' : 'd-none'}
        >
          <CodeAdventureTerminal />
        </MobileTopContent>
        {selectedMobileTab !== PROBLEM ? (
          <AdventureControls
            onBack={onBack}
            actionWidgetProps={actionWidgetProps}
          />
        ) : null}
      </ContentInner>
      <BottomNavBar
        hasAssn={true}
        hasMore={false}
        bottomBarRef={bottomBarRef}
      />
    </ContentOuter>
  );
};

const ContentOuter = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
`;

const ContentInner = styled.div`
  flex-grow: 1;
`;

const MobileTopContent = styled.div`
  padding-top: 1rem;
  flex-grow: 1;
  overflow: auto;
`;
