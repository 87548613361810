// LoadingContext.js

import { LoadingComponent } from 'components/loading/Loading';
import React, { createContext, memo, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

const defaultValue = {
  isLoading: false,
  showLoading: () => {},
  hideLoading: () => {},
};

export const LoadingContext = createContext(defaultValue);

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedIsLoading] = useDebounce(isLoading, 3); // 3ms should be enough to prevent flickering

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
      <GlobalLoadingOverlay debouncedIsLoading={debouncedIsLoading}/>
    </LoadingContext.Provider>
  );
}

export function useLoadingContext() {
  return useContext(LoadingContext);
}

interface GlobalLoadingOverlayProps {
  debouncedIsLoading: boolean;
}

const GlobalLoadingOverlay = memo(function GlobalLoadingOverlay({ debouncedIsLoading }: GlobalLoadingOverlayProps) {
  const isLoading = debouncedIsLoading;

  // the point of this ref is to make sure that the loading animation starts on the first render
  const hasStartedAnimation = useRef(false);
  const url = window.location.href;

  useEffect(() => {
    if (!debouncedIsLoading) {
      hasStartedAnimation.current = true;
    }
  }, [debouncedIsLoading]);

  // if you change the key, the animation will restart
  const componentKeyLoading = hasStartedAnimation.current ? 'loading' : 'loading-not-started';
  const componentKey = `${componentKeyLoading}-${url}`
  
  return (
    <LoadingOuter
      role="status"
      aria-live="polite"
      aria-hidden={!isLoading}
      style={{
        opacity: isLoading ? 1 : 0,
        pointerEvents: 'none',
      }}
      key={componentKey}
    >
      <VisuallyHidden>Loading, please wait...</VisuallyHidden>
      <LoadingComponent />
    </LoadingOuter>
  );
});

const LoadingOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: opacity 0.3s ease;
`;

// Utility for visually-hidden text (SR-only style).
// You can move this to a shared location if you need it elsewhere.
const VisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;