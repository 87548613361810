import prependHttp from 'prepend-http';
import {FaLink} from 'react-icons/fa';
import Swal from 'sweetalert2';

export const EditLinkButton = ({editor}) => {
  return (
    <button
      onClick={() => createEditableLink(editor)}
      className={'btn btn-sm btn-light '}
    >
      <FaLink />
    </button>
  );
};

const createEditableLink = editor => {
  // get the selected text and the link if there is one
  const {from, to} = editor.state.selection;
  let selectedText = editor.state.doc.textBetween(from, to, null, '\n');
  let selectedLink = '';
  let selectedNode = null;
  // const marks = editor.state.doc.marksAt(from);
  const pos = editor.state.selection.$from;
  const node = editor.state.doc.nodeAt(pos.pos);
  const linkMark = node?.marks.find(mark => mark.type.name === 'link');
  if (linkMark) {
    console.log(linkMark);
    selectedLink = linkMark.attrs.href;
    selectedText = node.text;
    selectedNode = node;
    // expand the selection to cover the whole selectedNode
    editor.chain().extendMarkRange('link').run();
  }

  // fire a swal that gets the link and the text from the user
  Swal.fire({
    html: `
    <b>Link:</b><br/>
    <input id="swal-input1" class="swal2-input" value="${selectedLink}" placeholder="www.python.org" style="width: 100%;margin:0px;"><br/><br/>
    <b>Text:</b>
    <input id="swal-input2" class="swal2-input" value="${selectedText}" placeholder="Click here" style="width: 100%;margin:0px;">
  `,
    width: '700px', // You can adjust this value as needed.
    focusConfirm: false,
    confirmButtonText: 'Update Link',
    preConfirm: () => {
      const link = document.getElementById('swal-input1').value;

      const text = document.getElementById('swal-input2').value;
      return {link, text};
    },
  }).then(result => {
    if (result.isConfirmed) {
      const {link, text} = result.value;
      // check if the url is valid
      const url = prependHttp(link, {https: true});
      let newContent = `<a href="${url}" target="_blank" >${text}</a>`;
      if (!selectedNode) {
        newContent += ' ';
      }
      editor.chain().focus().insertContent(newContent).run();

      // now focus the editor again right after the inserted link
      editor.chain().focus().run();
    }
  });
};
