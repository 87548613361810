// import firebase from "firebase";
import {Suspense, lazy, useEffect} from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useParams,
} from 'react-router-dom';
import {QueryClientProvider, QueryClient} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

// set up the style
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';

import {Authenticated} from './components/auth/Authenticated';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import PyodideProvider from './components/pyodide/PyodideProvider';
import {Loading} from 'components/loading/Loading';
import {EmailActionHandler} from 'course/emailActionHandler/EmailActionHandler';
import {CourseProvider} from 'contexts/CourseContext';
import {ProjectShowcase} from 'course/finalproject/showcase/ProjectShowcase';

import {WelcomeChoice} from 'landing/welcome';
import {Unsubscribe} from 'components/auth/Unsubscribe';
import {InterestForm} from 'forms/Interest';
import {CustomAuth} from 'components/auth/CustomAuth';
import CourseRoutes from './CourseRoutes';
import {TranslationProvider} from 'contexts/TranslationContext';
import {DevelopmentOnlyRoute} from 'components/DevelopmentOnly';
import {Page404} from 'components/errors/Page404';
import {NoAccess} from 'components/errors/NoAccess';
import {ErrorPage} from 'components/errors/ErrorPage';
import {AlwaysFailingPage} from 'components/errors/AlwaysFailingPage';
import CipAnalyticsWrapperRoute from 'engagement/CipAnalyticsWrapperRoute';
import {BlogPage} from 'blog/BlogPage';
import { LoadingTest } from 'components/loading/LoadingTest';
// Lazy imports load only when the page is visited, reducing initial load time
const TermsOfService = lazy(() => import('./landing/termsOfService/default'));
const Landing = lazy(() => import('./landing/home/default'));
const Team = lazy(() => import('./landing/team/default'));
const ContactUs = lazy(() => import('./landing/contactUs/default'));
// const CourseRoutes = lazy(() => import("./CourseRoutes"));
const AlumRoutes = lazy(() => import('./alumni/AlumniRoutes'));
const AlumniHome = lazy(() => import('./alumni/pages/Home'));
const AlumPageContainer = lazy(() => import('./alumni/AlumniPage'));
const DeleteWhatsappData = lazy(() => import('./components/auth/DeleteWhatsappData'));

// TODO: @piech. Refactor: pull Authenticated for the course page to the outer router

const queryClient = new QueryClient();

/*
 * This contains the broader route initialization.
 * If a route does not belong to a course, put it here.
 */
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<CipAnalyticsWrapperRoute />}
      errorElement={<ErrorBoundary />}
    >
      {/* Landing website */}
      <Route
        index
        element={
          <Suspense fallback={<></>}>
            <Landing />
          </Suspense>
        }
      />
      <Route
        path="terms-of-service"
        element={
          <Suspense>
            <TermsOfService />
          </Suspense>
        }
      />
      <Route
        path="team"
        element={
          <Suspense>
            <Team />
          </Suspense>
        }
      />
      <Route
        path="2023/showcase"
        element={
          <QueryClientProvider client={queryClient}>
            <ProjectShowcase courseId={'cip3'} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        }
      />
      <Route
        path="2024/showcase"
        element={
          <QueryClientProvider client={queryClient}>
            <ProjectShowcase courseId={'cip4'} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        }
      />
      <Route path="emailactionhandler" element={<EmailActionHandler />} />
      <Route
        path="welcome"
        element={<Authenticated component={WelcomeChoice} />}
      ></Route>

      <Route
        path="unsubscribe"
        element={<Authenticated component={Unsubscribe} />}
      ></Route>

      <Route
        path="loadingtest"
        element={<LoadingTest/>}
      />

      <Route
        path="delete-whatsapp-data"
        element={
          <Suspense>
            <DeleteWhatsappData />
          </Suspense>
        }
      />

      <Route path="interest" element={<InterestForm />}></Route>
      <Route path="oauth2" element={<CustomAuth />}></Route>
      <Route
        path="blog/:blogId"
        element={<Authenticated component={BlogPage} />}
      />

      <Route path="2021/showcase" element={<ShowcaseRedirect />} />
      <Route path="2021/showcase/:showcaseId" element={<ShowcaseRedirect />} />
      <Route
        path="contact"
        element={
          <Suspense>
            <ContactUs />
          </Suspense>
        }
      />

      <Route
        path="alumni"
        element={<Authenticated component={AlumPageContainer} />}
      >
        <Route path="*" element={<AlumRoutes queryClient={queryClient} />} />
        <Route index element={<AlumniHome />} />
      </Route>
      {/* Development only routes. Any route that starts with /dev will ONLY
       * exist in the locally built version of the app. These routes are
       * designed for testing and you can be confident that they will not appear
       * in the production version of the app.
       */}
      <Route path="dev" element={<DevelopmentOnlyRoute />}>
        <Route path="loading" element={<Loading />} />
        <Route path="404" element={<Page404 message="A test 404 page." />} />
        <Route
          path="noaccess"
          element={<NoAccess message="A test no access page." />}
        />
        <Route
          path="error"
          element={
            <ErrorPage
              title="An example error page."
              msg="This is a test error message."
            />
          }
        />
        <Route
          path="error-boundary"
          element={
            <Suspense fallback={<ErrorBoundary />}>
              <AlwaysFailingPage />
            </Suspense>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Route>
      <Route
        path=":courseId"

        element={
          <CourseProvider>
            {/* Translations are offered globally (see `src/index.js`), but when
             * a course is present, we offer course-specific translations.
             */}
            <TranslationProvider>
              <PyodideProvider>
                <Outlet />
              </PyodideProvider>
            </TranslationProvider>
          </CourseProvider>
        }
      >
        <Route path="*" element={<CourseRoutes queryClient={queryClient} />} />
        <Route index element={<Navigate to="home" replace />} />
      </Route>
    </Route>,
  ),
);

export const Router = () => {
  return <RouterProvider router={router} />;
};

function ShowcaseRedirect() {
  const showcaseId = useParams().showcaseId || '';
  const path = `https://codeinplace-2021.netlify.app/2021/showcase/${showcaseId}`;
  useEffect(() => {
    window.location.href = path;
  }, []);

  return <></>;
}
