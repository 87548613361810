import {analytics} from 'firebaseApp';
import {logEvent, type EventParams} from 'firebase/analytics';
import {cloneElement, type ReactElement} from 'react';

/**
 * Props for the LogOnClick component.
 */
interface LogOnClickProps {
  /** The child component to wrap. */
  children: ReactElement;
  /** The name of the event to log. Refer to
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
   */
  eventName?: string;
  /** The parameters for the event. Refer to
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
   */
  eventParams?: EventParams;
}

/**
 * This component takes any clickable child component and adds behavior to log
 * an event to Google Analytics.
 *
 * @example
 * Note that while
 *
 * ```typescript
 * <LogOnClick>
 *   <button>Click me</button>
 * </LogOnClick>
 * ```
 * will work, you are wasting a lot of metadata.
 *
 * Instead, you should at least specify eventParams (such as content_type and
 * content_id) and potentially eventName.
 *
 * ```typescript
 * <LogOnClick eventName="select_content" eventParams={{content_type: 'button', content_id: 'my_clickable_button'}}>
 *   <button>Click me</button>
 * </LogOnClick>
 * ```
 */
const LogOnClick = ({
  children,
  eventName = 'select_content',
  eventParams = undefined,
}: LogOnClickProps) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // Send log to GA4
    logEvent(analytics, eventName, eventParams);

    // Preserve any existing onClick handler on the child
    if (typeof children.props.onClick === 'function') {
      children.props.onClick(event);
    }
  };

  // Clone the child to intercept its onClick
  return cloneElement(children, {
    onClick: handleClick,
  });
};

export default LogOnClick;
