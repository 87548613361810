import {hashString} from 'react-hash-string';
import type {CourseId, UserId} from 'types/common';

const LEARN_REFERRAL_LINK_BASE =
  'https://codeinplace.stanford.edu/public/join/cip5';
const TEACH_REFERRAL_LINK_BASE =
  'https://codeinplace.stanford.edu/public/applyteach/cip5';

/**
 * Generates a unique referral code for a user-course pair.
 */
export const getReferralCode = (userId: UserId, courseId: CourseId): string => {
  // the referral code is a hash of the student id and the course id
  return String(Math.abs(hashString(`${userId}-${courseId}`)));
};

/**
 * Generates a referral link for a user-course pair for applying to learn in the live course.
 */
export function getLearnReferralLink(
  userId: UserId,
  courseId: CourseId,
): string {
  const referralCode = getReferralCode(userId, courseId);
  return `${LEARN_REFERRAL_LINK_BASE}?r=${referralCode}`;
}

/**
 * Generates a referral link for a user-course pair for teaching.
 */
export function getTeachReferralLink(
  userId: UserId,
  courseId: CourseId,
): string {
  const referralCode = getReferralCode(userId, courseId);
  return `${TEACH_REFERRAL_LINK_BASE}?r=${referralCode}`;
}
