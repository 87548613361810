import COUNTRIES from "../../../../data/countries/country_names";
import { FieldArray, useField } from "formik";
import { Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FaTrash } from "react-icons/fa";
import {
    TextInput,
    TextAreaInput,
    SelectInput,
    MultipleCheckbox,
    AgreeCheckbox,
    Checkbox,
    DateOfBirth,
  } from "../../../../components/Forms/FormixComponents";

export const NameInput = ({name}) => <TextInput
  label="What is your name?"
  name={name}
  type="text"
  placeholder="Jane Doe"
/>

export const GenderInput = ({name}) => <SelectInput label="Gender" name={name}>
    <option value=""></option>
    <option value="female">Female</option>
    <option value="male">Male</option>
    <option value="non-binary">Non-binary</option>
    <option value="other">Other</option>
    <option value="na">Prefer not to say</option>
</SelectInput>

export const DateOfBirthInput = ({name}) => <DateOfBirth name={name}/>

export const CityInput = ({name}) => <TextInput
  label="What city are you in?"
  name={name}
  type="text"
  placeholder="New York City"
/>

export const CountryInput = ({name, values, touched, errors, setFieldValue, setFieldTouched}) => {
  
  // value for typeahead must be a list
  const currValue = values.country ? [values.country] : []
  let error = errors.country ? errors.country : null
  const isTouched = touched.country ? touched.country : false
  if(error && !(typeof error === 'string' || error instanceof String)) {
    error = error.eng_name
  }
  
  const onChange = (e) => {
    console.log('here')
    // returns a list of countries
    const newValue = e[0]
    // if its an undefined country, dont save it
    if(!newValue) {
      return
    }
    setFieldValue("country", newValue) 
  }

  const setCountryFromText = (text) => {
    setFieldTouched("country", true)
    const country = COUNTRIES.find((country) => country.eng_name === text)
    if(country) {
      setFieldValue("country", country)
    } else {
      if(currValue) {
        setFieldValue("country", null)
      }
    }
  }

  const onInputChange = (text) => {
    setCountryFromText(text)
  }

  const onBlur = (e) => {
    const text = e.target.value
    setCountryFromText(text)
  }
    
  const countryKey = "eng_name"

  if(name != 'country') {
    return <>Error: name must be country</>
  }
  
  return <div className="question-spacing">
    <label className="form-label">
      What country are you in?
    </label>
    <div>
    <Typeahead
      id="country-input"
      className="about-text-input p-0"
      labelKey={countryKey}
      onChange={onChange}
      options={COUNTRIES}
      placeholder="United States"
      defaultSelected={currValue}
      onInputChange={(text) => onInputChange(text)}
      onBlur={onBlur}
    />
    </div>
    {isTouched && error && <div className="error">{error}</div>}
  </div> 

}

export const InterestInput = ({name}) => <MultipleCheckbox
    name={name}
    label="Why do you want to volunteer to section lead?"
    description="No wrong answers, but good for us to know!"
    >
    <Checkbox
    name={name}
    value="giveBack"
    label="Give back through community service"
    />
    <Checkbox
    name={name}
    value="community"
    label="Be part of a community of section leaders"
    />
    <Checkbox
    name={name}
    value="improveTeaching"
    label="Improve my own teaching ability"
    />
    <Checkbox
    name={name}
    value="improveProgramming"
    label="Improve my own programming ability"
    />
    <Checkbox
    name={name}
    value="loveTeaching"
    label="I just love teaching programming"
    />
    <Checkbox
    name={name}
    value="experimentInBetterEducation"
    label="I want to be part of an experiment in better online education"
    />
    <Checkbox
    name={name}
    value="job"
    label="I want to get a job as a programmer"
    />
    <Checkbox
    name={name}
    value="newExperiment"
    label="I want to be part of a new experiment in online-learning"
    />
    {/*<Checkbox name="interest" value="other" label="Other" />*/}
</MultipleCheckbox>

export const AgreeToBetaInput = ({name}) => <AgreeCheckbox
  name={name}
  description="As with any new technology, there may be some bumps along the way. We will do our best to make sure you have a great experience, but we may need your help to make sure everything is working smoothly."
  label="This course includes new learning experiences and tools. "
>
  <Checkbox name={name} value="yes" label="I understand! Let's learn." />
</AgreeCheckbox>

export const TimeAvailibleInput = ({name}) => <TextInput
label="How many hours per week will you have for this course?"
name={name}
type="number"
placeholder="10"
/>

export const IndendToCompleteInput = ({name}) => <AgreeCheckbox
  name={name}
  // description="The course runs April 21st to June 2nd (6 weeks).  You will be responsible for leading a 50-minute weekly section for a small group of students, preparing for section, participating in training sessions, and helping support your students."
  description="For Section Leaders, the first training runs from April 19th to April 21st, which is the start date for students. From April 23rd to May 30th you will be responsible for leading a 50-minute weekly section for a small group of students, preparing for section, participating in subsequent training sessions, and helping support your students."
  label="If accepted, I intend to complete this six week course"
>
  <Checkbox name={name} value="yes" label="Yes" />
</AgreeCheckbox>

export const CurrentOccupationInput = ({name}) => <TextInput
  label="What is your current occupation?"
  name={name}
  type="text"
  placeholder="Community College Student, Retired Landscaper, Fire-fighter, etc"
/>


export const CodingExperience = ({name}) => <TextAreaInput
label="What programming experience do you have?"
description="Please mention if you have programmed in Python."
name={name}
type="text"
placeholder="I have never coded before!"
/>

export const TeachingExperience = ({name}) => <TextAreaInput
label="It's ok if you don't have any teaching experience!"
description="What teaching experience do you have?"
name={name}
type="text"
placeholder="I have never taught before!"
/>

export const LanguageInput = ({name}) => <TextInput
  label="What is your preferred language for section?"
  name={name}
  type="text"
  placeholder="The course materials will be in English"
/>

export const EnglishProficiencyInput = ({name}) => <SelectInput 
label="How comfortable are you with spoken English?" 
description={<>You don't need to be fluent! But Please use the <a target="_blank" href="https://www.linkedin.com/pulse/language-proficiency-linkedin-duaa-ali/">LinkedIn proficiency definitions</a></>}
name={name}>
    <option value=""></option>
    <option value="none">I don't speak any english</option>
    <option value="beginner">Limited Working (Beginner)</option>
    <option value="intermediate">Minimum Professional (Intermediate)</option>
    <option value="advanced">Full Professional (Advanced)</option>
    <option value="native">Native or Bilingual</option>
</SelectInput>

export const AgreeHonorable = ({name}) => <AgreeCheckbox
  description="It is fine to discuss strategies with others. You may not copy any solutions from any other person or from online sources outside of the course materials."
  name={name}
  label="I promise that all the work in this application is my own"
>
  <Checkbox name={name} value="yes" label="Yes" />
</AgreeCheckbox>

export const AgreeOneSubmission = ({name}) => <AgreeCheckbox
  description="Only one submission per person. Thanks!"
  name={name}
  label="I promise to only submit one application"
>
  <Checkbox name={name} value="yes" label="Yes" />
</AgreeCheckbox>

export const AboutYourselfInput = ({name}) => <TextAreaInput
  label="Tell us a little about yourself:"
  name={name}
  description="Anything goes. What do you do for fun? Tell the course a quick anecdote about something that makes you unique—a talent, an unusual experience, or anything of that sort!"
/>

export const DiverseInput = ({name}) => <TextAreaInput
  label="We value having a diverse learning community so we can all learn from each other. What background and experiences would you bring to our online classroom?"
  name={name}
  description="Everyone is welcome here!"
/>

export const FriendsInput = ({name, values}) => {
  if(name !== "referrals") {
    return <>Error key must be "referrals"</>
  }
  const referrals = values.referrals || [];
  const getButtonText = (numReferrals) => {
    if (numReferrals === 0) {
      return "Add an applicants's email";
    } if(numReferrals === 1) {
      return "Add another email";
    } else {
      return "Only two referrals per section leader";
    }
  };

  return <>
  <label className="form-label" htmlFor="referrals">
    Do you have any friends or family who are applying to Code in
    Place as students? All accepted section leaders get two of their referrals automatically accepted. Make sure the email matches the one they used on their application.
  </label>
  <FieldArray name="referrals">
    {({ remove, push }) => (
      <div>
        {referrals.length > 0 &&
          referrals.map((referral, index) => (
            <div className="referralContainer" key={index}>
              <div className="refInput">
                <TextInput
                  label={`Email ${index + 1}`}
                  name={`referrals[${index}]`}
                  placeholder="Student email"
                  type="text"
                />
              </div>
              <div className="deleteButtonContainer">
                <button
                  className="deleteRef btn btn-danger"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        {referrals.length < 3 && (
          <Button
            className="addButton"
            variant="outline-primary"
            onClick={() => push("")}
            disabled={referrals.length >= 2}
          >
            {getButtonText(referrals.length)}
          </Button>
        )}
      </div>
    )}
  </FieldArray>
  </>
}