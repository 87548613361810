export type RoomData = {
    projId: string,
    teacherId: string,
    studentId: string,
    meetingId: string,
    meetingPassword: string,
    signature: string,
    userName: string,
    joinUrl: string,
    studentLeft: boolean,
    loading: boolean
}

export const unpackRoomData = (roomData: any, userName: string): RoomData => {

    const projId = "projId" in roomData ? roomData.projId : "";
    const teacherId = "teacherId" in roomData ? roomData.teacherId : "";
    const studentId = "studentId" in roomData ? roomData.studentId : "";
    const zoomInfo = "zoomInfo" in roomData ? roomData.zoomInfo : {};
    const signature = "signature" in zoomInfo ? zoomInfo.signature : "";
    const meetingId = "meetingId" in zoomInfo ? zoomInfo.meetingId : "";
    const meetingPassword = "password" in zoomInfo ? zoomInfo.password : "";
    const joinUrl = "joinUrl" in zoomInfo ? zoomInfo.password : "";
    const studentLeft = "studentLeft" in roomData ? roomData.studentLeft : false;

    return {
        projId,
        teacherId,
        studentId,
        signature,
        meetingId,
        meetingPassword,
        userName,
        joinUrl,
        studentLeft,
        loading: false
    }
}