import {useContext, useEffect, useRef} from 'react';
import {CollabSession} from '../Session';
import {CollabContext} from '../CollabContext';
import {IDEContext} from '../../contexts/IDEContext';
import {getAuth} from 'firebase/auth';
import {Collab, SPECIAL_CASE_ELEMENTS} from './utils';
import {
  buildInBoundsMouse,
  buildOutOfBoundsMouse,
  getFirstParentWithId,
  syncCanvasMouse,
  syncMouseGeneral,
} from '../mouse/MouseSync';

export const useCollabSession = () => {
  const {sessionRef, setIsSessionRefLoaded, setMice, setClientId, clientId} =
    useContext(CollabContext);
  const {
    projectData,
    setSelectedTab,
    setLeftColViewState,
    setCanvasViewState,
    setTerminalViewState,
    setKarelWorldState,
  } = useContext(IDEContext);
  const projectId = projectData?.uid;
  const lockRef = useRef(false);
  // useMouseListener();
  // useIDEStateListener(lockRef);
  // useKarelRunListener(lockRef);
  const teachNow = isTeachNow();

  const onUpdate = updateData => {
    lockRef.current = true;
    const {key, data, clientId: senderId} = updateData;
    if (!sessionRef.current) return;

    switch (key) {
      case Collab.CLIENT_ID:
        console.log(data, clientId);
        setClientId(data);
        break;
      case Collab.MOUSE:
        if (clientId.toString() === senderId.toString()) return;
        setMice(prev => {
          return {
            ...prev,
            [senderId]: data,
          };
        });
        break;
      case Collab.TAB_CHANGE:
        if (clientId.toString() === senderId.toString()) return;
        setSelectedTab(data);
        break;
      case Collab.CANVAS_VIEW:
        if (clientId.toString() === senderId.toString()) return;
        setCanvasViewState(data);
        break;
      case Collab.TERMINAL_VIEW:
        if (clientId.toString() === senderId.toString()) return;
        setTerminalViewState(data);
        break;
      case Collab.LEFT_COL_VIEW:
        if (clientId.toString() === senderId.toString()) return;
        setLeftColViewState(data);
        break;
      case Collab.KAREL:
        if (clientId.toString() === senderId.toString()) return;
        setKarelWorldState(data);
        break;
      default:
        break;
    }
  };

  // Setup collab session
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    const roomName = getUniqueProviderRoom(projectId);
    /** @typedef {CollabSession} */
    // onUpdate function is unused currently
    sessionRef.current = new CollabSession(
      roomName,
      user,
      onUpdate,
      projectData,
      teachNow,
    );
    setIsSessionRefLoaded(true);

    return () => {
      sessionRef.current.destroy();
    };
  }, []);
};

const useKarelRunListener = lockRef => {
  const {sessionRef} = useContext(CollabContext);
  const {karelWorldState} = useContext(IDEContext);

  useEffect(() => {
    if (!sessionRef.current) return;
    if (lockRef.current) {
      lockRef.current = false;
      return;
    }
    sessionRef.current.stream(Collab.KAREL, karelWorldState);
  }, [karelWorldState]);
};

const useIDEStateListener = lockRef => {
  const {sessionRef} = useContext(CollabContext);
  const {selectedTab, leftColViewState, terminalViewState, canvasViewState} =
    useContext(IDEContext);

  useEffect(() => {
    if (!sessionRef.current) return;
    if (lockRef.current) {
      lockRef.current = false;
      return;
    }
    sessionRef.current.stream(Collab.TAB_CHANGE, selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (!sessionRef.current) return;
    if (lockRef.current) {
      lockRef.current = false;
      return;
    }
    sessionRef.current.stream(Collab.TERMINAL_VIEW, terminalViewState);
  }, [terminalViewState]);

  useEffect(() => {
    if (!sessionRef.current) return;
    if (lockRef.current) {
      lockRef.current = false;
      return;
    }
    sessionRef.current.stream(Collab.CANVAS_VIEW, canvasViewState);
  }, [canvasViewState]);

  useEffect(() => {
    if (!sessionRef.current) return;
    if (lockRef.current) {
      lockRef.current = false;
      return;
    }
    sessionRef.current.stream(Collab.LEFT_COL_VIEW, leftColViewState);
  }, [leftColViewState]);
};

const useMouseListener = () => {
  const {mice, setMice, sessionRef} = useContext(CollabContext);
  const {editorRef} = useContext(IDEContext);
  const timeoutsRef = useRef({});
  const teachNow = isTeachNow();
  const lastEventTimeRef = useRef(Date.now()); // Use a ref to store the last event time
  const THROTTLE_INTERVAL = 50;

  useEffect(() => {
    console.log('useMouseListener', teachNow);
    if (!teachNow) return;
    const throttledMouseListener = e => {
      const now = Date.now();

      // Check if the interval since the last event is less than the throttle interval
      if (now - lastEventTimeRef.current < THROTTLE_INTERVAL) return;

      lastEventTimeRef.current = now; // Update the last event time

      if (!sessionRef.current) return;

      const clientX = e.clientX;
      const clientY = e.clientY;
      let element = document.elementFromPoint(clientX, clientY);
      if (!element) return;

      if (!element.id) {
        // Find the first parent with an id
        element = getFirstParentWithId(element);
        // If it's a special case element, ignore it
        if (
          !element ||
          !element.id ||
          SPECIAL_CASE_ELEMENTS.includes(element.id)
        )
          return;
      }

      const id = element.id;
      let data = {};

      if (id === 'code-editor') {
        if (!editorRef.current) return;
        const rect = editorRef.current.getDomNode().getBoundingClientRect();
        let x = clientX - rect.left;
        let y = clientY - rect.top;

        // Account for scroll distance
        x += editorRef.current.getScrollLeft();
        y += editorRef.current.getScrollTop();

        data = {
          x,
          y,
          type: id,
          clientId: sessionRef.current.ydoc.clientID,
          width: rect.width,
          height: rect.height,
        };
      } else {
        const rect = element.getBoundingClientRect();
        const absoulteX = clientX - rect.left;
        const absoulteY = clientY - rect.top;
        data = {
          type: id,
          clientId: sessionRef.current.ydoc.clientID,
          x: absoulteX,
          y: absoulteY,
          width: rect.width,
          height: rect.height,
        };
      }

      // Stream the data
      sessionRef.current.stream(Collab.MOUSE, data);
    };

    document.addEventListener('mousemove', throttledMouseListener);

    return () => {
      document.removeEventListener('mousemove', throttledMouseListener);
    };
  }, []);

  /**
   * @summary
   * Listen to scroll updates
   * This is neccessary to allow click-to-jump to work
   * The two places we need to listen to are docs and ace editor
   * Use timeoutsRef to debounce so that it only updates after the scroll has completed
   */

  // When mice is updated, draw the mice
  useEffect(() => {
    if (!teachNow) return;
    if (!sessionRef.current) return;
    drawMice(mice);
  }, [mice]);

  /**
   * @description draws collaborator mice
   * @param {any} mice
   * @returns {void}
   */
  const drawMice = mice => {
    if (!sessionRef.current) {
      return;
    }
    const clientIds = Object.keys(mice);

    clientIds.forEach(clientId => {
      if (sessionRef.current.ydoc.clientID.toString() === clientId.toString())
        return;
      const mouse = mice[clientId];
      if (!mouse || !mouse.type) return;

      let mouseElement = document.getElementById(clientId);

      if (!mouseElement) {
        mouseElement = document.createElement('div');
        mouseElement.id = clientId;
      }
      if (timeoutsRef.current[clientId]) {
        clearTimeout(timeoutsRef.current[clientId]);
        clearTimeout(timeoutsRef.current[`${clientId}-remove`]);
      }
      timeoutsRef.current[clientId] = setTimeout(() => {
        if (mouseElement.parentNode) {
          mouseElement.style.opacity = '0.1';
          setMice(prevMap => {
            const newMap = {...prevMap};
            delete newMap[clientId];
            return newMap;
          });
        }
      }, 10000);

      timeoutsRef.current[`${clientId}-remove`] = setTimeout(() => {
        if (mouseElement.parentNode) {
          mouseElement.remove();
        }
      }, 30000);

      const color = 'blue';
      switch (mouse.type) {
        case 'canvas':
          syncCanvasMouse(mouseElement, mouse, color);
          break;
        case 'code-editor':
          if (!editorRef.current) return;

          // Append the mouseElement to the editor's container
          const editorContainer = editorRef.current.getDomNode();
          if (editorContainer) {
            editorContainer.appendChild(mouseElement);
          }

          // Retrieve the scroll positions
          const scrollLeft = editorRef.current.getScrollLeft();
          const scrollTop = editorRef.current.getScrollTop();
          mouse.x -= scrollLeft;
          mouse.y -= scrollTop;

          // Get the layout info for width and height
          const layoutInfo = editorRef.current.getLayoutInfo();
          const editorWidth = layoutInfo.width;
          const editorHeight = layoutInfo.height;

          const editorWidthRange = [0, editorWidth];
          const editorHeightRange = [0, editorHeight];

          // Calculate if the mouse is out of bounds
          const outOfBounds = {
            outLeft: mouse.x < editorWidthRange[0],
            outRight: mouse.x > editorWidthRange[1],
            outUp: mouse.y < editorHeightRange[0],
            outDown: mouse.y > editorHeightRange[1],
          };

          if (
            outOfBounds.outLeft ||
            outOfBounds.outRight ||
            outOfBounds.outUp ||
            outOfBounds.outDown
          ) {
            buildOutOfBoundsMouse(
              mouseElement,
              mouse.x,
              mouse.y,
              editorWidth,
              editorHeight,
              color,
              outOfBounds,
            );
            break; // Use 'return' instead of 'break' since it's likely in a function scope
          }
          buildInBoundsMouse(mouseElement, mouse.x, mouse.y, color);
          break; // As above, assuming this logic is within a function
        default:
          syncMouseGeneral(mouseElement, mouse, color);
          break;
      }
    });
  };
};

/**
 *
 * @param {*} firebaseUrl
 * @returns a room name
 */
const getUniqueProviderRoom = firebaseUrl => {
  const SALT = 'codeinplace250419829';
  return SALT + firebaseUrl.replaceAll('/', '-');
};

const isTeachNow = () => {
  // get search params
  const urlParams = new URLSearchParams(window.location.search);
  const paths = window.location.pathname.split('/');
  // check second urlParam is "ide"
  const isIDE = paths[1] === 'ide';
  // check if searchparam "r"
  const roomId = urlParams.get('r');

  return isIDE && !!(roomId);

};
