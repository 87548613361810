import { useState, useEffect, useContext, useMemo } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { PartialLoading } from "components/loading/Loading";
import { ProfileContext } from "contexts/ProfileContext";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useCourseId } from "hooks/router/useUrlParams"
import { Spacer } from "components/layout/Spacer";
import { getLocalUTCTimezone } from 'components/timezones/timezoneHelper';
import { FaCheck, FaWindowClose, FaClipboard} from 'react-icons/fa';
import { parseAndFormatISODate } from 'components/timezones/timezoneHelper';
import { CompletionContext } from "contexts/CompletionContext";
import { RoadmapContext } from 'course/contexts/RoadmapContext'
import { collectAssignments } from 'course/code/RoadmapAssignments';
import { useNavigate } from "react-router-dom";
import {getFirestore, getDoc, doc} from 'firebase/firestore';

const functions = getFunctions();
const getGradesForOneStudent = httpsCallable(functions, 'getGradesForOneStudent');
const getAllAssnGradesForOneStudent = httpsCallable(functions, 'getAllAssnGradesForOneStudent');


export const StudentGradesPage= () => {
    return (
        <CoursePageBodyContainer
            mainColumn={<StudentGradesMain/>}
            singleColumn={<StudentGradesMain/>}
            rightColumn={<></>}
        />
    )

}


const StudentGradesMain = () => {
    const db = getFirestore();
    const courseId = useCourseId()
    const {userData} = useContext(ProfileContext);
    const userId = userData.id

    const [studentCurrentScore, setStudentCurrentScore] = useState(-1)
    const [studentCurrentGrade, setStudentCurrentGrade] = useState("")
    const [gradeDataIsLoading, setGradeDataIsLoading] = useState(true)

    const [studentAssnGrades, setStudentAssnGrades] = useState([])
    const [assnGradesDataIsLoading, setAssnGradesDataIsLoading] = useState(true)

    const { assnProgress } = useContext(CompletionContext) // assn id: bool tests passed or not
    const { getAssignmentsInRoadmap } = useContext(RoadmapContext)
    const assignments = useMemo(() => getAssignmentsInRoadmap(), [getAssignmentsInRoadmap])

    const [projectsList, metaData] = collectAssignments(assignments)
    const [feedbackData, setFeedbackData] = useState({}) // assn id: {feedback: bool, visibility: bool}
    const [feedbackDataIsLoading, setFeedbackDataIsLoading] = useState(true)

    useEffect(() => {
        // fetch overall grades from canvas
        const fetchGrades = async () => {
            const response = await getGradesForOneStudent({
                userId: userId,
                courseId: courseId
            })
            setGradeDataIsLoading(false)
            if(!response || !response.data) {
                // handle error
                console.error("Error fetching grades")
                return
            }
            setStudentCurrentScore(response.data.grades.current_score)
            setStudentCurrentGrade(response.data.grades.current_grade)

        }
        fetchGrades()

        // fetch assn grades from canvas
        const fetchAssnGrades = async () => {
            const response = await getAllAssnGradesForOneStudent({
                userId: userId,
                courseId: courseId
            })
            setAssnGradesDataIsLoading(false)
            if(!response || !response.data) {
                // handle error
                console.error("Error fetching assignment grades")
                return
            }
            setStudentAssnGrades(response.data)
        }
        fetchAssnGrades()

        const fetchFeedback = async () => {
            const feedbackResults = {};
            const promises = Object.values(assignments).map(async (assn) => {
                const assnId = assn.assnId;
                const overallFeedbackDocPath = `submissions/${courseId}/assignments/${assnId}/users/${userId}/feedback/overall`;
                const inlineFeedbackDocPath = `submissions/${courseId}/assignments/${assnId}/users/${userId}/feedback/inlineComments`;
                const visibilityDocPath = `submissions/${courseId}/assignments/${assnId}/users/${userId}/feedback/visibility`;

                const overallFeedbackDoc = await getDoc(doc(db, overallFeedbackDocPath));
                const inlineFeedbackDoc = await getDoc(doc(db, inlineFeedbackDocPath));
                const visibilityDoc = await getDoc(doc(db, visibilityDocPath));

                return {
                    assnId: assnId,
                    feedback: overallFeedbackDoc.exists() || inlineFeedbackDoc.exists(),
                    visibility: visibilityDoc.exists() ? visibilityDoc.data().visibility : false,
                };
            }
            );
            const results = await Promise.all(promises);
            results.forEach(result => {
                feedbackResults[result.assnId] = {
                    feedback: result.feedback,
                    visibility: result.visibility,
                };
            });
            setFeedbackData(feedbackResults);
            setFeedbackDataIsLoading(false);
        }
        fetchFeedback();
    }, [userId, courseId, assignments])

    if (gradeDataIsLoading || assnGradesDataIsLoading || feedbackDataIsLoading) {
        return <PartialLoading />
    }

    return <>
        <Spacer />
        <h3>Your Grades</h3>
        <h4>
            Current Grade: {studentCurrentScore}% 
            {studentCurrentGrade && ` (${studentCurrentGrade})`}
        </h4>
        <StudentGradesTable 
            studentAssnGrades={studentAssnGrades} 
            assnProgress={assnProgress}
            assnMetaData={metaData}
            feedbackData={feedbackData}
            courseId={courseId}
        />
    </>
}


const StudentGradesTable = ({ studentAssnGrades, assnProgress, assnMetaData, feedbackData, courseId }) => {
    const userTz = getLocalUTCTimezone()
    const navigate = useNavigate()

    const columns = [
        { 
            dataField: 'assnId', 
            text: 'assnId',
            hidden: true
        },
        { 
            dataField: 'name', 
            text: 'Assignment' 
        },
        { 
            dataField: 'due_at', 
            text: 'Due',
            formatter: (cell) => {
                if (!cell) {
                    return "N/A";
                }
                return parseAndFormatISODate(cell, userTz);
            }
        },
        { 
            dataField: 'submitted', 
            text: 'Submitted',
            formatter: (cell) => {
                return cell ? <FaCheck /> : <FaWindowClose />
            }
        },
        { 
            dataField: 'testsPassed', 
            text: 'Tests Passed',
            formatter: (cell) => {
                return cell ? <FaCheck /> : <FaWindowClose />
            }
        },
        { dataField: 'score', text: 'Score' },
        { 
            dataField: 'hasFeedback', 
            text: 'Feedback',
            formatter: (cell) => {
                return cell ? <FaClipboard style = {{color: "#316CF4"}}/> : <></>
            }
        },
    ]

    // TODO: we could also add more UI elements to show, e.g., grade_matches_current_submission, late, workflow_state (all in assn.submission)
    // TODO: change to map over metadata (released assns in roadmap)
    // add link to IDE for each assignment
    const data = Object.values(assnMetaData).map(assn => {
        const firestoreAssnId = assn.assnId
        const canvasAssnId = assn?.canvasAssnId
        const name = assn.title
        // hasFeedback is true if there is feedback (instructor has left inline or overall comments)
        // and visibility is true (i.e., feedback is visible to student)
        const hasFeedback = feedbackData[firestoreAssnId]?.feedback && feedbackData[firestoreAssnId]?.visibility

        const testsPassed = firestoreAssnId in assnProgress && assnProgress[firestoreAssnId]

        if (!canvasAssnId) {
            return {
                name: name,
                testsPassed: testsPassed,
                assnId: firestoreAssnId,
                hasFeedback: hasFeedback,
            }
        }

        const canvasAssn = studentAssnGrades.find(assn => assn.id === canvasAssnId)   
        const submitted = canvasAssn !== undefined && 'submission' in canvasAssn
        const dueAt = canvasAssn?.due_at
        const pointsPossible = canvasAssn?.points_possible
        let scoreString = pointsPossible ? `- / ${pointsPossible}` : "N/A"
        if (submitted && 'grade' in canvasAssn.submission && canvasAssn.submission.grade !== null) {
            scoreString = `${canvasAssn.submission.grade} / ${pointsPossible}`
        }

        return {
            name: name,
            testsPassed: testsPassed,
            assnId: firestoreAssnId,
            due_at: dueAt,
            submitted: submitted,
            score: scoreString,
            hasFeedback: hasFeedback,
        }
    })

    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            navigate(`/${courseId}/ide/a/${row.assnId}`)
        },
        onMouseEnter: (e, row, rowIndex) => {
            e.target.style.cursor = 'pointer';
        }
     }

    return <BootstrapTable
        bordered={false}
        striped={true}
        hover={true}
        bootstrap4={true}
        keyField='name'
        data={data}
        columns={columns} 
        rowEvents={tableRowEvents}
        />

}