/**
 * These are the items on the application that are always required even if the
 * user has requested auto accept.
 */
const ALWAYS_REQUIRED_CHECK_TYPES = [
  'aboutJob',  // Everyone must read the job expectations
  'form',  // Everyone must fill out the "About You" section
]

export const computeApplicationStatus = (allData, appStructure) => {
  const allChecks = getAllChecks(appStructure);

  if (allData.appData && allData.appData['withdrawn']) {
    return 'withdrawn';
  }
  var nComplete = 0;
  for (const check of allChecks) {
    if (check(allData)) {
      nComplete += 1;
    }
  }

  if (nComplete === 0) {
    return 'notStarted';
  }
  if (nComplete < allChecks.length) {
    return `incomplete (${nComplete}/${allChecks.length})`;
  }
  return 'complete';
};

export const checkComplete = (item, allAppData) => {
  // if the user has requested auto accept, we only need the biographical data
  if (allAppData?.appData?.hasRequestedAutoAccept && !ALWAYS_REQUIRED_CHECK_TYPES.includes(item.type)) {
    return true;
  }
  // check if an about course item is complete
  if (item.type == 'aboutCourse') {
    return learnAboutCourseComplete(allAppData);
  }

  if (item.type == 'aboutJob') {
    return learnAboutJobComplete(allAppData);
  }

  if (item.type == 'lesson') {
    return hasLesson(allAppData, item.id);
  }

  // check if a form item is complete
  if (item.type === 'form') {
    return hasAboutYou(allAppData);
  }

  if (item.type === 'debugging') {
    return debuggingComplete(allAppData);
  }

  // check if a record item is complete
  if (item.type === 'record') {
    return videoComplete(allAppData);
  }

  // check if a code item is complete
  if (item.type === 'code') {
    return hasAssn(allAppData, item.id);
  }

  return false;
};

function hasAboutYou(allData) {
  return allData.appData !== undefined && allData.appData['name'];
}

function hasLesson(allData, lessonId) {
  return allData.lessonsProgress[lessonId];
}

function hasAssn(allData, assnId) {
  return allData.assnProgress[assnId];
}

function videoComplete(allData) {
  return allData.videoUrl !== null;
}

function learnAboutCourseComplete(allData) {
  return allData.appData !== undefined && allData.appData['hasReadAboutCourse'];
}

function learnAboutJobComplete(allData) {
  return allData.appData !== undefined && allData.appData['hasReadAboutJob'];
}

function debuggingComplete(allData) {
  return allData.appData !== undefined && allData.appData['pythonDebug'];
}

function getAllChecks(appStructure) {
  const allChecks = [];
  for (const card of appStructure.cards) {
    for (const item of card.todo) {
      allChecks.push(allData => checkComplete(item, allData));
    }
  }
  return allChecks;
}
