import { useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { getAuth } from "firebase/auth";
import { useCourseId } from "../../hooks/router/useUrlParams";
import { useAuthState } from "react-firebase-hooks/auth";
import { useWindowSize } from "react-use-size";
import { FaChevronLeft, FaHome, FaTimes } from "react-icons/fa";
import { NavigationHistoryContext } from "contexts/NavigationContext";
import { useIsMobile } from "utils/general";


const MINIMIZED_BREAKPOINT = 768;
export const LandingNav = ({ applicationType, subpage }) => {

  const courseId = useCourseId()

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg="light"
      variant="light"
      sticky="top"
    >
      <div className="container-fluid">
        <BackHomeButton applicationType={applicationType} subpage={subpage} />
        {/* <Navbar.Brand>
          <Link to="/" className="stanfordRedLink">
            <BrandView />
          </Link>
        </Navbar.Brand> */}

        {/* <ApplicationLink applicationType={applicationType}/> */}
        <Nav className="me-auto ml-1">
          <ApplicationLink applicationType={applicationType} />{" "}
          <SubpageLink subpage={subpage} />
        </Nav>

        <Nav className="ms-auto">
          <ProfileNav />
        </Nav>
      </div>
    </Navbar>
  );
}

const BrandView = () => {
  const windowSize = useWindowSize();
  const isMinimized = windowSize.width < MINIMIZED_BREAKPOINT;
  if (isMinimized) {
    return <>CIP</>
  }
  return <>
    <img src="/stanford.png" style={{ width: 50 }}></img> Code in Place
  </>


}

const BackHomeButton = ({applicationType, subpage }) => {
  const { targetCourseId } = useParams()
  const {getPreviousLocation} = useContext(NavigationHistoryContext)
  
  const navigate = useNavigate()
  const location = useLocation()
  const currPath = location.pathname
  const previousLocation = getPreviousLocation([currPath])

  const appKey = applicationType === "student" ? "join" : "applyteach"
  const appHome = `/public/${appKey}/${targetCourseId}`
  
  const className = 'btn btn-sm btn-outline-secondary'
  if(subpage) {
    return <Link to={appHome} className={className}>
      <FaChevronLeft />
    </Link>
  }
  if(!previousLocation) {
    return <Link to={`/`} className={className}>
      <FaHome />
    </Link>
  }
  console.log('previousLocation', previousLocation)
  return <button
    className={className}
    onClick={() => navigate(previousLocation)}
  >
    <FaTimes />
  </button>
}

const SubpageLink = ({ subpage }) => {
  const windowSize = useWindowSize();
  const isMinimized = windowSize.width < MINIMIZED_BREAKPOINT;
  const courseId = useCourseId()
  if (!subpage) return <></>
  // if(isMinimized) return <Link to={``} className="nav-link">{subpage}</Link>
  if (isMinimized) return <></>
  return <>
    <span className="nav-link" style={{ paddingLeft: '0px', paddingRight: '0px' }}>/</span>
    <Link to={``} className="nav-link">{subpage}</Link>
  </>
}

const ApplicationLink = ({ applicationType }) => {
  const windowSize = useWindowSize();
  const isMinimized = useIsMobile()
  const courseId = useCourseId()
  const { targetCourseId } = useParams()

  const studentMsg = isMinimized ? "Student App" : "Student Application";
  const sectionLeaderMsg = isMinimized ? "Section Lead App" : "Section Leader Application";
  const slOnboardingMsg = isMinimized ? "SL Onboarding" : "Section Leader Onboarding";
  const studentOnboardingMsg = isMinimized ? "Onboarding" : "Student Onboarding";

  if (!applicationType) return <></>
  if (applicationType === "student") {
    return <Link to={`/${courseId}/join/${targetCourseId}`} className="nav-link">{studentMsg} </Link>
  }
  if (applicationType === "sectionLeader") {
    return <Link to={`/${courseId}/applyteach/${targetCourseId}`} className="nav-link">{sectionLeaderMsg}</Link>
  }
  if (applicationType === "slOnboarding") {
    return <Link to={`/${courseId}/onboarding/teach`} className="nav-link">{slOnboardingMsg}</Link>
  }
  if (applicationType == "studentOnboarding") {
    return <Link to={`/${courseId}/onboarding/student`} className="nav-link">{studentOnboardingMsg}</Link>
  }
}

export const ProfileNav = () => {
  const windowSize = useWindowSize();
  const isMinimized = windowSize.width < MINIMIZED_BREAKPOINT;
  const auth = getAuth();
  const [user] = useAuthState(auth)
  if (!user) {
    return <></>
  }
  return (
    <>
      {!isMinimized && <span className="d-flex flex-column justify-content-center mr-2">
        {user.displayName}
      </span>}
      <li className="nav-item">
        <button
          className="btn btn-sm btn-outline-secondary my-sm-0"
          onClick={() => {
            auth.signOut();
          }}
        >
          Log Out
        </button>
      </li>
    </>
  );
};
