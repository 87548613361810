import Swal from 'sweetalert2';
import {fileMap} from '../TipTapEditor';
import {v4 as uuidv4} from 'uuid';

export const handleDrop = (e, editor, isEditable) => {
  if (!isEditable) return;

  e.preventDefault();
  const file = e.dataTransfer.files[0];

  // check for a local file
  if (file && file.type.startsWith('image/')) {
    console.log('FILE DROP');
    console.log(file);
    const id = uuidv4();

    // Store the file in the global file map
    fileMap.set(id, file);

    // Insert KindImage node with the placeholder ID
    editor
      .chain()
      .focus()
      .insertContent({
        type: 'kindImage',
        attrs: {id, src: null},
      })
      .run();
  }

  // check for external image (HTML or URI content)
  // Check for external image (HTML or URI content)
  const htmlContent = e.dataTransfer.getData('text/html');
  const uriContent = e.dataTransfer.getData('text/uri-list');

  let imageUrl = null;

  if (htmlContent) {
    // Extract image URL from HTML content (common with dragging images from browsers)
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    const imgElement = doc.querySelector('img');
    if (imgElement) imageUrl = imgElement.src;
  } else if (uriContent) {
    // Use direct URI content if available
    imageUrl = uriContent;
  }

  if (imageUrl) {
    // Fetch and upload the external image
    fetchImageAndUpload(imageUrl, editor);
  }
};

const fetchImageAndUpload = async (url, editor) => {
  try {
    const response = await fetch(url, {mode: 'cors'});
    if (!response.ok) throw new Error('Failed to fetch the image');

    const blob = await response.blob();
    const fileName = `external_${uuidv4()}.jpg`; // Generate a unique filename

    // Create a File object from the blob
    const file = new File([blob], fileName, {type: blob.type});
    const id = uuidv4();

    // Store file temporarily
    fileMap.set(id, file);

    // Insert placeholder KindImage node
    editor
      .chain()
      .focus()
      .insertContent({
        type: 'kindImage',
        attrs: {id, src: null},
      })
      .run();
  } catch (error) {
    console.error('Error fetching and uploading image:', error);
    Swal.fire(
      'Error',
      'Failed to upload the image from the external source.',
      'error',
    );
  }
};
