import { useState } from "react";

import Switch from "react-switch";
import { makeRowColKey, makeWallStr } from "../util";
import { Karel } from "../karelapi";

export const getWorldDescription = (worldState, includeActionHistory) => {
  const world = `Karel is in a world that has ${worldState.nRows} rows and ${worldState.nCols} columns.`;
  const state = getKarelStateDescription(worldState)
  const beepers = getBeepersDescription(worldState)
  const walls = getWallsDescription(worldState)
  const blocked = getBlockedMsg(worldState);
  const actions = worldState.actionHistory?.join(", ") ?? "no actions have been performed"
  let description = `${world} ${state} ${beepers} ${walls} ${blocked}`
  if (includeActionHistory) {
    description += ` Karel has performed the following actions: ${actions}`
  }
  // console.log(description)
  return description

}

const ScreenReader = ({ textDescription }) => {
  const [showTextDescriptions, setShowTextDescriptions] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          alignItems: "center",
          margin: "8px 0",
        }}
      >
        <span
          style={{
            fontSize: "14px",
          }}
        >
          Text descriptions
        </span>
        <Switch
          checked={showTextDescriptions}
          onChange={(checked) => setShowTextDescriptions(checked)}
          height={14}
          width={35}
          onColor={"#085ED6"}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>
      {showTextDescriptions ? (
        <div
          style={{
            maxHeight: "200px",

            border: "1px solid black",
            width: "100%",
            background: "white",
            overflow: "scroll",
            marginBottom: "8px",
            borderRadius: "8px",
          }}
          className="p-2"
        >
          <div style={{ fontSize: "14px" }}>
            {textDescription}
          </div>
        </div>
      ) : null}
    </>
  );
};

const Actions = ({ worldState }) => {
  const hasActions = worldState.actionHistory?.length > 0;
  if (!hasActions) {
    return <></>
  }
  return <>
    <div style={{ fontWeight: "bold" }}>Action History</div>
    <ol>
      {worldState.actionHistory?.map((action) => {
        return <li>{action}</li>;
      })}
    </ol>
  </>
}

// TODO: there are a lot of functions here which are repeated from karelapi.ts

// Main functions
export function getKarelStateDescription(karelWorldState) {
  let state = `Location: Karel is standing on row ${karelWorldState.nRows - karelWorldState.karelRow
    } and column ${karelWorldState.karelCol + 1}, facing ${karelWorldState.karelDir
    }.`;

  const numBeepers =
    karelWorldState.beepers?.[karelWorldState.karelRow]?.[
    karelWorldState.karelCol
    ] ?? 0;
  if (numBeepers > 0) {
    state += ` There are ${numBeepers} beepers on this square.`;
  }

  return state;
}

function getBlockedMsg(karelWorldState) {
  const karel = new Karel(null, karelWorldState, false);
  let blockedMsg = ''
  if (karel.front_is_clear()) {
    blockedMsg += "Karel's front is clear. "
  } else {
    blockedMsg += "Karel's front is blocked. "
  }

  if (karel.left_is_clear()) {
    blockedMsg += "Karel's left is clear. "
  } else {
    blockedMsg += "Karel's left is blocked. "
  }

  if (karel.right_is_clear()) {
    blockedMsg += "Karel's right is clear. "
  } else {
    blockedMsg += "Karel's right is blocked. "
  }

  return blockedMsg
}

function getWallsDescription(karelWorldState) {
  const walls = karelWorldState.walls
  if (!walls || Object.keys(walls).length == 0) {
    return "Inner Walls: There are no inner walls in this world."
  } else {
    let wallStr = ''
    const wallDirs = ["North", "East"]
    for (var row = 0; row < karelWorldState.nRows; row++) {
      for (var col = 0; col < karelWorldState.nCols; col++) {
        for (var dir of wallDirs) {
          const rowColStr = makeWallStr(row, col, dir)
          if (walls[rowColStr] > 0) {
            const displayRow = karelWorldState.nRows - row
            let displayCol = col + 1
            let displayDir = dir
            
            if(wallStr.length > 0) {
              wallStr += ", "
            }
            wallStr += `(row ${displayRow}, column ${displayCol}, wall is to the ${displayDir})`
          }
        }
      }
    }
    return `Inner Walls: ${wallStr}.`
  }
}

function getBeepersDescription(karelWorldState) {

  // Important: beepers need to be output in a consistent order
  var beeperList = []
  if (karelWorldState.beepers) {


    for (var row = 0; row < karelWorldState.nRows; row++) {
      for (var col = 0; col < karelWorldState.nCols; col++) {

        const displayRow = karelWorldState.nRows - karelWorldState.karelRow
        const displayCol = karelWorldState.karelCol + 1

        const rowColStr = makeRowColKey(row, col)
        if (karelWorldState.beepers[rowColStr] > 0) {
          const numBeepers = karelWorldState.beepers[rowColStr]
          const beeperLi = ` ${numBeepers} beeper${numBeepers > 1 ? "s" : ""} on (row ${displayRow}, column ${displayCol}).`
          beeperList.push(beeperLi)
        }
      }
    }
  }

  if (beeperList.length == 0) {
    return "Beepers: There are no beepers in this world."
  }

  return 'Beepers: There is ' + beeperList.join(" ")
}

export function BeepersDescription(karelWorldState) {


  return (
    <p>{getBeepersDescription(karelWorldState)}</p>
  );
}
export function renderWorldDescription(karelWorldState) {
  const overviewMsg = `Karel is in a world that has ${karelWorldState.nRows} rows and ${karelWorldState.nCols} columns.`;

  return (
    <span>
      <span>{overviewMsg}</span>
    </span>
  );
}


export default ScreenReader;
