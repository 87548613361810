import styled, {keyframes} from 'styled-components';
import KarelSvgGroup from './KarelSvgGroup';

const stepDuration = 1400;

/**
 * The leftmost beeper fade in animation.
 */
const fadeInBeeper1 = keyframes`
  0% {
    opacity: 0;
    animation-timing-function: step-end;
  }
  83.333333% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

/**
 * The middle beeper fade in animation.
 */
const fadeInBeeper2 = keyframes`
  0% {
    opacity: 0;
    animation-timing-function: step-end;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

/**
 * The rightmost beeper fade in animation.
 */
const fadeInBeeper3 = keyframes`
  0% {
    opacity: 0;
    animation-timing-function: step-end;
  }
  16.666667% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

/**
 * The Karel translation animation. Moves Karel across the screen.
 */
const translateKarel = keyframes`
  0% {
    transform: translate(94px,98.036642px);
    animation-timing-function: step-end;
  }
  33.333333% {
    transform: translate(282px,98.036642px);
    animation-timing-function: step-end;
  }
  66.666667% {
    transform: translate(470px,98.036642px);
  }
  100% {
    transform: translate(470px,98.036642px);
  }
`;

/**
 * CSS Classes to apply to the animation elements.
 * The "width" and "height" of this SVG are controlled
 * via a scale prop in the styled component.
 */
const ORIGINAL_WIDTH = 564;
const ORIGINAL_HEIGHT = 189;

const StyledSVG = styled.svg<{$scale: number}>`
  display: block;
  margin: 0 auto; /* Removes large margins above/below and centers horizontally */
  width: ${({$scale}) => `${ORIGINAL_WIDTH * $scale}px`};
  height: ${({$scale}) => `${ORIGINAL_HEIGHT * $scale}px`};

  .beeper1 {
    animation: ${fadeInBeeper1} ${stepDuration}ms linear infinite normal forwards;
    will-change: opacity;
  }

  .beeper2 {
    animation: ${fadeInBeeper2} ${stepDuration}ms linear infinite normal forwards;
    will-change: opacity;
  }

  .beeper3 {
    animation: ${fadeInBeeper3} ${stepDuration}ms linear infinite normal forwards;
    will-change: opacity;
  }

  .karelTranslate {
    animation: ${translateKarel} ${stepDuration}ms linear infinite normal forwards;
    will-change: transform;
  }
`;

/**
 * The Karel animation component. This was generated by exporting an existing
 * SVG animation as a series of CSS classes and then adapting them to use the
 * `styled-components` library.
 */
export function KarelLoadingAnimation({scale = 1}: {scale?: number}) {
  return (
    <StyledSVG
      className="karelAnimation"
      $scale={scale}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g transform="translate(376.000109 0)">
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="translate(100.500003 99.000001)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="matrix(0 1 -1 0 100.500003 99.000001)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
      </g>

      <g transform="translate(188.000109 0)">
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="translate(100.500003 99.000001)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="matrix(0 1 -1 0 100.500003 99.000001)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
      </g>

      <g>
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="translate(100.500003 99.000001)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
        <line
          x1="-11.58011"
          y1="0"
          x2="11.580109"
          y2="0"
          transform="matrix(0 1 -1 0 100.500003 99.000001)"
          fill="none"
          stroke="#000"
          strokeWidth="4"
        />
      </g>

      <path
        className="beeper1"
        d="M0.75588,90.37263L88.9199,2.20861l88.16402,88.16402L88.9199,178.53665L0.75588,90.37263Z"
        transform="translate(376.00412 0)"
        opacity="0"
        fill="#28c0f8"
        stroke="#000"
      />
      <path
        className="beeper2"
        d="M0.75588,90.37263L88.9199,2.20861l88.16402,88.16402L88.9199,178.53665L0.75588,90.37263Z"
        transform="translate(188.00412 0)"
        opacity="0"
        fill="#28c0f8"
        stroke="#000"
      />
      <path
        className="beeper3"
        d="M0.75588,90.37263L88.9199,2.20861l88.16402,88.16402L88.9199,178.53665L0.75588,90.37263Z"
        opacity="0"
        fill="#28c0f8"
        stroke="#000"
      />
      <g className="karelTranslate">
        <KarelSvgGroup translateX={-100} translateY={-100} />
      </g>
    </StyledSVG>
  );
}
