import { useContext } from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import { AboutWithData } from "./AboutWithData";
import { Loading } from "components/loading/Loading";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useUserId } from "hooks/user/useUserId";
import { doc, getFirestore } from "firebase/firestore";
import { ProfileContext } from "contexts/ProfileContext";



export const StudentApplicationAbout = () => {
  const {targetCourseId} = useParams()
  const navigate = useNavigate()
  const userId = useUserId()

  const {userProtectedData, loading} = useContext(ProfileContext)

  // useDocumentDataOnce says "get the document at this path once (dont update it if server data updates)"
  // serverData = the current document from the database (undefined if still loading)
  const db = getFirestore();
  const firebasePath = `users/${userId}/${targetCourseId}/studentApplication`
  const [serverData, loadingAppData, error] = useDocumentDataOnce(doc(db, firebasePath));

  // debugger
  if (loadingAppData || loading) {
    console.log('loading', loadingAppData, loading)
    return <Loading />;
  }
  if(error) {
    return <p>Error: {error.message}</p>
  }

  let aboutData =
    serverData !== undefined ? serverData : {};
  
  // inject dob if it is know
  safeInject(aboutData, userProtectedData, 'dateOfBirth')
  safeInject(aboutData, userProtectedData, 'gender')
  
  const setAboutData = function (newFields) {
    // keep track of their gender and dob
    const protectedUpdate = {
      'gender': newFields.gender,
      'dateOfBirth': newFields.dateOfBirth
    }
    const protectedPath = `users/${userId}/docs/protected`
    firebase.firestore().doc(protectedPath).set({ ...protectedUpdate },{ merge: true })

    firebase.firestore().doc(firebasePath).set({ ...newFields },{ merge: true })
      .then(() => {
        Swal.fire({
          title:"Success",
          heightAuto: false,
          text:"About form has been filled out!",
          icon:"success",
          preConfirm:() => {
            navigate(`/public/join/${targetCourseId}`)
          }
        })
      });
  };

  return (
      <AboutWithData 
        aboutData={aboutData} 
        setAboutData={setAboutData} 
      />
  );
};

export function safeInject(serverData, fromData, key) {
  if(!serverData || !fromData) {
    return
  }
  // don't inject if you already have a value
  if(key in serverData) {
    return
  }
  // don't inject if you don't have a value to add
  if(!(key in fromData)) {
    return
  }
  // inject
  serverData[key] = fromData[key]
}