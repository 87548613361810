import { useContext, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BookContext } from 'textbook/context/BookContext';
import { BookSidebar } from 'textbook/sidebar/BookSidebar';
import { tiptapBookStyles } from './TipTapBookStyles';

export const BookPage = ({ children }) => {
  const location = useLocation();
  const [sidebarToggled, setSidebarToggled] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const { bookOutlineLoading } = useContext(BookContext);

  const onSidebarBreakPoint = broken => {
    const visible = !broken;
    setSidebarVisible(visible);
  };

  const toggleSidebar = () => {
    setSidebarToggled(!sidebarToggled);
  };

  const isFullWidth = getIsFullWidth(location);
  console.log('isFullWidth', isFullWidth);

  if (bookOutlineLoading) return <></>;

  let innerContent = null;
  if (isFullWidth) {
    innerContent = (
      <>
        <SidebarToggleButton
          sidebarVisible={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
        {children}
      </>
    );
  } else {
    innerContent = (
      <HandoutOuter id="handout-outer">
        <HandoutInner id="handout-inner">
          <SidebarToggleButton
            sidebarVisible={sidebarVisible}
            toggleSidebar={toggleSidebar}
          />
          {children}
        </HandoutInner>
      </HandoutOuter>
    );
  }

  return (
    <ChapterOuter className="chapter-outer">
      <BookSidebar
        toggled={sidebarToggled}
        onBreakPoint={onSidebarBreakPoint}
        onBackdropClick={toggleSidebar}
      />
      {innerContent}
    </ChapterOuter>
  );
};

export const SidebarToggleButton = ({ sidebarVisible, toggleSidebar }) => {
  if (sidebarVisible) return <></>;
  return (
    <ToggleButton className="btn btn-dark" onClick={toggleSidebar}>
      <FaBars />
    </ToggleButton>
  );
};

function getIsFullWidth(location) {
  return false;
}

const ToggleButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  margin-top: 5px;
  margin-right: 5px;
  color: white;
  background-color: black;
  border: 1px solid grey;
`;

export const ChapterOuter = styled.div`
  height: 100vh;
  display: flex;
`;

// HEADS UP: media queries should be coordinated
// with the Tiptap.scss .tiptapContentWrapper

export const HandoutOuter = styled.div`
  background-color: #555;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  overflow-y: auto;
  justify-content: center;
  min-height: 0; /* Add this line */
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 1100px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

// I think its suppsed to be 930
export const HandoutInner = styled.div`
  background-color: white;
  width: 100%;
  max-width: 930px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    padding: 0px;
  }

  ${tiptapBookStyles}
`;

export const ChapterBodyOuter = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  background-color: white;
  padding-bottom: 20px;

  @media (max-width: 1100px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 700px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const ChapterTitleInner = styled.div`
  padding-left: 90px;
  padding-right: 90px;
  font-size: 3.5rem;
  padding-top: 20px;
  padding-bottom: 20px;

  background-color: white;

  @media (max-width: 1100px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ChapterTitleWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  display: inline-block;
`;

export const ChapterTitleStyle = styled.h1`
  // font-family: 'Times New Roman', serif;
  text-align: center;
  font-size: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0; /* Reset default margins */
  padding-right: 24px; /* Reserve space for the icon */
`;

