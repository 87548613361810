import {useContext} from 'react';
import {FaWalking, FaRunning} from 'react-icons/fa';
import {IDEContext} from '../contexts/IDEContext';
import {MinMaxButtonHorizontal} from '../ResizeButtons';
import {
  checkIsProjectKarel,
  checkIsProjectConsole,
  saveUserWorldState,
} from '../utils/general';
import {Canvas} from './Canvas';
import {useState, useEffect} from 'react';
import Switch from 'react-switch';
import {UnitTestButton} from 'ide/UnitTest/RunUnitTestButton';
import {type KarelUnitTest} from '../../assignments/models/unitTests/karel';
import {IKarelState} from 'components/pyodide/KarelLib/karelInterfaces';

export const CanvasPane = props => {
  const canvasViewState = props.viewState;
  const isMinimized = canvasViewState == 'minimized';
  const canvasStyle: React.CSSProperties = {
    flexGrow: 2,
    padding: '10px',
    paddingLeft: '10px',
    paddingTop: '0px',
  };
  if (isMinimized) {
    canvasStyle.display = 'none';
  }
  
  const {
    stepMode,
    projectData,
    assnData,
    karelSleepTime,
    setKarelSleepTime,
    setKarelWorldState,
    setDefaultKarelWorldState,
    setHasHistory,
    setStepMode,
    karelWorldState,
    setIsRunning,
    karelWorldEditMode,
    loadedUnitTestData,
  } = useContext(IDEContext);

  useEffect(() => {
    if (!karelWorldEditMode) {
      saveUserWorldState(karelWorldState, projectData?.uid);
    } 
  }, [karelWorldEditMode]);

  const isConsole = checkIsProjectConsole(projectData, assnData);
  const isKarel = checkIsProjectKarel(projectData, assnData);

  useEffect(() => {
    setHasHistory(false);
    setStepMode(false);
  }, [karelWorldEditMode]);

  let title = isKarel ? 'World' : isConsole ? 'Test Center' : 'Canvas';
  if (stepMode) {
    title = 'Replay ' + title;
  }
  return (
    <div
      id="canvas-pane"
      className="w-100 h-100 d-flex flex-column items-center"
      style={{paddingLeft: isMinimized ? '5px' : '10px', paddingRight: '10px'}}
    >
      <div
        className="w-100"
        style={{
          paddingLeft: isMinimized ? '0px' : '10px',
          paddingBottom: '3px',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '10px',
        }}
      >
        {props.viewState != 'minimized' && (
          <span style={{textAlign: 'right'}} className="ideHeadingLight">
            {title}
          </span>
        )}
        <div>
          <MinMaxButtonHorizontal
            viewState={props.viewState}
            setViewState={props.setViewState}
          />
        </div>
      </div>
      <div style={canvasStyle}>
        <Canvas karelWorldEditMode={karelWorldEditMode} />
        {isKarel ? (
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-2 pt-2 w-full">
                <KarelTimeSlider
                  karelSleepTime={karelSleepTime}
                  setKarelSleepTime={setKarelSleepTime}
                />
              </div>
            </div>
            <SelectWorld
              unitTests={loadedUnitTestData?.unitTests ?? []}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

interface SelectWorldProps {
  unitTests: KarelUnitTest[];
}

const SelectWorld = ({
  unitTests
}: SelectWorldProps) => {

  const {
    isRunning,
    karelWorldEditMode,
    setKarelWorldEditMode,
    setKarelWorldState,
    setDefaultKarelWorldState,


  } = useContext(IDEContext);

  if (!unitTests || unitTests?.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          alignItems: 'baseline',
          margin: '8px 0',
        }}
      >
        <p>Edit World</p>
        <Switch
          disabled={isRunning &&!karelWorldEditMode}
          checked={karelWorldEditMode}
          onChange={checked => setKarelWorldEditMode(checked)}
          height={14}
          width={35}
          onColor={'#085ED6'}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>
    );
  }
  const [selectedWorldKey, setSelectedWorldKey] = useState(unitTests[0].key);

  const worldOptions = unitTests.map(test => {
    return (
      <option value={test.key} key={test.key}>
        {test.name}
      </option>
    );
  });

  useEffect(() => {
    const selectedWorld = unitTests.find(test => test.key === selectedWorldKey);
    if (!selectedWorld) {
      return;
    }
    setDefaultKarelWorldState(selectedWorld.pre);
    setKarelWorldState(selectedWorld.pre);
  }, [selectedWorldKey]);

  return (
    <div className="d-flex align-items-center gap-2 pt-1 w-full">
      World:
      <select
        disabled={isRunning}
        className="bg-primary text-white w-10 h-3 rounded mt-0"
        name="worlds"
        id="worlds"
        onChange={e => setSelectedWorldKey(e.target.value)}
      >
        {worldOptions}
      </select>
    </div>
  );
};

export const KarelTimeSlider = ({karelSleepTime, setKarelSleepTime}) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <FaWalking />
      <input
        id="stepslider"
        min={0}
        max={1}
        step={0.05}
        type="range"
        aria-label="Steps Slider"
        onChange={e => {
          setKarelSleepTime(1 - Number(e.target.value));
        }}
        value={1 - karelSleepTime}
      ></input>
      <FaRunning />
    </div>
  );
};
