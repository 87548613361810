// InteractiveDemo.jsx
import {useState, useEffect, useRef} from 'react';
import {NodeViewWrapper} from '@tiptap/react';
import {Node, mergeAttributes} from '@tiptap/core';
import {ReactNodeViewRenderer} from '@tiptap/react';
import styled from 'styled-components';

import {STARTER_CODE} from './starterCode';
import {RenderErrorBoundary} from './RenderErrorBoundary';
import {FaCode, FaTimes, FaTrash} from 'react-icons/fa';
import {evaluateCode} from './EvaluateCode';
import {availableModules} from './AvailableModules';
import {InteractiveMultiFileCodeView} from './InteractiveMultiFileCodeView';

// be very careful changing this -- old code will break
export const componentMainFile = 'Component.jsx';

// TipTap Node extension for the interactive demo
export const ReactInTipTap = Node.create({
  name: 'react-in-tip-tap',
  group: 'block',
  selectable: false,
  code: true,
  atom: true,
  draggable: false,

  addAttributes() {
    return {
      files: {
        default: {
          [componentMainFile]: STARTER_CODE,
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'react-in-tip-tap',
        getAttrs: element => {
          const filesAttr = element.getAttribute('data-files');
          let files = {[componentMainFile]: STARTER_CODE};
          if (filesAttr) {
            try {
              files = JSON.parse(filesAttr);
            } catch {
              // Fallback if parsing fails, use default
            }
          }
          return {files};
        },
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    const {files, ...rest} = HTMLAttributes;
    const serializedFiles = JSON.stringify(files);
    return [
      'react-in-tip-tap',
      mergeAttributes(rest, {'data-files': serializedFiles}),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(RenderInteractiveDemo);
  },
});

// Component that renders the interactive demo
export const RenderInteractiveDemo = props => {
  const liveReact = true;

  // Tracks whether the entire block is “focused/clicked” to show the toolbar
  const [showToolbar, setShowToolbar] = useState(false);

  // Tracks whether the code editor is expanded
  const [isCodeOpen, setIsCodeOpen] = useState(false);

  // Tracks if the editor as a whole is in an editable state
  const [isEditable, setIsEditable] = useState(props.editor.isEditable);

  // For error handling
  const [ErrorMessage, setErrorMessage] = useState(null);

  // Dynamically rendered component
  const [RenderedComponent, setRenderedComponent] = useState(null);

  // Reference to our NodeViewWrapper for detecting outside clicks
  const nodeViewWrapperRef = useRef(null);

  // Whenever the editor's "transaction" event fires, update `isEditable`
  useEffect(() => {
    const updateIsEditable = () => {
      setIsEditable(props.editor.isEditable);
    };
    props.editor.on('transaction', updateIsEditable);
    return () => {
      // cleanup
      props.editor.off('transaction', updateIsEditable);
    };
  }, [props.editor]);

  // Hide the toolbar if the user clicks anywhere outside our node
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        nodeViewWrapperRef.current &&
        !nodeViewWrapperRef.current.contains(event.target)
      ) {
        setShowToolbar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getFiles = () => props.node.attrs.files;

  const handleDelete = () => {
    const {editor, node, getPos} = props;
    const pos = getPos();
    const nodeSize = node.nodeSize;

    editor
      .chain()
      .focus()
      .deleteRange({from: pos, to: pos + nodeSize})
      .run();
  };

  const onFileChange = (fileName, newContent) => {
    const oldFiles = getFiles();
    props.updateAttributes({
      files: {
        ...oldFiles,
        [fileName]: newContent,
      },
    });
  };

  const files = getFiles();

  // Re-evaluate the code whenever it changes
  useEffect(() => {
    if (!liveReact) return;
    evaluateCode(
      files,
      availableModules,
      setRenderedComponent,
      setErrorMessage,
    );
  }, [files, liveReact]);

  const showCodeEditor = isEditable && isCodeOpen;

  const toggleCodeBtnText = showCodeEditor ? (
    <>
      <FaTimes />
      Hide Code
    </>
  ) : (
    <>
      <FaCode />
      Show Code
    </>
  );

  return (
    <NodeViewWrapper
      data-drag-handle
      ref={nodeViewWrapperRef}
      // Only show the toolbar if user clicks *within* this wrapper (and it's editable)
      onClick={() => {
        if (isEditable) setShowToolbar(true);
      }}
    >
      <ReactInTipTapOuter>
        {/* Code Editor (conditionally shown) */}
        {showCodeEditor && (
          <InteractiveMultiFileCodeView
            onFileChange={onFileChange}
            files={files}
          />
        )}

        {/* Rendered Output or Error Section */}
        <div>
          {ErrorMessage ? (
            <div style={{color: 'red'}}>
              <strong>Error:</strong> {ErrorMessage}
            </div>
          ) : RenderedComponent ? (
            <RenderErrorBoundary>
              <RenderedComponent />
            </RenderErrorBoundary>
          ) : (
            <div style={{fontStyle: 'italic'}}>
              Your interactive demo will appear here...
            </div>
          )}
        </div>

        {/* ButtonBar only appears if the editor is editable AND the user has clicked inside */}
        {isEditable && showToolbar && (
          <ButtonBar>
            <ToggleCodeButton
              className="btn btn-sm btn-light"
              onClick={() => setIsCodeOpen(!isCodeOpen)}
            >
              {toggleCodeBtnText}
            </ToggleCodeButton>

            <button className="btn btn-sm btn-light" onClick={handleDelete}>
              <FaTrash /> Delete
            </button>
          </ButtonBar>
        )}
      </ReactInTipTapOuter>
    </NodeViewWrapper>
  );
};

const ReactInTipTapOuter = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const dropdownHeightPx = 34;
const ButtonBar = styled.div`
position: absolute;
top: calc(100% - ${dropdownHeightPx}px);
left: 0;
background-color: #fff;
border: 1px solid #ccc;
border-radius: 4px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
z-index: 100;
display: flex;
`;

const ToggleCodeButton = styled.button`
  align-items: center;
  display: flex;
  gap: 5px;
`;
