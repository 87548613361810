import {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CoursePageBodyContainer} from '../../../components/layout/CoursePageBodyContainer';
import {useLoadedAssignmentData} from '../../../ide/hooks/loadIdeData';
import {doc, deleteDoc, getFirestore, setDoc} from 'firebase/firestore';
import {TipTap} from '../../../components/richTextEditor/TipTap/TipTap';
import {
  Button,
  Placeholder,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  Row,
  Col,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Stack,
} from 'react-bootstrap';
import {AssnEditorButtonBar as AssignmentEditorButtonBar} from 'components/richTextEditor/TipTap/buttonbars/AssnEditorButtonBar';
import {FaPlay} from 'react-icons/fa';
import {ProfileContext} from '../../../contexts/ProfileContext';
import NoAccess from 'components/errors/NoAccess';
import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';
import {CourseContext} from 'contexts/CourseContext';
import {CanvasAssnModal} from './extensions/CanvasAssnForm';
import {isAdmin, isMinimumRole} from 'contexts/profileUtil';
import {CourseId} from 'types/common';
import {
  AssignmentUnitTestType,
  AssignmentType,
  AssignmentUnitTestBehavior,
  ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES,
  isAssignmentType,
  ShowSolutionBehavior,
  SHOW_SOLUTION_BEHAVIOR_TOOLTIPS,
  SHOW_SOLUTION_BEHAVIOR_DISPLAY_NAMES,
  SUPPORTED_SHOW_SOLUTION_BEHAVIORS,
} from 'assignments/types';
import {AssignmentId} from 'assignments/types';
import {
  AssignmentMetadata,
  getAssignmentMetadataReference,
  inferShowSolutionBehavior,
} from 'assignments/models/assignmentMetadata';
import {UnitTestEditor} from 'assignments/unitTest/UnitTestEditor';
import {
  inferUnitTestType,
  inferUnitTestTypeFromMetadata,
} from 'ide/UnitTest/runUnitTests';
import styled from 'styled-components';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import Swal from 'sweetalert2';
import {useDebounce} from 'use-debounce';
import {useIsMobile} from 'utils/general';
import {getStarterCodeDocumentReference} from 'assignments/models/starterCode';
import {MiscellaneousFeatures} from './components/MiscellaneousFeatures';
import {UploadFile} from './components/UploadFile';
import {UnitTestMetadataEditor} from './components/UnitTestMetadataEditor';
import {ExpandableCodeEditor} from 'components/editor/ExpandableCodeEditor';
import {
  SelectDropdown,
  SelectOption,
} from 'components/reusableButtons/SelectDropdown';
import {ASSIGNMENT_DATA_DEBOUNCE_MS} from 'assignments/unitTest/constants';
import {
  AssignmentHeader,
  AssignmentHeaderPlaceholder,
} from './components/AssignmentHeader';
import {Role} from 'types/role';

export const AssignmentEditor = () => {
  const {userData} = useContext(ProfileContext);
  const {courseId, assnId: assignmentId} = useParams();
  const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(false);
  const navigate = useNavigate();
  const [isLoading, assignmentData] = useLoadedAssignmentData(
    courseId,
    assignmentId,
    () => {
      navigate(`/${courseId}/assnEditor`);
    },
  );

  if (!isMinimumRole(userData?.courseRole, Role.ADMIN)) {
    return <NoAccess />;
  }

  const BodyComponent =
    isLoading || shouldShowPlaceholder ? (
      <AssignmentEditorPlaceholder
        shouldShowPlaceholder={shouldShowPlaceholder}
        setShouldShowPlaceholder={setShouldShowPlaceholder}
      />
    ) : (
      <AssignmentEditorMain
        serverAssignmentData={assignmentData}
        assignmentId={assignmentId}
        courseId={courseId}
        shouldShowPlaceholder={shouldShowPlaceholder}
        setShouldShowPlaceholder={setShouldShowPlaceholder}
      />
    );
  return (
    <CoursePageBodyContainer
      mainColumn={BodyComponent}
      rightColumn={<></>}
      singleColumn={BodyComponent}
    />
  );
};

const PROMPT_TOOLTIP = 'The description of the assignment';
const STARTER_CODE_TOOLTIP = 'The starter code for the assignment';
const SOLUTION_TOOLTIP = 'Sample solution code for the assignment (optional)';
const UNIT_TESTS_TOOLTIP = 'The unit tests for the assignment';
const MISCELLANEOUS_TOOLTIP =
  'Additional miscellaneous features for the assignment';
const CANVAS_TOOLTIP = 'A link to the assignment on Canvas';

interface AssignmentEditorPlaceholderProps {
  shouldShowPlaceholder: boolean;
  setShouldShowPlaceholder: Dispatch<SetStateAction<boolean>>;
}
function AssignmentEditorPlaceholder({
  shouldShowPlaceholder,
  setShouldShowPlaceholder,
}: AssignmentEditorPlaceholderProps) {
  return (
    <>
      <AssignmentHeaderPlaceholder
        shouldShowPlaceholder={shouldShowPlaceholder}
        setShouldShowPlaceholder={setShouldShowPlaceholder}
      />
      <hr />
      <StyledAccordion alwaysOpen>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>{PROMPT_TOOLTIP}</Tooltip>}
        >
          <Accordion.Item eventKey="prompt">
            <Accordion.Header>Prompt</Accordion.Header>
            <Accordion.Body>
              <h4>Prompt</h4>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of a prompt that will be displayed to the
                  student.
                </Placeholder>
              </Placeholder>
            </Accordion.Body>
          </Accordion.Item>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>{STARTER_CODE_TOOLTIP}</Tooltip>}
        >
          <Accordion.Item eventKey="starter-code">
            <Accordion.Header>Starter Code</Accordion.Header>
            <Accordion.Body>
              <h4>Starter Code</h4>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of starter code that will be displayed to
                  the student.
                </Placeholder>
              </Placeholder>
              <h4>Upload File</h4>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of starter code that will be displayed to
                  the student.
                </Placeholder>
              </Placeholder>
            </Accordion.Body>
          </Accordion.Item>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>{SOLUTION_TOOLTIP}</Tooltip>}
        >
          <Accordion.Item eventKey="solution">
            <Accordion.Header>Solution</Accordion.Header>
            <Accordion.Body>
              <h4>Solution</h4>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of a solution that will be displayed to the
                  student.
                </Placeholder>
              </Placeholder>
            </Accordion.Body>
          </Accordion.Item>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>{UNIT_TESTS_TOOLTIP}</Tooltip>}
        >
          <Accordion.Item eventKey="unit-tests">
            <Accordion.Header>Unit Tests</Accordion.Header>
            <Accordion.Body>
              <h4>Unit Tests</h4>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of unit tests that will be displayed to the
                  student.
                </Placeholder>
              </Placeholder>
            </Accordion.Body>
          </Accordion.Item>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip>{MISCELLANEOUS_TOOLTIP}</Tooltip>}
        >
          <Accordion.Item eventKey="miscellaneous">
            <Accordion.Header>Miscellaneous Features</Accordion.Header>
            <Accordion.Body>
              <h4>Miscellaneous Features</h4>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of miscellaneous features that will be
                  displayed to the student.
                </Placeholder>
              </Placeholder>
              <h5>On Submit</h5>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of an on submit link that will be displayed
                  to the student.
                </Placeholder>
              </Placeholder>
              <h5>Tags</h5>
              <Placeholder as="p" animation="glow" className="user-select-none">
                <Placeholder>
                  This is an example of tags that will be displayed to the
                  student.
                </Placeholder>
              </Placeholder>
            </Accordion.Body>
          </Accordion.Item>
        </OverlayTrigger>
      </StyledAccordion>
    </>
  );
}

interface AssignmentEditorMainProps {
  serverAssignmentData: any;
  courseId: CourseId;
  assignmentId: AssignmentId;
  /** Whether or not to show the placeholder (the empty loading skeleton).
   * Useful for testing.*/
  shouldShowPlaceholder: boolean;
  setShouldShowPlaceholder: Dispatch<SetStateAction<boolean>>;
}

// Removes the default focus ring from the accordion
const StyledAccordion = styled(Accordion)`
  &.accordion,
  .accordion-item,
  .accordion-header,
  .accordion-button {
    box-shadow: none !important;
  }
`;

function AssignmentEditorMain({
  serverAssignmentData,
  courseId,
  assignmentId,
  shouldShowPlaceholder,
  setShouldShowPlaceholder,
}: AssignmentEditorMainProps) {
  const assignmentPath = `/${courseId}/ide/a/${assignmentId}`;
  const {courseCanvasAuthLink} = useContext(CourseContext);
  // Metadata Fields. These are the fields that are stored in the main
  // assignment document. Any changes to these fields should be reflected in the
  // assignmentMetadataDocument. We inherit the initial loaded values from the
  // serverAssignmentData, though we eventually want to load these from the
  // assignmentMetadataDocument directly as we update it. We use debounces to
  // prevent too many writes to the database.
  const firebaseAssignmentMetadataDocumentReference =
    getAssignmentMetadataReference(courseId, assignmentId);
  // The assignment data in `serverAssignmentData` might be stale (since we are
  // likely currently editing the assignment in the UI). We use the loaded data
  // as placeholder values but update them from the assignmentMetadataDocument
  // as it is updated.
  const [
    assignmentMetadataDocument,
    assignmentMetadataDocumentLoading,
    assignmentMetadataDocumentError,
  ] = useDocumentDataOnce(firebaseAssignmentMetadataDocumentReference);
  const [assignmentTitle, setAssignmentTitle] = useState(
    assignmentMetadataDocument?.title ?? '',
  );
  const [
    previouslyUploadedAssignmentTitle,
    setPreviouslyUploadedAssignmentTitle,
  ] = useState(assignmentTitle);
  const [debouncedAssignmentTitle] = useDebounce(
    assignmentTitle,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  const [unitTestType, setUnitTestType] = useState<AssignmentUnitTestType>(
    serverAssignmentData.metaData?.unitTestType ??
      inferUnitTestType(serverAssignmentData),
  );
  const [previouslyUploadedUnitTestType, setPreviouslyUploadedUnitTestType] =
    useState(unitTestType);
  const [debouncedUnitTestType] = useDebounce(
    unitTestType,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  const [unitTestBehavior, setUnitTestBehavior] =
    useState<AssignmentUnitTestBehavior>(
      serverAssignmentData.metaData?.unitTestBehavior ?? 'on_run',
    );
  const [
    previouslyUploadedUnitTestBehavior,
    setPreviouslyUploadedUnitTestBehavior,
  ] = useState(unitTestBehavior);
  const [debouncedUnitTestBehavior] = useDebounce(
    unitTestBehavior,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  const [tags, setTags] = useState<string[]>(
    serverAssignmentData.metaData?.tags ?? [],
  );
  const [previouslyUploadedTags, setPreviouslyUploadedTags] = useState(tags);
  const [debouncedTags] = useDebounce(tags, ASSIGNMENT_DATA_DEBOUNCE_MS, {
    equalityFn: (left, right) => {
      return (
        left.length === right.length &&
        left.every((tag, idx) => right[idx] === tag)
      );
    },
  });
  const [onSubmitLink, setOnSubmitLink] = useState<string>(
    serverAssignmentData.metaData?.onSubmitLink ?? '',
  );
  const [previouslyUploadedOnSubmitLink, setPreviouslyUploadedOnSubmitLink] =
    useState(onSubmitLink);
  const [debouncedOnSubmitLink] = useDebounce(
    onSubmitLink,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  const [canvasAssignmentId, setCanvasAssignmentId] = useState<
    number | undefined
  >(serverAssignmentData.metaData?.canvasAssnId);
  const [
    previouslyUploadedCanvasAssignmentId,
    setPreviouslyUploadedCanvasAssignmentId,
  ] = useState(canvasAssignmentId);
  const [showSolutionBehavior, setShowSolutionBehavior] =
    useState<ShowSolutionBehavior>(
      inferShowSolutionBehavior(serverAssignmentData?.metaData),
    );
  const [
    previouslyUploadedShowSolutionBehavior,
    setPreviouslyUploadedShowSolutionBehavior,
  ] = useState<ShowSolutionBehavior>(showSolutionBehavior);
  const [debouncedShowSolutionBehavior] = useDebounce(
    showSolutionBehavior,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  // The files representing the starter code for the assignment.
  const [starterCode, setStarterCode] = useState<Record<string, string>>({
    ...{'main.py': ''},
    ...serverAssignmentData.starterCode,
  });
  const [previouslyUploadedStarterCode, setPreviouslyUploadedStarterCode] =
    useState(starterCode);
  function isStarterCodeEqual(
    left: Record<string, string>,
    right: Record<string, string>,
  ) {
    if (!left || !right) {
      return left === right;
    }
    if (Object.keys(left).length !== Object.keys(right).length) {
      return false;
    }
    return Object.keys(left).every(key => left[key] === right[key]);
  }
  const [debouncedStarterCode] = useDebounce(
    starterCode,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
    {
      equalityFn: isStarterCodeEqual,
    },
  );

  // Whether or not the assignment is editable via the UI.
  const [assignmentEditable, setAssignmentEditable] = useState(false);
  const isMobile = useIsMobile();

  const serverType = serverAssignmentData.metaData?.type;
  const assignmentType = isAssignmentType(serverType) ? serverType : 'karel';
  const assignmentMetadataDocumentReference = getAssignmentMetadataReference(
    courseId,
    assignmentId,
  );
  const starterCodeDocumentReference = getStarterCodeDocumentReference(
    courseId,
    assignmentId,
  );
  const [
    starterCodeDocument,
    starterCodeDocumentLoading,
    starterCodeDocumentError,
  ] = useDocumentDataOnce(starterCodeDocumentReference);
  function hasMetadataChanged() {
    // If the metadata is still loading, we don't want to save it.
    if (assignmentMetadataDocumentLoading || assignmentMetadataDocumentError) {
      return false;
    }
    const tagsEqual =
      debouncedTags.length === previouslyUploadedTags.length &&
      debouncedTags.every((tag, idx) => tag === previouslyUploadedTags[idx]);
    return (
      previouslyUploadedAssignmentTitle !== debouncedAssignmentTitle ||
      previouslyUploadedUnitTestType !== debouncedUnitTestType ||
      previouslyUploadedUnitTestBehavior !== debouncedUnitTestBehavior ||
      !tagsEqual ||
      previouslyUploadedOnSubmitLink !== debouncedOnSubmitLink ||
      previouslyUploadedCanvasAssignmentId !== canvasAssignmentId ||
      previouslyUploadedShowSolutionBehavior !== debouncedShowSolutionBehavior
    );
  }

  const shouldSaveMetadata = hasMetadataChanged();
  // Saves the assignment metadata to the database.
  useEffect(() => {
    if (!shouldSaveMetadata) {
      return;
    }
    setDoc(assignmentMetadataDocumentReference, {
      title: debouncedAssignmentTitle,
      uid: assignmentId,
      type: assignmentType,
      unitTestType: debouncedUnitTestType,
      unitTestBehavior: debouncedUnitTestBehavior,
      tags: debouncedTags,
      onSubmitLink: debouncedOnSubmitLink,
      showSolutionBehavior: debouncedShowSolutionBehavior,
      ...(canvasAssignmentId !== undefined && {
        canvasAssnId: canvasAssignmentId,
      }),
    })
      .then(() => {
        setPreviouslyUploadedAssignmentTitle(debouncedAssignmentTitle);
        setPreviouslyUploadedUnitTestType(debouncedUnitTestType);
        setPreviouslyUploadedUnitTestBehavior(debouncedUnitTestBehavior);
        setPreviouslyUploadedTags(debouncedTags);
        setPreviouslyUploadedOnSubmitLink(debouncedOnSubmitLink);
        setPreviouslyUploadedCanvasAssignmentId(canvasAssignmentId);
        setPreviouslyUploadedShowSolutionBehavior(
          debouncedShowSolutionBehavior,
        );
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving assignment metadata',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving assignment metadata:', error);
      });
  }, [shouldSaveMetadata]);

  // Update the local state from the assignmentMetadataDocument as it is loaded.
  useEffect(() => {
    if (
      assignmentMetadataDocumentLoading ||
      assignmentMetadataDocumentError ||
      !assignmentMetadataDocument
    ) {
      return;
    }
    setAssignmentTitle(assignmentMetadataDocument?.title ?? '');
    setPreviouslyUploadedAssignmentTitle(
      assignmentMetadataDocument?.title ?? '',
    );
    setTags(assignmentMetadataDocument?.tags ?? []);
    setPreviouslyUploadedTags(assignmentMetadataDocument?.tags ?? []);
    setOnSubmitLink(assignmentMetadataDocument?.onSubmitLink ?? '');
    setPreviouslyUploadedOnSubmitLink(
      assignmentMetadataDocument?.onSubmitLink ?? '',
    );
    setCanvasAssignmentId(assignmentMetadataDocument?.canvasAssnId);
    setPreviouslyUploadedCanvasAssignmentId(
      assignmentMetadataDocument?.canvasAssnId,
    );
    setUnitTestType(inferUnitTestTypeFromMetadata(assignmentMetadataDocument));
    setPreviouslyUploadedUnitTestType(
      inferUnitTestTypeFromMetadata(assignmentMetadataDocument),
    );
    setUnitTestBehavior(
      assignmentMetadataDocument?.unitTestBehavior ?? 'on_run',
    );
    setPreviouslyUploadedUnitTestBehavior(
      assignmentMetadataDocument?.unitTestBehavior ?? 'on_run',
    );
    setShowSolutionBehavior(
      inferShowSolutionBehavior(assignmentMetadataDocument),
    );
    setPreviouslyUploadedShowSolutionBehavior(
      inferShowSolutionBehavior(assignmentMetadataDocument),
    );
  }, [assignmentMetadataDocumentLoading]);
  const shouldSaveStarterCode =
    !starterCodeDocumentLoading &&
    !starterCodeDocumentError &&
    !isStarterCodeEqual(debouncedStarterCode, previouslyUploadedStarterCode);
  // Update the local state from the starterCodeDocument as it is loaded.
  useEffect(() => {
    if (
      starterCodeDocumentLoading ||
      starterCodeDocumentError ||
      !starterCodeDocument
    ) {
      return;
    }
    setStarterCode(starterCodeDocument);
    setPreviouslyUploadedStarterCode(starterCodeDocument);
  }, [starterCodeDocumentLoading]);
  // Saves the starter code to the database.
  useEffect(() => {
    if (!shouldSaveStarterCode) {
      return;
    }
    setDoc(starterCodeDocumentReference, {
      ...debouncedStarterCode,
    })
      .then(() => {
        setPreviouslyUploadedStarterCode(debouncedStarterCode);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving starter code',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving starter code:', error);
      });
  }, [shouldSaveStarterCode]);
  return (
    <>
      <AssignmentHeader
        assignmentTitle={assignmentTitle}
        setAssignmentTitle={setAssignmentTitle}
        assignmentEditable={assignmentEditable}
        setAssignmentEditable={setAssignmentEditable}
        assignmentType={assignmentType}
        assignmentPath={assignmentPath}
        isMobile={isMobile}
        shouldShowPlaceholder={shouldShowPlaceholder}
        setShouldShowPlaceholder={setShouldShowPlaceholder}
      />
      <hr />
      <StyledAccordion alwaysOpen>
        <Accordion.Item eventKey="prompt">
          <Accordion.Header>Prompt</Accordion.Header>
          <Accordion.Body>
            <AssignmentEditorPrompt
              courseId={courseId}
              assignmentId={assignmentId}
              isEditable={assignmentEditable}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="starter-code">
          <Accordion.Header>Starter Code</Accordion.Header>
          <Accordion.Body>
            <EditMainStarterCode
              mainCode={starterCode?.['main.py'] ?? ''}
              setMainCode={newMainCode =>
                setStarterCode(oldCode => {
                  const newCode = {...oldCode};
                  newCode['main.py'] = newMainCode;
                  return newCode;
                })
              }
              isEditable={assignmentEditable}
            />
            <UploadFile
              starterCode={starterCode}
              setStarterCode={setStarterCode}
              isEditable={assignmentEditable}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="solution">
          <Accordion.Header>Solution</Accordion.Header>
          <Accordion.Body>
            <AssignmentEditorSolution
              courseId={courseId}
              assignmentId={assignmentId}
              showSolutionBehavior={showSolutionBehavior}
              setShowSolutionBehavior={setShowSolutionBehavior}
              isEditable={assignmentEditable}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="unit-tests">
          <Accordion.Header>Unit Tests</Accordion.Header>
          <Accordion.Body>
            <UnitTestMetadataEditor
              assignmentType={assignmentType}
              unitTestType={unitTestType}
              setUnitTestType={setUnitTestType}
              unitTestBehavior={unitTestBehavior}
              setUnitTestBehavior={setUnitTestBehavior}
              isEditable={assignmentEditable}
            />
            <UnitTestEditor
              unitTestType={unitTestType}
              courseId={courseId}
              assignmentId={assignmentId}
              serverAssignmentData={serverAssignmentData}
              isEditable={assignmentEditable}
            />
          </Accordion.Body>
        </Accordion.Item>
        {courseCanvasAuthLink && (
          <Accordion.Item eventKey="canvas-data">
            <Accordion.Header>Canvas Data</Accordion.Header>
            <Accordion.Body>
              <CanvasAssnModal
                assnId={assignmentId}
                canvasAssnId={canvasAssignmentId}
                assnTitle={assignmentTitle}
              />
            </Accordion.Body>
          </Accordion.Item>
        )}

        <Accordion.Item eventKey="miscellaneous">
          <Accordion.Header>Miscellaneous Features</Accordion.Header>
          <Accordion.Body>
            <MiscellaneousFeatures
              onSubmitLink={onSubmitLink}
              setOnSubmitLink={setOnSubmitLink}
              assignmentTags={tags}
              setAssignmentTags={setTags}
              isEditable={assignmentEditable}
            />
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
    </>
  );
}

interface EditStarterCodeProps {
  mainCode: string;
  setMainCode: (newCode: string) => void;
  isEditable: boolean;
}
const EditMainStarterCode = ({
  mainCode,
  setMainCode,
  isEditable,
}: EditStarterCodeProps) => {
  return (
    <div className="d-flex flex-column mt-3">
      <h3>Starter Code</h3>
      <ExpandableCodeEditor
        mode="python"
        value={mainCode}
        onChange={e => setMainCode(e)}
        readOnly={!isEditable}
      />
    </div>
  );
};

interface AssignmentEditorPromptProps {
  courseId: CourseId;
  assignmentId: AssignmentId;
  isEditable: boolean;
}
const AssignmentEditorPrompt = ({
  courseId,
  assignmentId,
  isEditable,
}: AssignmentEditorPromptProps) => {
  const promptPath = `assns/${courseId}/assignments/${assignmentId}/docs/prompt`;
  return (
    <div className="d-flex flex-column mt-3 w-100">
      <h3>Prompt</h3>
      <TipTap
        editable={isEditable}
        firebaseDocPath={promptPath}
        buttonBar={AssignmentEditorButtonBar}
      />
    </div>
  );
};

interface AssignmentEditorSolutionProps {
  courseId: CourseId;
  assignmentId: AssignmentId;
  showSolutionBehavior: ShowSolutionBehavior;
  setShowSolutionBehavior: (
    newShowSolutionBehavior: ShowSolutionBehavior,
  ) => void;
  isEditable: boolean;
}
const AssignmentEditorSolution = ({
  courseId,
  assignmentId,
  showSolutionBehavior,
  setShowSolutionBehavior,
  isEditable,
}: AssignmentEditorSolutionProps) => {
  const promptPath = `assns/${courseId}/assignments/${assignmentId}/docs/soln`;
  const showSolutionBehaviorOptions: SelectOption<ShowSolutionBehavior>[] =
    SUPPORTED_SHOW_SOLUTION_BEHAVIORS.map(behavior => {
      return {
        value: behavior,
        label: SHOW_SOLUTION_BEHAVIOR_DISPLAY_NAMES[behavior],
        tooltip: SHOW_SOLUTION_BEHAVIOR_TOOLTIPS[behavior],
      };
    });
  const selectedOption =
    showSolutionBehaviorOptions.find(
      option => option.value === showSolutionBehavior,
    ) ??
    showSolutionBehaviorOptions.find(option => option.value === 'never') ??
    showSolutionBehaviorOptions[0];
  const setSelectedOption = (option: SelectOption<ShowSolutionBehavior>) => {
    setShowSolutionBehavior(option.value);
  };
  return (
    <div className="d-flex flex-column mt-3 w-100">
      <h3>Solution</h3>
      <Stack direction="horizontal" gap={2}>
        <div>
          <b>Show Solution Behavior:</b>
        </div>
        <div>
          <SelectDropdown
            selectableOptions={showSolutionBehaviorOptions}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            configuration={{disabled: !isEditable}}
          />
        </div>
      </Stack>
      <TipTap
        editable={isEditable}
        firebaseDocPath={promptPath}
        buttonBar={AssignmentEditorButtonBar}
      />
    </div>
  );
};

// if an assnId doesn't exist, create it!
export async function createNewAssignment(
  courseId: CourseId,
  assignmentId: AssignmentId,
  assignmentTitle: string,
  assignmentType: AssignmentType,
) {
  if (!(assignmentType in ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES)) {
    console.warn(`Unsupported assignment type: ${assignmentType}`);
    return;
  }
  const assignmentUnitTestType =
    ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES[assignmentType][0];
  const assignmentUnitTestBehavior = 'on_run' as const;
  const metadata = {
    title: assignmentTitle,
    type: assignmentType,
    unitTestType: assignmentUnitTestType,
    unitTestBehavior: assignmentUnitTestBehavior,
  };
  updateAssignmentMetadata(courseId, assignmentId, metadata);
}

async function updateAssignmentMetadata(
  courseId: CourseId,
  assnId: AssignmentId,
  metadata: Partial<AssignmentMetadata>,
) {
  await setDoc(getAssignmentMetadataReference(courseId, assnId), metadata, {
    merge: true,
  });
}

export async function deleteAssignment(courseId, assnId, callback = () => {}) {
  const db = getFirestore();
  const path = `assns/${courseId}/assignments/${assnId}`;
  const assnRef = doc(db, path);
  await deleteDoc(assnRef);
  callback();
}
