import {useRef} from 'react';
import {v4 as uuidv4} from 'uuid';
import {FaImage} from 'react-icons/fa';
import {fileMap} from '../../TipTapEditor';

export const ImageButton = ({editor, buttonTxt = ''}) => {
  const fileInputRef = useRef(null);

  const onClick = () => {
    // Trigger the file input click event to open the file picker
    fileInputRef.current.click();
  };

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const id = uuidv4();

      // Store the file in the global file map
      fileMap.set(id, file);

      // Insert the KindImage node with the placeholder ID
      editor
        .chain()
        .focus()
        .insertContent({
          type: 'kindImage',
          attrs: {id, src: null},
        })
        .run();
    }
  };

  return (
    <>
      <button onClick={onClick} className="btn btn-sm btn-light">
        <FaImage /> {buttonTxt}
      </button>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{display: 'none'}}
        accept="image/*"
        onChange={handleFileChange}
      />
    </>
  );
};
