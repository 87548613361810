import {useEffect} from 'react';
import {isDevelopmentEnvironment} from 'utils/general';

/**
 * A test page that always throws an error.
 * This is useful for testing the error boundary (see the dev/ routes in Router.tsx).
 */
export const AlwaysFailingPage = () => {
  const isProduction = !isDevelopmentEnvironment();

  useEffect(() => {
    if (isProduction) {
      return;
    }

    throw new Error('This page should always fail, you can ignore this error!');
  }, []);

  if (isProduction) {
    console.warn('AlwaysFailingPage is not available in production');
  }

  return null;
};
