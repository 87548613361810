import {
  IKarelState,
  isIKarelState,
} from 'components/pyodide/KarelLib/karelInterfaces';
import {
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  getFirestore,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';

export interface KarelUnitTest {
  /** A human-readable name for the test. */
  name: string;
  /** A unique identifying key for the test. Previously a number, now a string. */
  key?: string | number;
  /** The starting state of the world before running the test. */
  pre: IKarelState;
  /** The expected state of the world after running the test. */
  post: IKarelState;
}

/**
 * Validates that an object is a KarelUnitTest.
 */
export function isKarelUnitTest(testObject: any): testObject is KarelUnitTest {
  return (
    testObject &&
    typeof testObject === 'object' &&
    typeof testObject.name === 'string' &&
    (!testObject.key ||
      typeof testObject.key === 'string' ||
      typeof testObject.key === 'number') &&
    isIKarelState(testObject.pre) &&
    isIKarelState(testObject.post)
  );
}

const KAREL_UNIT_TEST_DOC_CONVERTER: FirestoreDataConverter<KarelUnitTest[]> = {
  toFirestore(unitTests: KarelUnitTest[]): DocumentData {
    return {
      unitTestsKarel: unitTests,
      lastUpdated: Timestamp.now(),
    };
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions,
  ): KarelUnitTest[] {
    const data = snapshot.data(options);
    if (!data) {
      console.debug('No data found in snapshot');
      return [];
    }
    const unitTests = data.unitTestsKarel ?? data.unitTests;
    if (!unitTests || !Array.isArray(unitTests)) {
      console.debug('No unitTests found in snapshot');
      return [];
    }
    return unitTests
      .map((unitTest: any) => {
        return {...unitTest, key: String(unitTest.key)};
      })
      .filter(unitTest => {
        if (!isKarelUnitTest(unitTest)) {
          console.warn('Invalid Karel unit test:', unitTest);
          return false;
        }
        return true;
      });
  },
};

export function getKarelUnitTestReference(
  courseId: string,
  assignmentId: string,
): DocumentReference<KarelUnitTest[]> {
  return doc(
    getFirestore(),
    `/assns/${courseId}/assignments/${assignmentId}/docs/unitTests`,
  ).withConverter(KAREL_UNIT_TEST_DOC_CONVERTER);
}
