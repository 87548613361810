import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, getFirestore, setDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { useCourseId } from 'hooks/router/useUrlParams';
import { useUserId } from 'hooks/user/useUserId';
import { ProfileContext } from 'contexts/ProfileContext';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router';
import { TEACHER_STATUSES } from "./types";


export const AdminNudgeButton = () => {
  const db = getFirestore();
  const courseId = useCourseId();
  const userId = useUserId();
  const { userData } = useContext(ProfileContext);
  const displayName = userData?.displayName;
  const navigate = useNavigate();
  
  const userTeacherStatusRef = doc(db, "teachnow", courseId, "teachers", userId);
  const [teacherStatusData, teacherStatusLoading] = useDocumentData(userTeacherStatusRef);
  
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    teacherId: "BkxKgaz6v3hir1QJ5bPdBW0DaYj1",
    studentId: "rf937VkbPaXdMQQyZpiyacpwKX83",
  });

  const handleClickOpen = () => {
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTestNudgeTeachNow = async ({ testNudgeId }) => {
    try {
      const teacherDoc = {
        status: TEACHER_STATUSES.TEST_NUDGE,
        matched: false,
        displayName: displayName,
        teacherJoinedTime: serverTimestamp(),
        testNudgeStudentId: testNudgeId,
      };
      await setDoc(userTeacherStatusRef, teacherDoc);
      console.log("Teacher status updated for user:", userId);
    } catch (error) {
      console.error("Error sending test nudge:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleTestNudgeTeachNow({
      testNudgeId: formData.studentId,
    });
    // keep the modal open
  };

    // Remove teacher from the queue (cancel test nudge)
    const handleRemoveMe = async () => {
      try {
        await deleteDoc(userTeacherStatusRef);
        console.log("Removed teacher from queue:", userId);
        setShowModal(false);
      } catch (error) {
        console.error("Error removing teacher from queue:", error);
      }
    };
  
    // Listen for a match and navigate when matched
    useEffect(() => {
      if (!teacherStatusData || !("status" in teacherStatusData)) return;
      if (teacherStatusData.status === TEACHER_STATUSES.MATCHED) {
        if (teacherStatusData.roomId) {
          navigate(`/${courseId}/ide/t/teachnow?r=${teacherStatusData.roomId}`);
        } else {
          // Optionally, handle a case where the status is MATCHED but roomId is missing.
        }
      }
    }, [teacherStatusData, courseId, navigate]);
  
    return (
      <div>
        <Button variant="primary" onClick={handleClickOpen}>
          Send test nudge
        </Button>
  
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Send Test Nudge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {teacherStatusData && teacherStatusData.status === TEACHER_STATUSES.TEST_NUDGE ? (
              // While waiting for a match, show a loading spinner and a "Remove me" button.
              <div className="text-center">
                <p>Waiting for a match...</p>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <br />
                <Button variant="warning" onClick={handleRemoveMe} className="mt-3">
                  Remove me
                </Button>
              </div>
            ) : (
              // Otherwise, show the form to send a test nudge.
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Admin ID to Send the Nudge From:</Form.Label>
                  <Form.Control
                    type="text"
                    name="teacherId"
                    value={formData.teacherId}
                    onChange={handleChange}
                    placeholder="Enter Admin ID"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Student ID to test nudge:</Form.Label>
                  <Form.Control
                    type="text"
                    name="studentId"
                    value={formData.studentId}
                    onChange={handleChange}
                    placeholder="Enter Student ID"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Send
                </Button>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  };
