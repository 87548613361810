import {useCodeAdventure} from './CodeAdventureContext';
import {StartStopButton} from 'ide/NavIDE';
import {
  ReactNode,
  FC,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {Range} from 'monaco-editor';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';
import {MobileTab} from './MobileView';
import {editor} from 'monaco-editor';

interface ActionWidgetInnerProps {
  onNext: (nextDynamicStudentCode?: string, correct?: boolean) => void;
}

interface RunCodeProps extends ActionWidgetInnerProps {
  runCode: () => void;
}

const setUpDecorationsFunctionGenerator = (
  editorRef: MutableRefObject<editor.IStandaloneCodeEditor>,
  editorLoaded: boolean,
  currentDecorations: editor.IEditorDecorationsCollection,
  getDecorations: () => editor.IModelDeltaDecoration[],
  setDecorations: Dispatch<SetStateAction<editor.IEditorDecorationsCollection>>,
  selectedMobileTab: MobileTab,
  lineToRevealInCenter: number,
) => {
  return () => {
    if (
      !(
        editorLoaded &&
        editorRef.current &&
        selectedMobileTab === MobileTab.CODE
      )
    )
      return;
    if (currentDecorations) currentDecorations.clear();
    const newDecorations = getDecorations();
    setDecorations(
      editorRef.current.createDecorationsCollection(newDecorations),
    );
    editorRef.current.revealLineInCenter(lineToRevealInCenter);
    editorRef.current.focus();
  };
};

const RunCode: FC<RunCodeProps> = ({runCode, onNext}) => {
  const {isRunning, selectedMobileTab, setSelectedMobileTab} =
    useCodeAdventure();
  const [hasRun, setHasRun] = useState<boolean>(false);
  const [scheduleRun, setScheduleRun] = useState<boolean>(false);

  const run = () => {
    setSelectedMobileTab(MobileTab.OUTPUT);
    setScheduleRun(true);
  };

  useEffect(() => {
    if (scheduleRun && selectedMobileTab === MobileTab.OUTPUT) {
      runCode();
      setHasRun(true);
      setScheduleRun(false);
    }
  }, [scheduleRun, selectedMobileTab]);

  return (
    <>
      <StartStopButton
        isRunning={isRunning}
        startStopButtonClicked={run}
        isKarel={false}
        defaultKarelWorldState={null}
        karelWorldState={null}
        editable={true}
      />
      {hasRun && <NextButton onNext={onNext} />}
    </>
  );
};

interface Choice {
  code: string;
  display?: string;
}

export interface AddCodeProps extends ActionWidgetInnerProps {
  choices: Choice[];
  correctChoiceIdx: number;
  lineNumber: number;
}

const AddCode: FC<AddCodeProps> = ({choices, correctChoiceIdx, lineNumber, onNext}) => {
  const {
    prevSlideNodesCodes,
    editorRef,
    editorLoaded,
    setCode,
    decorations,
    setDecorations,
    selectedMobileTab,
    setSelectedMobileTab,
  } = useCodeAdventure();
  const prevSlideNodeCode =
    prevSlideNodesCodes.current[
      prevSlideNodesCodes.current.length - 1
    ] || '';

  const getDecorations = () => {
    return [
      {
        range: new Range(lineNumber, 1, lineNumber, 2),
        options: {
          after: {
            content: 'Click below to insert code',
            inlineClassName: 'add-code-highlight',
          },
        },
      },
    ];
  };
  const setUpDecorations = setUpDecorationsFunctionGenerator(
    editorRef,
    editorLoaded,
    decorations,
    getDecorations,
    setDecorations,
    selectedMobileTab,
    lineNumber,
  );

  useEffect(() => {
    setSelectedMobileTab(MobileTab.CODE);
    const countTabs = 1; // TODO: number of tabs should be based on prior lines in code
    const tabs = '\t'.repeat(countTabs);
    const lines = prevSlideNodeCode.split('\n');
    lines.splice(lineNumber - 1, 0, tabs);
    setCode(lines.join('\n'));
  }, []);

  useEffect(() => {
    if (!(editorLoaded && editorRef.current)) return;
    const dispose = editorRef.current.onDidChangeModelContent(setUpDecorations);
    return () => {
      dispose.dispose();
      decorations?.clear();
    };
  }, [editorLoaded]);

  useEffect(() => {
    if (
      !(
        editorLoaded &&
        editorRef.current &&
        selectedMobileTab === MobileTab.CODE
      )
    )
      return;
    setUpDecorations();
  }, [editorLoaded, selectedMobileTab]);

  // onClick add the choice to the code at the specified line number
  const onClick = (choice: Choice, idx: number) => {
    setCode(() => {
      // TODO: use number of tabs based on prior lines indentation
      const lines = prevSlideNodeCode.split('\n');
      lines.splice(lineNumber - 1, 0, '\t' + choice.code);
      const updatedCode = lines.join('\n');
      onNext(updatedCode, idx === correctChoiceIdx);
      return updatedCode;
    });
  };

  return (
    <>
      {choices.map((choice, idx) => (
        <AddCodeButton
          className="btn btn-sm btn-light text-nowrap"
          key={idx}
          onClick={() => onClick(choice, idx) }
        >
          {choice.display ?? choice.code}
        </AddCodeButton>
      ))}
    </>
  );
};

export interface RemoveLineProps extends ActionWidgetInnerProps {
  lineNumber: number;
}

const RemoveLine: FC<RemoveLineProps> = ({lineNumber, onNext}) => {
  const {
    setCode,
    setSelectedMobileTab,
    editorLoaded,
    editorRef,
    selectedMobileTab,
    decorations,
    setDecorations,
  } = useCodeAdventure();

  useEffect(() => {
    setSelectedMobileTab(MobileTab.CODE);
  }, []);

  useEffect(() => {
    const getDecorations = () => {
      const removeLine = editorRef.current
        .getModel()
        .getLineContent(lineNumber);
      const endCol = removeLine.length + 1;
      const startCol = removeLine.search(/\w/g) + 1;
      return [
        {
          range: new Range(lineNumber, startCol, lineNumber, endCol),
          options: {inlineClassName: 'remove-line-word-highlight'},
        },
      ];
    };
    setUpDecorationsFunctionGenerator(
      editorRef,
      editorLoaded,
      decorations,
      getDecorations,
      setDecorations,
      selectedMobileTab,
      lineNumber,
    )();
  }, [editorLoaded, selectedMobileTab]);

  const removeLine = () => {
    setCode((prevCode) => {
      const lines = prevCode.split('\n');
      lines.splice(lineNumber - 1, 1);
      const updatedStudentCode = lines.join('\n');
      onNext(updatedStudentCode);
      return updatedStudentCode;
    });
  };

  return (
    <Button variant="light" size="sm" onClick={removeLine}>
      Remove line
    </Button>
  );
};

interface CompleteProps extends ActionWidgetInnerProps {
  onComplete: () => void;
}

const Complete: FC<CompleteProps> = ({onComplete}) => {
  return (
    <Button variant="light" size="sm" onClick={onComplete}>
      Continue
    </Button>
  );
};

const LoadingWidget: FC = () => {
  return <p>Loading...</p>;
};

interface OuterWidgetProps {
  widget: 'AddCode' | 'RunCode' | 'RemoveLastLine' | 'Complete';
  prompt: string;
  children?: ReactNode;
}

type InnerWidgetProps = Partial<RunCodeProps | AddCodeProps | CompleteProps>;

export type ActionWidgetProps = OuterWidgetProps & InnerWidgetProps;

const widgetComponents: Record<string, FC<any>> = {
  AddCode,
  RunCode,
  RemoveLine,
  Complete,
};

const ActionWidget: FC<ActionWidgetProps> = props => {
  const {widget, prompt, children, ...innerWidgetProps} = props;
  const ActionWidgetComponent: FC<InnerWidgetProps> =
    widgetComponents[widget] || LoadingWidget;
  const {isTabletOrLarger} = useCodeAdventure();

  const actionWdigetOuterFlexDirection = isTabletOrLarger
    ? 'flex-row'
    : 'flex-column';
  const actionWdigetOuterClasses = `d-inline-flex ${actionWdigetOuterFlexDirection} gap-1`;
  const Prompt = `h${isTabletOrLarger ? 5 : 6}` as keyof JSX.IntrinsicElements;
  return (
    <>
      <div className="mb-1">
        <Prompt className="text-white">{prompt}</Prompt>
      </div>
      <div className={actionWdigetOuterClasses}>
        <ActionWidgetComponent {...innerWidgetProps} />
      </div>
      <div className="d-flex flex-row-reverse">{children}</div>
    </>
  );
};

export default ActionWidget;

const NextButton = ({onNext}) => {
  return (
    <WideButton
      variant="light"
      size="sm"
      className="mr-2"
      onClick={() => onNext()}
    >
      Next
    </WideButton>
  );
};

const WideButton = styled(Button)`
  width: 80px;
`;

const AddCodeButton = styled(Button)`
  background-color: #cbc3e3;
  border-color: #cbc3e3;
  font-family: Menlo, Monaco, 'Courier New', monospace;
  &:hover,
  &:focus-visible {
    background-color: #cbc3e3;
    border-color: var(--bs-btn-hover-color);
  }
  width: min-content;
`;
