import { MinAgeBirthday } from "course/application/general/MinAgeBirthday";
import React from "react";
import styled from "styled-components";
import { useIsMobile } from "utils/general";



export const Responsibilities = () => {
  return (
    <div>
      <h1>Your Responsibilities</h1>

      <ol>
      <li>Spend 7 hours a week learning to code &mdash; it takes time.</li>
        <li>Attend a 50-minute section once a week for 7 weeks.</li>
      </ol>

    </div>
  );
};

export const Qualifications = () => {
  return (
    <div>
      <h1>Qualifications</h1>
      
      <ol>
        <li><Blue>Speak english</Blue> well enough to hold a real-time conversation.</li>
        <li><Blue>Have internet</Blue> stable enough for an audio
        call.</li>
        <li><Blue>Coding beginner</Blue>. If you already know the concepts covered (even if that is in another coding language like C++ or Java), apply to teach instead! </li>
        <li><Blue>Over 16 years old</Blue>. In other words: you were born before the <MinAgeBirthday/></li>
      </ol>
      
      

      <p>Please apply! We have students from 150 countries around the world and all walks of life. </p>
      
      
    </div>
  );
};

// export const Coverage = () => {
//   return (
//     <div>
//       <h1>You will Learn (in Python)</h1>
    
//         <ol>
//           <li>Loops, functions and conditionals <a target="_blank" href="https://compedu.stanford.edu/karel-reader/docs/python/en/chapter1.html">Karel the Robot</a></li>
//           <li>Variables and control-flow</li>
//           <li>Practice with graphics and animations</li>
//           <li>Intro to datastructures including lists and dictionaries</li>
//         </ol>
//         <p>
//         The course is free for you (the cost is covered by Stanford and our generous donors).</p>
//         <p>The course is in standard Python, but for Karel and Graphics we use easy-to-learn libraries. You are not expected to know them before the course starts.</p>

      
      
      
//     </div>
//   );
// }

export const Coverage = () => {
  const isMobile = useIsMobile()
  return <>
    <h2 style={{ paddingTop: "20px" }}>You Will Learn</h2>
    <p>In Code in Place you will learn the fundamentals of Python programming including: control flow, loops and conditionals, console programs with variables, graphics, lists and dictionaries. </p>
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col"><h5><span className="badge text-bg-primary">{!isMobile && "Week"} #</span></h5></th>
          <th scope="col"><h5><span className="badge text-bg-primary">Topic</span></h5></th>
          <th scope="col"><h5><span className="badge text-bg-primary">You Build</span></h5></th>
          {!isMobile && <th scope="col"><h5><span className="badge text-bg-primary">Unit</span></h5></th>}
        </tr>
      </thead>
      <tbody className="table-group-divider">
        <tr>
          <td scope="row">Week 1</td>
          <td>Control flow with Karel</td>
          <td>Welcome Karel</td>
          {!isMobile && <td>Karel</td>}
        </tr>
        <tr>
          <td>Week 2</td>
          <td>The Art of Coding</td>
          <td>Puzzle Karel</td>
          {!isMobile && <td>Karel</td>}
        </tr>
        <tr>
          <td>Week 3</td>
          <td>Console Programs</td>
          <td>Sandcastles</td>
          {!isMobile && <td>Console</td>}
        </tr>
        <tr>
          <td>Week 4</td>
          <td>Understanding Variables</td>
          <td>Khansole Academy</td>
          {!isMobile && <td>Console</td>}
        </tr>
        <tr>
          <td>Week 5</td>
          <td>Graphics</td>
          <td>Random Art</td>
          {!isMobile && <td>Graphics</td>}
        </tr>
        <tr>
          <td>Week 6</td>
          <td>Lists + Dictionaries</td>
          <td>Baby Snake</td>
          {!isMobile && <td>Graphics</td>}
        </tr>
        <tr>
          <td scope="row">Beyond {isMobile ? "" : "Code in Place"}</td>
          <td>Continue Learning</td>
          <td>Final Project</td>
          {!isMobile && <td>Creativity</td>}
        </tr>
      </tbody>

    </table>
  </>
}

{/*  */}

const Blue = styled.b`
  color:#007bff;
`