import {FaFlag} from 'react-icons/fa';
import {
  AssnPrompt,
  LessonCodeEditor,
  AdventureControls,
  CodeAdventureTerminal,
} from './CodeAdventureLesson';
import styled from 'styled-components';

export const DesktopView = ({
  title,
  tasks,
  onBack,
  actionWidgetProps,
}) => {
  return (
    <div className="d-flex flex-row h-100 mt-1">
      <div className="left-column w-25 d-flex flex-row">
        <div className="ide-side-bar d-flex flex-column justify-content-top mr-1">
          <div
            role="button"
            className={'btn radius ide-nav-selected'}
            aria-label={'Your Task'}
            aria-disabled={true}
          >
            <FaFlag size={18} />
          </div>
        </div>
        <div className="assn-prompt-container flex-grow-1">
          <AssnPrompt tasks={tasks} title={title} />
        </div>
      </div>
      <div className="right-column d-flex flex-column w-75 h-100">
        <RightColumnSection flexBasis={'50%'} overflowYAuto>
          <LessonCodeEditor />
        </RightColumnSection>
        <RightColumnSection flexBasis={'10%'}>
          <AdventureControls
            onBack={onBack}
            actionWidgetProps={actionWidgetProps}
          />
        </RightColumnSection>
        <RightColumnSection flexBasis={'40%'} overflowYAuto>
          <CodeAdventureTerminal />
        </RightColumnSection>
      </div>
    </div>
  );
};

const RightColumnSection = styled.div<{
  flexBasis: string;
  overflowYAuto?: boolean;
}>`
  flex-basis: ${props => props.flexBasis};
  overflow-y: ${props => (props.overflowYAuto ? 'auto' : 'visible')};
`;
