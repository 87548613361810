/**
 * @fileoverview Handles logic and rendering of the /{courseid}/code and /{courseid}/create course pages.
 */

import {useContext} from 'react';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {CoursePageSingleCol} from 'components/layout/CoursePageSingleCol';
import {AssnContext} from 'course/contexts/AssnContext';
import {CustomProjectsTable} from './CustomProjects';
import {RoadmapAssignments} from './RoadmapAssignments';
import { PartialLoading } from 'components/loading/Loading';

interface CodeSplashProps {
  /** Used to distinguish from provided code (e.g. assns & examples) from student projects; can be undefined */
  creative?: boolean;
}

/**
 * Handles initial logic and component bundling for rendering the /{courseid}/code and
 * /{courseid}/create pages. Props contain a flag that renders either creative projects
 * or the coding assignment list when true/false respectively.
 *
 * @param {CodeSplashProps} props - properties for rendering the code/create pages listed in the description
 * @returns {JSX.Element} CoursePageSingleCol containing either the user's creative projects or the assignment list
 */
export const CodeSplash = ({creative}: CodeSplashProps) => {
  {
    /* If a creative flag is provided, use it. Otherwise, assume the user wants the standard assignment list. */
  }
  creative = creative ?? false;

  return (
    <CoursePageSingleCol column={<CodeMainColumn creative={creative} />} />
  );
};

interface CodeMainColProps {
  /** Used to distinguish from provided code (e.g. assns & examples) from student projects */
  creative: boolean;
}

/**
 * Handles loading of assignments and creative projects and provides CodeMainSplash with
 * the relevant page to render based on what the creative flag is set to.
 *
 * @param {CodeMainColProps} props - properties for rendering the code/create pages listed in the description
 * @returns {JSX.Element} The user's creative projects or the instructor-set assignment list
 */
const CodeMainColumn = ({creative}: CodeMainColProps) => {
  const {assnLoaded, creativeLoaded} = useContext(AssnContext);

  if (!assnLoaded || !creativeLoaded) {
    return <PartialLoading />;
  }

  if (creative) {
    return <CustomProjectsTable />;
  }

  return <RoadmapAssignments />;
};
