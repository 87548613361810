// This file contains Sierra's implementations for more helpful error messages
// to be used and tested in Code in Place 2023. These error message types were
// inspired/copied from others' work. The goal of this work is to compare the
// different enhancement techniques.

import { callGptChat } from "utils/gpt";

const nonkarel_system_message = "You are an inspiring computer science tutor, helping students learn the basic intuition and implementation of code in python. Given the student's code and error message, generate a 1-2 sentence explanation of all the most likely reasons that could have caused this error and if there is a simple fix. Do NOT write any code for the student. Only explain what might be the cause of the error.";

const karel_system_message = "You are an inspiring computer science tutor, helping students learn the basic intuition and implementation of code in python. Given the student's Karel code and error message, generate a 1-2 sentence text explanation of the most likely reason to have caused this syntax error and if there is a simple fix. Try to be thoughtful of the intent of the student. Be aware, the error might be from the line before the error line. Karel does not use variables. Here are the Karel functions: move(), turn_left(), put_beeper(), pick_beeper(). Here are some common karel conditions: front_is_clear(), left_is_clear(), right_is_clear(), beepers_present(), no_beepers_present(). Karel also knows the commands random(p) where p is a number between 0 and 1, and paint_corner(color) where color is an html color string. The correct import line is `from karel.stanfordkarel import *`";

const lineNumberRegex = /File "<exec>", line (\d+)/;
const karelErrorRegex = /Line (\d+):/;

const getKarelMessages = (stderr, code) => {
    console.log("stderr", stderr);
    const errorMsg = getKarelError(stderr);
    const prompt = `Karel Code:\n\`\`\`${code}\n\`\`\`\nKarel Error message:\n${errorMsg}\n`
    return [{ role: 'system', content: karel_system_message }, { role: 'user', content: prompt }];
}

// Construct the messages prompt if it is a non karel program
export const getStdMessages = (stderr, code) => {
    const errorMsg = getLastLineOfError(stderr);
    const prompt = `Code:\n\`\`\`${code}\n\`\`\`\nError message:\n${errorMsg}\n`
    return [{ role: 'system', content: nonkarel_system_message }, { role: 'user', content: prompt }];
}

export const getStdYellowMessage = (stderr, code) => {
    const errorMsg = getLastLineOfError(stderr);
    return yellowText(boldText(underlineText(`Error: ${errorMsg}`)));
}

function yellowText(text) {
    return '\x1B[93m' + text + '\x1B[0m';
}

function underlineText(text) {
    return '\x1B[4' + text + '\x1B[0m';
}

function boldText(text) {
    return '\x1B[1m' + text + '\x1B[0m';
}

export function getLastLineOfError(stderr) {
    const errorLines = stderr.trim().split("\n");
    return errorLines[errorLines.length - 1];
}

// Get the line of stderr that contains the line number
export function getLineNumber(stderr) {
    if (karelErrorRegex.test(stderr)) {
        // This is a karel error
        const lineNum = parseInt(stderr.match(karelErrorRegex)[1]) + 1;
        return lineNum;
    } else {
        const errorLines = stderr.split("\n");
        for (let i = errorLines.length - 1; i >= 0; i--) {
            const line = errorLines[i];
            const match = line.match(lineNumberRegex);
            if (match) {
                const lineNumber = match[1];
                return lineNumber - 5;
            }
        }
        return -1;
    }
}

function getKarelError(stderr) {
    if (karelErrorRegex.test(stderr)) {
        // This is a karel error
        const result = stderr.replace(/Line (\d+)/g, (match, lineNumber) => {
            const nextLineNumber = parseInt(lineNumber) + 1;
            return `Line ${nextLineNumber}`;
        });
        return result
    } else {
        // This is a pyodide error
        return getLastLineOfError(stderr)
    }
}

// Outputs the line number and last line of error message
function getTrimError(code, stderr) {
    const lineNum = getLineNumber(stderr);
    const error = getLastLineOfError(stderr);
    if (lineNum < 0) {
        return yellowText(boldText(error))
    }
    return yellowText(boldText(underlineText("(Line " + lineNum + ") " + error)));
}

// Write message to tell user they got an error and we are calling GPT
const introMessage = (errMsg, term, isRuntime, isKarel) => {
    if (isRuntime && isKarel) {
        term.writeAndScroll("\r\n" + errMsg + "\r\n" +
            yellowText(`Your code had a runtime error so it stopped running`))
    } else {
        term.writeAndScroll("\r\n" + errMsg + "\r\n" +
            yellowText(`Your code had an error so it wasn't able to run. We are crafting an explanation for you. ` +
                `It might take a second, thank you for being patient!...`))
    }

}

// Place this outside the getErrorMessage function, in a scope accessible by all calls to getErrorMessage.
let isGptErrorMessageCallInProgress = false;

// Return an error generated by GPT
export const getErrorMessage = async (code, stderr, term) => {
    console.log(stderr)

    // in order to be a runtime in needs to (a) have a traceback and (b) the traceback should not say syntax error
    const hasTraceback = stderr.includes("Traceback");
    const isSyntaxError = stderr.includes("SyntaxError");
    const isRuntime = hasTraceback && !isSyntaxError;
    // Generate an explanation from GPT
    if (term.isKarel) {
        introMessage(yellowText(boldText(underlineText(getKarelError(stderr)))), term, isRuntime, true);
        const messages = getKarelMessages(stderr, code);

        if (!isRuntime && !isGptErrorMessageCallInProgress) {
            isGptErrorMessageCallInProgress = true;
            callGptChat("gpt-4", messages).then((response) => {
                const header = yellowText(boldText("Error Explanation: "));
                let responseTxt = "";
                if (!response) {
                    responseTxt = yellowText("Sorry, we are unable to craft an explanation for this error right now.");
                } else {
                    responseTxt = yellowText(parseResponse(response));
                }
                term.writeAndScroll(yellowText(`${header} ${responseTxt}\n`));
                term.handleEnd()
                isGptErrorMessageCallInProgress = false;
            })
        }
    } else {

        introMessage(getTrimError(code, stderr), term, isRuntime, false);
        const messages = getStdMessages(stderr, code);

        if (!isGptErrorMessageCallInProgress) {
            isGptErrorMessageCallInProgress = true;
            callGptChat("gpt-3.5-turbo", messages).then((response) => {
                const header = yellowText(boldText("Error Explanation: "));
                let responseTxt = "";
                if (!response) {
                    responseTxt = yellowText("Sorry, we are unable to craft an explanation for this error right now.");
                } else {
                    responseTxt = yellowText(parseResponse(response));
                }
                term.writeAndScroll(yellowText(`${header} ${responseTxt}\n`));
                term.handleEnd();
                isGptErrorMessageCallInProgress = false;
            });
        }
    }
    // return yellowText(("" + gptResponse).trim());
    return ''
}

function parseResponse(response) {
    // First, use a regular expression to remove the outer ``` and the language specifier
    let modifiedResponse = response.replace(/```python\n([\s\S]*?)```/g, '\n\r$1');

    // Then ensure that each line ends with a \r (carriage return)
    // Split the response into lines, add \r to each, and then join them back together
    modifiedResponse = modifiedResponse.split('\n').map(line => line + '\r').join('\n');

    return modifiedResponse;
}
