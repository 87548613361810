import React, { useContext, useEffect, useRef } from "react";
import { useComponentSize } from "react-use-size";
import styled from "styled-components";
import { IDEContext } from "ide/contexts/IDEContext";
import { TerminalToolbar } from "../TerminalPane";
import { ConsoleInput, MobileTerminalShell, TerminalPre, TerminalShell } from "./TerminalStyles";
import { FlexConsoleInput } from "./ConsoleInput";

/**
 * IDETerminalView
 *
 * Renders the console/terminal UI. 
 *
 * Key points:
 *  - In "step mode", the user cannot directly type into the console.
 *  - If not in "step mode":
 *    - Clicking (with no drag to select text) focuses the input.
 *    - Selecting text with the mouse does NOT lose selection.
 *    - Pressing keys anywhere in the shell focuses the input (typical terminal behavior).
 */
export const IDETerminalView = (props) => {
  const {
    stepSaveOn,
    stepMode,
    stepPtr,
    stepList,
    stepLogs,
    isTabletOrLarger
  } = useContext(IDEContext);

  // This is not a component, instead it is the class instance that manages the terminal
  const { terminalRef } = props;

  // References to console input and terminal elements
  const consoleInputRef = useRef(null);
  const terminalPreRef = useRef(null);
  const terminalContentRef = useRef(null);
  const shellRef = useRef(null);

  /**
   * Update the terminal when in step mode:
   *  - Only show logs up to the current pointer.
   */
  useEffect(() => {
    if (stepMode && stepSaveOn && terminalRef?.current) {
      const logsToShow = stepLogs.slice(0, stepList[stepPtr]["logptr"]);
      terminalRef.current.step(logsToShow);
    }
  }, [stepPtr, stepMode, stepSaveOn, stepLogs, stepList, terminalRef]);

  /**
   * Keydown handler:
   *  - If the user types while the shell is focused (not the input),
   *    automatically focus the console input.
   */
  useEffect(() => {
    const handleKeyDown = (e) => {
      // If user is pressing typical shortcut keys (meta/ctrl), let them copy/paste/etc.
      if (e.metaKey || e.ctrlKey) return;

      // If not in step mode and the console input isn't focused, focus it.
      if (!stepMode && document.activeElement !== consoleInputRef.current) {
        consoleInputRef.current.focus();
      }
    };

    const shellElement = shellRef.current;
    if (shellElement) {
      // We add the event listener on the shell so we capture keydown events
      shellElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (shellElement) {
        shellElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [stepMode]);

  /**
   * MouseUp handler:
   *  - If no text is selected (i.e., user just clicked rather than dragged a selection),
   *    focus the console input.
   */
  const handleShellMouseUp = () => {
    if (!stepMode && consoleInputRef.current) {
      const selectedText = window.getSelection().toString();
      // Only focus if nothing was selected
      if (!selectedText) {
        consoleInputRef.current.focus();
      }
    }
  };

  /**
   * Rendering logic:
   *  - For mobile layouts, we use `MobileTerminalShell`.
   *  - For tablet/desktop, use `TerminalShell`.
   *  - We attach the same event handling (mouse up for selection logic, keydown for auto‐focus).
   *  - `FlexConsoleInput` is only rendered if NOT in step mode.
   */
  if (!isTabletOrLarger) {
    return (
      <MobileTerminalShell
        ref={shellRef}
        onMouseUp={handleShellMouseUp}
        tabIndex={0} // Allows the shell to receive keydown events
      >
        <TerminalToolbar {...props} />
        <TerminalPre ref={terminalPreRef} id="terminal-pre" aria-label="Terminal Content">
          <span ref={terminalContentRef} id="terminal"></span>
          {!stepMode && (
            <FlexConsoleInput
              consoleInputRef={consoleInputRef}
              terminalPreRef={terminalPreRef}
            />
          )}
        </TerminalPre>
      </MobileTerminalShell>
    );
  }

  return (
    <TerminalShell
      ref={shellRef}
      onMouseUp={handleShellMouseUp}
      tabIndex={0} // Allows the shell to receive keydown events
    >
      <TerminalToolbar {...props} />
      <TerminalPre ref={terminalPreRef} id="terminal-pre" aria-label="Terminal Content">
        <span ref={terminalContentRef} id="terminal"></span>
        {!stepMode && (
          <FlexConsoleInput
            consoleInputRef={consoleInputRef}
            terminalPreRef={terminalPreRef}
          />
        )}
      </TerminalPre>
    </TerminalShell>
  );
};