import React, {useEffect, useState} from 'react';
import {Loading} from './Loading';

// This component repeatedly mounts and unmounts Loading at intervals
export const LoadingTest = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    const intervals = [
      {duration: 500},
      {duration: 500},
      {duration: 500},
      {duration: 500},
      {duration: 500},
      {duration: 500},
    ];

    let currentStep = 0;

    const runInterval = () => {
      if (currentStep >= intervals.length) return;

      const {duration} = intervals[currentStep];

      setTimeout(() => {
        setShowLoading(true);
        setStep(currentStep + 1);

        setTimeout(() => {
          setShowLoading(false);
          currentStep += 1;
          runInterval(); // Proceed to the next interval
        }, duration);
      }, 0);
    };

    runInterval();
  }, []);

  return <Loading key={step} />;
};
