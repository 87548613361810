/**
 * =============================================================================
 * IMPORTANT, READ THIS MESSAGE BEFORE EDITING THIS FILE.
 *
 * This file needs to be kept tightly in sync with its corresponding version in
 * the backend. If you are changing this file, please also make the
 * corresponding changes in:
 * `cip-backend:functions/src/projects/types.ts` and submit both PRs
 * simultaneously.
 *
 * Be careful because import paths may be different. Don't forget to double
 * check that you update the comments in `cip-backend` as well.
 * =============================================================================
 */
/** ============== COPY EVERYTHING STARTING HERE TO cip-backend ============= */
/**
 * The metadata for a file in a project. This allows the project to look up the
 * file by its project file ID and path within the project file system.
 */
export interface ProjectFileData {
  /** The path of the file within the project file system. */
  name: string;
  /** The project file ID of the file. */
  id: ProjectFileId;
}

/**
 * A map of the project file ID to the file's contents.
 */
export interface ProjectFilesCode {
  /** The project file ID of the file. */
  [projectFileId: ProjectFileId]: {
    /** The contents of the file. */
    content: string;
  };
}

export const PROJECT_FOLDER_TYPE = 'folder';
export interface ProjectFolder {
  /** The name of the folder. */
  name: string;
  /** The type of the folder. */
  type: typeof PROJECT_FOLDER_TYPE;
  /** The files in the folder. */
  files: ProjectFileStructure;
}

export const PROJECT_FILE_TYPE = 'file';
export interface ProjectFile {
  /** The name of the file. */
  name: string;
  /** The project file ID of the file. */
  id: ProjectFileId;
  /** The type of the file. */
  type: typeof PROJECT_FILE_TYPE;
  /** The format of the file (e.g. "doc", "image"). */
  format?: string;
  /** Whether this file was generated by the starter code of the assignment. */
  starterFile?: boolean;
  /** The URL of the file (for images). */
  url?: string;
}

/**
 * The file structure of a user project. Typically represented as an array of
 * one element, the root folder of the project, which in turn contains the
 * files and folders of the project.
 */
export type ProjectFileStructure = (ProjectFolder | ProjectFile)[];

/**
 * The ID of a file in the project file system.
 */
export type ProjectFileId = string;
/** The ID of a Project (a unique set of files owned by a user). */
export type ProjectId = string;

// Typescript boilerplate to verify types at runtime
/**
 * Checks if a given data structure is a valid project file.
 * @param data The data structure to check.
 * @returns True if the data structure is a valid project file, false otherwise.
 */
export function isProjectFile(data: unknown): data is ProjectFile {
  if (!data || typeof data !== 'object') {
    return false;
  }
  const projectFile = data as ProjectFile;
  return (
    typeof projectFile.id === 'string' &&
    typeof projectFile.name === 'string' &&
    projectFile.type === PROJECT_FILE_TYPE &&
    (!projectFile.format || typeof projectFile.format === 'string') &&
    (!projectFile.starterFile ||
      typeof projectFile.starterFile === 'boolean') &&
    (!projectFile.url || typeof projectFile.url === 'string')
  );
}

/**
 * Checks if a given data structure is a valid project folder.
 * @param data The data structure to check.
 * @returns True if the data structure is a valid project folder, false otherwise.
 */
export function isProjectFolder(data: unknown): data is ProjectFolder {
  if (!data || typeof data !== 'object') {
    return false;
  }
  const projectFolder = data as ProjectFolder;
  return (
    typeof projectFolder.name === 'string' &&
    projectFolder.type === PROJECT_FOLDER_TYPE &&
    Array.isArray(projectFolder.files) &&
    projectFolder.files.every(entry => {
      if (entry.type === PROJECT_FILE_TYPE) {
        return isProjectFile(entry);
      } else if (entry.type === PROJECT_FOLDER_TYPE) {
        return isProjectFolder(entry);
      }
      return false;
    })
  );
}

/**
 * Checks if a given data structure is a valid project file structure. A
 * project file structure is a list of project files and folders.
 * @param data The data structure to check.
 * @returns True if the data structure is a valid project file structure, false otherwise.
 */
export function isProjectFileStructure(
  data: unknown,
): data is ProjectFileStructure {
  if (!data || !Array.isArray(data)) {
    return false;
  }
  return data.every(entry => {
    if (entry.type === PROJECT_FILE_TYPE) {
      return isProjectFile(entry);
    } else if (entry.type === PROJECT_FOLDER_TYPE) {
      return isProjectFolder(entry);
    }
    return false;
  });
}
