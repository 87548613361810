export const transformImportsExportsPlugin = () => ({
  visitor: {
    ImportDeclaration(path) {
      // Remove import declarations as they are handled separately
      path.remove();
    },
    ExportNamedDeclaration(path) {
      const declaration = path.node.declaration;
      if (declaration) {
        // Replace 'export const X = ...' with 'const X = ...'
        path.replaceWith(declaration);
      } else {
        // Remove export declarations without a declaration
        path.remove();
      }
    },
    ExportDefaultDeclaration(path) {
      const declaration = path.node.declaration;
      if (declaration.type === 'Identifier') {
        // Replace 'export default InteractiveDemo;' with 'InteractiveDemo;'
        path.replaceWith(
          React.Babel.types.expressionStatement(
            React.Babel.types.assignmentExpression(
              '=',
              React.Babel.types.identifier('__default__'),
              React.Babel.types.identifier(declaration.name),
            ),
          ),
        );
      } else {
        // Replace 'export default () => { ... };' with 'const __default__ = () => { ... };'
        path.replaceWith(
          React.Babel.types.variableDeclaration('const', [
            React.Babel.types.variableDeclarator(
              React.Babel.types.identifier('__default__'),
              declaration,
            ),
          ]),
        );
      }
    },
  },
});
