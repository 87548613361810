/**
 * IMPORTANT, READ THIS BEFORE EDITING THIS FILE:
 *
 * Make sure you update `cip-backend:functions/src/applications/isUserApplicationAutoAcceptEligible.ts`
 * if you make any changes here.
 */

import {useContext, useEffect, useState} from 'react';
import {ProfileContext} from 'contexts/ProfileContext';
import type {CourseId} from 'types/common';
import {useCipHttpsCallable} from '../../../firebase/functions/use_cip_https_callable';
import {useParams} from 'react-router-dom';

////////////////////////////START COPYING HERE//////////////////////////////////
/**
 * The request for the is application auto accepted function.
 */
interface IsUserApplicationAutoAcceptEligibleRequest {
  /**
   * The email of the user.
   */
  userEmail: string;
  /**
   * The ID of the course whose auto accept list we should check.
   */
  courseId: CourseId;
  /**
   * The type of application (e.g. 'student', 'sl')
   */
  applicationType: string;
}

type IsUserApplicationAutoAcceptEligibleResponse = Promise<boolean>;
///////////////////////////////END COPYING HERE/////////////////////////////////

interface UseIsUserApplicationAutoAcceptEligibleResponse {
  isUserApplicationAutoAcceptEligible: boolean;
  isLoading: boolean;
  error: Error | null;
}

export function useIsSectionLeaderApplicationAutoAcceptEligible(): UseIsUserApplicationAutoAcceptEligibleResponse {
  const {targetCourseId: courseId} = useParams();
  const userEmail = useContext(ProfileContext)?.userData?.email;
  const [
    isUserApplicationAutoAcceptEligibleFunction,
    isFunctionLoading,
    isFunctionError,
  ] = useCipHttpsCallable<
    IsUserApplicationAutoAcceptEligibleRequest,
    IsUserApplicationAutoAcceptEligibleResponse
  >('isuserapplicationautoaccepteligible');
  const [
    isUserApplicationAutoAcceptEligible,
    setIsUserApplicationAutoAcceptEligible,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userEmail || !courseId) {
      return;
    }
    setIsLoading(true);
    isUserApplicationAutoAcceptEligibleFunction({
      userEmail,
      courseId,
      applicationType: 'sl',
    })
      .then(async result => {
        const data = await result.data;
        setIsUserApplicationAutoAcceptEligible(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  }, [userEmail, courseId]);
  return {
    isUserApplicationAutoAcceptEligible,
    isLoading: isFunctionLoading || isLoading,
    error: isFunctionError ?? error,
  };
}
