/**
 * @fileoverview Contains cards which are AdminRichTextEditors linked to expectation
 * firebase doc paths for each of the various roles. These expectations essentially
 * act as a syllabus for the roles as they have individual responsibilities.
 */

import {roleToFirebaseString} from 'contexts/profileUtil';
import {Role} from 'types/role';
import {AdminRichTextEditor} from 'course/components/AdminRichTextEditor';
import {DiscussionButtonBar} from 'components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar';

/**
 * JSX element containing the syllabus/expectation information for Head TAs
 *
 * @param {string} courseId - courseId used to pull the relevant firebase expectations doc.
 * @returns {JSX.Element} AdminRichTextEditor JSX elem containing Head TA expectations.
 */
export const AdminCard = ({courseId}) => {
  const taExpectationsFirebasePath = `course/${courseId}/docs/expectations/role/${roleToFirebaseString(Role.TA)}`;

  return (
    <AdminRichTextEditor
      firebaseDocPath={taExpectationsFirebasePath}
      buttonBar={DiscussionButtonBar}
    />
  );
};

/**
 * JSX element containing the syllabus/expectation information for SLs.
 *
 * @param {string} courseId - courseId used to pull the relevant firebase expectations doc.
 * @returns {JSX.Element} AdminRichTextEditor JSX elem containing an SL's CIP expectations
 */
export const SectionLeaderCard = ({courseId}) => {
  const slExpectationsFirebasePath = `course/${courseId}/docs/expectations/role/${roleToFirebaseString(Role.SECTION_LEADER)}`;

  return (
    <AdminRichTextEditor
      firebaseDocPath={slExpectationsFirebasePath}
      buttonBar={DiscussionButtonBar}
    />
  );
};

/**
 * JSX element containing the syllabus/expectation information for 1-on-1 mentors.
 *
 * @param {string} courseId - courseId used to pull the relevant firebase expectations doc.
 * @returns {JSX.Element} AdminRichTextEditor JSX elem containing an SL Mentor's CIP expectations.
 *
 * @note SL Mentors do not teach a weekly section and instead focus on TeachNows.
 *       They can cover sections for absent SLs, though.
 */
export const MentorCard = ({courseId}) => {
  const mentorExpectationsFirebasePath = `course/${courseId}/docs/expectations/role/${roleToFirebaseString(Role.MENTOR)}`;

  return (
    <AdminRichTextEditor
      firebaseDocPath={mentorExpectationsFirebasePath}
      buttonBar={DiscussionButtonBar}
    />
  );
};

/**
 * JSX element containing the syllabus/expectation information for experienced students.
 *
 * @param {string} courseId - courseId used to pull the relevant firebase expectations doc.
 * @returns {JSX.Element} AdminRichTextEditor JSX elem containing an experienced student's CIP expectations.
 *
 * @note experienced students don't have to attend a weekly section since they have been
 *       deemed too skilled in programming background/knowledge. This can disrupt
 *       learning experiences for true beginners.
 */
export const ExperiencedStudentCard = ({courseId}) => {
  const expStudentExpectationsFirebasePath = `course/${courseId}/docs/expectations/role/${roleToFirebaseString(Role.EXPERIENCED_STUDENT)}`;

  return (
    <AdminRichTextEditor
      firebaseDocPath={expStudentExpectationsFirebasePath}
      buttonBar={DiscussionButtonBar}
    />
  );
};

/**
 * JSX element containing the syllabus/expectation information for students.
 *
 * @param {string} courseId - courseId used to pull the relevant firebase expectations doc.
 * @returns {JSX.Element} AdminRichTextEditor JSX elem containing a student's CIP expectations
 */
export const StudentCard = ({courseId}) => {
  const studentExpectationsFirebasePath = `course/${courseId}/docs/expectations/role/${roleToFirebaseString(Role.STUDENT)}`;

  return (
    <AdminRichTextEditor
      firebaseDocPath={studentExpectationsFirebasePath}
      buttonBar={DiscussionButtonBar}
    />
  );
};
