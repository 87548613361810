// SortableApplication.js
import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {FaGripVertical, FaTrash} from 'react-icons/fa';

const SortableApplication = ({
  id,
  applicationKey,
  title,
  onSetTitle,
  onSetKey,
  onDelete,
}) => {
  // Initialize sortable behavior with the unique id
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({
      id: id, // Unique identifier from keyToIdRef.current[key]
    });

  // Define the transformation and transition styles
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="d-flex align-items-center mb-2"
    >
      {/* Drag Handle */}
      <div
        {...attributes}
        {...listeners}
        className="me-2"
        style={{cursor: 'grab'}}
      >
        <FaGripVertical />
      </div>

      {/* Application Key Input */}
      <input
        type="text"
        className="form-control me-2"
        value={applicationKey}
        onChange={e => onSetKey(applicationKey, e.target.value)}
        placeholder="Key"
      />

      {/* Application Title Input */}
      <input
        type="text"
        className="form-control me-2"
        value={title}
        onChange={e => onSetTitle(applicationKey, e.target.value)}
        placeholder="Title"
      />

      {/* Delete Button */}
      <button
        className="btn btn-danger btn-sm"
        onClick={onDelete}
        aria-label="Delete Application"
      >
        <FaTrash />
      </button>
    </div>
  );
};

export default SortableApplication;
