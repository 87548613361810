export const TEACHER_STATUSES = {
    NO_STUDENTS: "no-students",
    NUDGE: "nudge",
    ACCEPTED: "accepted",
    MATCHED: "matched",
    EXITED: "exited",
    REJECTED: "rejected",
    JOINED: "joined",
    TEST_NUDGE: "test-nudge"
}

export const STUDENT_STATUSES = {
    NUDGE: "nudge",
    ACCEPTED: "accepted",
    MATCHED: "matched",
    UNNUDGE: "unnudge",
    EXITED: "exited",
    REJECTED: "rejected",
    TIMEOUT: "timeout",
}
