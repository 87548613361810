import {Card} from 'react-bootstrap';

/**
 * A component that displays a message indicating that the section leading training is not open yet.
 */
export const SectionLeadingNotOpen = ({}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card>
        <Card.Body>
          <h4>
            Section Leader Training starts on April 20th, 9am Pacific Time
          </h4>
        </Card.Body>
      </Card>
      <div style={{height: '5px'}} />
    </div>
  );
};

/**
 * A component that displays a message indicating that the stories are not open yet.
 */
export const StoriesNotOpen = ({}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card>
        <Card.Body>
          <h4>Stories are coming soon!</h4>
        </Card.Body>
      </Card>
      <div style={{height: '5px'}} />
    </div>
  );
};
