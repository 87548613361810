import {getAuth, signOut} from 'firebase/auth';
import {BlockedKarelWithText} from 'course/aboutcourse/BlockedKarel';
import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';
import {useIsMobile} from 'utils/general';
import styled from 'styled-components';
import {Stack} from 'react-bootstrap';
import React from 'react';

const DESKTOP_KAREL_SCALE = 2.5;
const MOBILE_KAREL_SCALE = 1.6;
const DESKTOP_MESSAGE_SCALE_REM = 1.8;
const MOBILE_MESSAGE_SCALE_REM = 1.4;

interface ErrorPageProps {
  /**
   * The message to display on the error page.
   */
  msg?: string | React.ReactNode;
  /**
   * The title of the error page.
   */
  title?: string | React.ReactNode;
}

const ErrorPageMessage = styled.p<{isMobile: boolean}>`
  font-size: ${props =>
    props.isMobile
      ? `${MOBILE_MESSAGE_SCALE_REM}rem`
      : `${DESKTOP_MESSAGE_SCALE_REM}rem`};
`;

/**
 * A component that displays an error page.
 */
export const ErrorPage = ({title, msg}: ErrorPageProps) => {
  console.error('Error encountered:', msg);
  console.trace();

  const navigate = useHistoryNavigate();
  const isMobile = useIsMobile();

  const displayedMessage =
    typeof msg === 'string' ? (
      <ErrorPageMessage isMobile={isMobile}>{msg}</ErrorPageMessage>
    ) : (
      msg ?? 'An unknown error occurred'
    );

  return (
    <Stack gap={2} className="align-items-center justify-content-center vh-100">
      {/* Title / Heading */}
      {title && <h1>{title}</h1>}

      {/* Karel Graphic */}
      <div className="d-flex align-items-center">
        <BlockedKarelWithText
          scale={isMobile ? MOBILE_KAREL_SCALE : DESKTOP_KAREL_SCALE}
        />
      </div>

      {/* Message */}
      <div className="d-flex text-center">{displayedMessage}</div>

      {/* Actions */}
      <div className="d-flex align-items-center">
        <button
          onClick={() => navigate(-1)}
          className="btn btn-primary mt-3 me-2"
        >
          Go Back
        </button>
        <button
          onClick={() => signOut(getAuth())}
          className="btn btn-secondary mt-3"
        >
          Sign Out
        </button>
      </div>
    </Stack>
  );
};
