// InteractiveDemo.jsx

import * as React from 'react';
// import { BarChart } from './Charts/BarChart';
// import { LineChart } from './Charts/LineChart';
import styled from 'styled-components';
// import jStat from 'jstat';
// import gsap from 'gsap';

// import { InlineMath as OriginalInlineMath, BlockMath as OriginalBlockMath } from 'react-katex';
import {downloadFile, useFile, useImageUrl} from './useFile';
import {getStorage, ref, getDownloadURL} from 'firebase/storage';
// import { BetaChart } from './Charts/BetaChart';
import * as FontAwesome from 'react-icons/fa';
import * as MathJs from 'mathjs';
// import * as Three from 'three';
// import { macros } from '../../latex/macros';
// import { KatexBlock } from 'richText/latex/KatexBlock';
// import { KatexInline } from 'richText/latex/KatexInline';
import {useWindowSize} from 'react-use-size';
// import { useHandoutSize } from 'hooks/useWindow';

// export const InlineMath = ({ math, children }) => {
//   const latex = math || children;
//   if (typeof latex !== "string") {
//     console.error("InlineMath received a non-string expression:", latex);
//     return null;
//   }

//   return <KatexInline content={latex} isEditable={false}/>;
// };

// const BlockMath = ({ math, children, textAlign='center'}) => {
//   const latex = math || children;
//   return <KatexBlock content={latex} isEditable={false} textAlign={textAlign}/>;
// };

// Map of available modules for import in the user code
export const availableModules = {
  react: React, // Map 'react' to the React object
  // charts: {
  //   BarChart,
  //   LineChart,
  //   BetaChart
  // },
  files: {
    useFile,
    useImageUrl,
    downloadFile,
  },
  // gsap:gsap,
  // 'useWindow':{useWindowSize, useHandoutSize},
  // 'mathjs': MathJs,
  'react-icons/fa': FontAwesome,
  'firebase/storage': {getStorage, ref, getDownloadURL},
  'styled-components': styled,
  // jstat: jStat,
  // three: Three,
  // 'react-katex': { InlineMath, BlockMath }
};

// TODO add svg-js
