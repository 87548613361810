// SectionEditor.js
import React, {useRef, useEffect, useState} from 'react';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableChapter from './SortableChapter';
import SortableApplication from './SortableApplication';
import {v4 as uuidv4} from 'uuid';

const SectionEditor = ({
  section,
  titles,
  onUpdate,
  onTitlesUpdate,
  canMoveUp,
  canMoveDown,
  onMoveUp,
  onMoveDown,
}) => {
  // Single ref to store key-to-ID mappings for both chapters and examples
  const keyToIdRef = useRef({});

  // State to manage error messages
  const [errorMessage, setErrorMessage] = useState('');

  // Initialize key-to-ID mappings and titles for examples and sections on component mount or when section changes
  useEffect(() => {
    let needsUpdate = false;
    const updatedTitles = {...titles};

    // Initialize mappings for sections (chapters)
    section.sections.forEach(chapterKey => {
      if (!keyToIdRef.current[chapterKey]) {
        keyToIdRef.current[chapterKey] = uuidv4();
      }
    });

    // Initialize mappings for examples (applications)
    section.examples.forEach(exampleKey => {
      if (!keyToIdRef.current[exampleKey]) {
        keyToIdRef.current[exampleKey] = uuidv4();
      }
    });

    // Initialize titles for examples if missing
    section.examples.forEach(exampleKey => {
      if (!updatedTitles[exampleKey]) {
        updatedTitles[exampleKey] = 'New Application'; // Default title
        needsUpdate = true;
      }
    });

    // Initialize titles for sections if missing (optional)
    section.sections.forEach(chapterKey => {
      if (!updatedTitles[chapterKey]) {
        updatedTitles[chapterKey] = 'New Chapter'; // Default title
        needsUpdate = true;
      }
    });

    if (needsUpdate) {
      onTitlesUpdate(updatedTitles);
    }
  }, [section.sections, section.examples, titles, onTitlesUpdate]);

  // Initialize DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {delay: 150, tolerance: 5},
    }),
  );

  // Handler for section title change
  const handleSectionTitleChange = e => {
    onUpdate({...section, title: e.target.value});
  };

  // Handler for section key change
  const handleSectionKeyChange = e => {
    onUpdate({...section, key: e.target.value});
  };

  // Handler for setting titles
  const onSetTitle = (key, title) => {
    onTitlesUpdate({...titles, [key]: title});
  };

  // Handler for setting chapter (section) keys
  const onSetChapterKey = (oldKey, newKey) => {
    // Trim whitespace and convert to lowercase
    const sanitizedKey = newKey.trim().toLowerCase().replace(/\s+/g, '-');

    if (!isValidKey(sanitizedKey)) {
      setErrorMessage(
        'Invalid key format. Only lowercase letters, numbers, hyphens, and underscores are allowed.',
      );
      return;
    }

    if (sanitizedKey !== oldKey && titles[sanitizedKey]) {
      setErrorMessage('A chapter or example with this key already exists!');
      return;
    }

    // Clear any existing error messages
    setErrorMessage('');

    // Proceed only if the key is actually changing
    if (sanitizedKey === oldKey) return;

    // Update the key-to-ID mapping
    const id = keyToIdRef.current[oldKey];
    delete keyToIdRef.current[oldKey];
    keyToIdRef.current[sanitizedKey] = id;

    // Update titles mapping
    const updatedTitles = {...titles};
    updatedTitles[sanitizedKey] = updatedTitles[oldKey];
    delete updatedTitles[oldKey];

    // Update sections list with new key
    const updatedSections = section.sections.map(key =>
      key === oldKey ? sanitizedKey : key,
    );

    onTitlesUpdate(updatedTitles);
    onUpdate({...section, sections: updatedSections});
  };

  // Handler for setting example (application) keys
  const onSetExampleKey = (oldKey, newKey) => {
    // Trim whitespace and convert to lowercase
    const sanitizedKey = newKey.trim().toLowerCase().replace(/\s+/g, '-');

    if (!isValidKey(sanitizedKey)) {
      setErrorMessage(
        'Invalid key format. Only lowercase letters, numbers, hyphens, and underscores are allowed.',
      );
      return;
    }

    if (sanitizedKey !== oldKey && titles[sanitizedKey]) {
      setErrorMessage('A chapter or example with this key already exists!');
      return;
    }

    // Clear any existing error messages
    setErrorMessage('');

    // Proceed only if the key is actually changing
    if (sanitizedKey === oldKey) return;

    // Update the key-to-ID mapping
    const id = keyToIdRef.current[oldKey];
    delete keyToIdRef.current[oldKey];
    keyToIdRef.current[sanitizedKey] = id;

    // Update titles mapping
    const updatedTitles = {...titles};
    updatedTitles[sanitizedKey] = updatedTitles[oldKey];
    delete updatedTitles[oldKey];

    // Update examples list with new key
    const updatedExamples = section.examples.map(key =>
      key === oldKey ? sanitizedKey : key,
    );

    onTitlesUpdate(updatedTitles);
    onUpdate({...section, examples: updatedExamples});
  };

  // Handle drag end for both sections (chapters) and examples (applications)
  const handleDragEnd = (event, type) => {
    const {active, over} = event;

    if (!over) return; // Dropped outside any sortable context

    if (active.id !== over.id) {
      if (type === 'sections') {
        const oldIndex = section.sections.findIndex(
          key => keyToIdRef.current[key] === active.id,
        );
        const newIndex = section.sections.findIndex(
          key => keyToIdRef.current[key] === over.id,
        );
        const reordered = arrayMove(section.sections, oldIndex, newIndex);
        onUpdate({...section, sections: reordered});
      } else if (type === 'examples') {
        const oldIndex = section.examples.findIndex(
          key => keyToIdRef.current[key] === active.id,
        );
        const newIndex = section.examples.findIndex(
          key => keyToIdRef.current[key] === over.id,
        );
        const reordered = arrayMove(section.examples, oldIndex, newIndex);
        onUpdate({...section, examples: reordered});
      }
    }
  };

  // Add new section (chapter) or example (application)
  const addNewItem = type => {
    const timestamp = Date.now();
    const newKey = `new_${type}_${timestamp}`;
    const defaultTitle =
      type === 'sections' ? 'New Chapter' : 'New Application';
    const newId = uuidv4();

    if (type === 'sections') {
      keyToIdRef.current[newKey] = newId;
      onUpdate({...section, sections: [...section.sections, newKey]});
    } else if (type === 'examples') {
      keyToIdRef.current[newKey] = newId;
      onUpdate({...section, examples: [...section.examples, newKey]});
    }

    onTitlesUpdate({...titles, [newKey]: defaultTitle});
  };

  // Delete a section (chapter)
  const handleDeleteChapter = chapterKey => {
    // Remove mapping
    delete keyToIdRef.current[chapterKey];

    // Remove chapter from sections
    onUpdate({
      ...section,
      sections: section.sections.filter(key => key !== chapterKey),
    });

    // Remove title
    const updatedTitles = {...titles};
    delete updatedTitles[chapterKey];
    onTitlesUpdate(updatedTitles);
  };

  // Delete an example (application)
  const handleDeleteExample = exampleKey => {
    // Remove mapping
    delete keyToIdRef.current[exampleKey];

    // Remove example from examples
    onUpdate({
      ...section,
      examples: section.examples.filter(key => key !== exampleKey),
    });

    // Remove title
    const updatedTitles = {...titles};
    delete updatedTitles[exampleKey];
    onTitlesUpdate(updatedTitles);
  };

  return (
    <div className="mb-4 p-3 border rounded">
      {/* Error Message */}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}

      {/* Example: Up / Down buttons at the top */}
      <div className="d-flex justify-content-end mb-2">
        <button
          className="btn btn-sm btn-outline-secondary me-1"
          onClick={onMoveUp}
          disabled={false}
        >
          ↑
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={onMoveDown}
          disabled={!canMoveDown}
        >
          ↓
        </button>
      </div>

      {/* Section Title */}
      <div className="mb-2">
        <label className="form-label">Section Title:</label>
        <input
          type="text"
          className="form-control"
          value={section.title}
          onChange={handleSectionTitleChange}
        />
      </div>

      {/* Section Key */}
      <div className="mb-2">
        <label className="form-label">Section Key:</label>
        <input
          type="text"
          className="form-control"
          value={section.key}
          onChange={handleSectionKeyChange}
          placeholder="Enter section key (no spaces, URL-friendly)"
        />
      </div>

      {/* Chapters */}
      <div className="mb-2">
        <label className="form-label">Chapters:</label>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={event => handleDragEnd(event, 'sections')}
        >
          <SortableContext
            items={section.sections.map(key => keyToIdRef.current[key])}
            strategy={verticalListSortingStrategy}
          >
            {section.sections.map(chapterKey => (
              <SortableChapter
                key={keyToIdRef.current[chapterKey]} // React key
                id={keyToIdRef.current[chapterKey]} // @dnd-kit id
                chapterKey={chapterKey} // Mutable key
                title={titles[chapterKey]} // Chapter title
                onSetTitle={title => onSetTitle(chapterKey, title)}
                onSetKey={newKey => onSetChapterKey(chapterKey, newKey)}
                onDelete={() => handleDeleteChapter(chapterKey)}
              />
            ))}
          </SortableContext>
        </DndContext>
        <button
          className="btn btn-secondary btn-sm mt-2"
          onClick={() => addNewItem('sections')}
        >
          Add Chapter
        </button>
      </div>

      {/* Examples (Applications) */}
      <div className="mb-2">
        <label className="form-label">Stories:</label>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={event => handleDragEnd(event, 'examples')}
        >
          <SortableContext
            items={section.examples.map(key => keyToIdRef.current[key])}
            strategy={verticalListSortingStrategy}
          >
            {section.examples.map(exampleKey => (
              <SortableApplication
                key={keyToIdRef.current[exampleKey]} // React key
                id={keyToIdRef.current[exampleKey]} // @dnd-kit id
                applicationKey={exampleKey} // Mutable key
                title={titles[exampleKey]} // Application title
                onSetTitle={(key, newTitle) => onSetTitle(key, newTitle)}
                onSetKey={(key, newKey) => onSetExampleKey(key, newKey)}
                onDelete={() => handleDeleteExample(exampleKey)}
              />
            ))}
          </SortableContext>
        </DndContext>
        <button
          className="btn btn-secondary btn-sm mt-2"
          onClick={() => addNewItem('examples')}
        >
          Add Application
        </button>
      </div>
    </div>
  );
};

// utils/validateKey.js
const isValidKey = key => {
  // Regular expression to allow only lowercase letters, numbers, hyphens, and underscores
  const regex = /^[a-z0-9_-]+$/;
  return regex.test(key);
};

export default SectionEditor;
