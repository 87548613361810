import {useState, useEffect, useContext} from 'react';
import {doc, getDoc, setDoc, getFirestore} from 'firebase/firestore';
import SectionEditor from './SectionEditor';

import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  ChapterTitleInner,
  ChapterTitleStyle,
  ChapterTitleWrapper,
} from 'textbook/layout/BookLayout';
import {BookContext} from 'textbook/context/BookContext';

export const EditBookOutline = () => {
  return <EditBookOutlineInner />;
};

const EditBookOutlineInner = () => {
  const {bookFirebaseRoot} = useContext(BookContext);
  const [outline, setOutline] = useState([]);
  const [titles, setTitles] = useState({});
  const [loading, setLoading] = useState(true);

  console.log(titles);

  // Inside EditBookOutlineInner

  const moveSectionUp = index => {
    // If it’s already at the top, do nothing
    if (index === 0) return;

    const newOutline = [...outline];
    // Swap the section with the one above it
    [newOutline[index - 1], newOutline[index]] = [
      newOutline[index],
      newOutline[index - 1],
    ];
    setOutline(newOutline);
  };

  const moveSectionDown = index => {
    // If it’s already at the bottom, do nothing
    if (index === outline.length - 1) return;

    const newOutline = [...outline];
    // Swap the section with the one below it
    [newOutline[index + 1], newOutline[index]] = [
      newOutline[index],
      newOutline[index + 1],
    ];
    setOutline(newOutline);
  };

  // Fetch data from Firebase
  useEffect(() => {
    const fetchOutline = async () => {
      try {
        const db = getFirestore();
        const outlineDoc = doc(db, `${bookFirebaseRoot}/outline`);
        const docSnapshot = await getDoc(outlineDoc);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setOutline(data.outline || []);
          setTitles(data.titles || {});
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOutline();
  }, []);

  // Save the updated outline to Firebase
  const saveOutline = async () => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save the current changes to the outline?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const db = getFirestore();
        const outlineDoc = doc(db, `${bookFirebaseRoot}/outline`);
        await setDoc(outlineDoc, {outline, titles});
        Swal.fire({
          icon: 'success',
          title: 'Outline saved successfully.',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error('Error saving document:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to save outline.',
          text: 'Please try again.',
        });
      }
    }
  };

  // Update a section
  const updateSection = (index, updatedSection) => {
    const updatedOutline = [...outline];
    updatedOutline[index] = updatedSection;
    setOutline(updatedOutline);
  };

  // Add a new section
  const addSection = () => {
    setOutline(prev => [
      ...prev,
      {
        key: `new_section_${Date.now()}`,
        title: 'New Section',
        sections: [],
        examples: [],
      },
    ]);
  };

  return (
    <Content>
      <ChapterTitleInner>
        <ChapterTitleWrapper>
          <ChapterTitleStyle>Edit Book Outline</ChapterTitleStyle>
          <hr />
        </ChapterTitleWrapper>
      </ChapterTitleInner>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {outline.map((section, index) => (
            <SectionEditor
              key={section.key}
              section={addDefaultSectionAttributes(section)}
              index={index}
              titles={titles}
              onUpdate={updatedSection => updateSection(index, updatedSection)}
              onTitlesUpdate={updatedTitles => setTitles(updatedTitles)}
              canMoveUp={index > 0}
              canMoveDown={index < outline.length - 1}
              onMoveUp={() => moveSectionUp(index)}
              onMoveDown={() => moveSectionDown(index)}
            />
          ))}
          <ButtonsOuter>
            <button className="btn btn-primary mt-3" onClick={addSection}>
              Add Section
            </button>
            <button className="btn btn-success mt-3 ms-3" onClick={saveOutline}>
              Save Outline
            </button>
          </ButtonsOuter>
        </>
      )}
    </Content>
  );
};

const addDefaultSectionAttributes = section => {
  return {
    ...section,
    sections: section.sections || [],
    examples: section.examples || [],
  };
};

const ButtonsOuter = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 50px;
`;

const Content = styled.div`
  background-color: white;
`;
