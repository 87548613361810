import {functions} from '../../../firebaseApp';
import {httpsCallable} from 'firebase/functions';
import type {
  NotOpenAiByProjectIdRequest,
  NotOpenAiByProjectIdResponse,
} from './api';

const notOpenAiByProjectId = httpsCallable<
  NotOpenAiByProjectIdRequest,
  NotOpenAiByProjectIdResponse
>(functions, 'notopenaibyprojectid');

export class NotOpenAiJsClient {
  response: string | null = null;
  error: Error | null = null;
  /**
   * Creates a request to the NotOpenAI API and sets the response in the
   * global \`jsNotOpenAi.response\` variable.
   *
   * @param request The request to send to the NotOpenAI API.
   * @returns A promise that resolves when the request has completed.
   */
  create(request: NotOpenAiByProjectIdRequest): Promise<void> {
    return notOpenAiByProjectId(request)
      .then(result => {
        this.response = result.data;
        this.error = null;
      })
      .catch(error => {
        this.response = null;
        this.error = error;
      });
  }
}
